export function EstatusCredito({pasos = [1]}){

  return(
    <div id="estatusSolicitudCredito">
      {pasos.includes(1) ? (
        <>
          <p class="active" >
            1. Inicio
          </p>
        </>
      ) : (
        <p title="Descargar Solicitud">
          1
        </p>
      )}

      {pasos.includes(2) ? (
        <p class="active" >
          2. Documentación cliente
        </p>
      ) : (
        <p title="Aceptar Solicitud">
          2
        </p>
      )}

      {pasos.includes(3) ? (
        <p class="active" >
          3. Respuesta de banco
        </p>
      ) : (
        <p title="Vivienda">
          3
        </p>
      )}

      {pasos.includes(4) ? (
        <p class="active" >
          4. Evaluación vivienda
        </p>
      ) : (
        <p title="Avalúo">
          4
        </p>
      )}

      {pasos.includes(5) ? (
        <p class="active" >
          5. Proceso de escrituración
        </p>
      ) : (
        <p title="Notaría">
          5
        </p>
      )}

      {pasos.includes(6) ? (
        <p class="active" >
          6. Finalizados
        </p>
      ) : (
        <p title="Formalizaci&oacute;n y Firma">
          6
        </p>
      )}
    </div>
  )
  
}

export function EstatusCreditoUnico({paso = 0}){

  const pasos = [
    {id: 1, titulo: "Inicio"},
    {id: 2, titulo: "Documentación cliente"},
    {id: 3, titulo: "Respuesta banco"},
    {id: 4, titulo: "Evaluación vivienda"},
    {id: 5, titulo: "Proceso escrituración"},
    {id: 6, titulo: "Finalizados"}
  ]

  return(
    <>
      <div id="estatusSolicitudCredito" className="contenido_centrado">
        {
          pasos.map((_paso) =>
            <p className={_paso.id === paso ? "bg-active" : ""}>
              {_paso.id}.
            </p>
          )
        }
      </div>
      <div className="flex_centrado fuente-secundaria">
        {pasos.find(_paso => _paso.id === paso)?.titulo}
      </div>
    </>
  )
  
}