import React, { useState, useEffect, useRef } from "react";
import { Cookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import { Columna, ColumnaEstatica } from "../../../components/kanban/Columna.jsx";
import { DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { get, putEP } from "../../../utils/http.js";
import EtapaClienteEjecutivo from "./EtapaClienteEjecutivo.jsx";
import { Elemento } from "../../../components/kanban/Elemento.jsx";
import { SortableContext } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import { formatoMoneda } from "../../../utils/operaciones_formulario.js";


export default function KanbanClientesEjecutivo() {
  const cookies = new Cookies();

  const tokenEjecutivo = useRef(cookies.get("token"));
  const idEmpresa = useRef(jwtDecode(cookies.get("token"))?.idEmpresa);

  const [seguimientoSolicitudCliente, setSeguimientoSolicitudCliente] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const [clientesInicio, setClientesInicio] = useState([]);
  const [clientesContactado, setClientesContactado] = useState([]);
  const [clientesConvencido, setClientesConvencido] = useState([]);
  const [clientesDatosEntregados, setClientesDatosEntregados] = useState([]);
  const [clientesPrecalificado, setClientesPrecalificado] = useState([]);
  const [clientesEntregaDocumentos, setClientesEntregaDocumentos] = useState([]);
  const [clientesEnviadoBancos, setClientesEnviadoBancos] = useState([]);
  const [clientesAutorizado, setClientesAutorizado] = useState([]);
  const [clientesDocumentacionPropiedad, setClientesDocumentacionPropiedad] = useState([]);
  const [clientesAvaluado, setClientesAvaluado] = useState([]);
  const [clientesFormalizacion, setClientesFormalizacion] = useState([]);
  const [clientesFirmado, setClientesFirmado] = useState([]);

  const etapasEjecutivo = [
    {id: 1, titulo: "Inicio", setter: setClientesInicio, clientes: clientesInicio},
    {id: 2, titulo: "Contactado", setter: setClientesContactado, clientes: clientesContactado},
    {id: 3, titulo: "Convencido", setter: setClientesConvencido, clientes: clientesConvencido},
    {id: 4, titulo: "Datos entregados", setter: setClientesDatosEntregados, clientes: clientesDatosEntregados},
    {id: 5, titulo: "Precalificado", setter: setClientesPrecalificado, clientes: clientesPrecalificado},
    {id: 6, titulo: "Enviado a bancos", setter: setClientesEnviadoBancos, clientes: clientesEnviadoBancos, elementosInactivos: true},
  ]

  const etapasUsuarios = [
    {id: 7, titulo: "Entrega de documentos", setter: setClientesEntregaDocumentos, clientes: clientesEntregaDocumentos},
    {id: 8, titulo: "Autorizado", setter: setClientesAutorizado, clientes: clientesAutorizado},
    {id: 9, titulo: "Documentación de propiedad", setter: setClientesDocumentacionPropiedad, clientes: clientesDocumentacionPropiedad},
    {id: 10, titulo: "Avalúo", setter: setClientesAvaluado, clientes: clientesAvaluado},
    {id: 11, titulo: "Formalización", setter: setClientesFormalizacion, clientes: clientesFormalizacion},
    {id: 12, titulo: "Firmado", setter: setClientesFirmado, clientes: clientesFirmado}
  ]

  const idsEtapas = useRef(etapasEjecutivo.map(etapa => etapa.id));
  const [columnaActiva, setColumnaActiva] = useState(null);
  const [elementoActivo, setElementoActivo] = useState(null);
  const [prevColumnaElemento, setPrevColumnaElemento] = useState(null);

  async function obtClientes(){
    const peticionClientes = etapasEjecutivo.concat(etapasUsuarios).map(async etapa => {
      const peticion = await get("obtClientesEstatus", `?idEstatus=${etapa.id}${idEmpresa.current ? "&idEmpresa=" + idEmpresa.current : ""}`);
      if(peticion?.statusCode === 200){
        etapa.setter(peticion.respuesta.clientes.map(cliente =>{
          return {
            id: cliente.idCliente,
            titulo: cliente.cliente,
            telefono: cliente.telefono,
            renglon1: cliente.correo,
            renglon2: formatoMoneda(cliente?.valorPropiedad),
            estatus: cliente.idEstatus,
            tooltip: <div className="cont-tooltip">
              <p><strong>{cliente.cliente}</strong></p>
              <p><strong>Correo:</strong> {cliente.correo}</p>
              <p><strong>Teléfono:</strong> {cliente.telefono}</p>
              <p><strong>Valor propiedad:</strong> {formatoMoneda(cliente?.valorPropiedad)}</p>
              <p><strong>Monto solicitado:</strong> {formatoMoneda(cliente?.montoSolicitado)}</p>
              <p><strong>Plazo:</strong> {cliente?.plazo} años</p>
            </div>,
            notificacion: {
              mensaje: cliente.mensajeNuevo === 1,
            }
          }
        }
        ));
        return true;
      }
      return false;
    });

    await Promise.all(peticionClientes);
  }

  function seguirSolicitudCliente(cliente){
    setSeguimientoSolicitudCliente({
      id: cliente?.id,
      nombreCompleto: cliente?.titulo,
      correo: cliente?.correo,
      telefono: cliente?.telefono
    });
  }

  function formatoElemento(elemento, columna){
    return {
      id: elemento.id,
      titulo: elemento.titulo,
      telefono: elemento.telefono,
      renglon1: elemento.renglon1,
      renglon2: elemento.renglon2,
      tooltip: elemento.tooltip,
      estatus: columna.id,
      notificacion: {
        mensaje: elemento.notificacion.mensaje,
      }
    }
  }

  const handleDragStart = (evento) => {
    const { active } = evento;
    if(active.data.current?.tipo === "columna"){
      setColumnaActiva(active.data.current.columna);
      return;
    }

    if(active.data.current?.tipo === "elemento"){
      setElementoActivo(active.data.current.elemento);
      setPrevColumnaElemento(active.data.current.elemento.estatus);
      return;
    }
  }

  const handleDragOver = (evento) => {
    const { active, over } = evento;
    if(!over) return;

    const idColumnaActiva = active.id;
    const idColumnaObjetivo = over?.id;

    if(idColumnaActiva === idColumnaObjetivo) return;

    const estaElementoActivo = active.data.current?.tipo === "elemento";
    const estaElementoObjetivo = over?.data.current?.tipo === "elemento";

    if(!estaElementoActivo) return;

    if(estaElementoActivo && estaElementoObjetivo){
      const elementoObjetivo = over.data.current.elemento;
      const columnaObjetivo = etapasEjecutivo.find(etapa => etapa.id === elementoObjetivo.estatus);

      if(columnaObjetivo?.clientes?.some(cliente => cliente.id === elementoActivo.id)) return;

      const prevEtapa = etapasEjecutivo.find(etapa => etapa.id === elementoActivo.estatus);
      prevEtapa?.setter(prevClientes => prevClientes.filter(cliente => cliente.id !== elementoActivo.id));

      columnaObjetivo?.setter(prevClientes => [...prevClientes, formatoElemento(elementoActivo, columnaObjetivo)]);

      setElementoActivo(formatoElemento(elementoActivo, columnaObjetivo));
    }

    const estaSobreColumna = over?.data.current?.tipo === "columna";

    if(elementoActivo && estaSobreColumna){
      const sobreColumna = over.data.current.columna;

      if(sobreColumna?.clientes?.some(cliente => cliente.id === elementoActivo.id)) return;

      const etapa = etapasEjecutivo.find(etapa => etapa.id === elementoActivo.estatus);
      etapa?.setter(prevClientes => prevClientes.filter(cliente => cliente.id !== elementoActivo.id));
      
      sobreColumna.setter(prevClientes => [...prevClientes, formatoElemento(elementoActivo, sobreColumna)]);

      setElementoActivo(formatoElemento(elementoActivo, sobreColumna));
    }
  }

  const handleDragEnd = async (evento) => {
    setColumnaActiva(null);
    setElementoActivo(null);
    
    const { active, over } = evento;
    if(!over) return;

    if(evento.active.data.current?.tipo === "elemento"){
      const cliente = evento.active.data.current?.elemento;

      const peticionEstatus = await putEP("actualizarEstatusCliente", {}, {
        idCliente: cliente.id,
        idEstatus: cliente.estatus
      });

      if(peticionEstatus?.statusCode !== 200 && prevColumnaElemento !== null){
        const columnaObjetivo = etapasEjecutivo.find(etapa => etapa.id === cliente.estatus);
        columnaObjetivo.setter(prevClientes => prevClientes.filter(_cliente => _cliente.id !== cliente.id));

        const prevColumna = etapasEjecutivo.find(etapa => etapa.id === prevColumnaElemento);
        prevColumna.setter(prevClientes => [...prevClientes, {id: cliente.id, titulo: cliente.titulo, estatus: prevColumnaElemento}]);
      }
    }
    setPrevColumnaElemento(null);
  }
  
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3
      }
    })
  );

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtClientes();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);
 
  if(seguimientoSolicitudCliente !== undefined){
    return(
      <EtapaClienteEjecutivo data={{cliente: seguimientoSolicitudCliente}} regresar={() => {
        setSeguimientoSolicitudCliente(undefined);
      }}/>
    );
  }else{
    return (
      <>
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <div className="loading-text">Cargando...</div>
          </div>
        )}

        <div className="cont_Info">
          <h1 className="titt">Kanban dashboard</h1>
          <p className="descripcionClientes">
            Aquí encontraras el status de tus clientes y prospectos​
          </p>

          <div>
            <h2 className="titulo2-bg">Etapa 1</h2>
            <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver}>
              <div className="cont-kanban">
                <SortableContext items={idsEtapas.current}>
                  {etapasEjecutivo.map(etapa => 
                    <Columna key={etapa.id} columna={etapa} elementos={etapa.clientes} elementosInactivos={etapa.elementosInactivos} onClickElement={seguirSolicitudCliente}/>
                  )}
                </SortableContext>
              </div>
              {createPortal(
                <DragOverlay>
                  {columnaActiva && (
                    <Columna columna={columnaActiva} elementos={columnaActiva.clientes} onClickElement={seguirSolicitudCliente}/>
                  )}
                  {elementoActivo && (
                    <Elemento elemento={elementoActivo} onClick={seguirSolicitudCliente}/>
                  )}
                </DragOverlay>,
                document.body
              )}
            </DndContext>

            <h2 className="titulo2-bg">Etapa 2</h2>
            <div className="cont-kanban">
              {etapasUsuarios.map(etapa =>
                <ColumnaEstatica key={etapa.id} columna={etapa} elementos={etapa.clientes} elementosInactivos={etapa.elementosInactivos} onClickElement={seguirSolicitudCliente}/>
              )}
            </div>
          </div>

          <br/>
        </div>
      </>
    );
  }
  
}