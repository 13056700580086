import { createContext, useContext, useState } from "react";

export const ChatContext = createContext(undefined);

export function useChatContext(){
  const { chat } = useContext(ChatContext);
  return chat;
}

export function useSetChatContext() {
  const { setChat } = useContext(ChatContext);
  return setChat;
}

export function ChatProvider({children}){
  const [chat, setChat] = useState({
    ejecutivo: {
      id: undefined,
      token: undefined,
    },
    chat: {
      id: undefined,
      contacto: {
        id: undefined,
        telefono: undefined,
        nombre: undefined
      },
      ultimoMensaje: {
        id: undefined,
        fechaCreacion: undefined,
        remitente: undefined,
        tipo: undefined,
        texto: undefined
      },
      ultimoMensajeEjecutivo: {
        id: undefined,
        fechaCreacion: undefined,
        remitente: undefined,
        idEjecutivo: undefined,
        nombreEjecutivo: undefined,
        tipo: undefined,
        texto: undefined
      }
    },
    actualizarChats: false
  });

  return(
    <ChatContext.Provider value={{chat, setChat}}>
      {children}
    </ChatContext.Provider>
  );
}