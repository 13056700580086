import { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { postEP } from "../../utils/http";
import { useChatContext } from "../../providers/ChatProvider";
import { ruleNoTelefono } from "../../assets/validateRules";
import Swal from "sweetalert2";


export default function DialogNuevaConversacion({open, onSend, onClose}) {
	
	const [isLoading, setIsLoading] = useState(false);
  const chatData = useChatContext();

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  function cerrar(){
    resetField("nombre");
    resetField("telefono");
    onClose();
  }

  async function enviar(data) {
    setIsLoading(true);
    const peticionChat = await postEP("whatsapp/crearChat", 
      {"x-token": chatData.ejecutivo.token},
      {
      nombre: data.nombre,
      telefono: data.telefono
    });

    if(peticionChat?.statusCode === 200){
      onSend(peticionChat.respuesta.chat);
      cerrar();
    }else if(peticionChat.statusCode === 400){
      cerrar();
      Swal.fire({
        title: "Error al crear chat",
        text: peticionChat.msg,
        icon: "error",
        confirmButtonText: "Aceptar"
      });
    }else{
      cerrar();
      Swal.fire({
        title: "Error al crear chat",
        text: "Intentalo de nuevo más tarde",
        icon: "error",
        confirmButtonText: "Aceptar"
      });
    }
    setIsLoading(false);
  }

	return (
		<Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Crea un nuevo chat
      </DialogTitle>
			<DialogContent>

        <form onSubmit={handleSubmit(enviar)}>
          <div style={{display: "flex", flexDirection: "column", gap: "1em", marginBottom: "1em"}}>
            <TextField
              {...register("nombre", { required: true })}
              label="Nombre"
              variant="standard"
              autoComplete="off"
            />
            <TextField
              type="tel"
              label="Teléfono"
              variant="standard"
              autoComplete="off"
              {...register("telefono", { required: true, validate: ruleNoTelefono })}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
              error={errors.telefono?.type === "validate"}
              helperText={errors.telefono?.type === "validate" && "Formato no válido"}
            />
          </div>
          <div className='flex justify-between'>
            <Button type="button" variant="outline" onClick={onClose} >Cancelar</Button>
            <Button
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <span class="spinner"></span>
              ) : (
                "Crear"
              )}
            </Button>
          </div>
        </form>
			</DialogContent>
		</Dialog>
	);
};