import { obtFechaRelativa, esMismoDia } from "../../utils/operaciones_formulario";

export default function IndicadorFecha({ mensaje, prevMensaje }){
	
	return (
		<>
      {
        !prevMensaje || !esMismoDia(prevMensaje.fechaCreacion, mensaje.fechaCreacion) ? (
          <div id="chat-indicador-fecha" className="flex_centrado">
            <p>
              {obtFechaRelativa(mensaje, prevMensaje)}
            </p>
          </div>
        ) : null
      }
    </>
	);
}