import React, { useState, useEffect, useRef } from "react";
import { Cookies } from "react-cookie";
import { formatoFechaIsoADma, formatoMoneda } from "../../../utils/operaciones_formulario.js";
import { EstatusCreditoUnico } from "../../../components/EstatusCredito.jsx";
import { BtnDocumentacion } from "../../../components/Botones.jsx";
import { ModalWrapper } from "../../../components/Modals.jsx";
import { get, getEP, postEP } from "../../../utils/http.js";
import { 
  DocumentacionInicial, 
  InfoPersonal, 
  InfoLaboral, 
  InfoMedica, 
  FormularioAvaluo, 
  FormularioFormalizacion, 
  FormularioNotaria, 
  FormularioRespuestaBanco, 
  FormularioSolicitud, 
  DocumentacionInicialCoAcreditado,
  InfoPersonalCoAcreditado,
  InfoLaboralCoAcreditado,
  InfoMedicaCoAcreditado,
  InfoDatosExtra,
  FormularioInmueble,
  DatosCliente
} from "../../../components/Formularios.jsx";
import { UltimaConversacion } from "../../../components/chat/Conversacion.jsx";
import { Nota } from "../../../components/Cards.jsx";
import Swal from "sweetalert2";
import PopupChat from "../../../components/chat/PopupChat.jsx";
import { ChatProvider } from "../../../providers/ChatProvider.jsx";

export default function EtapaClienteEjecutivo({data, regresar}) {
  const cookies = new Cookies();

  const cliente = data?.cliente;

  const [cargarUltConversacion, setCargarUltConversacion] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const tokenEjecutivo = useRef(cookies.get("token"));
  const idEjecutivo = useRef(cookies.get("idEjecutivo"));

  const [pop, setPop] = useState(null);
  const [documentacionCoAcreditado, setDocumentacionCoAcreditado] = useState(false);

  const [mensajeTarea, setMensajeTarea] = useState(null);
  const [conversacion, setConversacion] = useState(null);
  const [notas, setNotas] = useState([]);

  const [cotizaciones, setCotizaciones] = useState(null); // lista de idCuestionarios
  const [idCotizacionActual, setIdCotizacionActual] = useState(null);
  const [cotizacion, setCotizacion] = useState(null);

  const [procesoCreditoActual, setProcesoCreditoActual] = useState(null);

  // Estados para almacenar secciones completadas
  const [seccionesDocPersonal, setSeccionesDocPersonal] = useState([]);
  const [seccionesDocPersonalCoa, setSeccionesDocPersonalCoa] = useState([]);

  const btnsDocumentacionAcreditado = [
    {id: 1, titulo: "Documentacion Inicial", 
      accion: () => {
        setPop(<DocumentacionInicial cliente={cliente} idResPrecalifica={procesoCreditoActual?.idResPrecalifica} alCompletar={() => setSeccionesDocPersonal(prevSecciones => [...prevSecciones, 1])} alIncompletar={() => setSeccionesDocPersonal(prevSecciones => prevSecciones.filter(seccion => seccion !== 1))} />);
      }
    },
    {id: 2, titulo: "Información personal", 
      accion: () => {setPop(<InfoPersonal cliente={cliente} alCompletar={() => setSeccionesDocPersonal(prevSecciones => [...prevSecciones, 2])} />)}
    },
    {id: 3, titulo: "Información laboral", 
      accion: () => {setPop(<InfoLaboral cliente={cliente} alCompletar={() => setSeccionesDocPersonal(prevSecciones => [...prevSecciones, 3])} />)}
    },
    {id: 4, titulo: "Información médica", 
      accion: () => {setPop(<InfoMedica cliente={cliente} alCompletar={() => setSeccionesDocPersonal(prevSecciones => [...prevSecciones, 4])} />)}
    },
  ];

  const btnsDocumentacionCoacreditado = [
    {id: 1, titulo: "Doc. Inicial Coacreditado", 
      accion: () => {setPop(<DocumentacionInicialCoAcreditado cliente={cliente} idResPrecalifica={procesoCreditoActual?.idResPrecalifica} actividadEconomica={cotizacion?.idActividadEconomica} alCompletar={() => setSeccionesDocPersonalCoa(prevSecciones => [...prevSecciones, 1])} alIncompletar={() => setSeccionesDocPersonalCoa(prevSecciones => prevSecciones.filter(seccion => seccion !== 1))} />)}
    },
    {id: 2, titulo: "Información personal Coacreditado", 
      accion: () => {setPop(<InfoPersonalCoAcreditado cliente={cliente} alCompletar={() => setSeccionesDocPersonalCoa(prevSecciones => [...prevSecciones, 2])}/>)}
    },
    {id: 3, titulo: "Información laboral Coacreditado", 
      accion: () => {setPop(<InfoLaboralCoAcreditado cliente={cliente} alCompletar={() => setSeccionesDocPersonalCoa(prevSecciones => [...prevSecciones, 3])}/>)}
    },
    {id: 4, titulo: "Información médica Coacreditado", 
      accion: () => {setPop(<InfoMedicaCoAcreditado cliente={cliente} alCompletar={() => setSeccionesDocPersonalCoa(prevSecciones => [...prevSecciones, 4])}/>)}
    },
    {id: 5, titulo: "Datos Extra", 
      accion: () => {setPop(<InfoDatosExtra cliente={cliente} alCompletar={() => setSeccionesDocPersonalCoa(prevSecciones => [...prevSecciones, 5])}/>)}
    },
  ];

  async function obtCotizaciones(){
    const peticionCotizaciones = await get("obtCotizacionesIds", cliente?.id);

    if(peticionCotizaciones?.statuscode === 200){
      setCotizaciones(peticionCotizaciones.respuesta);
      setIdCotizacionActual(peticionCotizaciones.respuesta[0]);
    }
  }

  // Actualización de documentación personal
  async function obtEstatusDocumentacionPersonal(){
    const secciones = [
      {id: 1, clave: "infoDocInicial"},
      {id: 2, clave: "infoPersonal"},
      {id: 3, clave: "infoLaboral"},
      {id: 4, clave: "infoMedica"}
    ]

    const peticionDocPersonal = await get("getVerificarSeccionInformacion", data?.cliente?.id);

    if(peticionDocPersonal?.statuscode === 200){
      secciones.forEach(seccion => {
        if(peticionDocPersonal.respuestas[seccion.clave]){
          setSeccionesDocPersonal(prevSecciones => [...prevSecciones, seccion.id]);
        }else{
          setSeccionesDocPersonal(prevSecciones => prevSecciones.filter(id => id !== seccion.id));
        }
      });
    }
  }

  // Actualización de documentación personal coacreditado
  async function obtEstatusDocumentacionPersonalCoacreditado(){
    const secciones = [
      {id: 1, clave: "infoDocInicial"},
      {id: 2, clave: "infoPersonal"},
      {id: 3, clave: "infoLaboral"},
      {id: 4, clave: "infoMedica"},
      {id: 5, clave: "infoDatosExtras"}
    ]

    const peticionDocPersonal = await get("getVerificarSeccionInformacionCoAcreditado", cliente?.id);

    if(peticionDocPersonal?.statuscode === 200){
      secciones.forEach(seccion => {
        if(peticionDocPersonal.respuestas[seccion.clave]){
          setSeccionesDocPersonalCoa(prevSecciones => [...prevSecciones, seccion.id]);
        }else{
          setSeccionesDocPersonalCoa(prevSecciones => prevSecciones.filter(id => id !== seccion.id));
        }
      });
    }
  }

  async function obtSiguienteTarea(idResPrecalifica){
    const peticionMensaje = await get("obtSiguienteTareaSolicitud", idResPrecalifica);

    if(peticionMensaje?.statuscode === 200){
      setMensajeTarea(peticionMensaje.respuesta.msgSiguienteTarea);
    }else{
      mensajeTarea(null);
    }
  }

  async function obtUltimoMensaje(){
    const peticionUltimoMensaje = await getEP("whatsapp/obtUltimoMensajeChat/" + cliente?.id, {
      "x-token": tokenEjecutivo.current,
    });

    if(peticionUltimoMensaje?.statusCode === 200){
      const ultimoMensaje = peticionUltimoMensaje?.respuesta?.ultimoMensaje;
      const chat = peticionUltimoMensaje?.respuesta?.chat;

      setConversacion({
        idChat: chat?.id,
        idUltimoMensaje: ultimoMensaje?.idMensaje,
        remitente: ultimoMensaje?.remitente ?? "",
        tipoMensaje: ultimoMensaje?.tipoMensaje ?? "texto",
        texto: ultimoMensaje?.texto ?? "",
        nuevoMensaje: ultimoMensaje?.nuevoMensaje ?? false,
        fechaCreacionMensaje: ultimoMensaje?.fechaCreacionMensaje ?? chat?.fechaCreacion,
      });
    }else{
      setConversacion({
        idChat: undefined,
        idUltimoMensaje: undefined,
        remitente: "",
        tipoMensaje: "texto",
        texto: "",
        fechaCreacionMensaje: undefined,
      });
    }
  }

  async function obtNotasCliente(){
    const peticionNotas = await getEP("ejecutivo/obtNotasCotizacion/" + idCotizacionActual);

    if(peticionNotas?.statusCode === 200){
      setNotas(peticionNotas.respuesta.notas);
    }else{
      setNotas([]);
    }
  }

  async function crearNota(texto){
    const peticionNota = await postEP("ejecutivo/crearNotaCotizacion", {}, {
      idEjecutivo: idEjecutivo.current,
      idCliente: cliente?.id,
      idCuestionario: idCotizacionActual,
      texto: texto
    });

    if(peticionNota?.statusCode === 200){
      setNotas(prevNotas => [{
        id: peticionNota.respuesta.idNotaCliente,
        texto: texto,
        fechaCreacion: formatoFechaIsoADma(peticionNota.respuesta.fechaCreacion),
        autor: cookies.get("nombreEjecutivo"),
      },
      ...prevNotas]);
      
    }else{
      Swal.fire({
        title: "Error",
        text: "No se pudo crear la nota",
        icon: "error",
        confirmButtonText: "Aceptar"
      });
    }
  }

  // Actualización de la cotizacion
  useEffect(() => {
    async function cargarNuevaCotizacion(){
      const peticionCotizacion = await get("obtResumenCotizacion", idCotizacionActual);

      if(peticionCotizacion?.statuscode === 200){
        setCotizacion({...peticionCotizacion?.respuesta.resumenCotizacion, idActividadEconomica: peticionCotizacion?.respuesta.idActividadEconomica});
        setProcesoCreditoActual(peticionCotizacion?.respuesta.resumenCotizacion?.procesosDeCredito[0]);

        const idResPrecalifica = peticionCotizacion?.respuesta.resumenCotizacion?.procesosDeCredito[0]?.idResPrecalifica;
        obtSiguienteTarea(idResPrecalifica);
        obtNotasCliente();

      }else{
        setCotizacion(null);
        setProcesoCreditoActual(null);
      }
    }

    if(idCotizacionActual){
      cargarNuevaCotizacion();
    }
  }, [idCotizacionActual]);

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        obtUltimoMensaje();
        obtCotizaciones();
        obtEstatusDocumentacionPersonal();
        obtEstatusDocumentacionPersonalCoacreditado();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    window.scrollTo(0, 0);
    cargarDatos();
  }, []);

  return (
    <div className="cont_Info">
      {pop !== null && (
        <ModalWrapper onClose={() => setPop(null)}>
          {pop}
        </ModalWrapper>
      )}

      <button type="button" className="link linkGrande back" onClick={regresar}>
        {" "}
        {"<"} Regresar
      </button>
      <br/><br/>

      <div className="display_renglon align-baseline">
        <h1 className="titt">{cliente?.nombreCompleto}</h1>
        <button type="button" className="link fs-16" on onClick={() => {
          setPop(<DatosCliente idCliente={cliente?.id}/>);
        }}>
          Ver datos del cliente
        </button>
      </div>
      <p className="descripcionClientes">
        Aquí puedes conocer el crédito de tu cliente, ver el proceso de tu cliente y trabajar con el.
      </p>

      {/*Cotizaciones*/}
      <div className="cont-scroll-x cont-flex-gap">
        {cotizaciones?.map((idCotizacion, idx) => {
          return (
            <button key={idx} className={`btn-tab ${idCotizacion === idCotizacionActual ? "active" : ""}`} 
              onClick={() => setIdCotizacionActual(idCotizacion)}
            >
              Cotización {idx+1}
            </button>
          );
        })}
      </div>
      <br/>

      <div id="form-etapas-ejecutivo-cliente">
        
        <div className="grid50_50">
          {/*Crédito solicitado*/}
          <div>
            <h4 className="margin0">Crédito Solicitado:</h4>
            <div className="grid3_modal margintop_1em">
              <div>
                <p className="margin0">Costo Vivienda:</p>
                <p className="margin0 sin_ruptura">{formatoMoneda(cotizacion?.costoVivienda ?? 0)}</p>
              </div>
              <div>
                <p className="margin0">Enganche:</p>
                <p className="margin0 sin_ruptura">{formatoMoneda(cotizacion?.enganche ?? 0)}</p>
              </div>
              <div>
                <p className="margin0">Crédito:</p>
                <p className="margin0 sin_ruptura">{formatoMoneda(cotizacion?.credito ?? 0)}</p>
              </div>
            </div>
          </div>

          {/*Estatus*/}
          <div>
            <div>
              <h4 className="margin0">Estatus:</h4>
              <br/>
              <EstatusCreditoUnico paso={procesoCreditoActual?.estatus?.at(-1)}/>
            </div>
          </div>
        </div>

        <div className="cont-contenido-barra">
          
          {/*Contenido*/}
          <div className="cont-contenido">
            <div className="cont-flex-gap align-items-center">
              <h4 className="margin0">Créditos en Proceso:</h4>
              <div className="cont-flex-gap">
                {
                  cotizacion?.procesosDeCredito?.map((proceso, idx) => (
                    <button key={idx} type="button" className={`btn-tab ${proceso.idResPrecalifica === procesoCreditoActual?.idResPrecalifica ? "active" : ""}`}
                      onClick={() => setProcesoCreditoActual(proceso)}
                    >
                      {proceso?.banco}
                    </button>
                  ))
                }
              </div>
            </div>

            {/*Documentación personal*/}
            <div>
              <div className="flex-renglon">
                <h5>Documentación personal:</h5>
                <div>
                  <div className="cont-toggle align-items-center">
                    Acreditado
                    <span className="btn-toggle">
                      <input type="checkbox" name="checkbox" id="checkRecordatorio"
                        checked={documentacionCoAcreditado}
                        onChange={(e) => {
                          setDocumentacionCoAcreditado(e.target.checked);
                        }}
                      />
                      <label className="toggle" htmlFor="checkRecordatorio"></label>
                    </span>
                    Coacreditado
                  </div>
                </div>
              </div>
              <br/>

              <div style={{width: "350px"}}>
                <ul className="check">
                  {
                    documentacionCoAcreditado ? 
                    btnsDocumentacionCoacreditado.map((doc) => (
                      <li className={`linkInfoGeneral ${seccionesDocPersonalCoa.includes(doc.id) ? "active informacionGeneralCompletado" : ""}`}>
                        <BtnDocumentacion titulo={doc.titulo} accion={doc.accion}/>
                      </li>
                    ))
                    : btnsDocumentacionAcreditado.map((doc) => (
                      <li className={`linkInfoGeneral ${seccionesDocPersonal.includes(doc.id) ? "active informacionGeneralCompletado" : ""}`}>
                        <BtnDocumentacion titulo={doc.titulo} accion={doc.accion}/>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </div>

            {/*Etapa 1: solicitud*/}
            <div className="cont_collaps margintop_1em">
              <button type="button" className="btn_collaps"
                onClick={(ev) => {
                  ev.currentTarget.classList.toggle("active");
                  const contElement = ev.currentTarget.nextElementSibling;
                  if (contElement) {
                    contElement.style.display =
                      contElement.style.display === "block" ? "none" : "block";
                  }
                }}
              >
                Solicitud <i className="fa fa-caret-down" aria-hidden="true"></i>
              </button>
              <div className="collaps_contenido">
                <br/>
                {
                  procesoCreditoActual?.idResPrecalifica &&
                  <FormularioSolicitud idCuestionario={idCotizacionActual} idResPrecalifica={procesoCreditoActual?.idResPrecalifica}
                    alCompletar={() => obtSiguienteTarea(procesoCreditoActual?.idResPrecalifica)}
                  />
                }
                <br/>
              </div>
            </div>

            {/*Etapa 2: respuesta de banco*/}
            <div className="cont_collaps margintop_1em">
              <button type="button" className="btn_collaps"
                onClick={(ev) => {
                  ev.currentTarget.classList.toggle("active");
                  const contElement = ev.currentTarget.nextElementSibling;
                  if (contElement) {
                    contElement.style.display =
                      contElement.style.display === "block" ? "none" : "block";
                  }
                }}
              >
                Respuesta de banco <i className="fa fa-caret-down" aria-hidden="true"></i>
              </button>
              <div className="collaps_contenido">
                {
                  procesoCreditoActual?.idResPrecalifica &&
                  <FormularioRespuestaBanco idCuestionario={idCotizacionActual} idResPrecalifica={procesoCreditoActual?.idResPrecalifica}
                    alCompletar={() => obtSiguienteTarea(procesoCreditoActual?.idResPrecalifica)}
                  />
                }
              </div>
            </div>

            {/*Etapa 3: inmueble*/}
            <div className="cont_collaps margintop_1em">
              <button type="button" className="btn_collaps"
                onClick={(ev) => {
                  ev.currentTarget.classList.toggle("active");
                  const contElement = ev.currentTarget.nextElementSibling;
                  if (contElement) {
                    contElement.style.display =
                      contElement.style.display === "block" ? "none" : "block";
                  }
                }}
              >
                Inmueble <i className="fa fa-caret-down" aria-hidden="true"></i>
              </button>
              <div className="collaps_contenido">
                {
                  procesoCreditoActual?.idResPrecalifica &&
                  <FormularioInmueble idCuestionario={idCotizacionActual} idResPrecalifica={procesoCreditoActual?.idResPrecalifica}
                    alCompletar={() => obtSiguienteTarea(procesoCreditoActual?.idResPrecalifica)}
                  />
                }
                <br/>
              </div>
            </div>

            {/*Etapa 4: avaluo*/}
            <div className="cont_collaps margintop_1em">
              <button type="button" className="btn_collaps"
                onClick={(ev) => {
                  ev.currentTarget.classList.toggle("active");
                  const contElement = ev.currentTarget.nextElementSibling;
                  if (contElement) {
                    contElement.style.display =
                      contElement.style.display === "block" ? "none" : "block";
                  }
                }}
              >
                Avalúo <i className="fa fa-caret-down" aria-hidden="true"></i>
              </button>
              <div className="collaps_contenido">
                {
                  procesoCreditoActual?.idResPrecalifica &&
                  <FormularioAvaluo idCuestionario={idCotizacionActual} idResPrecalifica={procesoCreditoActual?.idResPrecalifica}
                    alCompletar={() => obtSiguienteTarea(procesoCreditoActual?.idResPrecalifica)}
                  />
                }
              </div>
            </div>

            {/*Etapa 5: notaria*/}
            <div className="cont_collaps margintop_1em">
              <button type="button" className="btn_collaps"
                onClick={(ev) => {
                  ev.currentTarget.classList.toggle("active");
                  const contElement = ev.currentTarget.nextElementSibling;
                  if (contElement) {
                    contElement.style.display =
                      contElement.style.display === "block" ? "none" : "block";
                  }
                }}
              >
                Notaria <i className="fa fa-caret-down" aria-hidden="true"></i>
              </button>
              <div className="collaps_contenido">
                {
                  procesoCreditoActual?.idResPrecalifica &&
                  <FormularioNotaria idCuestionario={idCotizacionActual} idResPrecalifica={procesoCreditoActual?.idResPrecalifica}
                    alCompletar={() => obtSiguienteTarea(procesoCreditoActual?.idResPrecalifica)}
                  />
                }
              </div>
            </div>

            {/*Etapa 6: formalizacion*/}
            <div className="cont_collaps margintop_1em">
              <button type="button" className="btn_collaps"
                onClick={(ev) => {
                  ev.currentTarget.classList.toggle("active");
                  const contElement = ev.currentTarget.nextElementSibling;
                  if (contElement) {
                    contElement.style.display =
                      contElement.style.display === "block" ? "none" : "block";
                  }
                }}
              >
                Formalización <i className="fa fa-caret-down" aria-hidden="true"></i>
              </button>
              <div className="collaps_contenido">
                {
                  procesoCreditoActual?.idResPrecalifica &&
                  <FormularioFormalizacion idCuestionario={idCotizacionActual} idResPrecalifica={procesoCreditoActual.idResPrecalifica}
                    alCompletar={() => obtSiguienteTarea(procesoCreditoActual?.idResPrecalifica)}
                  />
                }
              </div>
            </div>

          </div>

          {/*Tareas y conversaciones*/}
          <aside className="barra-lateral">
            <div>{/*Siguiente tarea*/}
              <h5 className="margin0">Siguiente tarea</h5>
              <br/>
              <ul>
                <li>{mensajeTarea}</li>
              </ul>
            </div>
            <br/>
            <div>{/*Ultima conversación*/}
              <h5 className="margin0">Última Conversación</h5>
              <br/>
              <div>
                {conversacion && (
                  <UltimaConversacion conversacion={conversacion} cargando={cargarUltConversacion} onClick={async (_conversacion) => {
                    if(conversacion.idChat === undefined){
                      setCargarUltConversacion(true);

                      const peticionChat = await postEP("whatsapp/crearVincularChat", {
                        "x-token": tokenEjecutivo.current,
                      }, {
                        idCliente: cliente?.id,
                        nombre: cliente?.nombreCompleto,
                        telefono: cliente?.telefono,
                      });
                      if(peticionChat?.statusCode === 200){
                        const nuevoChat = peticionChat.respuesta.chat;
                        setConversacion({
                          idChat: nuevoChat.idChat,
                          idUltimoMensaje: undefined,
                          remitente: "",
                          tipoMensaje: "texto",
                          texto: "",
                          fechaCreacionMensaje: nuevoChat.fechaCreacionChat,
                        });
                        setPop(
                          <ChatProvider>
                            <PopupChat ejecutivo={{id: idEjecutivo.current, token: tokenEjecutivo.current}} idChat={nuevoChat.idChat}/>
                          </ChatProvider>
                        );
                      }else if(peticionChat?.statusCode){
                        Swal.fire({
                          icon: "error",
                          title: "Error al crear chat",
                          text: peticionChat?.msg,
                          confirmButtonText: "Aceptar"
                        });
                      }else{
                        Swal.fire({
                          icon: "error",
                          title: "Error al crear chat",
                          text: "El nombre o el teléfono del cliente no son válidos",
                          confirmButtonText: "Aceptar"
                        });
                      }
                      
                      setCargarUltConversacion(false);
                    }else{
                      setConversacion(_conversacion);
                      setPop(
                        <ChatProvider>
                          <PopupChat ejecutivo={{id: idEjecutivo.current, token: tokenEjecutivo.current}} idChat={conversacion.idChat}/>
                        </ChatProvider>
                      );
                    }
                  }}/>
                )}
              </div>
            </div>
            <br/>
            <div>{/*Notas*/}
              <h5 className="margin0">Notas</h5>
              <br/>
              <div className="cont-flex-column">
                <Nota 
                  className="no-text-select pointer cursor-pointer"
                  limpiarInput={true}
                  nota={{autor: cookies.get("nombreEjecutivo"), fechaCreacion: new Date().toLocaleDateString(), texto: ""}}
                  guardar={crearNota}
                />
                {
                  notas?.map(nota => (
                    <Nota key={nota.id} nota={nota} editable={false}/>
                  ))
                }
              </div>
            </div>
          </aside>

        </div>

      </div>
    </div>
  );
}