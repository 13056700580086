import { Pause, Play } from 'lucide-react';
import { useState, useRef, useEffect } from 'react';

export default function AudioPlayer({ src }) {
  const intervalo = 50;
  const audioRef = useRef(null);
  const [enReproduccion, setEnReproduccion] = useState(false);
  const [tiempoActual, setTiempoActual] = useState(0);
  const [duracion, setDuracion] = useState(0);
  const [progreso, setProgreso] = useState(0);

  // Función para formatear el tiempo en minutos:segundos
  function formatoTiempo(seconds){
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  function togglePlayPause(){
    const audio = audioRef.current;
    if (enReproduccion) {
      audio.pause();
    } else {
      audio.play();
    }
    setEnReproduccion(!enReproduccion);
  };

  // Cambia el tiempo del audio cuando se mueve la barra de progreso
  function reproduccion(ev){
    const newTime = (ev.target.value / 100) * duracion;
    audioRef.current.currentTime = newTime;
    setProgreso(ev.target.value);
  };

  useEffect(() => {
    const audio = audioRef.current;

    const handleLoadedMetadata = () => {
      setDuracion(audio.duration);
    };

    const handleTimeUpdate = () => {
      setTiempoActual(audio.currentTime);
    };

    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  //Actualiza la barra de progreso de forma fluida
  useEffect(() => {
    const audio = audioRef.current;

    const interval = setInterval(() => {
      if (audio && !audio.paused) {
        setProgreso((audio.currentTime / audio.duration) * 100);
      }
    }, intervalo);

    return () => clearInterval(interval);
  }, []);

  return (
    <div id="reproductor-audio">
      <audio ref={audioRef} src={src} onEnded={() => {
        setEnReproduccion(false);
      }}></audio>

      <button className='btn none' onClick={togglePlayPause}>
        {enReproduccion ? <Pause size={16} /> : <Play size={16} />}
      </button>
      <div>
      <span>{formatoTiempo(tiempoActual)}</span> / <span>{formatoTiempo(duracion)}</span>
      </div>
      <input
        type="range"
        value={progreso}
        onChange={reproduccion}
        max="100"
        className='margin0'
      />
    </div>
  );
};
