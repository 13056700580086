function obtenerAnioCompleto(ultimosDigitos){
  ultimosDigitos = parseInt(ultimosDigitos, 10)
  const anioActual = new Date().getFullYear()
  const primDigitosAnioActual = Math.floor(anioActual/100)

  var siglo;
  // �ltimos d�gitos de a�o actual 
  if ((anioActual % 100) > ultimosDigitos) {
      siglo = primDigitosAnioActual * 100;
  } else {
      siglo = (primDigitosAnioActual - 1) * 100;
  }

  var anioCompleto = siglo + ultimosDigitos;
  return ""+anioCompleto
}

export function fechaNacimientoDeRFC(rfc){    
  const fecha = rfc.length == 12 ? rfc.substring(3, 9): rfc.substring(4, 10);
  
  var anio = fecha.substring(0, 2);
  const mes = fecha.substring(2, 4);
  const dia = fecha.substring(4, 6);

  anio = obtenerAnioCompleto(anio);

  return `${dia}/${mes}/${anio}`;
}

export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function formatoDinero(value) {
  return "$" + value.toLocaleString("es-MX", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function textoAMoneda(monto) {
  monto = monto.replace(/[^0-9.]/g, "");                     
  let parts = monto.split(".");

  if (parts.length < 2){
    return `$ ${new Intl.NumberFormat('es-MX').format(monto)}`;
  }else{
    // Permito el primer punto decimal
    if (parts.length > 2) {
      monto = parts[0] + "." + parts[1];
    }
    
    // Permito los primeros dos d�gitos despu�s del punto
    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);
    }

    // Agrega d�gitos despu�s del punto
    if (parts[1]){
      monto = `$ ${new Intl.NumberFormat('es-MX').format(parts[0])}.` + parts[1];
    }else{ // Coloca el punto decimal
      monto = `$ ${new Intl.NumberFormat('es-MX').format(monto)}.`;
    }

    return monto;
  }
}

export function agergarDecimales(monto) {
  let numero = monto.replace(/[^0-9.]/g, "");
  
  if(/^\d+$/.test(numero)){
    return monto + ".00";
  }else if (/^\d+\.$/.test(numero)){
    return monto + "00";
  }else if (/^\d+\.\d{1}$/.test(numero)){
    return monto + "0";
  }
  return monto;
}

export function formatoMoneda(numero){
  if(numero >= 0)
    return `$ ${new Intl.NumberFormat('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(numero)}`;
  return "N/A";
}

export function formatoIsoFecha(fecha) {
  return fecha.split("T")[0];
}

export function sumarCapitalizacionFecha(fecha, unidad, capitalizacion){
  switch(capitalizacion.toLowerCase()){
    case "mensual":
      fecha.setMonth(fecha.getMonth() + unidad);
      break;
    case "quincenal":
      fecha.setDate(fecha.getDate() + (unidad * 15));
      break;
    default:
      break;
  }
}

export function formatoFechaIsoADma(fecha){
  if(fecha){
    const partes = fecha.split("T")[0].split('-');
    return `${partes[2]}/${partes[1]}/${partes[0]}`;
  }
  return "";
}

export function formatoFechaDmaAIso(fecha){
  const partes = fecha.split('/');
  return `${partes[2]}-${partes[1]}-${partes[0]}T00:00:00.000Z`;
}

export function calcularEdad(fecha){
  let hoy = new Date();
  let cumpleanos = new Date(fecha);
  let edad = hoy.getFullYear() - cumpleanos.getFullYear();
  let m = hoy.getMonth() + 1 - cumpleanos.getMonth() + 1;
  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
  }
  return edad;
}

export function formatoFechaChat(dateTime){
  if(!dateTime) return "";
  let fecha = new Date(dateTime.at(-1) === "Z" ? dateTime : dateTime + "Z");

  let fechaActual = new Date();
  fechaActual.setHours(0, 0, 0, 0);

  let _fecha = new Date(fecha);
  _fecha.setHours(0, 0, 0, 0);

  let difTiempo = fechaActual - _fecha;
  let difDias = Math.floor(difTiempo / (1000 * 60 * 60 * 24));

  // Verifica si es hoy
  if (difDias === 0) {
    return fecha.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true });
  }

  // Verifica si es ayer
  if (difDias === 1) {
    return "Ayer";
  }

  // Verifica si es dentro de la misma semana
  if (difDias < 7 && _fecha.getDay() < fechaActual.getDay()) {
    let dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"];
    return dias[_fecha.getDay()];
  }

  return _fecha.getDate().toString().padStart(2, "0") + "/" + (_fecha.getMonth() + 1).toString().padStart(2, "0") + "/" + _fecha.getFullYear();
}

export function esMismoDia(fecha1, fecha2){
  const _fecha1 = new Date(fecha1);
	const _fecha2 = new Date(fecha2);
	return (
		_fecha1.getFullYear() === _fecha2.getFullYear() &&
		_fecha1.getMonth() === _fecha2.getMonth() &&
		_fecha1.getDate() === _fecha2.getDate()
	);
}

export function obtFechaRelativa(mensaje, prevMensaje){
  const fechaActual = new Date();
	const fechaAyer = new Date(fechaActual);
	fechaAyer.setDate(fechaAyer.getDate() - 1);
	const fechaSemanaPasada = new Date(fechaActual);
	fechaSemanaPasada.setDate(fechaSemanaPasada.getDate() - 7);

	const fechaMensaje = new Date(mensaje.fechaCreacion);

	if (!prevMensaje || !esMismoDia(prevMensaje.fechaCreacion, fechaMensaje.getTime())) {
		if (esMismoDia(fechaMensaje.getTime(), fechaActual.getTime())) {
			return "Hoy";
		} else if (esMismoDia(fechaMensaje.getTime(), fechaAyer.getTime())) {
			return "Ayer";
		} else if (fechaMensaje.getTime() > fechaSemanaPasada.getTime()) {
			let days = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"];
      return days[fechaMensaje.getDay()];
		} else {
			return fechaMensaje.getDate().toString().padStart(2, "0") + "/" + (fechaMensaje.getMonth() + 1).toString().padStart(2, "0") + "/" + fechaMensaje.getFullYear();
		}
	}
}
