import React, { Component } from "react";

class BotonCopiar extends Component {
  handleClick = () => {
    const { invitacion } = this.props;
    // Copia el contenido de la invitación al portapapeles
    navigator.clipboard.writeText(invitacion).then(() => {
      alert("Contenido de la invitación copiado al portapapeles.");
    });
  };

  render() {
    // console.log(this.props.invitacion);
    // console.log("prueba");
        return (
            <button onClick={this.handleClick}>Copiar Invitación</button>
        );
    }
}

export default BotonCopiar;

export function btnCopiar(props) {
  const { invitacion } = props;

  const handleClick = () => {
    // Copia el contenido de la invitación al portapapeles
    navigator.clipboard.writeText(invitacion).then(() => {
      alert("Contenido de la invitación copiado al portapapeles.");
    });
  };
  //   console.log("aqui");
    return (
        <button onClick={handleClick}>Copiar Invitación</button>
    );
}


export function BtnDocumentacion({titulo, accion}){
  return (
    <button type="button" className="btn btn-sin-estilo" onClick={accion}>
      <div className="btn_documentacion">
        <div className="main_text fs-16">
          <i className="fa fa-circle-check"></i>{titulo}
        </div>
        <div className="sub_btn_doc fs-11">
          <span>
            Editar <i className="fa fa-pencil"></i>
          </span>
        </div>
      </div>
    </button>
  );
}