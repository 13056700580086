import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";

import React, { useState, useEffect, useRef, useMemo } from "react";
import { obtEmpresaUrl } from "../../../utils/global_functions.js";
import { textoLocalAgGrid } from "../../../components/Tablas.jsx";
import { deleteEP, get } from "../../../utils/http.js";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Cookies } from "react-cookie";
import Swal from "sweetalert2";
import { AgGridReact } from "ag-grid-react";
import EtapaClienteEjecutivo from "./EtapaClienteEjecutivo.jsx";
import { formatoFechaIsoADma } from "../../../utils/operaciones_formulario.js";
import { ModalEliminacion } from "../../../components/Modals.jsx";


export default function InvitacionesEjecutivo() {
  const [isLoading, setIsLoading] = useState(true);
  const [invitaciones, setInvitaciones] = useState([]);
  const [seguimientoSolicitudCliente, setSeguimientoSolicitudCliente] = useState(undefined);
  const [formEliminacion, setFormEliminacion] = useState(undefined);
  
  const navigate = useNavigate();

  const cookies = new Cookies();
  const tokenEjecutivo = useRef(cookies.get("token") ? jwtDecode(cookies.get("token")) : null);

  const columnDefs = [
    { headerName: "Fecha de envío", field: "fechaEnvio", valueFormatter: param => param.value ? formatoFechaIsoADma(param.value) : "N/A"},
    { headerName: "Nombre", field: "nombreInvitado"},
    { headerName: "Teléfono", field: "telefonoInvitado"},
    { headerName: "Correo electrónico", field: "correoInvitado"},
    { headerName: "Estatus", field: "estatus",
      valueFormatter: param => {
        switch(param.value){
          case 1: return "Enviado";
          case 2: return "Link abierto";
          case 3: return "Registrado";
          default: return "Sin información";
        }
      },
    },
    { headerName: "Referencia", field: "empresa"},
    { headerName: "Correo socio", field: "correoSocio"},
    {
      headerName: "Registrar", field: "boton",
      cellRenderer: (props) => props.data?.estatus !== 3 && (
        <button className="btn_cuadrado color_empresa"
        onClick={() => {
          localStorage.setItem("idInvitacion", props.data?.idInvitaciones);
          navigate("/ejecutivo/registrarCliente");
        }}
        >
          Registrar
        </button>
      ),
      minWidth: 150
    },
    {
      headerName: "Eliminar", field: "boton", 
      cellRenderer: (props) => 
        <button className="btn_cuadrado color_empresa"
          onClick={() => {
            setFormEliminacion(props.data);
          }}
        >
          Eliminar
        </button>,
      minWidth: 150
    }
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      floatingFilter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMovable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      minWidth: 200
    };
  }, []);

  const statusBar = useMemo(() => { 
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent' },
        { statusPanel: 'agTotalRowCountComponent' },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' }
      ]
    };
  }, []);

  const gridOptions = {
    suppressCellFocus: true,
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
    },
    components: {
      rowNodeIdRenderer: function (params) {
        return params.node.id + 1;
      },
    },
    sideBar: true,
    pagination: true,
    rowGroupPanelShow: "always",
    statusBar: {
      items: [{ component: "agAggregationComponent" }],
    },
    paginationPageSize: 500,
    enableRangeSelection: true,
    enableCharts: true,
    localeText: textoLocalAgGrid,
  };

  function seguirSolicitudCliente(data){
    if(data?.idCliente){
      setSeguimientoSolicitudCliente({
        id: data?.idCliente,
        nombreCompleto: data?.nombreInvitado,
        correo: data?.correoInvitado,
        telefono: data?.telefonoInvitado
      });
    }else{
      Swal.fire({
        icon: "error",
        title: "Invitación no asociada",
        text: "¿Registrar invitación?",
        confirmButtonText: "Sí",
        showCancelButton: true,
        cancelButtonText: "No"
      }).then((res) => {
        if(res.isConfirmed){
          localStorage.setItem("idInvitacion", data?.idInvitaciones);
          navigate("/ejecutivo/registrarCliente");
        }
      });
    }
  }

  async function eliminarInvitacion(data){
    const peticionEliminar = await deleteEP("eliminarInvitacion/" + formEliminacion.idInvitaciones,
      {"x-token": cookies.get("token")},
      {
        idCatCausa: data.causa,
        razon: data.razon
      }
    );

    if(peticionEliminar?.statusCode === 200){
      setInvitaciones(prevData => prevData.filter(invitacion => invitacion.idInvitaciones !== formEliminacion.idInvitaciones));
      Swal.fire({
        icon: "success",
        title: "Invitación eliminada",
        confirmButtonText: "Aceptar",
        timer: 1500
      });
    }else if(peticionEliminar?.statusCode === 500){
      Swal.fire({
        icon: "error",
        title: "No se pudo eliminar la invitación",
        confirmButtonText: "Aceptar"
      });
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al eliminar la invitación",
        text: "Vuelve a intentarlo más tarde",
        confirmButtonText: "Aceptar"
      });
    }
    setFormEliminacion(undefined);
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();

        if(tokenEjecutivo.current){
          const peticion = await get("obtUltimasInvitaciones", tokenEjecutivo.current?.idEmpresa ?? "");

          if(peticion?.statuscode === 200){
            setInvitaciones(peticion.respuesta);
          }
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);

  if(seguimientoSolicitudCliente !== undefined){
    return(
      <EtapaClienteEjecutivo data={{cliente: seguimientoSolicitudCliente}} regresar={() => {
        setSeguimientoSolicitudCliente(undefined);
      }}/>
    );
  }

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      {formEliminacion && (
        <ModalEliminacion
          onSend={eliminarInvitacion}
          onClose={() => setFormEliminacion(undefined)}
        />
      )}

      <div className="cont_Info">
        <h1 className="titt">Invitaciones</h1>
        <p className="descripcionClientes">
          Aquí puedes conocer las capacidades de crédito de tus clientes y la etapa en la que se encuentran en su crédito
        </p>

        <div className="cont_flex cont-form">
          <div className="dosCuttonFormularios">
            <Link
              className="btn btn_bco col3 campo-form "
              to="/ejecutivo/nuevaInvitacion"
            >
              Enviar nueva invitación a cliente
            </Link>
          </div>
        </div>

        <div className="tabla_clientes">
          <div className="ag-theme-material" style={{ height: 400 }}>
            <AgGridReact
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              rowData={invitaciones}
              statusBar={statusBar}
              gridOptions={gridOptions}
              onCellClicked={(props) => {
                if(props.column.colId !== "boton" && props.column.colId !== "boton_1"){
                  seguirSolicitudCliente(props.data);
                }
              }}
            />
          </div>
        </div>
        <br/>

      </div>
    </>
  );
}