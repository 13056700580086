import { Avatar } from "@mui/material";
import InputMensaje from "./InputMensaje";
import ContenedorMensajes from "./ContenedorMensajes";
import { useSetChatContext } from "../../providers/ChatProvider";
import { useEffect, useState } from "react";
import { getEP } from "../../utils/http";

export default function PopupChat({ ejecutivo, idChat }){
  const setDataChat = useSetChatContext();

  const [cargando, setCargando] = useState(true);
  const [nombreContacto, setNombreContacto] = useState("");

  async function abrirChat(){
    if(idChat){
      const peticionChat = await getEP("whatsapp/obtChat/" + idChat, {
        "x-token": ejecutivo.token,
      });

      if(peticionChat?.statusCode === 200 && peticionChat.respuesta.chat){
        const conversacion = peticionChat.respuesta.chat;
        setNombreContacto(conversacion.nombre);
        setDataChat(prevData => {
          return {
            ...prevData,
            ejecutivo: {
              id: ejecutivo.id,
              token: ejecutivo.token,
            },
            chat: {
              id: conversacion.idChat,
              contacto: {
                id: conversacion.idContacto,
                telefono: conversacion.telefono,
                nombre: conversacion.nombre
              },
              ultimoMensaje: {
                id: conversacion.idUltimoMensaje,
                fechaCreacion: conversacion.fechaCreacionMensaje,
                remitente: conversacion.remitente,
                tipo: conversacion.tipoMensaje,
                texto: conversacion.texto
              }
            }
          };
        });
      }
    }
  }
  
  useEffect(() => {
    async function cargarDatos(){
      setCargando(true);
      await abrirChat();
      setCargando(false);
    }

    cargarDatos();
  }, []);

  return (
    <>
      <div id="chat-panelDerecho" className="width100 height100">
        <div className='panel-header'>
          <div className='header-container'>
            <div className='header-left'>
              <Avatar className="avatar"/>
              <div className='conversation-name'>
                <p>{nombreContacto}</p>
              </div>
            </div>
          </div>
        </div>
        {!cargando && <ContenedorMensajes />}
        <InputMensaje />
      </div>
    </>
  );
}