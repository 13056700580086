import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { deleteEP, get } from "../../../utils/http.js";
import { Cookies } from "react-cookie";
import EtapaClienteEjecutivo from "./EtapaClienteEjecutivo.jsx";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { textoLocalAgGrid } from "../../../components/Tablas.jsx";
import { ModalCotizacionesCliente, ModalEliminacion } from "../../../components/Modals.jsx";
import { jwtDecode } from "jwt-decode";
import { formatoFechaIsoADma } from "../../../utils/operaciones_formulario.js";

export default function ClientesEjecutivo() {
  const cookies = new Cookies();
  const tokenEjecutivo = useRef(cookies.get("token"));
  const idEmpresa = useRef(jwtDecode(cookies.get("token"))?.idEmpresa);

  const navigate = useNavigate();
  const tablaRef = useRef(null);
  const ultimoCursor = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [seguimientoSolicitudCliente, setSeguimientoSolicitudCliente] = useState(undefined);
  const [masDetalleCliente, setMasDetalleCliente] = useState(undefined);
  const [formEliminacion, setFormEliminacion] = useState(undefined);

  /*Configuración de Tabla de Clientes*/
  const confFiltros = {
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions : [ "contains" ],
      maxNumConditions: 1
    }
  }

  const columnDefs = [
    { headerName: "Fecha de registro", field: "fechaRegistro", valueFormatter: param => param.value ? formatoFechaIsoADma(param.value) : "N/A"},
    { 
      headerName: "Cliente", 
      field: "cliente", 
      ...confFiltros, 
      cellRenderer: (props) => 
      <div>
        {props.data?.mensajeNuevo && (
          <i className="fa fa-whatsapp color-notificacion-chat"></i>
        )}
        {props.value}
      </div>
    },
    { headerName: "Correo", field: "correo", ...confFiltros },
    { headerName: "Telefono", field: "telefono" },
    { headerName: "Banco seleccionado", field: "bancoSeleccionado",
      cellDataType: "Number",
      valueFormatter: param => param.value? "SI" : "NO",
    },
    {
      headerName: "Cotizar", field: "boton", 
      cellRenderer: (props) => 
        <button className="btn_cuadrado color_empresa"
          onClick={() => {
            localStorage.setItem("idCliente", props.data?.idCliente);
            navigate("/ejecutivo/registrarCliente");
          }}
        >
          Cotizar
        </button>,
      minWidth: 120
    },
    {
      headerName: "Cotizaciones", field: "boton", 
      cellRenderer: (props) => {
        if(props.data?.cotizaciones){
          return (<button className="link"
            onClick={() => {
              setMasDetalleCliente(props.data);
            }}
            >
              Ver cotizaciones
            </button>);
        }else{
          return(<button className="link inactivo">Sin cotizaciones</button>);
        }
      },
      minWidth: 200
    },
    {
      headerName: "Eliminar", field: "boton", 
      cellRenderer: (props) => 
        <button className="btn_cuadrado color_empresa"
          onClick={() => {
            setFormEliminacion({id: props.data?.idCliente});
          }}
        >
          Eliminar
        </button>,
      minWidth: 150
    }
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      editable: false,
      suppressMovable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      minWidth: 200,
      filter: false
    };
  }, []);

  const gridOptions = {
    suppressCellFocus: true,
    defaultColDef: {
      //sortable: true,
      resizable: true,
      filter: true,
    },
    components: {
      rowNodeIdRenderer: function (params) {
        return params.node.id + 1;
      },
    },
    sideBar: true,
    //pagination: true,
    rowGroupPanelShow: "always",
    statusBar: {
      items: [{ component: "agAggregationComponent" }],
    },
    //paginationPageSize: 500,
    enableRangeSelection: true,
    enableCharts: true,
    localeText: textoLocalAgGrid,
    
    rowBuffer: 0,
    rowModelType: "infinite",
    cacheBlockSize: 100,
    cacheOverflowSize: 2,
    maxConcurrentDatasourceRequests: 1,
    infiniteInitialRowCount: 0,
    maxBlocksInCache: 10,
  };

  const onGridReady = useCallback((params) => {
    let filtroCorreo = undefined;
    let filtroNombre = undefined;
    const ruta = idEmpresa.current ? `${idEmpresa.current}/` : "";

    const dataSource = {
      rowCount: undefined,
      getRows: async (params) => {

        const filterModel = params.filterModel;
        const filterPresent = filterModel && Object.keys(filterModel).length > 0;

        if (filterPresent) {
          ultimoCursor.current = filtroCorreo === filterModel?.correo?.filter && filtroNombre === filterModel?.cliente?.filter ? ultimoCursor.current : null;
          filtroCorreo = filterModel?.correo?.filter;
          filtroNombre = filterModel?.cliente?.filter;
        }else{
          ultimoCursor.current = filtroCorreo !== undefined || filtroNombre !== undefined ? null : ultimoCursor.current;
          filtroCorreo = undefined;
          filtroNombre = undefined;
        }

        const cursor = ultimoCursor.current ? `&cursor=${ultimoCursor.current}` : "";
        const correo = filterModel?.correo ? `&correo=${filterModel.correo.filter}` : "";
        const nombre = filterModel?.cliente ? `&nombre=${filterModel.cliente.filter}` : "";
        const query = `?longitudPagina=100${cursor}${correo}${nombre}`;
        const peticion = await get("obtUltimosClientes", ruta + query);

        if (peticion?.statuscode === 200) {
          const { clientes, cursorSiguiente } = peticion.respuesta;

          ultimoCursor.current = cursorSiguiente;

          let lastRow = -1;
          if(clientes.length < 100){
            lastRow = clientes.length + params.startRow;
          }else if(!cursorSiguiente){
            lastRow = params.startRow;
          }

          params.successCallback(clientes, lastRow);
        }
      }
    };

    params.api.setDatasource(dataSource);
    
  }, []);

  function seguirSolicitudCliente(data){
    setSeguimientoSolicitudCliente({
      id: data?.idCliente,
      nombreCompleto: data?.cliente,
      correo: data?.correo,
      telefono: data?.telefono
    });
  }

  async function eliminarCliente(data){
    const peticionEliminar = await deleteEP("eliminarCliente/" + formEliminacion.id,
      {"x-token": tokenEjecutivo.current},
      {
        idCatCausa: data.causa,
        razon: data.razon
      }
    );

    if(peticionEliminar?.statusCode === 200){
      ultimoCursor.current = null;
      tablaRef.current.api.purgeInfiniteCache();
      setFormEliminacion(undefined);
    }else if(peticionEliminar?.statusCode === 500){
      Swal.fire({
        icon: "error",
        title: "No se pudo eliminar cliente",
        confirmButtonText: "Aceptar"
      });
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al eliminar cliente",
        text: "Vuelve a intentarlo más tarde",
        confirmButtonText: "Aceptar"
      });
    }
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);

  if(seguimientoSolicitudCliente !== undefined){
    return(
      <EtapaClienteEjecutivo data={{cliente: seguimientoSolicitudCliente}} regresar={() => {
        setSeguimientoSolicitudCliente(undefined);
        ultimoCursor.current = null;
      }}/>
    );
  }else{
    return (
      <>
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <div className="loading-text">Cargando...</div>
          </div>
        )}

        {masDetalleCliente && (
          <ModalCotizacionesCliente
            cliente={masDetalleCliente}
            onClose={() => {
              setMasDetalleCliente(undefined);
            }}
          />
        )}

        {formEliminacion && (
          <ModalEliminacion
            onSend={eliminarCliente}
            onClose={() => setFormEliminacion(undefined)}
          />
        )}
  
        <div className="cont_Info">
          <h1 className="titt">Clientes</h1>
          <p className="descripcionClientes">
            Aquí puedes conocer las capacidades de crédito de tus clientes y la etapa en la que se encuentran en su crédito
          </p>
  
          <div className="tabla_clientes">
            <div className="ag-theme-material" style={{ height: 400 }}>
              <AgGridReact
                ref={tablaRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                gridOptions={gridOptions}
                onCellDoubleClicked={(props) => {
                  if(props.column.colId === "cliente" || props.column.colId === "correo" || props.column.colId === "telefono"){
                    seguirSolicitudCliente(props.data);
                  }
                }}
              />
            </div>
          </div>
          <br/>
        </div>
      </>
    );
  }
}