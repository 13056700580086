import { useSearchParams } from "react-router-dom";
import OfertasBancos from "../../components/OfertasBancos";

export default function ClienteBancosOfertados(){
  const [searchParams, ] = useSearchParams();
  const idCuestionario = searchParams.get("idCuestionario");
  const idResPaso1 = searchParams.get("idResPaso1");

  if(idCuestionario === null || idResPaso1 === null){
    return(
      <div className="flex_centrado height100">
        <div className="textoCentrado">
          <h1>Error!</h1>
          <p>No se pudo cargar los datos</p>
        </div>
      </div>
    );
  }

  return(
    <>
      <div className="cont_sp_contenido">
        <OfertasBancos idCuestionario={idCuestionario} idResPaso1={idResPaso1}/>
      </div>
    </>
  );
}