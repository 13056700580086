import { useEffect, useRef, useState } from "react";
import BurbujaChat from "./BurbujaChat";
import { getEP } from "../../utils/http";
import { useChatContext, useSetChatContext,  } from "../../providers/ChatProvider";

export default function ContenedorMensajes() {
	const chatData = useChatContext();
	const setChatData = useSetChatContext();

	const [socket, setSocket] = useState(null);
	const [mensajes, setMensajes] = useState([]);
	const [restriccionTemplates, setRestriccionTemplates] = useState(false);
	const refListaMensajes = useRef(null);

	async function obtMensajes(){
		const peticionMensajes = await getEP("whatsapp/obtMensajes/" + chatData.chat.id, {
			"x-token": chatData.ejecutivo.token
		});

		if(peticionMensajes.statusCode === 200){
			setMensajes(peticionMensajes.respuesta.mensajes);
			if(peticionMensajes.respuesta.ultimoMensajeCliente){
				const date = new Date(peticionMensajes.respuesta.ultimoMensajeCliente.fechaCreacion);
				let diferenciaDias = Math.floor((new Date() - date) / (1000 * 60 * 60 * 24));
				if(diferenciaDias > 0){
					setRestriccionTemplates(true);
				}else{
					setRestriccionTemplates(false);
				}
			}else{
				setRestriccionTemplates(true);
			}
		}

		const ultimoHijo = refListaMensajes.current?.lastChild;
		if(ultimoHijo){
			ultimoHijo.scrollIntoView({ behavior: "smooth" });
		}
	}

	async function webSocketActualizarChat(){
		socket.send(JSON.stringify({
			action: "chatWhatsapp/ejecutivo/actualizarChat",
			data: {
				idChat: chatData.chat.id
			}
		}));
	}

	useEffect(() => {
		obtMensajes();
		if(socket !== null){
			webSocketActualizarChat();
		}
	}, [chatData.chat.id]);

	useEffect(() => {
		if(chatData.chat.ultimoMensajeEjecutivo?.id){
			const nuevoMensaje = chatData.chat.ultimoMensajeEjecutivo;
			setMensajes(prevMensajes => [...prevMensajes, nuevoMensaje]);
			setChatData(prevData => {
				const data = {...prevData};
				data.chat.ultimoMensajeEjecutivo = undefined;
				return data;
			});
		}
	}, [chatData.chat.ultimoMensajeEjecutivo?.id]);

	useEffect(() => {
		const nuevoSocket = new WebSocket("wss://siamo5y8o8.execute-api.us-east-2.amazonaws.com/production/");

		nuevoSocket.onopen = () => {
			console.log("Conexión WebSocket abierta");

			nuevoSocket.send(JSON.stringify({
				action: "registrarConexion",
				data: {
					servicio: "chatWhatsapp",
					ladoConexion: "cliente",
					usuario: {
						tipo: "ejecutivo",
						id: chatData.ejecutivo.id
					},
					actual: {
						idChat: chatData.chat.id,
						idCuentaWhatsapp: 1,
					}
  			}
			}));
		}

		nuevoSocket.onmessage = (event) => {
			const data = JSON.parse(event.data);

			if(data.evento === "chatWh-mensajeCliente"){
				const ultimoMensaje = data.data;
				setMensajes(prevMensajes => [...prevMensajes, ultimoMensaje]);
				setChatData(prevData => {
					const data = {...prevData, actualizarChats: true};
					data.chat.ultimoMensaje = {
						id: ultimoMensaje.id,
						fechaCreacionMensaje: ultimoMensaje.fechaCreacion,
						remitente: ultimoMensaje.remitente,
						tipo: ultimoMensaje.tipo,
						texto: ultimoMensaje.texto,
						url: ultimoMensaje.url,
						nombreMedia: ultimoMensaje.nombreMedia
					}
					return data;
				});
				if(restriccionTemplates){
					setRestriccionTemplates(false);
				}
			}
		}

		nuevoSocket.onclose = (event) => {
      console.log("Conexión cerrada:", event.reason);
    };

    nuevoSocket.onerror = (error) => {
      console.error("Error en la conexión:", error);
    };

		setSocket(nuevoSocket);

		return () => {
      console.log("Cerrando la conexión WebSocket...");
      nuevoSocket.close(1000, "Componente desmontado");
    };
	}, []);

  return (
		<>
			<div id="chat-contenedor-mensajes">
				<div ref={refListaMensajes} className='message-list'>
					{mensajes?.map((mensaje, idx) => (
							<BurbujaChat key={mensaje.id} mensaje={mensaje} prevMensaje={idx > 0 ? mensajes[idx-1] : undefined} />
					))}
				</div>
			</div>
			{restriccionTemplates && (
				<div className="chat-alerta">
					<small className="chat-alerta-texto">
						<strong>No se puede enviar mensajes de texto</strong><br/>
						El último mensaje del cliente fue hace más de 24hrs.
					</small>
				</div>
			)}
		</>
	);
}