import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Tooltip } from "react-tooltip";

export function Elemento({elemento, inactivo = false, onClick = () => {}}){

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
      id: elemento.id,
      data: {
        tipo: "elemento",
        elemento
      },
      disabled: inactivo
    });

  const style = {
      transition,
      transform: CSS.Transform.toString(transform)
    }

  if(isDragging){
    return <div ref={setNodeRef} style={{border: "1px #C1C1C1 solid", minHeight: "1.5em", marginBlock: "0.2em", cursor: "grabbing", ...style,}} className="el-kanban">
    </div>;
  }

  return(
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="el-kanban"  onClick={() => {
      onClick(elemento);
    }}>
      {elemento?.notificacion?.mensaje && (<div className="el-notificacion-kanban">
        <i className="fa fa-whatsapp"></i>
      </div>)}
      <h3 className="el-titulo-kanban">{elemento.titulo}</h3>
      <div id={`tooltip-kanban-${elemento.id}`} className="el-contenido-kanban">
        <p>{elemento?.renglon1}</p>
        <p>{elemento?.renglon2}</p>
      </div>
      {
        elemento.tooltip && (
          <Tooltip anchorSelect={`#tooltip-kanban-${elemento.id}`} place="bottom">
            <div className="tooltip_normal">
              {elemento.tooltip}
            </div>
          </Tooltip>
        )
      }
    </div>
  )
}

export function ElementoEstatico({elemento, onClick = () => {}}){
  return(
    <div className="el-kanban estatico"  onClick={() => {
      onClick(elemento);
    }}>
      {elemento?.notificacion?.mensaje && (<div className="el-notificacion-kanban">
        <i className="fa fa-whatsapp"></i>
      </div>)}
      <h3 className="el-titulo-kanban">{elemento.titulo}</h3>
      <div id={`tooltip-kanban-${elemento.id}`} className="el-contenido-kanban">
        <p>{elemento?.renglon1}</p>
        <p>{elemento?.renglon2}</p>
      </div>
      {
        elemento.tooltip && (
          <Tooltip anchorSelect={`#tooltip-kanban-${elemento.id}`} place="bottom">
            <div className="tooltip_normal">
              {elemento.tooltip}
            </div>
          </Tooltip>
        )
      }
    </div>
  )
}