import { X } from "lucide-react";
import { Avatar } from "@mui/material";
import { useChatContext, useSetChatContext } from "../../providers/ChatProvider";
import ContenedorMensajes from "./ContenedorMensajes";
import InputMensaje from "./InputMensaje";
import MarcadorChat from "./MarcadorChat";

export default function PanelDerecho() {
	const chatData = useChatContext();
	const setChatData = useSetChatContext();
	if (!chatData?.chat?.id) return <MarcadorChat /> ;

	const nombreContacto = chatData.chat.contacto.nombre;

	function cerrarChat(){
		setChatData(prevData => ({
			...prevData,
			chat: undefined
		}));
	}

	return (
		<div id="chat-panelDerecho">
			<div className='panel-header'>
				<div className='header-container'>
					<div className='header-left'>
						<Avatar className="avatar"/>
						<div className='conversation-name'>
							<p>{nombreContacto}</p>
						</div>
					</div>

					<div className='header-right'>
						<X size={16} className='cursor-pointer' onClick={cerrarChat} />
					</div>
				</div>
			</div>
			<ContenedorMensajes />
			<InputMensaje />
		</div>
	);
}