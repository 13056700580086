
export default function MarcadorChat() {
  return (
		<div id="chat-placeholder">
			<div className='chat-placeholder-content'>
				<p className='chat-placeholder-title'>Conéctate con tus clientes fácilmente</p>
				<p className='chat-placeholder-text'>
					Chatea con tus clientes en tiempo real y lleva un registro completo de las conversaciones.
				</p>
			</div>
		</div>
	);
}