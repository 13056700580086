import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { deleteDocEndpoint, postGetFile, get, post, putS3 } from '../utils/http';
import Swal from 'sweetalert2';
import { ModalNombreArchivo } from './Modals';

const FILE_MAX_SIZE = 50 * 1024 * 1024; // 50MB

const DropdownArchivo = ({ opciones = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} style={{ position: 'relative', display: 'inline-block' }}>
      <button className='btn-icon' onClick={toggleDropdown}>
        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
      </button>

      {isOpen && (
        <ul className='dropdown-opciones'>
          {opciones.map((opcion, index) => (
            <li
              key={index}
              style={{ padding: '5px 10px', cursor: 'pointer' }}
              onClick={() => {
                opcion.funcion();
                closeDropdown();
              }}
            >
              {opcion.nombre}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export function Archivo({archivo, actualizarArchivos}){
  const { open, getRootProps, getInputProps, isDragActive } = useDropzone({noDragEventsBubbling: true, noClick: true, maxSize: FILE_MAX_SIZE, onDropAccepted: async (archivos) => {

    const nombresArchivos = archivos.map(archivo => archivo.name);

    const peticionSubirArchivo = await post("adminSaturn/subirArchivoCompartido", {
      archivos: nombresArchivos,
      ruta: archivo.ruta
    });

    if(peticionSubirArchivo?.statuscode === 200){
      const urlsPrefirmadas = peticionSubirArchivo.respuesta;

      const peticionesS3 = await Promise.all(archivos.map( (archivo) => {
        return putS3(urlsPrefirmadas[archivo.name], archivo);
      }));

      if(peticionesS3.some(peticionS3 => peticionS3?.ok)){
        Swal.fire({
          icon: "success",
          title: "Archivo subido correctamente",
          confirmButtonText: "Aceptar"
        });
  
        const peticionObtArchivos = await get("adminSaturn/obtArchivosCompartidos", "");
        if(peticionObtArchivos?.statuscode === 200){
          actualizarArchivos([peticionObtArchivos.respuesta]);
        }
      }else{
        Swal.fire({
          icon: "error",
          title: "Un archivo no se subió correctamente",
          confirmButtonText: "Aceptar"
        });
      }
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al subir archivo",
        confirmButtonText: "Aceptar"
      });
    }
    
  }, 
  onDropRejected: (rechazados) => {
    if(rechazados[0].errors.find(error => error.code === "file-too-large")){
      Swal.fire({
        icon: "error",
        title: "Archivo muy grande",
        text: "El archivo no puede pesar más de 50MB.",
        confirmButtonText: "Aceptar"
      });
    }
  }});
  const [abierto, setAbierto] = useState(archivo?.visible ?? false);
  const [nuevoArchivo, setNuevoArchivo] = useState(false);

  const estiloDropzone = useMemo(() => {
    return {
      ...(isDragActive ? {
        borderWidth: "1px",
        borderRadius: "2px",
        borderColor: "var(--ColorBoton)",
        borderStyle: "dashed"
      } : {}),
    }
  }, [isDragActive]);

  const eliminarArchivo = async () => {
    const peticion = await deleteDocEndpoint("adminSaturn/eliminarArchivoCompartido", {
      nombreArchivo: archivo.ruta
    });

    if(peticion?.statuscode === 200){
      Swal.fire({
        icon: "success",
        title: "Eliminación exitosa",
        confirmButtonText: "Aceptar"
      });
      const peticion = await get("adminSaturn/obtArchivosCompartidos", "");
      if(peticion?.statuscode === 200){
        actualizarArchivos([peticion.respuesta]);
      }
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al eliminar archivo",
        confirmButtonText: "Aceptar"
      });
    }
  }

  const descargarArchivo = async () => {
    const resultado = await postGetFile("adminSaturn/obtArchivoCompartido", {
      nombreArchivo: archivo.ruta,
    });
    if (resultado !== 1){
      Swal.fire({
        icon: "error",
        title: "Algo salió mal",
        text: "Inténtelo nuevamente más tarde.",
        confirmButtonText: "Aceptar"
      });
    }
  }

  const crearCarpeta = async (data) => {
    const nuevaCarpeta = archivo.ruta + (archivo.ruta === "/" ? "" : "/") + data.nombreArchivo;

    const peticionCarpeta = await post("adminSaturn/crearCarpeta", {
      nombreArchivo: nuevaCarpeta,
    });

    if(peticionCarpeta?.statuscode === 200){
      const peticion = await get("adminSaturn/obtArchivosCompartidos", "");
      if(peticion?.statuscode === 200){
        actualizarArchivos([peticion.respuesta]);
      }
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al crear carpeta",
        confirmButtonText: "Aceptar"
      });
    }
  }

  if(archivo.esCarpeta){
    return(
      <>
        <ModalNombreArchivo open={nuevoArchivo} onClose={() => setNuevoArchivo(false)} dataCallback={crearCarpeta}/>
        <li {...getRootProps({className: "my-1", style: estiloDropzone})} >
          <input {...getInputProps()} />
          <span>
            <div className='fe-archivo'>
              <div onClick={() => setAbierto(!abierto)}>
                {archivo.archivos.length > 0 && (<i className="fa fa-angle-right" aria-hidden="true"></i>)}
                <i className="fa fa-folder" aria-hidden="true"></i>
                {archivo.nombre}
              </div>
              <DropdownArchivo opciones={[
                {nombre: "+Archivo", funcion: open},
                {nombre: "+Carpeta", funcion: () => setNuevoArchivo(true)},
                {nombre: "Eliminar", funcion: eliminarArchivo},
              ]} />
            </div>
          </span>
    
          {abierto && (
              <ul className="pl-2">
                {archivo.archivos?.map((archivo) => (
                  <Archivo key={archivo.nombre} archivo={archivo} actualizarArchivos={actualizarArchivos} />
                ))}
              </ul>
            )
          }
        </li>
      </>
      
    );
  }else{
    return (
      <li className="my-1">
        <span>
          <div className='fe-archivo'>
            <div>
              <i className="fa fa-file" aria-hidden="true"></i>
              {archivo.nombre}
            </div>
            <DropdownArchivo opciones={[
              {nombre: "Eliminar", funcion: eliminarArchivo},
              {nombre: "Descargar", funcion: descargarArchivo}
            ]} />
          </div>
        </span>
      </li>
    );
  }
}

/*function ArbolArchivosEditable({archivos, setArchivos}) {

  return (
    <div id="arbol-archivos">
      <ul>
        {archivos?.map((archivo) => (
          <Archivo key={archivo.nombre} archivo={archivo} actualizarArchivos={setArchivos} />
        ))}
      </ul>
    </div>
  );
}*/

export function ArchivoNoEditable({archivo, actualizarArchivos}){
  const [abierto, setAbierto] = useState(archivo?.visible ?? false);

  const descargarArchivo = async () => {
    const resultado = await postGetFile("adminSaturn/obtArchivoCompartido", {
      nombreArchivo: archivo.ruta,
    });
    if (resultado !== 1){
      Swal.fire({
        icon: "error",
        title: "Algo salió mal",
        text: "Inténtelo nuevamente más tarde.",
        confirmButtonText: "Aceptar"
      });
    }
  }

  if(archivo.esCarpeta){
    return(
      <li className="my-1" >
        <span>
          <div className='fe-archivo'>
            <div onClick={() => setAbierto(!abierto)}>
              {archivo.archivos.length > 0 && (<i className="fa fa-angle-right" aria-hidden="true"></i>)}
              <i className="fa fa-folder" aria-hidden="true"></i>
              {archivo.nombre}
            </div>
          </div>
        </span>
  
        {abierto && (
            <ul className="pl-2">
              {archivo.archivos?.map((archivo) => (
                <ArchivoNoEditable key={archivo.nombre} archivo={archivo} actualizarArchivos={actualizarArchivos} />
              ))}
            </ul>
          )
        }
      </li>
    );
  }else{
    return (
      <li className="my-1">
        <span>
          <div className='fe-archivo'>
            <div>
              <i className="fa fa-file" aria-hidden="true"></i>
              {archivo.nombre}
            </div>
            <DropdownArchivo opciones={[
              {nombre: "Descargar", funcion: descargarArchivo}
            ]} />
          </div>
        </span>
      </li>
    );
  }
}
