import "../../App.css";
import { InfoCotizacionXL, InfoDefault } from "../../components/Cards";
import { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import { deleteEP, get } from "../../utils/http";
import {
  ConCotizacionP1,
  ConCotizacionP2,
} from "../../components/Msjs";
import * as ReactDOM from "react-dom/client";
import { ModalLoadingGeneral } from "../../components/Modals";
import { obtEmpresaUrl } from "../../utils/global_functions";
import Swal from "sweetalert2";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------ Pantalla donde se muestran las cotizaciones del usuario ----------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function Cotizaciones() {
  const cookies = new Cookies();

  localStorage.removeItem("idResPaso1Cuestionario");
  localStorage.removeItem("BancoLS1");
  localStorage.removeItem("BancoLS2");
  localStorage.removeItem("idResPaso1SeleccionarBancos");
  localStorage.removeItem("verBancos");
  localStorage.removeItem("plazoParaBancosSeleccionados");

  localStorage.removeItem("monto");
  localStorage.removeItem("plazoVal");
  localStorage.removeItem("montoPrestar");
  localStorage.removeItem("enganche");
  localStorage.removeItem("tasa");
  localStorage.removeItem("mensualidad");
  localStorage.removeItem("sinRespuestaBuro");

  localStorage.removeItem("statuscodepaso2");

  localStorage.removeItem("idCuestionarioSolicitud");
  localStorage.removeItem("idResPrecalifica");
  localStorage.removeItem("idCredito");
  localStorage.removeItem("idActividadEconomica");

  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModalLoading, setIsOpenModalLoading] = useState(true);

  const [cotizaciones, setCotizaciones] = useState([]);

  const info = async () => {
    try {
      let data = await get("userCotizaciones", cookies.get("id"));
      if (data.statucode === 200) {
        let infoCotizaciones = [];
        let promises = [];

        for (let num = 0; num < data.cotizaciones.length; num++) {
          promises.push(
            get("ObtIdCuestionarioPorIdResPaso1",parseInt(data.cotizaciones[num].id))
          );
          promises.push(
            get("obtDatosPaso2PorIdResPaso1", parseInt(data.cotizaciones[num].id))
          );
        }

        let responses = await Promise.all(promises);

        for (let i = 0; i < responses.length; i += 2) {
          let datosIdResPaso0 = responses[i];
          let responseBtBuro = responses[i + 1];

          if (
            datosIdResPaso0.statucode === 200 &&
            responseBtBuro.statuscode !== 0
          ) {
            let dataBLC = null;
            let enviarTotalBancos = 0;
            if (datosIdResPaso0.idCuestionario !== 0) {
              dataBLC = await get("userBancos", datosIdResPaso0.idCuestionario);
              if (dataBLC.statucode === 200) {
                enviarTotalBancos = dataBLC.bancos.length;
              }
            }

            infoCotizaciones.push({
              nombre: "Cotización " + [i / 2 + 1],
              fechaCrecion: data.cotizaciones[i / 2].fechaCrecion,
              valorPropiedad: data.cotizaciones[i / 2].valorPropiedad,
              plazo: data.cotizaciones[i / 2].plazo,
              montoSolicitado: data.cotizaciones[i / 2].montoSolicitado,
              enganche: data.cotizaciones[i / 2].enganche,
              tasa: data.cotizaciones[i / 2].tasaPromedio,
              cuestionarioId: datosIdResPaso0.idCuestionario,
              idResPaso1: data.cotizaciones[i / 2].id,
              longitudBancos: enviarTotalBancos,
              statusBuro: responseBtBuro.statuscode,
            });
          }
        }

        if (infoCotizaciones.length === 0) {
          ReactDOM.createRoot(
            document.getElementById("mostrarSinCotizaciones")
          ).render(<InfoDefault nombre="cotizaciones" />);
        } else {
          setCotizaciones(infoCotizaciones);
          console.log("Cotizaciones", cotizaciones);
        }

        setIsOpenModalLoading(false);
      }
    } catch (error) {
      console.error("Error al obtener las cotizaciones:", error);
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    info();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">

        

        {cotizaciones?.length === 0 ? (
            <div id="mostrarSinCotizaciones"></div>
          ) : (
            <>
            <div className="Resum cont-Cotizaciones">
              <ConCotizacionP1 />
            </div>

            <div className="cont_flex con_flex_wrap flex_start">
              {cotizaciones?.map(cotizacion => 
                <InfoCotizacionXL
                  key={cotizacion.nombre}
                  nombre={cotizacion.nombre}
                  valorPropeidad={cotizacion.valorPropiedad}
                  plazo={cotizacion.plazo}
                  fecha={cotizacion.fechaCrecion}
                  montoSolicitado={cotizacion.montoSolicitado}
                  enganche={cotizacion.enganche}
                  tasa={cotizacion.tasa}
                  cuestionarioId={cotizacion.cuestionarioId}
                  longitudBancos={cotizacion.longitudBancos}
                  idResPaso1={cotizacion.idResPaso1}
                  statusBuro={cotizacion.statusBuro}
                  onRemove={async () => {
                    const peticionCotizacion = cotizacion.cuestionarioId > 0 ? 
                      await deleteEP("eliminarCotizacion/" + cotizacion.cuestionarioId)
                      : await deleteEP("eliminarResPaso1/" + cotizacion.idResPaso1);
                    
                    if(peticionCotizacion?.statusCode === 200){
                      setCotizaciones(prevData => prevData.filter(cotiz => cotiz.idResPaso1 !== cotizacion.idResPaso1));
                    }else{
                      Swal.fire({
                        title: "Error al eliminar la cotización",
                        text: peticionCotizacion?.msg,
                        icon: "error",
                        confirmButtonText: "Aceptar",
                      });
                    }
                  }}
                />
              )}
            </div>

            <div className="Resum cont-Cotizaciones">
              <ConCotizacionP2 /> 
            </div>
            </>
          )
        }

        <ModalLoadingGeneral open={isOpenModalLoading}></ModalLoadingGeneral>
      </div>
    </>
  );
}
