import "../../App.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  useDataPublic,
  useSetDataPublic,
} from "../../providers/dataPublic/PublicProvider";
import { useForm } from "react-hook-form";
import { ruleEmail, ruleContraseña } from "../../assets/validateRules";
import { post, put } from "../../utils/http";
import {
  Aprobado1,
  NoAprobado1,
  NoBuro,
  NoBuroLogueado,
} from "../../components/Msjs";
import Swal from "sweetalert2";

import {
  actualizarEstatusInvitacion,
  obtEmpresaUrl,
  renderGoogleAuthButton,
} from "../../utils/global_functions";
import { Tooltip } from "react-tooltip";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------------------- Pantalla: Crear cuenta ---------------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function CrearCuenta() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm();
  const actionContext = useSetDataPublic();
  const { statuscode } = useDataPublic();
  const [nombreR, setNombre] = useState(
    localStorage.getItem("nombreCalculadora")
      ? localStorage.getItem("nombreCalculadora")
      : ""
  );
  const [apPaternoR, setAPpaterno] = useState(
    localStorage.getItem("apellidoPCalculadora")
      ? localStorage.getItem("apellidoPCalculadora")
      : ""
  );
  const [apMaternoR, setApmaterno] = useState(
    localStorage.getItem("apellidoMCalculadora")
      ? localStorage.getItem("apellidoMCalculadora")
      : ""
  );
  const [emailR, setEmail] = useState(
    localStorage.getItem("correoCalculadora")
      ? localStorage.getItem("correoCalculadora")
      : ""
  );
  const [passwordR, setPassword] = useState("");
  const [validarReglaContraseña, setvalidarReglaContraseña] = useState();
  const cookie = new Cookies();

  function loadingPop(){
    let timerInterval;
    Swal.fire({
      title: "Iniciando sesión",
      timer: 20000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft();
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        Swal.fire({
          title: "Upss...",
          confirmButtonText: "Aceptar",
          icon: "info",
          html: "<b>El tiempo de espera ha sido superado, intentalo mas tarde...</b>",
        });
      }
    });
  }

  const signUp = async () => {
    if (validarReglaContraseña === true) {
      let idSocios = null;
      if (
        localStorage.getItem("idSocioHash") != undefined &&
        localStorage.getItem("idSocioHash") != null
      ) {
        idSocios = localStorage.getItem("idSocioHash");
      } else {
        idSocios = localStorage.getItem("idEmpresaUsuarioDefault");
      }

      let data = {
        usaurio: {
          correo: emailR,
          nombre: nombreR,
          paterno: apPaternoR,
          materno: apMaternoR,
          idSocios: idSocios,
        },
        password: {
          password1: passwordR,
        },
      };

      let response = await post("newUser", data);
      actionContext({
        statuscode:
          response.statuscode === undefined
            ? response.status
            : response.statuscode,
      });
      if (response.statuscode === 200) {
        let timerInterval;
        Swal.fire({
          title: "Iniciando sesión",
          timer: 20000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {});
        signIn();
      } else {
        Swal.fire({
          title: "El correo ya ha sido utilizado",
          html:
            "Si es tu correo inicia sesión <br/>" +
            "Si no recuerdas la contraseña, reestablecela. <br/>" +
            "En su defecto intenta con otro correo.",
          icon: "info",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Iniciar sesión",
          denyButtonText: `Cambiar contraseña`,
          cancelButtonText: "Intentar con otro correo",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // window.location.href = "./home";
            window.location.href = "./informacionGeneral";
          } else if (result.isDenied) {
            window.location.href = "./recuperapass";
          }
        });
      }
    }
  };

  const signIn = async () => {
    let data = {
      usaurio: {
        correo: emailR,
      },
      password: {
        password1: passwordR,
      },
    };

    let response = await post("login", data);

    if (response.statuscode === 200) {
      cookie.set("session", true, { path: "/" });
      cookie.set("nombre", response.nombre, { path: "/" });
      cookie.set("correo", response.correo, { path: "/" }); //TODO: Posiblemente correo ath o jwt
      cookie.set("id", response.id, { path: "/" });
      //Una vez que se completo el registro borramos el hash
      localStorage.removeItem("idSocioHash");
      localStorage.removeItem("idEmpresaUsuarioDefault");
      //*Actualizamos el estatus de la invitacion, ya que se ha logueado y registrado correctamente
      if (localStorage.getItem("idInvitacionHash") != null) {
        //let params = {idInvitacion: localStorage.getItem("idInvitacionHash"), estatus:2};
        //let response = await put("actualizaEstatusInvitacion", params);
        //if(response.statuscode === 200){
        //  localStorage.removeItem("idInvitacionHash");
        //  console.log("estatus actualizado");
        //}
        actualizarEstatusInvitacion(
          localStorage.getItem("idInvitacionHash"),
          3
        );
      }

      // window.location.href = "./home";
      window.location.href = "./informacionGeneral";
    } else {
      Swal.fire({
        title: "Usuario y/o contraseña erronea!",
        text: "Verifica que la cuenta y la contraseña sea correcta",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const googleSignIn = async (respuesta) => {
    let idSocios = null;
    if (localStorage.getItem("idSocioHash") != null) {
      idSocios = localStorage.getItem("idSocioHash");
    } else {
      idSocios = localStorage.getItem("idEmpresaUsuarioDefault");
    }

    let peticion = await post("googleAuthCreacionCuenta", {
      token: respuesta.credential,
      idSocios: idSocios,
      validarEmail: localStorage.getItem("correoCalculadora")
    });

    actionContext({statuscode: peticion?.statuscode});

    if (peticion?.statuscode === 200) {
      cookie.set("session", true, { path: "/" });
      cookie.set("nombre", peticion.respuesta.nombre, { path: "/" });
      cookie.set("correo", peticion.respuesta.correo, { path: "/" });
      cookie.set("id", peticion.respuesta.id, { path: "/" });

      //Una vez que se completo el registro borramos el hash
      localStorage.removeItem("idSocioHash");
      localStorage.removeItem("idEmpresaUsuarioDefault");
      //*Actualizamos el estatus de la invitacion, ya que se ha logueado y registrado correctamente
      if (localStorage.getItem("idInvitacionHash") != null) {
        
        actualizarEstatusInvitacion(
          localStorage.getItem("idInvitacionHash"),
          3
        );
      }
      
      window.location.href = "./informacionGeneral";
    } else if (peticion?.statuscode === 401) {
      Swal.fire({
        title: "El correo ya ha sido utilizado",
        html:
          "Si es tu correo inicia sesión <br/>" +
          "Si no recuerdas la contraseña, reestablecela. <br/>" +
          "En su defecto intenta con otro correo.",
        icon: "info",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Iniciar sesión",
        denyButtonText: `Cambiar contraseña`,
        cancelButtonText: "Intentar con otro correo",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "./informacionGeneral";
        } else if (result.isDenied) {
          window.location.href = "./recuperapass";
        }
      });
    } else if (peticion?.statuscode === 406) {
      Swal.fire({
        title: "Error al crear cuenta!",
        text: "Utilice el correo utilizado en el formulario anterior",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    } else {
      Swal.fire({
        title: "Error de autenticación!",
        text: "Iniciar sesión con otro método",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  function verPassword() {
    var x = document.getElementById("password");
    var icono = document.getElementById("iconoPassword");
    if (x.type === "password") {
      x.type = "text";
      icono.className = "fa fa-eye-slash iconoOcultarPassword";
    } else {
      x.type = "password";
      icono.className = "fa fa-eye iconoOcultarPassword";
    }
  }

  useEffect(() => {
    obtEmpresaUrl();
    setValue("email", localStorage.getItem("correoCalculadora") ?? "");

    renderGoogleAuthButton((respuesta) => {
      googleSignIn(respuesta);
      loadingPop();
    }, "contenedorGoogleAuth");

    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <>
      <div className="cont_flex">
        <div className="col6">
          {!statuscode && <h1>Bienvenido</h1>}
          {statuscode === 200 && (
            <h1>
              Para obtener el mejor crédito hipotecario es necesario que te
              registres
            </h1>
          )}
          {statuscode === 400 && (
            <h1>Nos interesa darte el empuje que necesitas</h1>
          )}

          {!statuscode && (
            <p>
              <strong>Para poder ver cuanto te presta cada Banco es necesario generes una cuenta.</strong>
              Además tendrás acceso a los otros beneficios de pertenecer a nuestra comunidad.
            </p>
          )}

          {statuscode === 400 && (
            <p>
              Para ello, por favor registrate para que un ejecutivo te contacte
              y puedan diseñar un plan para mejorar tu capacidad crediticia
            </p>
          )}

          <form onSubmit={handleSubmit(signUp)}>
            <label htmlFor="nombre">Nombre(s) </label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              value={nombreR}
              onInput={(ev) => {
                setNombre(ev.target.value);
              }}
              {...register("nombre", { required: true })}
            />
            {errors.nombre?.type === "required" && (
              <span class="span">Este campo es requerido</span>
            )}
            <label htmlFor="apPaterno">Apellido Paterno</label>
            <input
              type="text"
              id="apPaterno"
              name="apPaterno"
              value={apPaternoR}
              onInput={(ev) => {
                setAPpaterno(ev.target.value);
              }}
              {...register("apPaterno", { required: true })}
            />
            {errors.apPaterno?.type === "required" && (
              <span class="span">Este campo es requerido</span>
            )}
            <label htmlFor="apMaterno">Apellido Materno</label>
            <input
              type="text"
              id="apMaterno"
              name="apMaterno"
              value={apMaternoR}
              onInput={(ev) => {
                setApmaterno(ev.target.value);
              }}
              {...register("apMaterno", { required: true })}
            />
            {errors.apMaterno?.type === "required" && (
              <span class="span">Este campo es requerido</span>
            )}
            <label htmlFor="email">
              Correo electrónico &nbsp;
              <i
                id="tooltip-correo-nuevo-usuario"
                className="fa fa-circle-exclamation tooltip_icon"
              ></i>
              <Tooltip anchorSelect="#tooltip-correo-nuevo-usuario">
                <div className="tooltip_normal">
                  Este campo no se puede modificar, se toma el correo electrónico
                  utilizado en el formulario anterior.
                </div>
              </Tooltip>
            </label>
            <input
              type="email"
              disabled
              id="email"
              name="email"
              value={emailR}
              onInput={(ev) => {
                setEmail(ev.target.value);
              }}
              {...register("email", { required: true, validate: ruleEmail })}
            />
            {errors.email?.type === "required" && (
              <span class="span">Este campo es requerido</span>
            )}
            {errors.email && (
              <span class="span"> El formato del correo no es valido</span>
            )}
            {/* {statuscode === 400 && <span class="span">Correo ya utilizado</span>} */}
            <label htmlFor="password">Contraseña</label>
            <div className="relativePosition">
              <input
                type="password"
                id="password"
                name="password"
                value={passwordR}
                onInput={(ev) => {
                  let tempPASSWWORD = ev.target.value;
                  setvalidarReglaContraseña(ruleContraseña(tempPASSWWORD));
                  setPassword(tempPASSWWORD);
                }}
                {...register("password", { required: true })}
              />
              <i
                id="iconoPassword"
                className="fa fa-eye iconoOcultarPassword"
                onClick={() => {
                  verPassword();
                }}
              >
                {" "}
              </i>
            </div>
            {validarReglaContraseña === false && (
              <small class="span">
                {" "}
                Ingresar contraseña con al menos una mayúscula, un número, un
                caracter especial y mínimo 8 caracteres.
              </small>
            )}
            {errors.password?.type === "required" && (
              <span class="span">Este campo es requerido</span>
            )}
            <div className="botones">
              <button type="submit" className="btn btn_morado">
                Crear cuenta
              </button>
            </div>

            <small>
              Tus datos personales son muy importantes para nosotros y tu
              seguridad es nuestra prioridad.
              <br /> Consulta nuestro &nbsp;
              <Link to="/avisoDePrivacidad" className="link">
                Aviso de privacidad
              </Link>
            </small>
          </form>
          <br/> 
          <br/>

          <div className="flex_centrado">
            <div id="contenedorGoogleAuth"></div>
          </div>
        </div>
        <div className="col6 cont_img">
          {localStorage.getItem("sinRespuestaBuro") === "true" ? (
            <NoBuro />
          ) : localStorage.getItem("statuscodepaso2") === "200" ? (
            <Aprobado1 />
          ) : (
            <NoAprobado1 />
          )}
          {/* {localStorage.getItem("statuscodepaso2") === 200 ?   <Aprobado1 /> : <NoAprobado1/>}   */}
        </div>
      </div>
    </>
  );
}
