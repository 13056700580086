import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMemo } from "react";
import { Elemento, ElementoEstatico } from "./Elemento";

export function Columna({columna, elementos, inactivo = true, elementosInactivos = false, onClickElement = () => {}}){
  const idsElementos = useMemo(() => elementos.map(elemento => elemento.id), [elementos]);

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: columna.id,
    data: {
      tipo: "columna",
      columna
    },
    disabled: inactivo
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  }

  if(isDragging){
    return <div ref={setNodeRef} style={{border: "1px #C1C1C1 solid", ...style,}} className="col-kanban"></div>;
  }

  return(
    <div ref={setNodeRef} style={style} className="col-kanban">
      <div {...attributes} {...listeners} className="col-header-kanban">
        <h2 className="col-titulo-kanban">{columna.titulo}</h2>
      </div>
      <div className="cont-el-kanban scrollbar" >
        <SortableContext items={idsElementos}>
          {elementos?.map(elemento => (
            <Elemento key={elemento.id} elemento={elemento} inactivo={elementosInactivos} onClick={onClickElement}/>
          ))}
        </SortableContext>
      </div>
    </div>
  )
}

export function ColumnaEstatica({columna, elementos, onClickElement = () => {}}){

  return(
    <div className="col-kanban">
      <div className="col-header-kanban">
        <h2 className="col-titulo-kanban">{columna.titulo}</h2>
      </div>
      <div className="cont-el-kanban scrollbar" >
          {elementos?.map(elemento => (
            <ElementoEstatico key={elemento.id} elemento={elemento} onClick={onClickElement}/>
          ))}
      </div>
    </div>
  )
}