import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import logoMorado from "../images/logo_morado.svg";

export function NavAdminEmpresa({ active }) {

  return (
    <>
      <div className=" menu_lateral">
        <ul>
          <li>
            <Link 
              to="/administradorEmpresa/invitaciones" 
              className={active === "01" ? "active" : ""}
            >
              <i className="fa fa-envelope"></i> Invitaciones
            </Link>
          </li>
          <li>
            <Link
              to="/administradorEmpresa/clientes"
              className={active === "02" ? "active" : ""}
            >
              <i className="fa fa-user"></i> Clientes
            </Link>
          </li>

        </ul>
        <div className="copyright">
          <p>
            En caso de que tengas preguntas, dudas o necesites asesoría no dudes
            en contactarnos. Nos encanta atenderte a la hora que lo necesites
          </p>
          <li>
            {" "}
            <Link to="/AvisoPrivacidadLogueado" className="link">
              Aviso de privacidad
            </Link>
          </li>
          <li>
            {" "}
            <Link to="/TerminosCondicionesLogueado" className="link">
              Términos y condiciones
            </Link>
          </li>
          <p>© 2025. Saturn5 Technologies SAPI de CV</p>
        </div>
      </div>
    </>
  );
}

export function MenuLateralResponsivoAdminEmpresa({ children, open, close }) {
  const cookies = new Cookies();

  const logOut = () => {
    cookies.remove("sessionEjecutivo", { path: "/" });
    cookies.remove("nombreEjecutivo", { path: "/" });
    cookies.remove("idEjecutivo", { path: "/" });
    cookies.remove("idSocio", { path: "/" });
    cookies.remove("rol", { path: "/" });
    cookies.remove("token", { path: "/" });

    window.location.href = "/inicioEjecutivo";
  };

  if (!open) return null;
  return (
    <>
      {cookies.get("idEjecutivo") ? (
        <div>
          <div className="modal_back" />
          <div className="modal_pop_menuLateral anchoGrandePopMenuLateral">
            <div className="closeModal_menu">
              <div>
              </div>
              <button
                type="button"
                aria-label="Toggle Navigation"
                className="lines-button x"
                onClick={close}
              >
                <span className="lines"></span>
              </button>
            </div>
            {children}
            <div className="menu_lateral_pop">
              <div className="widthCompleto">
                <ul>
                  <li onClick={close}>
                    <Link to="/administradorEmpresa/invitaciones">
                      <i className="fa fa-envelope"></i> Invitaciones{" "}
                    </Link>
                  </li>
                  <li onClick={close}>
                    <Link to="/administradorEmpresa/clientes">
                      <i className="fa fa-th-large"></i> Clientes{" "}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="botonesModal">
                <button
                  className="btn btn_morado_modal"
                  onClick={() => {
                    logOut();
                  }}
                >
                  Cerrar sesión
                </button>
              </div>
              <div className="copyright">
                <p>
                  En caso de que tengas preguntas, dudas o necesites asesoría no
                  dudes en contactarnos. Nos encanta atenderte a la hora que lo
                  necesites
                </p>
                <li onClick={close}>
                  {" "}
                  <Link to="/AvisoPrivacidadLogueado" className="link">
                    Aviso de privacidad
                  </Link>
                </li>
                <li onClick={close}>
                  {" "}
                  <Link to="/TerminosCondicionesLogueado" className="link">
                    Términos y condiciones
                  </Link>
                </li>
                <p>© 2025. Saturn5 Technologies SAPI de CV</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="modal_back" />
          <div className="modal_pop_menuLateral anchoGrandePopMenuLateral">
            <div className="closeModal_menu">
              <div>
                <Link to="/inicioEjecutivo">
                  {" "}
                  <img src={logoMorado} alt="logo" />
                </Link>
              </div>
              <button
                type="button"
                aria-label="Toggle Navigation"
                className="lines-button x"
                onClick={close}
              >
                <span className="lines"></span>
              </button>
            </div>
            {children}
            <div className="menu_lateral_pop">
              <div className="widthCompleto">
                <ul>
                  <li onClick={close}>
                    <Link to="/inicioSocios">
                      <i className="fa fa-th-large"></i> Iniciar sesión{" "}
                    </Link>
                  </li>
                  <li onClick={close}>
                    <Link to="/CrearCuentaSocios">
                      <i className="fa fa-user"></i> Crear cuenta{" "}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="copyright">
                <p>
                  En caso de que tengas preguntas, dudas o necesites asesoría no
                  dudes en contactarnos. Nos encanta atenderte a la hora que lo
                  necesites
                </p>
                <li onClick={close}>
                  {" "}
                  <Link to="/AvisoPrivacidadLogueado" className="link">
                    Aviso de privacidad
                  </Link>
                </li>
                <li onClick={close}>
                  {" "}
                  <Link to="/TerminosCondicionesLogueado" className="link">
                    Términos y condiciones
                  </Link>
                </li>
                <p>© 2025. Saturn5 SAPI de CV</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
