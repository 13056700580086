import { formatoFechaChat } from "../../utils/operaciones_formulario";
import { Avatar } from "@mui/material";
import { useChatContext, useSetChatContext } from "../../providers/ChatProvider";
import { CheckCheck, Files, Image, Volume2,  } from "lucide-react";

export default function Conversacion({ conversacion }){
	const chatData = useChatContext();
	const setChatData = useSetChatContext();

	const ultimoMensaje = {
		id: conversacion?.idUltimoMensaje,
		fechaCreacion: conversacion?.fechaCreacionMensaje,
		remitente: conversacion?.remitente,
		tipo: conversacion?.tipoMensaje,
		texto: conversacion?.texto,
		nuevo: conversacion?.nuevoMensaje
	};

	const nombreContacto = conversacion.nombre;
	const fechaCreacionChat = conversacion.fechaCreacionChat;
	const chatSeleccionado = chatData.chat?.id === conversacion.idChat;

	function seleccionarChat(){
		setChatData(prevData => {
			return {
				...prevData,
				chat: {
					id: conversacion.idChat,
					contacto: {
						id: conversacion.idContacto,
						telefono: conversacion.telefono,
						nombre: conversacion.nombre
					},
					ultimoMensaje: {
						id: conversacion.idUltimoMensaje,
						fechaCreacion: conversacion.fechaCreacionMensaje,
						remitente: conversacion.remitente,
						tipo: conversacion.tipoMensaje,
						texto: conversacion.texto
					}
				}
			};
		});
	}

	return (
		<>
			<div id="chat-conversacion" className={`${chatSeleccionado ? "seleccionado" : ""}`} onClick={seleccionarChat}>
				<Avatar />
				<div className='width100'>
					<div className='conversacion-header'>
						<h3 className='conversacion-name'>{nombreContacto}</h3>
						<span className={'conversacion-time ' + (ultimoMensaje.nuevo ? "color-notificacion-chat" : "")}>
							{formatoFechaChat((ultimoMensaje.fechaCreacion || fechaCreacionChat))}
						</span>
					</div>
					<div style={{display: "grid", gridTemplateColumns: "1fr auto"}}>
						<p className='conversacion-message'>
							{ultimoMensaje.remitente === "ejecutivo" ? <CheckCheck size={16} /> : ""}
							{!ultimoMensaje.id && "Saluda a tu cliente!"}
							{ultimoMensaje.tipo === "text" ? ultimoMensaje.texto?.length > 15 ? (
								<span className='text-xs' style={ultimoMensaje.nuevo ? {fontWeight: "bold"} : {}}>{ultimoMensaje.texto?.slice(0, 15)}...</span>
							) : (
								<span className='text-xs' style={ultimoMensaje.nuevo ? {fontWeight: "bold"} : {}}>{ultimoMensaje.texto}</span>
							) : null}
							{ultimoMensaje.tipo === "image" && <Image size={16} />}
							{ultimoMensaje.tipo === "document" && <Files size={16} />}
							{ultimoMensaje.tipo === "audio" && <Volume2 size={16} />}
						</p>
						{ultimoMensaje?.nuevo && <span className="notificacion-nuevo-msg"></span>}
					</div>
				</div>
			</div>
			<hr className="conversacion-separador" />
		</>
	);
}

export function UltimaConversacion({ conversacion, cargando = false, onClick }){	
	const nombreContacto = conversacion.nombre;
	const fechaCreacionChat = conversacion?.fechaCreacionChat;

	const ultimoMensaje = {
		id: conversacion?.idUltimoMensaje,
		fechaCreacion: conversacion?.fechaCreacionMensaje,
		remitente: conversacion?.remitente,
		tipo: conversacion?.tipoMensaje,
		texto: conversacion?.texto,
		nuevo: conversacion?.nuevoMensaje
	};

	return (
		<>
			<div id="chat-conversacion" className="seleccionado" onClick={cargando ? undefined : () => {
				onClick({
					...conversacion,
					nuevoMensaje: false
				});
			}}>
				<Avatar sx={{ width: 28, height: 28 }} />
				<div className='width100'>
					<div className='conversacion-header'>
						<h3 className='conversacion-name'>{nombreContacto}</h3>
						<span className={'conversacion-time ' + (ultimoMensaje.nuevo ? "color-notificacion-chat" : "")}>
							{formatoFechaChat((ultimoMensaje.fechaCreacion || fechaCreacionChat))}
						</span>
					</div>
					<div style={{display: "grid", gridTemplateColumns: "1fr auto"}}>
						<p className='conversacion-message'>
							{ultimoMensaje.remitente === "ejecutivo" ? <CheckCheck size={16} /> : ""}
							{cargando ? "Cargando chat..." : !ultimoMensaje.id && "Saluda a tu cliente!"}
							{ultimoMensaje.tipo === "text" ? ultimoMensaje?.texto.length > 30 ? (
								<span className='text-xs' style={ultimoMensaje.nuevo ? {fontWeight: "bold"} : {}}>{ultimoMensaje?.texto.slice(0, 30)}...</span>
							) : (
								<span className='text-xs' style={ultimoMensaje.nuevo ? {fontWeight: "bold"} : {}}>{ultimoMensaje.texto}</span>
							) : null}
							{ultimoMensaje.tipo === "image" && <Image size={16} />}
							{ultimoMensaje.tipo === "document" && <Files size={16} />}
							{ultimoMensaje.tipo === "audio" && <Volume2 size={16} />}
						</p>
						{ultimoMensaje?.nuevo && <span className="notificacion-nuevo-msg"></span>}
					</div>
				</div>
			</div>
		</>
	);
}