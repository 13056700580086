import * as ReactDOM from "react-dom/client";
import { useEffect, useState } from "react";
import { get } from "../utils/http";
import { TarjetaBancoFijoInfo, TarjetaBancoFijoResponsivoInfo, TarjetaBancoInfo } from "./Cards";
import Swal from "sweetalert2";

export default function OfertasBancos({idCuestionario, idResPaso1}){
  const [sinRespuesta, setSinRespuesta] = useState(false);

  let plazoCotizacion;
  let bancosTarjetas;
  let tabla;
  let tablaResponsiva;
  let dataCotizacion;
 
  const obtCotizacion = async () => {
    if (idResPaso1 !== null) {
      dataCotizacion = await get("obtDatosCotizacionPorId",idResPaso1);
      plazoCotizacion = dataCotizacion.cotizacion.plazo;
    }
  };

  const verificarBancos = async (arrBancos) => {
    let bancosData = await get("obtBancos", "");

    let nombresBancosBD = bancosData.bancos.map((banco) => banco.toLowerCase());

    // Filtrar el array arrBancos para mantener solo los bancos que existen en la base de datos
    let bancosFiltrados = arrBancos.filter((banco) =>
      nombresBancosBD.includes(banco.productoNombre.toLowerCase())
    );

    return bancosFiltrados;
  };

  const clasificaOfertas = (bancos) => {
    let mejoresOfertas = [];
    let otrasOpciones = [];
    let _nombreBancos = [];

    _nombreBancos.push(bancos[0].productoNombre)
    mejoresOfertas.push(bancos[0]);

    bancos.slice(1).forEach((banco) => {

      if(!_nombreBancos.includes(banco.productoNombre) && mejoresOfertas.length < 7 ){
        mejoresOfertas.push(banco)
        _nombreBancos.push(banco.productoNombre)
      }else{
        otrasOpciones.push(banco)
      }
    });

    return {
      mejoresOfertas,
      otrasOpciones
    }

  };

  const renderizarTarjetasOfertas = async (arrBancosOrdenado) => {
    bancosTarjetas = arrBancosOrdenado.map((banco, index) => (
      <TarjetaBancoInfo
        key={index}
        idTarjeta={banco.idResPrecalifica}
        banco={banco.productoNombre}
        creditoOfertado={banco.prestamoMax}
        montoSolicitado={dataCotizacion.cotizacion.montoSolicitado}
        valorPropiedad={dataCotizacion.cotizacion.valorPropiedad}
        enganche={dataCotizacion.cotizacion.enganche}
        Plazo={plazoCotizacion}
        Mensualidad={banco.pagoMensual}
        Tasa={banco.tasa}
        descripcion={banco.descripcion}
        PorcentajeTasa={
          banco.porcentajeTasa === null ? 0 : banco.porcentajeTasa
        }
        tipopago={banco.pago}
      />
    ));

    const root = ReactDOM.createRoot(
      document.getElementById("mostrarMostrarBancos")
    );

    root.render(bancosTarjetas);
  };

  const renderizarTablaOfertas = async (arrBancosOrdenado) => {

    const mostrarTablaOfertasDiv = document.getElementById(
      "mostrarTablaOfertas"
    );
    const root2 = ReactDOM.createRoot(document.getElementById("mostrarFijos"));

    if (arrBancosOrdenado.length > 0) {
      // Renderizar la tabla solo si arrBancosOrdenado no está vacío
      tabla = arrBancosOrdenado.map((banco, index) => (
        <TarjetaBancoFijoInfo
          key={index}
          idTarjeta={banco.idResPrecalifica}
          banco={banco.productoNombre}
          creditoOfertado={banco.prestamoMax}
          montoSolicitado={dataCotizacion.cotizacion.montoSolicitado}
          valorPropiedad = {dataCotizacion.cotizacion.valorPropiedad}
          enganche = {dataCotizacion.cotizacion.enganche}
          Plazo={plazoCotizacion}
          Mensualidad={banco.pagoMensual}
          Tasa={banco.tasa}
          descripcion={banco.descripcion}
          PorcentajeTasa={
            banco.porcentajeTasa === null ? 0 : banco.porcentajeTasa
          }
          gastosInvestigacion={banco.gastosInvestigacion}
          costoApertura={banco.costoAvaluo}
          costoAvaluo={banco.costoAvaluo}
          pagoInicial={banco.pagoInicial}
          costoAdmin={banco.comisionAdmin}
          pago={banco.pago}
          esquema={banco.esquema}
          tipoPago = {banco.pago}
        />
      ));

      // Mostrar el elemento mostrarTablaOfertas
      mostrarTablaOfertasDiv.style.display = "block";

      // Renderizar la tabla en mostrarFijos
      root2.render(tabla);
    } else {
      // Si arrBancosOrdenado está vacío, ocultar mostrarTablaOfertas
      mostrarTablaOfertasDiv.style.display = "none";
    }
  };

  const renderizarTablaOfertasResponsivo = async (arrBancosOrdenado) => {

    tablaResponsiva = arrBancosOrdenado.map((banco, index) => (
      <TarjetaBancoFijoResponsivoInfo
        key={index}
        idTarjeta={banco.idResPrecalifica}
        banco={banco.productoNombre}
        montoSolicitado={dataCotizacion.cotizacion.montoSolicitado}
        valorPropiedad = {dataCotizacion.cotizacion.valorPropiedad}
        enganche = {dataCotizacion.cotizacion.enganche}
        creditoOfertado={banco.prestamoMax}
        Plazo={plazoCotizacion}
        Mensualidad={banco.pagoMensual}
        Tasa={banco.tasa}
        descripcion={banco.descripcion}
        PorcentajeTasa={
          banco.porcentajeTasa === null ? 0 : banco.porcentajeTasa
        }
        tipoPago={banco.pago}
      />
    ));

    const root3 = ReactDOM.createRoot(
      document.getElementById("mostrarFijosResponsivo")
    );

    root3.render(tablaResponsiva);
  };

  const bancos = async () => {
    if (idCuestionario !== undefined) {
      await obtCotizacion();

      let peticionBancos = await get("userBancosOrdenados", idCuestionario);

      if(peticionBancos.statusCode === 200){
        const bancos = peticionBancos.bancos;
        //Primero verificamos que los bancos existan en nuestro sistema
        let arrayOrdenado = await verificarBancos(bancos);
        //Luego extaemos las mejores ofertas de cada banco
        let ofertas = clasificaOfertas(arrayOrdenado);
        //let mejoresOfertas = await extraerMejoresOfertas(arrayOrdenado);
        
        // <---------- Renderizar tarjetas con ofertas ---------->
        await renderizarTarjetasOfertas(ofertas.mejoresOfertas);
        // <---------- Renderizar tabla con ofertas (No responsivo) ---------->
        await renderizarTablaOfertas(ofertas.otrasOpciones);
        // <---------- Renderizar tabla con ofertas (Responsivo) ---------->
        await renderizarTablaOfertasResponsivo(ofertas.otrasOpciones);
        setSinRespuesta(false);
      }else{
        setSinRespuesta(true);
      }
    } else {

      Swal.fire({
        icon: "error",
        title: "Error al obtener ofertas",
        text: "Inténtelo de nuevo.",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Regresar",
      }).then((result) => {
        if (result.isConfirmed) {
          bancos();
        } else {
        }
      });
    }
  };

  useEffect(() => {
    bancos();
  }, []);

  return(
    <>
      {sinRespuesta ? (
        <div className="flex_centrado height100">
          <div className="textoCentrado">
            <h1>Sin ofertas disponibles</h1>
            <p>No se encontraron respuestas de bancos para esta solicitud.</p>
          </div>
        </div>
      ) : (
        <div>
          {/* <---------- Tarjetas con ofertas ----------> */}
          <div
            className="cont_flex con_flex_wrap responsivoColumn"
            id="mostrarMostrarBancos"
          ></div>

          <div id="mostrarTablaOfertas">
            <h1 className="titt">Otras opciones </h1>
            <br />
            {/* <---------- Titulos de tabla con ofertas (No responsivo) ----------> */}
            <div className="tablaFijosCeldasTitulo" id="tituloTablaBancosFijos">
              <div className="etiquetaTablaBancos">
                <b>Nombre</b>
              </div>
              <div className="etiquetaTablaBancos">
                <b>Oferta</b>
              </div>
              <div className="etiquetaTablaBancos">
                <b>Plazo</b>
              </div>
              <div className="etiquetaTablaBancos">
                <b>Mensualidad</b>
              </div>
              <div className="etiquetaTablaBancos">
                <b>Tipo Pago</b>
              </div>
              <div className="etiquetaTablaBancos">
                <b>Tasa</b>
              </div>
              <div className="etiquetaTablaBancos">
                <b>Información adicional</b>
              </div>
            </div>
          </div>
          
          {/* <---------- Tabla con ofertas (No responsivo) ----------> */}
          <div className="tablaFijosCeldas" id="mostrarFijos"></div>
          {/* <---------- Tabla con ofertas (Responsivo) ----------> */}
          <div id="mostrarFijosResponsivo"></div>
          <br />
          <div id="guardarBancosBotonSeleccion">
          </div>
          
        </div>
      )}
    </>
  );
}