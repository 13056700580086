import { Button, Input, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { MessageSquareText, Send } from "lucide-react";
import { useForm } from "react-hook-form";
import { getEP, postEP } from "../../utils/http";
import { useChatContext, useSetChatContext } from "../../providers/ChatProvider";
import { useEffect, useState } from "react";

export default function InputMensaje() {
	const chatData = useChatContext();
	const setChatData = useSetChatContext();
	const [plantillasVisibles, setPlantillasVisibles] = useState(false);
	const [plantillas, setPlantillas] = useState([]);

	const { register, handleSubmit, watch, resetField } = useForm({
		defaultValues: {
			mensaje: ""
		}
	});

	const mensaje = watch("mensaje");

	async function enviarMensaje(data){
		const texto = data.mensaje.trim();
		resetField("mensaje");

		const peticionMensaje = await postEP("whatsapp/enviarMensaje", {
			"x-token": chatData.ejecutivo.token,
		},{
			idChat: chatData.chat.id,
			texto: texto
		});

		if(peticionMensaje?.statusCode === 200){
			const ultimoMensaje = peticionMensaje.respuesta.mensaje;
			setChatData(prevData => {
				const data = {...prevData, actualizarChats: true};
				data.chat.ultimoMensaje = {
					id: ultimoMensaje.id,
					fechaCreacionMensaje: ultimoMensaje.fechaCreacion,
					remitente: ultimoMensaje.remitente,
					tipo: ultimoMensaje.tipo,
					texto: texto
				}
				data.chat.ultimoMensajeEjecutivo = {
					id: ultimoMensaje.id,
					fechaCreacion: ultimoMensaje.fechaCreacion,
					remitente: ultimoMensaje.remitente,
					idEjecutivo: ultimoMensaje.idEjecutivo,
					nombreEjecutivo: ultimoMensaje.nombreEjecutivo,
					tipo: ultimoMensaje.tipo,
					texto: texto
				};
				return data;
			});
		}else{
			console.error("Error al enviar mensaje: ", peticionMensaje);
		}
	}

	async function enviarPlantilla(idPlantilla){
		setPlantillasVisibles(false);

		const peticionPlantilla = await postEP("whatsapp/enviarPlantilla", {
			"x-token": chatData.ejecutivo.token,
		}, {
			idChat: chatData.chat.id,
			idPlantilla: idPlantilla
		});

		if(peticionPlantilla?.statusCode === 200){
			const plantillaEnviada = plantillas.find(plantilla => plantilla.id === idPlantilla);
			const ultimoMensaje = peticionPlantilla.respuesta.mensaje;
			setChatData(prevData => {
				const data = {...prevData, actualizarChats: true};
				data.chat.ultimoMensaje = {
					id: ultimoMensaje.id,
					fechaCreacionMensaje: ultimoMensaje.fechaCreacion,
					remitente: ultimoMensaje.remitente,
					tipo: ultimoMensaje.tipo,
					texto: plantillaEnviada.texto
				}
				data.chat.ultimoMensajeEjecutivo = {
					id: ultimoMensaje.id,
					fechaCreacion: ultimoMensaje.fechaCreacion,
					remitente: ultimoMensaje.remitente,
					idEjecutivo: ultimoMensaje.idEjecutivo,
					nombreEjecutivo: ultimoMensaje.nombreEjecutivo,
					tipo: ultimoMensaje.tipo,
					texto: plantillaEnviada.texto
				};
				return data;
			});
		}else{
			console.error("Error al enviar plantilla");
		}
	}

	async function obtPlantillasMensajes(){
		const peticionPlantillas = await getEP("whatsapp/obtPlantillasMensajes");
		if(peticionPlantillas.statusCode === 200){
			setPlantillas(peticionPlantillas.respuesta.plantillas);
		}else{
			setPlantillas([]);
		}
	}

	useEffect(() => {
		obtPlantillasMensajes();
	}, []);

	return (
		<div>
			<div id="chat-input-mensaje">
				<div className='message-input-icons'>
					{/* EMOJI PICKER WILL GO HERE */}
					{/*<Laugh className="icon" />
					<Plus className="icon" />*/}
				</div>
				<form className='message-input-form' onSubmit={handleSubmit(enviarMensaje)}>
					<div className='message-input-field'>
						<Input
							{...register("mensaje", { required: false })}
							multiline
							maxRows={3}
							autoComplete="off"
							placeholder='Escribe un mensaje...'
							className='message-input'
						/>
					</div>
					<div className='message-input-actions'>
						{mensaje.length > 0 ? (
							<Button
								type='submit'
								size={"sm"}
								className="message-input-button"
							>
								<Send className="icono" />
							</Button>
						) : (
							<Button
								type="button"
								size="sm"
								className="message-input-button"
								onClick={() => setPlantillasVisibles(valorPrev => !valorPrev)}
							>
								<MessageSquareText className="icono"/>
							</Button>
						)}
						
					</div>
				</form>
			</div>
			<div style={{maxHeight: "200px", overflowY: "auto"}}>
				{plantillasVisibles && (
					<List disablePadding>
						{plantillas.map(plantilla => (
							<ListItem disablePadding>
								<ListItemButton onClick={() => enviarPlantilla(plantilla.id)}>
									<ListItemText primary={plantilla.nombre} secondary={plantilla.texto.length < 80 ? plantilla.texto : plantilla.texto.slice(0, 80) + "..."} />
								</ListItemButton>
							</ListItem>
						))}
					</List>
				)}
			</div>
		</div>
	);
}