import { Routes, Route } from "react-router-dom";
import { Raiz, Index } from "./pages/Index";
import { RaizSocios, InicioSocio } from "./pages/InicioSocios"; //JPB 07-09-2023 Nuevo login para Socios
import CrearCuentaSocios from "./pages/noAuth/CrearCuentaSinSimulacionSocios";

import { PreInformacion, InfoDocumentacionInicial, InfoLaborar, InfoPersonal, InfoMedica, InfoInmueble, DocumentosEnviados } from "./pages/auth/Informacion";
import { Home } from './pages/auth/Home'
import { HomeSocios } from './pages/auth/socios/screenSocios'
import Resumen from './pages/auth/Resumen'
import { MisDatos, Simulador } from "./pages/auth/MisDatos"
import Cotizaciones from "./pages/auth/Cotizaciones"
import MiHipoteca from "./pages/auth/MiHipoteca";
import MisDatosHipoteca from "./pages/auth/MisDatosHipoteca";
import AmortizacionHipoteca from "./pages/auth/AmortizacionHipoteca";

//JPB 26-09-2023
import CotizacionesAplicadas from "./pages/auth/CotizacionesAplicadas"

import Bancos from "./pages/auth/Bancos"
import BancosSeleccionados from "./pages/auth/BancosSeleccionados"
import Paso1Logeado from "./pages/auth/Paso1Logueado"
import RespuestaBuro from "./pages/auth/RespuestaBuro"
import DescargarSolicitud from "./pages/auth/DescargarSolicitud"

import CrearCuenta from "./pages/noAuth/CrearCuenta"
import CrearCuentaSinSimulacion from "./pages/noAuth/CrearCuentaSinSimulacion"
import Paso1 from "./pages/noAuth/Paso1"
import ValidarPass from "./pages/noAuth/ValidarPass"
import RecuperaPass from "./pages/noAuth/RecuperaPass"

import CalculadoraWeb from "./pages/generales/CalculadoraWeb"
import CalculadoraWebLiquidez from "./pages/generales/CalculadoraWebLiquidez";
import CalculadoraWebFirmauto from "./pages/generales/CalculadoraWebFirmauto"
import AvisoPrivacidad from "./pages/generales/AvisoDePrivacidad"
import TerminosCondiciones from "./pages/generales/TerminosCondiciones"

import { InformacionGeneral } from "./pages/auth/InformacionGeneral"
import { Auth, NoAuth, AuthSocio, NoAuthSocios, AuthVendedor, AuthEjecutivo, NoAuthEjecutivo } from './components/Auth'
import Cookies from 'universal-cookie'
import { PublicProvider } from './providers/dataPublic/PublicProvider'
import SolicitudAceptada from "./pages/auth/SolicitudAceptada";
import DocumentosVivienda from "./pages/auth/DocumentosVivienda";
import DocumentosViviendaDocs from "./pages/auth/DocumentacionViviendaDocs"
import InfoVendedorVivienda from "./pages/auth/InfoVendedorVivienda"
import PagoEvaluo from "./pages/auth/PagoEvaluo"
import Avaluo from "./pages/auth/Avaluo"
import NotariaAsignada from "./pages/auth/NotariaAsignada"
import InsExterna from "./pages/auth/InsExterna"
import FormalizacionFirma from "./pages/auth/FormalizacionFirma"
import Felicidades from "./pages/auth/Felicidades"

import RecuperarPassSocio from "./pages/noAuth/RecuperarPassSocio"
import PanelSocios from "./pages/auth/socios/PanelSocios";
import Home_Socios from "./pages/auth/socios/Home_Socios";
import InvitacionesSocios from "./pages/auth/socios/invitacionesSocios";
import NuevoCliente from "./pages/auth/socios/frmNuevoCliente";
import MisComisiones from "./pages/auth/socios/MisComisiones";


import InfoLaboral from "./pages/auth/frmLaboral";
import InfoFrmMedica from "./pages/auth/frmMedica";
import InfoPersonalForm from "./pages/auth/frmPersonal";
import InfoGeneral from "./pages/auth/infoGeneral";



//Informacion general del coAcreditado
import InfoGeneralCoAcreditado from "./pages/auth/infoGeneralCoAcreditado";
import { InfoDocumentacionInicialCoAcreditado } from "./pages/auth/informacionCoAcreditado"
import { InfoPersonalCoAcreditado } from "./pages/auth/frmPersonalCoAcreditado"
import InfoLaboralCoAcreditado from "./pages/auth/frmLaboralCoAcreditado";
import InfoFrmMedicaCoAcreditado from "./pages/auth/frmMedicaCoAcreditado";
import InfoFrmDatosExtra from "./pages/auth/frmDatosExtra";

//import MyForm from "./pages/auth/ejemplo"

import Paso1Dinamico from "./pages/noAuth/Paso1Dinamico";

import DocumentosViviendaVendedor from "./pages/auth/vendedor/DocumentosViviendaVendedor";
import VendedorInfoVendedorVivienda from "./pages/auth/vendedor/VendedorInfoVendedorVivienda";
import VendedorDocumentacionVivienda from "./pages/auth/vendedor/VendedorDocumentaciónVivienda";
import CalculadoraWebMejoraHipoteca from "./pages/generales/CalculadoraWebMejoraHipoteca";

import { InicioEjecutivo, RaizEjecutivo } from "./pages/noAuth/ejecutivo/InicioEjecutivo";
import TemplateEjecutivo from "./pages/auth/ejecutivo/TemplateEjecutivo";
import ClientesEjecutivo from "./pages/auth/ejecutivo/ClientesEjecutivo";
import InvitacionesEjecutivo from "./pages/auth/ejecutivo/InvitacionesEjecutivo";
import RegistrarClienteEjecutivo from "./pages/auth/ejecutivo/RegistrarClienteEjecutivo";
import NuevaInvitacion from "./pages/auth/ejecutivo/NuevaInvitacion";
import DocumentosEjecutivo from "./pages/auth/ejecutivo/DocumentosEjecutivo";

import TemplateAdminEmpresa from "./pages/auth/adminEmpresa/TemplatePanelEmpresa";
import InvitacionesAdminEmpresa from "./pages/auth/adminEmpresa/InvitacionesAdminEmpresa";
import ClientesAdminEmpresa from "./pages/auth/adminEmpresa/ClientesAdminEmpresa";

import TemplateAdminSaturn from "./pages/auth/adminSaturn/TemplateAdminSaturn";
import AccesosAdminSaturn from "./pages/auth/adminSaturn/AccesosAdminSaturn";
import AdministrativosAdminSaturn from "./pages/auth/adminSaturn/AdministrativosAdminSaturn";
import EmpresasAdminSaturn from "./pages/auth/adminSaturn/EmpresasAdminSaturn";
import DocumentosAdminSaturn from "./pages/auth/adminSaturn/DocumentosAdminSaturn";
import KanbanClientesEjecutivo from "./pages/auth/ejecutivo/KanbanClientesEjecutivo";
import ConversacionesEjecutivo from "./pages/auth/ejecutivo/ConversacionesEjecutivo";
import { ChatProvider } from "./providers/ChatProvider";

import ClienteBancosOfertados from "./pages/generales/ClienteBancosOfertados";


// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Declaración de rutas para mostrar las diferentes pantallas  --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
function App() {
  const cookie = new Cookies()
  return (

    <PublicProvider>
      <Routes>
        <Route element={<NoAuth session={cookie.get('session')} />}>
          <Route path="/" element={<Raiz><Index /></Raiz>} />

          <Route path="/crearcuenta" element={<Raiz><CrearCuenta /></Raiz>} />
          <Route path="/crearcuentaSinSimulacion" element={<Raiz><CrearCuentaSinSimulacion /></Raiz>} />

          <Route path="/recuperapass" element={<Raiz><RecuperaPass /></Raiz>} />
          <Route path="/validarPass" element={<Raiz><ValidarPass /></Raiz>} />

          <Route path='/calculadora' element={
            <Raiz>
              <div class="cont_flex flex_center">
                <div className="cont_Info">
                  <CalculadoraWeb />
                </div>
              </div>
            </Raiz>}
          />

          <Route path='/calculadoraLiquidez' element={
            <Raiz>
              <div class="cont_flex flex_center">
                <div className="cont_Info">
                  <CalculadoraWebLiquidez />
                </div>
              </div>
          </Raiz>}
          />

          <Route path='/calculadoraMejoraHipoteca' element={
            <Raiz>
              <div class="cont_flex flex_center">
                <div className="cont_Info">
                  <CalculadoraWebMejoraHipoteca />
                </div>
              </div>
            </Raiz>}
          />

          <Route path="/pre_registro" element={<Raiz><Paso1 /></Raiz>} />
          <Route path="/preRegistroDinamico" element={<Raiz><Paso1Dinamico/></Raiz>} />
          
        </Route>
        
        {/* <---------- Rutas protegidas (Usuario logueado) ----------> */}
        <Route element={<Auth session={cookie.get('session')} />}>
          <Route path="/home" element={<Home active='01'><Resumen /></Home>} />
          <Route path="/misDatos" element={<Home active='02'><MisDatos /></Home>} />
          <Route path="/simulacion" element={<Home active='02'><Simulador /></Home>} />
          <Route path="/cotizaciones" element={<Home active='03'><Cotizaciones /></Home>} />
          <Route path="/miHipoteca" element={<Home active='07'><MiHipoteca /></Home>} />
          <Route path="/miHipoteca/misDatosDeHipoteca" element={<Home active='07'><MisDatosHipoteca /></Home>} />
          <Route path="/miHipoteca/amortizacion" element={<Home active='07'><AmortizacionHipoteca /></Home>} />

          {/*JPB 26-09-2023*/}
          <Route path="/cotizacionesAplicadas" element={<Home active='04'><CotizacionesAplicadas /></Home>} />

          <Route path="/informacion" element={<Home active='06'><PreInformacion /></Home>} />
          <Route path="/informacion/inf-DocumentacionInicial" element={<Home active=''><InfoDocumentacionInicial /></Home>} />
          <Route path="/informacion/inf-laboral" element={<Home active=''><InfoLaborar /></Home>} />
          <Route path="/informacion/inf-personal" element={<Home active=''><InfoPersonal /></Home>} />
          <Route path="/informacion/inf-medica" element={<Home active='04'><InfoMedica /></Home>} />
          <Route path="/informacion/inf-inmueble" element={<Home active='04'><InfoInmueble /></Home>} />
          <Route path="/informacionEnviada" element={<Home active='04'><DocumentosEnviados /></Home>} />

          <Route path="/informacionGeneral" element={<Home active='05'><InformacionGeneral /></Home>} />

          <Route path="/observarRegistro" element={<Home active='03'><Paso1Logeado /></Home>} />
          <Route path="/AvisoPrivacidadLogueado" element={<Home active=''><AvisoPrivacidad /></Home>} />
          <Route path="/TerminosCondicionesLogueado" element={<Home active=''><TerminosCondiciones /></Home>} />
          <Route path="/Bancos" element={<Home active='03'><Bancos /></Home>} />
          <Route path="/BancosSeleccionados" element={<Home active='03'><BancosSeleccionados /></Home>} />
          <Route path="/respuestaBuro" element={<Home active='03'><RespuestaBuro /></Home>} />
          <Route path="/descargarSolicitud" element={<Home active='07'><DescargarSolicitud /></Home>} />
          <Route path="/solicitudAceptada" element={<Home active='03'><SolicitudAceptada /></Home>} />
          <Route path="/documentosVivienda" element={<Home active='03'><DocumentosVivienda /></Home>} />
          <Route path="/documentacionViviendaDocs" element={<Home active='03'><DocumentosViviendaDocs /></Home>} />
          <Route path="/infoVendedorVivienda" element={<Home active='03'><InfoVendedorVivienda /></Home>} />
          <Route path="/pagoEvaluo" element={<Home active='03'><PagoEvaluo /></Home>} />
          <Route path="/avaluo" element={<Home active='03'><Avaluo /></Home>} />
          <Route path="/notariaAsignada" element={<Home active='03'><NotariaAsignada /></Home>} />
          <Route path="/institucionExterna" element={<Home active='03'><InsExterna /></Home>} />
          <Route path="/formalizacionFirma" element={<Home active='03'><FormalizacionFirma /></Home>} />
          <Route path="/felicidades" element={<Home active='03'><Felicidades /></Home>} />


          <Route path="/frmInfoLaboral" element={<Home active='03'><InfoLaboral /></Home>} />
          <Route path="/frmInfoMedica" element={<Home active='03'><InfoFrmMedica /></Home>} />
          <Route path="/frmInfoPersonal" element={<Home active='03'><InfoPersonalForm /></Home>} />
          <Route path="/informacion-general" element={<Home active='05'><InfoGeneral /></Home>} />
          {/*---------- Informacion del CoAcreditado ----------*/}

          {/* Inicio del CoAcreditado*/}
          <Route path="/InfocoAcreditado" element={<Home active='06'><InfoGeneralCoAcreditado /></Home>} />


          {/* formularios de Co acreditadto */}
          <Route path="/informacion/inf-DocumentacionInicial-coAcreditado" element={<Home active=''><InfoDocumentacionInicialCoAcreditado /></Home>} />
          <Route path="/frmPersonal-coAcreditado" element={<Home active=''><InfoPersonalCoAcreditado /></Home>} />
          <Route path="/frmInfoLaboral-coAcreditado" element={<Home active=''><InfoLaboralCoAcreditado /></Home>} />
          <Route path="/frmInfoMedica-coAcreditado" element={<Home active=''><InfoFrmMedicaCoAcreditado /></Home>} />
          <Route path="/frmDatosExtra" element={<Home active=''><InfoFrmDatosExtra /></Home>} />

        </Route>

        {/* <---------- Rutas protegidas socios (socio logueado) ----------> */}
        <Route element={<AuthSocio session={cookie.get('sessionSocio')} />}>
          <Route path="/panelSocios" element={<HomeSocios active='01'><PanelSocios /></HomeSocios>} />
          <Route path="/invitacionesSocios" element={<HomeSocios active='02'><InvitacionesSocios /></HomeSocios>} />
          <Route path="/frmNuevoCliente" element={<HomeSocios active='02'><NuevoCliente /></HomeSocios>} />
          <Route path="/Home_Socios" element={<HomeSocios active='03'><Home_Socios /></HomeSocios>} />
          <Route path="/misComisiones" element={<HomeSocios active='04'><MisComisiones /></HomeSocios>} />
        </Route>

        {/* <---------- Rutas no protegidas socios  ----------> */}
        <Route element={<NoAuthSocios session={cookie.get('sessionSocio')} />}>
          <Route path="/InicioSocios" element={<RaizSocios><InicioSocio /></RaizSocios>} />
          <Route path="/CrearCuentaSocios" element={<RaizSocios verificar="true" ><CrearCuentaSocios /></RaizSocios>} />
          <Route path="/recuperapassSocios" element={<RaizSocios><RecuperarPassSocio /></RaizSocios>} />
        </Route>

        {/* <---------- Rutas no protegidas Ejecutivos ----------> */}
        <Route element={<NoAuthEjecutivo session={cookie.get('sessionEjecutivo')} sessionToken={cookie.get('token')} />}>
          <Route path="/inicioEjecutivo" element={<RaizEjecutivo><InicioEjecutivo /></RaizEjecutivo>} />
        </Route>

        {/* <---------- Rutas protegidas Ejecutivos (ejecutivo logueado) ----------> */}
        <Route element={<AuthEjecutivo session={cookie.get('sessionEjecutivo')} sessionToken={cookie.get('token')} rolesPermtidos={['ejecutivo']} />}>
          <Route path="/ejecutivo/dashboard" element={<TemplateEjecutivo active='01'><KanbanClientesEjecutivo/></TemplateEjecutivo>} />
          <Route path="/ejecutivo/clientes" element={<TemplateEjecutivo active='02'><ClientesEjecutivo/></TemplateEjecutivo>} />
          <Route path="/ejecutivo/invitaciones" element={<TemplateEjecutivo active='03'><InvitacionesEjecutivo/></TemplateEjecutivo>} />
          <Route path="/ejecutivo/nuevaInvitacion" element={<TemplateEjecutivo active='03'><NuevaInvitacion /></TemplateEjecutivo>} />
          <Route path="/ejecutivo/registrarCliente" element={<TemplateEjecutivo active='04'><RegistrarClienteEjecutivo/></TemplateEjecutivo>} />
          <Route path="/ejecutivo/documentos" element={<TemplateEjecutivo active='05'><DocumentosEjecutivo/></TemplateEjecutivo>} />
          <Route path="/ejecutivo/conversaciones" element={<TemplateEjecutivo active='06'><ChatProvider><ConversacionesEjecutivo/></ChatProvider></TemplateEjecutivo>} />
        </Route>

        {/* <---------- Rutas protegidas administrador empresa ----------> */}
        <Route element={<AuthEjecutivo session={cookie.get('sessionEjecutivo')} sessionToken={cookie.get('token')} rolesPermtidos={['administrador_empresa']} />}>
          <Route path="/administradorEmpresa/invitaciones" element={<TemplateAdminEmpresa active='01'><InvitacionesAdminEmpresa/></TemplateAdminEmpresa>} />
          <Route path="/administradorEmpresa/clientes" element={<TemplateAdminEmpresa active='02'><ClientesAdminEmpresa /></TemplateAdminEmpresa>} />
        </Route>

        <Route element={<AuthEjecutivo session={cookie.get('sessionEjecutivo')} sessionToken={cookie.get('token')} rolesPermtidos={['administrador_saturn']} />}>
          <Route path="/administradorSaturn/roles" element={<TemplateAdminSaturn active='01'><AdministrativosAdminSaturn/></TemplateAdminSaturn>} />
          <Route path="/administradorSaturn/accesos" element={<TemplateAdminSaturn active='02'><AccesosAdminSaturn/></TemplateAdminSaturn>} />
          <Route path="/administradorSaturn/empresas" element={<TemplateAdminSaturn active='03'><EmpresasAdminSaturn/></TemplateAdminSaturn>} />
          <Route path="/administradorSaturn/documentos" element={<TemplateAdminSaturn active='04'><DocumentosAdminSaturn/></TemplateAdminSaturn>} />
        </Route>
        
        {/* <---------- Rutas para que el vendedor suba sus documentos ----------> */}
        <Route element={<AuthVendedor/>}>
          <Route path="/documentosViviendaVendedor" element={
            <Raiz>
              <div class="cont_flex flex_center">
                <div className="cont_Info">
                  <DocumentosViviendaVendedor />
                </div>
              </div>
            </Raiz>
          }/>

          <Route path="/documentosViviendaVendedor/infoVendedorVivienda" element={
            <Raiz>
              <div class="cont_flex flex_center">
                <div className="cont_Info">
                  <VendedorInfoVendedorVivienda />
                </div>
              </div>
            </Raiz>
          }/>

          <Route path="/documentosViviendaVendedor/documentacionVivienda" element={
            <Raiz>
              <div class="cont_flex flex_center">
                <div className="cont_Info">
                  <VendedorDocumentacionVivienda />
                </div>
              </div>
            </Raiz>
          }/>
        </Route>

        <Route path='/calculadoraFirmauto' element={
          <Raiz>
            <div class="cont_flex flex_center">
              <div className="cont_Info">
                <CalculadoraWebFirmauto />
              </div>
            </div>
          </Raiz>}
        />

        <Route path="/cliente/bancosOfertados" element={<ClienteBancosOfertados />} />

        <Route path="/avisoDePrivacidad" element={<AvisoPrivacidad />} />
        <Route path="/terminosCondiciones" element={<TerminosCondiciones />} />

        {/* <---------- Pantalla mostrada cuando la URL no se encuentra ----------> */}
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </PublicProvider>

  );
}

export default App;
