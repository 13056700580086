import React, { useEffect, useState } from "react";
import { get } from "../../utils/http";
import * as ReactDOM from "react-dom/client";
import Swal from "sweetalert2";
import { useDataPublic } from "../../providers/dataPublic/PublicProvider";
import { ConSeleccionBancos } from "../../components/Msjs";
import { useNavigate } from "react-router-dom";
import { ModalLoadingGeneral } from "../../components/Modals";
import {
  TarjetaBancos,
  TarjetaFijos,
  TarjetaFijosResponsivo,
} from "../../components/Cards";
import { obtEmpresaUrl } from "../../utils/global_functions";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Pantalla donde se muestran las ofertas(Bancos) disponibles ---------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function Bancos() {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModalLoading, setIsOpenModalLoading] = useState(true);
  const { cuestionarioId } = useDataPublic();
  const navigate = useNavigate();

  if (cuestionarioId !== undefined) {
    localStorage.setItem("idResPaso1SeleccionarBancos", cuestionarioId);
  }

  let bancosTarjetas;
  let tabla;
  let tablaResponsiva;
  let dataCotizacion;
  let idCuestionarioBancos = parseInt(
    localStorage.getItem("idResPaso1SeleccionarBancos")
  );
  let idResPaso1Cuestionario = parseInt(
    localStorage.getItem("idResPaso1Cuestionario")
  );

  const obtCotizacion = async () => {
    if (idResPaso1Cuestionario !== null) {
      dataCotizacion = await get(
        "obtDatosCotizacionPorId",
        idResPaso1Cuestionario
      );
      localStorage.setItem("plazoVal", dataCotizacion.cotizacion.plazo);
    }
  };

  const obtBancos = async () => {
    return get("userBancosOrdenados", idCuestionarioBancos);
  };

  const obtCatalogoBancos = async () => {
    return get("obtBancos", "");
  };

  const obtDescripcionBanco = async (nombre) => {
    return get("obtDescripcionBanco", nombre);
  };

  const verificarBancos = async (arrBancos) => {
    let bancosData = await obtCatalogoBancos();

    let nombresBancosBD = bancosData.bancos.map((banco) => banco.toLowerCase());

    // Filtrar el array arrBancos para mantener solo los bancos que existen en la base de datos
    let bancosFiltrados = arrBancos.filter((banco) =>
      nombresBancosBD.includes(banco.productoNombre.toLowerCase())
    );

    // Ordenar el array resultante por pagoMensual
    //let infoBancos = bancosFiltrados.sort((x, y) => x.pagoMensual - y.pagoMensual);

    // Retornar el resultado
    //return infoBancos;

    return bancosFiltrados;
  };

  const extraerMejoresOfertas = async (arrBancos) => {
    const mejoresOfertas = [];

    arrBancos.forEach((banco) => {
      if (banco.tasa.toLowerCase() === "fija") {
        // Obtener el pagoMensual de cada banco
        const pagoMensual = banco.pagoMensual;

        // Verificar si ya hay una mejor oferta para este banco
        if (
          !mejoresOfertas[banco.productoNombre] ||
          pagoMensual < mejoresOfertas[banco.productoNombre].pagoMensual
        ) {
          // Almacenar la mejor oferta para este banco
          mejoresOfertas[banco.productoNombre] = {
            ...banco, // Mantener todos los datos del banco
            pagoMensual: pagoMensual, // Actualizar el pagoMensual con el más bajo
          };
        }
      }
    });

    // Convertir el objeto de mejoresOfertas a un array
    const resultado = Object.values(mejoresOfertas);
    return resultado;
  };

  const clasificaOfertas = (bancos) => {
    let mejoresOfertas = [];
    let otrasOpciones = [];
    let _nombreBancos = [];

    _nombreBancos.push(bancos[0].productoNombre)
    mejoresOfertas.push(bancos[0]);

    bancos.slice(1).forEach((banco) => {

      if(!_nombreBancos.includes(banco.productoNombre) && mejoresOfertas.length < 7 ){
        mejoresOfertas.push(banco)
        _nombreBancos.push(banco.productoNombre)
      }else{
        otrasOpciones.push(banco)
      }
    });

    return {
      mejoresOfertas,
      otrasOpciones
    }

  };

  const extraerOfertasRestantes = async (arrBancos, mejoresOfertas) => {
    // Filtrar el array original para excluir las ofertas con el mismo nombre de banco y el mismo pago mensual
    const ofertasRestantes = arrBancos.filter((banco) => {
      const esMejorOferta = mejoresOfertas.some(
        (oferta) =>
          oferta.productoNombre === banco.productoNombre &&
          oferta.pagoMensual === banco.pagoMensual
      );

      // Si no es una mejor oferta, incluir en las ofertas restantes
      return !esMejorOferta;
    });
    return ofertasRestantes;
  };

  const renderizarTarjetasOfertas = async (arrBancosOrdenado) => {
    bancosTarjetas = arrBancosOrdenado.map((banco, index) => (
      <TarjetaBancos
        key={index}
        idTarjeta={banco.idResPrecalifica}
        banco={banco.productoNombre}
        creditoOfertado={banco.prestamoMax}
        montoSolicitado={dataCotizacion.cotizacion.montoSolicitado}
        valorPropiedad={dataCotizacion.cotizacion.valorPropiedad}
        enganche={dataCotizacion.cotizacion.enganche}
        Plazo={
          localStorage.getItem("plazoVal") === null
            ? localStorage.getItem("plazoParaBancosSeleccionados")
            : localStorage.getItem("plazoVal")
        }
        Mensualidad={banco.pagoMensual}
        Tasa={banco.tasa}
        descripcion={banco.descripcion}
        PorcentajeTasa={
          banco.porcentajeTasa === null ? 0 : banco.porcentajeTasa
        }
        tipopago={banco.pago}
      />
    ));

    const root = ReactDOM.createRoot(
      document.getElementById("mostrarMostrarBancos")
    );

    root.render(bancosTarjetas);
  };

  const renderizarTablaOfertas = async (arrBancosOrdenado) => {

    const mostrarTablaOfertasDiv = document.getElementById(
      "mostrarTablaOfertas"
    );
    const root2 = ReactDOM.createRoot(document.getElementById("mostrarFijos"));

    if (arrBancosOrdenado.length > 0) {
      // Renderizar la tabla solo si arrBancosOrdenado no está vacío
      tabla = arrBancosOrdenado.map((banco, index) => (
        <TarjetaFijos
          key={index}
          idTarjeta={banco.idResPrecalifica}
          banco={banco.productoNombre}
          creditoOfertado={banco.prestamoMax}
          montoSolicitado={dataCotizacion.cotizacion.montoSolicitado}
          valorPropiedad = {dataCotizacion.cotizacion.valorPropiedad}
          enganche = {dataCotizacion.cotizacion.enganche}
          Plazo={
            localStorage.getItem("plazoVal") === null
              ? localStorage.getItem("plazoParaBancosSeleccionados")
              : localStorage.getItem("plazoVal")
          }
          Mensualidad={banco.pagoMensual}
          Tasa={banco.tasa}
          descripcion={banco.descripcion}
          PorcentajeTasa={
            banco.porcentajeTasa === null ? 0 : banco.porcentajeTasa
          }
          gastosInvestigacion={banco.gastosInvestigacion}
          costoApertura={banco.costoAvaluo}
          costoAvaluo={banco.costoAvaluo}
          pagoInicial={banco.pagoInicial}
          costoAdmin={banco.comisionAdmin}
          pago={banco.pago}
          esquema={banco.esquema}
          tipoPago = {banco.pago}
        />
      ));

      // Mostrar el elemento mostrarTablaOfertas
      mostrarTablaOfertasDiv.style.display = "block";

      // Renderizar la tabla en mostrarFijos
      root2.render(tabla);
    } else {
      // Si arrBancosOrdenado está vacío, ocultar mostrarTablaOfertas
      mostrarTablaOfertasDiv.style.display = "none";
    }
  };

  const renderizarTablaOfertasResponsivo = async (arrBancosOrdenado) => {

    tablaResponsiva = arrBancosOrdenado.map((banco, index) => (
      <TarjetaFijosResponsivo
        key={index}
        idTarjeta={banco.idResPrecalifica}
        banco={banco.productoNombre}
        montoSolicitado={dataCotizacion.cotizacion.montoSolicitado}
        valorPropiedad = {dataCotizacion.cotizacion.valorPropiedad}
        enganche = {dataCotizacion.cotizacion.enganche}
        creditoOfertado={banco.prestamoMax}
        Plazo={
          localStorage.getItem("plazoVal") === null
            ? localStorage.getItem("plazoParaBancosSeleccionados")
            : localStorage.getItem("plazoVal")
        }
        Mensualidad={banco.pagoMensual}
        Tasa={banco.tasa}
        descripcion={banco.descripcion}
        PorcentajeTasa={
          banco.porcentajeTasa === null ? 0 : banco.porcentajeTasa
        }
        tipoPago={banco.pago}
      />
    ));

    const root3 = ReactDOM.createRoot(
      document.getElementById("mostrarFijosResponsivo")
    );

    root3.render(tablaResponsiva);
  };

  const bancos = async () => {
    localStorage.removeItem("BancoLS1");
    localStorage.removeItem("BancoLS2");

    if (idCuestionarioBancos !== undefined) {
      await obtCotizacion();

      let dataGet = await obtBancos();

      if (dataGet.bancos.length !== 0) {
        if (dataGet.statusCode === 200) {
          localStorage.removeItem("BancoLS1");
          localStorage.removeItem("BancoLS2");

          //Primero verificamos que los bancos existan en nuestro sistema
          let arrayOrdenado = await verificarBancos(dataGet.bancos);
          //Luego extaemos las mejores ofertas de cada banco
          let ofertas = clasificaOfertas(arrayOrdenado);
          //let mejoresOfertas = await extraerMejoresOfertas(arrayOrdenado);
          
          // <---------- Renderizar tarjetas con ofertas ---------->
          await renderizarTarjetasOfertas(ofertas.mejoresOfertas);
          
          //Por ultimo extraemos las ofertas restantes
          /*let ofertasRestantes = await extraerOfertasRestantes(
            arrayOrdenado,
            mejoresOfertas
          );*/
          // <---------- Renderizar tabla con ofertas (No responsivo) ---------->
          await renderizarTablaOfertas(ofertas.otrasOpciones);
          // <---------- Renderizar tabla con ofertas (Responsivo) ---------->
          await renderizarTablaOfertasResponsivo(ofertas.otrasOpciones);

          setIsOpenModalLoading(false);
        } else if (dataGet.statusCode === 400) {
          setIsOpenModalLoading(false);

          Swal.fire({
            icon: "error",
            title: "Sin ofertas disponibles",
            text: "Inténtelo de nuevo.",
            showCancelButton: false,
            confirmButtonText: "Aceptar",
          }).then((result) => {
            if (result.isConfirmed) {
              bancos();
            }
          });
        }
      } else {
        setIsOpenModalLoading(false);

        Swal.fire({
          icon: "error",
          title: "Sin ofertas disponibles",
          text: "No se encontraron bancos para esta solicitud.",
          showCancelButton: false,
          confirmButtonText: "Aceptar",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/cotizaciones");
          }
        });
      }
    } else {
      setIsOpenModalLoading(false);

      Swal.fire({
        icon: "error",
        title: "Error al obtener ofertas",
        text: "Inténtelo de nuevo.",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Regresar",
      }).then((result) => {
        if (result.isConfirmed) {
          bancos();
        } else {
          navigate("/cotizaciones");
        }
      });
    }
  };

  const verificarNulo = async () => {
    let verificacion;
    let idCuestionarioBancos = parseInt(
      localStorage.getItem("idResPaso1SeleccionarBancos")
    );
    let dataGet = await get("userBancos", idCuestionarioBancos);
    let arrayOrdenado = dataGet.bancos.sort(
      (x, y) => x.pagoMensual - y.pagoMensual
    );

    // Verifica si al menos un elemento cumple con la condición
    const algunNull = arrayOrdenado
      .slice(arrayOrdenado.length / 2, arrayOrdenado.length)
      .some((banco) => banco.porcentajeTasa === null);

    verificacion = algunNull ? 0 : 1;
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    bancos();
    verificarNulo();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <h1 className="titt">
          Estas son las mejores ofertas <br /> que encontramos para ti.
        </h1>
        <p>
          Selecciona las mejores opciones e inicia tu solicitud de crédito.{" "}
          <b> Debes solicitar una o dos opciones de crédito.</b>
        </p>
        {/* <---------- Tarjetas con ofertas ----------> */}
        <div
          className="cont_flex con_flex_wrap responsivoColumn"
          id="mostrarMostrarBancos"
        ></div>
        <div id="mostrarTablaOfertas">
          <h1 className="titt">Otras opciones </h1>
          <br />
          {/* <---------- Titulos de tabla con ofertas (No responsivo) ----------> */}
          <div className="tablaFijosCeldasTitulo" id="tituloTablaBancosFijos">
            <div className="etiquetaTablaBancos">
              <b>Nombre</b>
            </div>
            <div className="etiquetaTablaBancos">
              <b>Oferta</b>
            </div>
            <div className="etiquetaTablaBancos">
              <b>Plazo</b>
            </div>
            <div className="etiquetaTablaBancos">
              <b>Mensualidad</b>
            </div>
            <div className="etiquetaTablaBancos">
              <b>Tipo Pago</b>
            </div>
            <div className="etiquetaTablaBancos">
              <b>Tasa</b>
            </div>
            <div className="etiquetaTablaBancos">
              <b>Información adicional</b>
            </div>
            <div className="etiquetaTablaBancos">
              <b>Aplicar</b>
            </div>
          </div>
        </div>
        {/* <---------- Tabla con ofertas (No responsivo) ----------> */}
        <div className="tablaFijosCeldas" id="mostrarFijos"></div>
        {/* <---------- Tabla con ofertas (Responsivo) ----------> */}
        <div id="mostrarFijosResponsivo"></div>
        <br />
        <div id="guardarBancosBotonSeleccion">
          <ConSeleccionBancos />
        </div>
        <ModalLoadingGeneral open={isOpenModalLoading}></ModalLoadingGeneral>
      </div>
    </>
  );
}
