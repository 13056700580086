import { MessageSquareDiff } from "lucide-react";
import DialogNuevaConversacion from "./DialogNuevaConversacion";
import { useEffect, useState } from "react";
import { getEP } from "../../utils/http";
import Conversacion from "./Conversacion";
import { useChatContext, useSetChatContext } from "../../providers/ChatProvider";

export default function PanelIzquierdo(){
	const chatData = useChatContext();
	const setChatData = useSetChatContext();

	const [dialogConversacion, setDialogConversacion] = useState(false);
	const [conversaciones, setConversaciones] = useState([]);

	async function obtConversaciones(){
		const peticionConversaciones = await getEP("whatsapp/obtChats", {
			"x-token": chatData.ejecutivo.token
		});

		if(peticionConversaciones?.statusCode === 200){
			setConversaciones(peticionConversaciones.respuesta.chats);
		}else{
			setConversaciones([]);
		}
	}

	function registrarChat(data){
		setConversaciones(prevConversaciones => [data, ...prevConversaciones]);
	}

	// Acci�n de actualizaci�n de chats
	useEffect(() => {
		if(!chatData?.actualizarChats) return;
		obtConversaciones();
		setChatData(prevData => ({
			...prevData,
			actualizarChats: false
		}));
	}, [chatData?.actualizarChats]);

	// Acci�n de selecci�n de un chat
	useEffect(() => {
		if(chatData?.chat?.id){
			if(conversaciones.some(conversacion => conversacion.idChat === chatData?.chat?.id && conversacion.nuevoMensaje === true)){
				setConversaciones(prevConversaciones => prevConversaciones.map(conversacion => {
					if(conversacion.idChat === chatData?.chat?.id){
						return {
							...conversacion,
							nuevoMensaje: false
						};
					}
					return conversacion;
				}));
			}
		}
	}, [chatData?.chat?.id]);

	useEffect(() => {
		obtConversaciones();
	}, []);

	return (
		<div id="chat-panelIzquierdo">
			<div className='sticky'>
				{/* Header */}
				<div className='header'>
					<div className='icons'>
						<MessageSquareDiff size={20} onClick={() => {setDialogConversacion(true)}} />
						<DialogNuevaConversacion open={dialogConversacion} onSend={registrarChat} onClose={() => setDialogConversacion(false)}/>
					</div>
				</div>

				{/*<div className='search-container'>
					<div className='search-wrapper'>
						<TextField id="outlined-search" label="Buscar" type="search" size="small" fullWidth />
					</div>
					<ListFilter className='icons filter-icon' />
				</div>*/}
			</div>

			{/* Chat List */}
			<div className='chat-list'>
				{
					conversaciones?.map((conversacion) => (
						<Conversacion key={conversacion.idChat} conversacion={conversacion} />
					))
				}
				{conversaciones?.length === 0 && (
					<><p className='no-conversations'>No tienes chats activos</p></>
				)}
			</div>
		</div>
	);
};