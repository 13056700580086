import "../App.css";
import "../sass/style.css";
import React from "react";
import * as ReactDOM from "react-dom/client";
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { InfoBancos } from "../components/Cards";
import logoMorado from "../images/logo_morado.svg";
import Cookies from "universal-cookie";
import loadingicon from "../images/loadingicon.gif";
import { get, post, incode, incodeFlowId } from "../utils/http";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { useDataPublic, useSetDataPublic } from "../providers/dataPublic/PublicProvider";
import { BancosNavHome } from "./Cards";
import valido from "../images/Valido.png";
import { obtEmpresaUrl, verificarCoacreditado } from "../utils/global_functions";
import { InfoScore } from "../components/Cards";

import imgGanancias from "../images/ganancias.png";
import imgUniversidad from "../images/universidad.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import imgEscanearINE from "../images/tutorial_ine.png";
import FlujoIncode from "../pages/noAuth/FlujoIncode";
import { ruleEmail } from "../assets/validateRules";

import imgInstrucciones from "../images/instrucciones.png";
import { formatoMoneda } from "../utils/operaciones_formulario";
import { EstatusCredito } from "./EstatusCredito";
import OfertasBancos from "./OfertasBancos";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------- Modal para ingresar el NIP del sms entre paso 1 y 2 ------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export function ModalCodigoSms({
  children,
  open,
  modalBuroId,
  modalResPaso1,
  close,
}) {
  const [digito1, setDigito1] = useState();
  const [digito2, setDigito2] = useState();
  const [digito3, setDigito3] = useState();
  const [digito4, setDigito4] = useState();
  const [digito5, setDigito5] = useState();
  const [digito6, setDigito6] = useState();
  const actionContext = useSetDataPublic();

  const [isOpenModalLoading, setIsOpenModalLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = () => {
    let nip = digito1 + digito2 + digito3 + digito4 + digito5 + digito6;
    sendpaso2(nip);
  };

  let location = useLocation();

  const sendpaso2 = async (nip) => {
    setIsOpenModalLoading(true);

    let data = {
      buroId: parseInt(modalBuroId),
      nip: parseInt(nip),
      idResPaso1: parseInt(modalResPaso1),
    };
    let response = await post("prePaso2", data);

    if (response.statuscode === 200) {
      setIsOpenModalLoading(false);
      localStorage.setItem("statuscodepaso2", response.statuscode);
      localStorage.setItem("sinRespuestaBuro", "false");
      if (location.pathname === "/preRegistroDinamico") {
        actionContext({ idResPaso1: parseInt(modalResPaso1) });
        localStorage.setItem("idResPaso1Buro", modalResPaso1);
        localStorage.setItem("idResPaso1Cuestionario", modalResPaso1);
        navigate("/crearcuenta");
      } else {
        localStorage.setItem("idResPaso1Cuestionario", modalResPaso1);
        // navigate('/informacionGeneral')

        let responseBtBuro = await get(
          "obtDatosPaso2PorIdResPaso1",
          parseInt(modalResPaso1)
        );
        if (responseBtBuro.statuscode === 200) {
          if (parseInt(responseBtBuro.paso2.endeudamiento) >= 40) {
            navigate("/respuestaBuro");
          } else {
            navigate("/informacionGeneral");
          }
        } else {
          navigate("/respuestaBuro");
        }
      }
    } else {
      setIsOpenModalLoading(false);
      localStorage.setItem("idResPaso1Buro", modalResPaso1);
      localStorage.setItem("statuscodepaso2", response.statuscode);

      Swal.fire({
        title: "No fue posible consultar tu buró",
        text: "Con la información que nos proporcionaste no fue posible consultar tu buró, sin embargo continua para obtener opciones de creditos disponibles para ti.",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "Continuar sin resultado del buró",
      }).then((result) => {
        if (result.isConfirmed) {
          if (location.pathname === "/preRegistroDinamico") {
            actionContext({ idResPaso1: parseInt(modalResPaso1) });
            localStorage.setItem("idResPaso1Buro", modalResPaso1);
            localStorage.setItem("sinRespuestaBuro", "true");
            localStorage.setItem("idResPaso1Cuestionario", modalResPaso1);
            navigate("/crearcuenta");
          } else {
            localStorage.setItem("idResPaso1Buro", modalResPaso1);
            localStorage.setItem("idResPaso1Cuestionario", modalResPaso1);
            navigate("/respuestaBuro");
            localStorage.setItem("sinRespuestaBuro", "true");
          }
        }
      });
    }
  };

  useEffect(() => {
    obtEmpresaUrl();
  }, []);

  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop pop_sms">
        <div className="closeModal">
          <span onClick={close}>
            {" "}
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </span>
        </div>
        {children}
        {/* <img src={isotipo_negro} className={"isotipo"} alt="logo" /> */}
        <div className="cont_text">
          <h1>Solo para estar seguros que eres tú</h1>
          <p>
            Te enviamos por SMS un código de autenticación al celular que
            registraste{" "}
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="divCodigoSms">
              {[1, 2, 3, 4, 5, 6].map((num) => (
                <input
                  key={num}
                  type="tel"
                  name={`num${num}`}
                  id={`num${num}`}
                  maxLength="1"
                  onInput={(ev) => {
                    let tempNum = ev.target.value;
                    if (!isNaN(tempNum) && tempNum.length > 0) {
                      tempNum = ev.target.value;
                      const nextInput = ev.target.nextElementSibling;
                      if (nextInput) {
                        nextInput.focus();
                      }
                    } else if (tempNum.length === 0) {
                      const prevInput = ev.target.previousElementSibling;
                      if (prevInput) {
                        prevInput.focus();
                      }
                    }
                    switch (num) {
                      case 1:
                        setDigito1(tempNum);
                        break;
                      case 2:
                        setDigito2(tempNum);
                        break;
                      case 3:
                        setDigito3(tempNum);
                        break;
                      case 4:
                        setDigito4(tempNum);
                        break;
                      case 5:
                        setDigito5(tempNum);
                        break;
                      case 6:
                        setDigito6(tempNum);
                        break;
                      default:
                        break;
                    }
                  }}
                  {...register(`num${num}`, { required: true })}
                />
              ))}
              
            </div>

            <div>
              {[1, 2, 3, 4, 5, 6].some((num) => ( errors[`num${num}`]?.type === "required")) && 
                <span>
                  Ingresa los 6 dígitos de tu NIP
                </span>
              }
            </div>

            <div className="botonesModal">
              <button type="submit" className="btn btn_morado_modal">
                Validar NIP
              </button>
            </div>
          </form>
        </div>
      </div>
      <ModalLoadingGeneral open={isOpenModalLoading}></ModalLoadingGeneral>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------- Modal para aceptar términos y condiciones ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalCasiListos({ children, open, close, accept }) {
  const [aceptarAviso, setAceptarAviso] = useState(0);
  const [aceptarTerminos, setAceptarTerminos] = useState(0);

  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop">
        <div className="closeModal">
            <span onClick={()=>{
              setAceptarAviso(0);
              setAceptarTerminos(0);
              close();
            }}>
              {" "}
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </span>
        </div>
        {children}
        <div className="cont_text">
          <h1>Casi listos</h1>
          <p>
            Antes de continuar, ¿estás de acuerdo con los{" "}
            <a className="link" href="terminosCondiciones" target="_blank">
              términos y condiciones{" "}
            </a>{" "}
            y con <b>consultar tu buró de crédito?</b>
          </p>
          <form>
            <div>
              <div className="aceptarTerminos">
                <input
                  type="radio"
                  value={aceptarAviso}
                  onChange={() => {
                    setAceptarAviso(1);
                  }}
                />{" "}
                <label> Acepto términos y condiciones </label>
              </div>
              <div className="aceptarTerminos">
                <input
                  type="radio"
                  value={aceptarTerminos}
                  onChange={() => {
                    setAceptarTerminos(1);
                  }}
                />{" "}
                <label>
                  {" "}
                  Estoy de acuerdo con la consulta a mi buró de crédito{" "}
                </label>
              </div>
            </div>
            {aceptarAviso !== 0 && aceptarTerminos !== 0 && (
              <div className="botonesModal">
                <button className="btn btn_morado_modal" onClick={accept}>
                  Continuar
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------------------ Modal Informacion Enviada ----------------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

//JPB
export function ModalInformacionSubida({ children, open, close }) {
  useEffect(() => {
    obtEmpresaUrl();
  }, []);
  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop_informacion_subida">
        {children}
        <img src={valido} />
        <div className="cont_text">
          <h1>
            Información subida correctamente <br />{" "}
          </h1>
          <p>
            Hemos recibido su información, su agente contactara con usted una
            vez que se validen los documentos
          </p>
        </div>
        <div className="botonesModal">
          <button className="btn btn_morado_modal" onClick={close}>
            {" "}
            Aceptar y regresar
          </button>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------------------ Modal Sabias que... ----------------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalSabiasQue({ children, open, close }) {
  const [mostrarImagen, setMostrarImagen] = useState();

  useEffect(() => {
    async function fetchData() {
      const empresa = await obtEmpresaUrl();
      if (empresa && empresa.imagenLoading) {
        setMostrarImagen(empresa.imagenLoading);
      }
    }
    fetchData();
  }, []);

  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop">
        {children}
        <div className="cont_text">
          <img src={mostrarImagen} alt="" />
          <h1>
            Estamos <br />
            analizando tu perfil
          </h1>
          <p>
            <b>En lo que revisamos tus datos sabías que...</b>{" "}
          </p>
          <p>
            Más de la mitad de la población no tiene acceso a hipotecas. Con{" "}
            <span className="link">Nosotros</span> 7 de cada 10 solicitantes
            obtienen el crédito que buscaban
          </p>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------- Modal en donde se muestran los resultados del buró de crédito -------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalBuroDeCredito({ children, open, cardScoreModal, close }) {
  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop_buro anchoGrandePop30">
        <div className="closeModal_buro">
          <span onClick={close}>
            {" "}
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </span>
        </div>
        {children}
        <div className="cont_flex" id="buroResultados">
          {cardScoreModal}
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------- Modal en donde se muestra el deatlle de las ofertas al seleccionarlas ---------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalMasInfoBancos({
  children,
  open,
  close,
  ModalBanco,
  ModalCreditoOfertado,
  ModalMontoSolicitado,
  ModalPlazo,
  ModalMensualidad,
  ModalTasa,
  descripcion,
  PorcentajeTasa,
  montoSolicitado,
  Tasa,
  tipoPago,
  valorPropiedad,
  enganche,
  
}) {
  let cardBancos = (
    <InfoBancos
      banco={ModalBanco}
      creditoOfertado={ModalCreditoOfertado}
      montoSolicitado={montoSolicitado}
      plazo={ModalPlazo}
      mensualidad={ModalMensualidad}
      tasa={ModalTasa}
      descripcion={descripcion}
      PorcentajeTasa={PorcentajeTasa}
      tipoTasa = {Tasa}
      tipoPago = {tipoPago}
      valorPropiedad = {valorPropiedad}
      enganche = {enganche}
    ></InfoBancos>
  );

  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop_buro anchoGrandePop">
        <div className="closeModal">
          <span onClick={close}>
            {" "}
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </span>
        </div>
        {children}
        <div className="cont_flex">{cardBancos}</div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------- Modal en donde se muestra el deatlle de las ofertas al seleccionarlas2 ---------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalDetallesBancos({ key, children, open, cardBanco, close }) {
  const [isOpenModalBuro, setIsOpenModalBuro] = useState(false);
  const [pintarScore, setPintarScore] = useState();
  const [isOpenModalSinBancos, setIsOpenModalSinBancos] = useState(false);
  const [sinBancoId, setSinBancoId] = useState();

  const GetNF = (value) => {
    return value.toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  function redireccionarCard() {
    if (cardBanco.longitudBancos === 0) {
      verAplicacionPreviaCotizacion();
    } else {
      localStorage.setItem("idResPaso1Cuestionario", cardBanco.idResPaso1);

      localStorage.setItem("verBancos", cardBanco.cuestionarioId);
      localStorage.setItem("plazoParaBancosSeleccionados", cardBanco.plazo);
      window.location.href = "./BancosSeleccionados";
    }
  }

  const verAplicacionPreviaCotizacion = async () => {
    if (cardBanco.cuestionarioId !== 0) {
      let aplicadoPrevio = await get(
        "obtResPrecalificaSinBancosPorIdCuestionario",
        parseInt(cardBanco.cuestionarioId)
      );
      if (aplicadoPrevio.statucode === 200) {
        setSinBancoId(aplicadoPrevio.msg);
        setIsOpenModalSinBancos(true);
      } else {
        let responseBtBuro = await get(
          "obtDatosPaso2PorIdResPaso1",
          parseInt(cardBanco.idResPaso1)
        );
        if (responseBtBuro.statuscode === 200) {
          if (parseInt(responseBtBuro.paso2.endeudamiento) >= 40) {
            Swal.fire({
              title: "Lo sentimos",
              text: "Por tu nivel de endeudamiento no puedes continuar con el proceso, pero un agente se pondrá en contacto contigo.",
              icon: "error",
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: "Aceptar",
            }).then((result) => {});
          } else {
            localStorage.setItem(
              "idResPaso1Cuestionario",
              cardBanco.idResPaso1
            );
            window.location.href = "./InformacionGeneral";
          }
        } else {
          localStorage.setItem("idResPaso1Cuestionario", cardBanco.idResPaso1);
          window.location.href = "./InformacionGeneral";
        }
      }
    } else {
      let responseBtBuro = await get(
        "obtDatosPaso2PorIdResPaso1",
        parseInt(cardBanco.idResPaso1)
      );
      if (responseBtBuro.statuscode === 200) {
        if (parseInt(responseBtBuro.paso2.endeudamiento) >= 40) {
          Swal.fire({
            title: "Lo sentimos",
            text: "Por tu nivel de endeudamiento no puedes continuar con el proceso, pero un agente se pondrá en contacto contigo.",
            icon: "error",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: "Aceptar",
          }).then((result) => {});
        } else {
          localStorage.setItem("idResPaso1Cuestionario", cardBanco.idResPaso1);
          window.location.href = "./InformacionGeneral";
        }
      } else {
        localStorage.setItem("idResPaso1Cuestionario", cardBanco.idResPaso1);
        window.location.href = "./InformacionGeneral";
      }
    }
  };

  const nombreBanco = async () => {
    if (cardBanco.cuestionarioId !== 0) {
      let aplicadoPrevio = await get(
        "obtResPrecalificaSinBancosPorIdCuestionario",
        parseInt(cardBanco.cuestionarioId)
      );
      if (aplicadoPrevio.statucode === 200) {
        const btnBank = document.getElementById(
          "nombreBtnAccion" + cardBanco.idResPaso1
        );
        btnBank.innerHTML = "Ver resultado";
      }
    }
  };

  useEffect(() => {
    obtEmpresaUrl();
    nombreBanco();
  }, []);

  const verModalburo = async () => {
    let responseBtBuro = await get(
      "obtDatosPaso2PorIdResPaso1",
      parseInt(cardBanco.idResPaso1)
    );
    if (responseBtBuro.statuscode === 200) {
      let infoGetScore = [
        {
          pagoMensual: responseBtBuro.paso2.pagoMensual,
          capacidadPago: responseBtBuro.paso2.capacidadPago,
          deuda: responseBtBuro.paso2.deuda,
          endeudamiento: responseBtBuro.paso2.endeudamiento,
          comportamiento: responseBtBuro.paso2.comportamiento,
          mensualidadSinDeuda: responseBtBuro.paso2.mensualidadSinDeuda,
          montoSinDeuda: responseBtBuro.paso2.montoSinDeuda,
          mensualidadConDeuda: responseBtBuro.paso2.mensualidadConDeuda,
          montoConDeuda: responseBtBuro.paso2.montoConDeuda,
        },
      ];

      let cardScore = infoGetScore.map((infoScore) => (
        <InfoScore
          pagoMensual={infoScore.pagoMensual}
          capacidadPago={infoScore.capacidadPago}
          deuda={infoScore.deuda}
          endeudamiento={infoScore.endeudamiento}
          comportamiento={infoScore.comportamiento}
          mensualidadSinDeuda={infoScore.mensualidadSinDeuda}
          montoSinDeuda={infoScore.montoSinDeuda}
          mensualidadConDeuda={infoScore.mensualidadConDeuda}
          montoConDeuda={infoScore.montoConDeuda}
        />
        
      ));
      setPintarScore(cardScore);
      setTimeout(function () {
        setIsOpenModalBuro(true);
      }, 1000);
    } else {
      Swal.fire({
        title: "Error en la consulta al buro de crédito",
        text: "Inténtelo de nuevo",
        icon: "error",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: "Aceptar",
      }).then((result) => {});
    }
  };

  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      {/* <div id="cotizacionesBancos" className='modal_pop_banco ancho-modal-banco-cotizacion'> */}
      <div id="cotizacionesBancos" className="modal_pop alto_pop">
        <div className="closeModal">
          <span onClick={close}>
            {" "}
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </span>
        </div>
        {children}
        {/* <div className="cont_flex" id="buroResultadosBancos"> */}
        <div className="cont_loop_solicitud">
          <h3>{cardBanco.nombre}</h3>
          <p className="descripcion font14">
            Creado el <span>{cardBanco.fecha.substr(0, 10)}</span>
          </p>
          {
            cardBanco.solicitudEnProceso && (
              <div className="estado_cotizacion font14">
                <p>En proceso de solicitud</p>
              </div>
            )
          }
          <div className="font14">
            <div className="renglon">
              <p className="descripcion col_ini">Valor de propiedad</p>
              <p className="precio col_fin">${GetNF(cardBanco.valorPropeidad)} MXN</p>
            </div>
            <div className="renglon">
              <p className="descripcion col_ini">Plazo </p>
              <p className="col_fin">{cardBanco.plazo} años</p>
            </div>
            <div className="renglon">
              <p className="descripcion col_ini">Monto solicitado </p>
              <p className="col_fin">${GetNF(cardBanco.montoSolicitado)} MXN</p>
            </div>
            <div className="renglon">
              <p className="descripcion col_ini">Enganche </p>
              <p className="col_fin">${GetNF(cardBanco.enganche)} MXN</p>
            </div>
          </div>
          <p className="tasa">
            Tasa: <span className="monto">{cardBanco.tasa}%</span>
          </p>
          {cardBanco.statusBuro === 200 && (
            <button
              className="btn btn_bco marginEspacioArribaAbajo ancho100"
              id={"verburo" + cardBanco.idResPaso1}
              onClick={verModalburo}
            >
              Consulta los detalles de tu buró
            </button>
          )}
         
          <div id="leyendaBoton">
            <button
              className="btn btn_morado ancho100"
              id={"nombreBtnAccion" + cardBanco.idResPaso1}
              onClick={redireccionarCard}
            >
              {" "}
              {cardBanco.longitudBancos === 0
                ? "Aplicar"
                : "Ver opciones de crédito"}
            </button>
          </div>
          {/* </div> */}
        </div>
        <div className="cont_resultados_buro">
          <ModalBuroDeCredito
            open={isOpenModalBuro}
            cardScoreModal={pintarScore}
            close={() => setIsOpenModalBuro(false)}
          ></ModalBuroDeCredito>
        </div>
        
        <ModalRespuestaPreAutorizaSinBancosTarjetas
          open={isOpenModalSinBancos}
          msg={sinBancoId}
          close={() => setIsOpenModalSinBancos(false)}
        ></ModalRespuestaPreAutorizaSinBancosTarjetas>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------- Modal que se muestra cuando no estas registrado en crediteka  -------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalVerificarRegistroCrediteka({ children, open, close }) {
  const navigate = useNavigate();

  function regresarHome() {
    navigate("/cotizaciones");
  }

  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop">
        {children}
        <div className="cont_text">
          <h1>
            Por favor <br /> verifica tu registro
          </h1>
          <p>
            <b>Te hemos enviado un correo para comprobar que eres tú</b>
          </p>
          <p>Si no encuentras el correo, buscalo en la carpeta de spam </p>
        </div>
        <div className="botonesModal">
          <button className="btn btn_morado_modal" onClick={close}>
            Aceptar
          </button>
        </div>
        <div className="botonesModal">
          <button className="btn btn_morado_modal" onClick={regresarHome}>
            Regresar
          </button>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------- Modal cuando no se puede procesar una solicitud --------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalErrorVerificar({ children, open, close }) {
  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop">
        {children}
        <div className="cont_text">
          <p>
            <b>Error al procesar su solicitud</b>{" "}
          </p>
          <p>Inténtelo más tarde</p>
        </div>
        <div className="botonesModal">
          <button className="btn btn_morado_modal" onClick={close}>
            Aceptar
          </button>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------ Modal utilizado como menú lateral reponsivo ----------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function MenuLateralResponsivo({ children, open, close, active }) {
  const dataPublic = useDataPublic();
  const setDataPublic = useSetDataPublic();
  const cookies = new Cookies();
  const [bancosSubM, setBancosSubM] = useState([]);
  const [resultado, setResultado] = useState(0);
  const [esCoAcreditado, setEsCoAcreditado] = useState(false);

  async function revisarCoacreditado() {
    try {
      const tieneCoAcrediado = await verificarCoacreditado(cookies.get("id"));
      if (tieneCoAcrediado === true) {
        setEsCoAcreditado(true);
      } else {
        setEsCoAcreditado(false);
      }
    } catch (error) {
      console.error("Ocurrió un error al revisar el coacreditado:", error);
    }
    setDataPublic({actualizarNav: false});
  }

  const logOut = () => {
    localStorage.removeItem("idResPaso1Cuestionario");
    localStorage.removeItem("BancoLS1");
    localStorage.removeItem("BancoLS2");
    localStorage.removeItem("idResPaso1SeleccionarBancos");
    localStorage.removeItem("verBancos");

    localStorage.removeItem("monto");
    localStorage.removeItem("plazoVal");
    localStorage.removeItem("montoPrestar");
    localStorage.removeItem("enganche");
    localStorage.removeItem("tasa");
    localStorage.removeItem("mensualidad");
    localStorage.removeItem("sinRespuestaBuro");

    cookies.remove("session");
    cookies.remove("nombre");
    cookies.remove("correo");
    cookies.remove("id");
    window.location.href = "./";
  };

  const info = async () => {
    try {
      let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
      //let contadorBancos = 0;
      let arrbancos = [];

      if (idCuestionario.statuscode === 200) {
        if (idCuestionario.infoCalifica.length !== 0) {
          for (let i = 0; i < idCuestionario.infoCalifica.length; i++) {
            for (
              let j = 0;
              j < idCuestionario.infoCalifica[i].cuestionarioColeccion.length;
              j++
            ) {
              arrbancos.push({
                banco:
                  idCuestionario.infoCalifica[i].cuestionarioColeccion[j]
                    .resPrecalificas.productoNombre,
                idResPrecalifica:
                  idCuestionario.infoCalifica[i].cuestionarioColeccion[j]
                    .resPrecalificas.idResPrecalifica,
                idCuestionario:
                  idCuestionario.infoCalifica[i].cuestionarioColeccion[j]
                    .resPrecalificas.idCuestionario,
                idCredito:
                  idCuestionario.infoCalifica[i].cuestionarioColeccion[j]
                    .idCredito,
                idActividadEconomica:
                  idCuestionario.infoCalifica[i].cuestionarioColeccion[j]
                    .idActividadEconomica,
              });
              //contadorBancos++;
              //setResultado(contadorBancos);
            }
          }

          setBancosSubM(arrbancos);
        }
      } else {
        //Se mandan vacíos al no tener informacion en info califica
        //setResultado(0);
        setBancosSubM(arrbancos);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function inicializarPantalla(){
      await revisarCoacreditado();
      await info();
    }
    
    inicializarPantalla();
  }, []);

  // Actualiza el contenido del menú responsivo
  useEffect(() => {
    if(dataPublic?.actualizarNav === true){
      revisarCoacreditado();
      info();
    }
  }, [dataPublic?.actualizarNav]);

  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop_menuLateral anchoGrandePopMenuLateral">
        <div className="closeModal_menu">
          <div>
            {/* <Link to="/home">
              {" "}
              <img src={logoMorado} alt="logo" />
            </Link> */}
          </div>
          <button
            type="button"
            aria-label="Toggle Navigation"
            className="lines-button x"
            onClick={close}
          >
            <span className="lines"></span>
          </button>
        </div>
        {children}
        <div className=" menu_lateral_pop">
          <div className="widthCompleto">
            <ul>
              <li onClick={close}>
                <Link to="/home" className={active === "01" ? "active" : ""}>
                  <i className="fa fa-th-large"></i> Resumen{" "}
                </Link>
              </li>
              <li onClick={close}>
                <Link
                  to="/misDatos"
                  className={active === "02" ? "active" : ""}
                >
                  <i className="fa  fa-user"></i> Mis Datos{" "}
                </Link>
              </li>
              <li onClick={close}>
                <Link
                  to="/cotizaciones"
                  className={active === "03" ? "active" : ""}
                >
                  <i className="fa  fa-file"></i> Mis Cotizaciones{" "}
                </Link>
              </li>
              <li onClick={close}>
                <Link 
                  to="/cotizacionesAplicadas"
                  className={active === "04" ? "active" : ""}
                >
                  <i className="fa  fa-file"></i> Mis Solicitudes de Crédito
                </Link>
              </li>
              <div className="subMenu">
                {bancosSubM?.length > 0 && (
                  <li onClick={close}>
                    <Link
                      to="/informacion-general"
                      className={active === "03" ? "active" : ""}
                    >
                      <i className="fa fa-info"></i> Información General{" "}
                    </Link>
                  </li>
                )}
                {esCoAcreditado && (
                  <li onClick={close}>
                    <Link
                      to="/InfocoAcreditado"
                      className={active === "04" ? "active" : ""}
                    >
                      <i className="fa fa-info"></i> Información General
                      Coacreditato{" "}
                    </Link>
                  </li>
                )}
              </div>

              <div className="bancos-list-container">
                {bancosSubM.map((banco) => (
                  <BancosNavHome
                    key={banco.idCuestionario}
                    banco={banco.banco}
                    idCuestionario={banco.idCuestionario}
                    idResPrecalifica={banco.idResPrecalifica}
                    idCredito={banco.idCredito}
                    idActividadEconomica={banco.idActividadEconomica}
                    clase={
                      localStorage.getItem("idResPrecalifica") ===
                        banco.idResPrecalifica &&
                      localStorage.getItem("idCuestionarioSolicitud") ===
                        banco.idCuestionario
                        ? "active"
                        : ""
                    }
                  />
                ))}
              </div>
              
              <br />
              <li onClick={close}>
                <Link to="/miHipoteca" className={active === "07" ? "active" : ""}>
                  <i className="fa fa-money"></i> Mi Hipoteca
                </Link>
              </li>
              
              <li onClick={close}>
                <Link to="/simulacion">
                  {" "}
                  <i className="fa fa-plus-square" aria-hidden="true"></i> Nueva
                  Cotización{" "}
                </Link>
              </li>
            </ul>
          </div>
          <div className="botonesModal">
            <button
              className="btn btn_morado_modal"
              onClick={() => {
                logOut();
              }}
            >
              Cerrar sesión
            </button>
          </div>
          <div className="copyright">
            <p>
              En caso de que tengas preguntas, dudas o necesites asesoría no
              dudes en contactarnos. Nos encanta atenderte a la hora que lo
              necesites
            </p>
            <li onClick={close}>
              {" "}
              <Link to="/AvisoPrivacidadLogueado" className="link">
                Aviso de privacidad
              </Link>
            </li>
            <li onClick={close}>
              {" "}
              <Link to="/TerminosCondicionesLogueado" className="link">
                Términos y condiciones
              </Link>
            </li>
            <p>© 2025. Saturn5 Technologies SAPI de CV</p>
          </div>
          {/*<NavSocio />*/}
        </div>
      </div>
    </>
  );
}

export function MenuLateralResponsivoSocios({ children, open, close }) {
  const cookies = new Cookies();

  const logOut = () => {
    localStorage.removeItem("idResPaso1Cuestionario");
    localStorage.removeItem("BancoLS1");
    localStorage.removeItem("BancoLS2");
    localStorage.removeItem("idResPaso1SeleccionarBancos");
    localStorage.removeItem("verBancos");

    localStorage.removeItem("monto");
    localStorage.removeItem("plazoVal");
    localStorage.removeItem("montoPrestar");
    localStorage.removeItem("enganche");
    localStorage.removeItem("tasa");
    localStorage.removeItem("mensualidad");

    cookies.remove("sessionSocio");
    cookies.remove("nombreSocio");
    cookies.remove("correoSocio");
    cookies.remove("idSocios");
    cookies.remove("idSocio");
    window.location.href = "./inicioSocios";
  };

  if (!open) return null;
  return (
    <>
      {cookies.get("idSocio") ? (
        <div>
          <div className="modal_back" />
          <div className="modal_pop_menuLateral anchoGrandePopMenuLateral">
            <div className="closeModal_menu">
              <div>
                {/* <Link to="/inicioSocios">
                  {" "}
                  <img src={logoMorado} alt="logo" />
                </Link> */}
              </div>
              <button
                type="button"
                aria-label="Toggle Navigation"
                className="lines-button x"
                onClick={close}
              >
                <span className="lines"></span>
              </button>
            </div>
            {children}
            <div className="menu_lateral_pop">
              <div className="widthCompleto">
                <ul>
                  <li onClick={close}>
                    <Link to="/Home_Socios">
                      <i className="fa fa-user"></i> Home Socios{" "}
                    </Link>
                  </li>
                  <li onClick={close}>
                    <Link to="/invitacionesSocios">
                      <i className="fa fa-envelope"></i> Invitaciones{" "}
                    </Link>
                  </li>
                  <li onClick={close}>
                    <Link to="/panelSocios">
                      <i className="fa fa-th-large"></i> Mis Clientes{" "}
                    </Link>
                  </li>
                  <li onClick={close}>
                    <Link to="/misComisiones">
                      <i className="fa fa-user"></i> Mis Comisiones{" "}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="botonesModal">
                <button
                  className="btn btn_morado_modal"
                  onClick={() => {
                    logOut();
                  }}
                >
                  Cerrar sesión
                </button>
              </div>
              <div className="copyright">
                <p>
                  En caso de que tengas preguntas, dudas o necesites asesoría no
                  dudes en contactarnos. Nos encanta atenderte a la hora que lo
                  necesites
                </p>
                <li onClick={close}>
                  {" "}
                  <Link to="/AvisoPrivacidadLogueado" className="link">
                    Aviso de privacidad
                  </Link>
                </li>
                <li onClick={close}>
                  {" "}
                  <Link to="/TerminosCondicionesLogueado" className="link">
                    Términos y condiciones
                  </Link>
                </li>
                <p>© 2025. Saturn5 Technologies SAPI de CV</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="modal_back" />
          <div className="modal_pop_menuLateral anchoGrandePopMenuLateral">
            <div className="closeModal_menu">
              <div>
                <Link to="/inicioSocios">
                  {" "}
                  <img src={logoMorado} alt="logo" />
                </Link>
              </div>
              <button
                type="button"
                aria-label="Toggle Navigation"
                className="lines-button x"
                onClick={close}
              >
                <span className="lines"></span>
              </button>
            </div>
            {children}
            <div className="menu_lateral_pop">
              <div className="widthCompleto">
                <ul>
                  <li onClick={close}>
                    <Link to="/inicioSocios">
                      <i className="fa fa-th-large"></i> Iniciar sesión{" "}
                    </Link>
                  </li>
                  <li onClick={close}>
                    <Link to="/CrearCuentaSocios">
                      <i className="fa fa-user"></i> Crear cuenta{" "}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="copyright">
                <p>
                  En caso de que tengas preguntas, dudas o necesites asesoría no
                  dudes en contactarnos. Nos encanta atenderte a la hora que lo
                  necesites
                </p>
                <li onClick={close}>
                  {" "}
                  <Link to="/AvisoPrivacidadLogueado" className="link">
                    Aviso de privacidad
                  </Link>
                </li>
                <li onClick={close}>
                  {" "}
                  <Link to="/TerminosCondicionesLogueado" className="link">
                    Términos y condiciones
                  </Link>
                </li>
                <p>© 2025. Saturn5 SAPI de CV</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Modal 'loading' cuando se envia un cuestionario preAutoriza --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalLoading({ children, open }) {
  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop">
        {children}
        <div className="cont_text">
          <img src={loadingicon} alt="" />
          <h1>
            Estamos <br />
            analizando tu solicitud
          </h1>
          <p>
            <b>Analizando información...</b>{" "}
          </p>
          <p>
            Favor de no cerrar la ventana, en un momento podrá observar los
            resultados obtenidos
          </p>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------- Modal que aparece cuando se valida el hash al cambiar contraseña ------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalLoadingCambiarContraseña({ children, open, close }) {
  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop">
        {children}
        <div className="cont_text">
          <img src={loadingicon} alt="" />
          <h1>
            Estamos <br /> asegurandonos que eres tú
          </h1>
          <p>
            <b>Favor de no cerrar la ventana...</b>{" "}
          </p>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------- Modal que muestra mensaje si es que no se retornan bancos ---------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalRespuestaPreAutorizaSinBancos({
  children,
  open,
  msg,
  close,
}) {
  function regresarHome() {
    window.location.href = "./home";
  }

  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop">
        {children}
        <div className="cont_text">
          <h1>Resultado de su consulta</h1>
          {/* <p> {msg} </p>                     */}
          <div
            id="txtSinBanco"
            className="sinbancosClass"
            dangerouslySetInnerHTML={{ __html: msg }}
          ></div>
        </div>
        <div className="botonesModal">
          <button className="btn btn_morado_modal" onClick={close}>
            Editar información
          </button>
          <button className="btn btn_morado_modal" onClick={regresarHome}>
            Aceptar
          </button>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------- Modal que muestra mensaje si es que no se retornan bancos en la pantalla "Mis cotizaciones" ------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalRespuestaPreAutorizaSinBancosTarjetas({
  children,
  open,
  msg,
  close,
}) {
  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop doble_pop">
        <div className="closeModal">
          <span onClick={close}>
            {" "}
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </span>
        </div>
        {children}
        <div className="cont_text">
          <h1>Resultado de su consulta</h1>
          {/* <p> {msg} </p>         */}
          <div
            id="txtSinBanco"
            className="sinbancosClass"
            dangerouslySetInnerHTML={{ __html: msg }}
          ></div>
        </div>
        <div className="botonesModal">
          <button className="btn btn_morado_modal" onClick={close}>
            Aceptar
          </button>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------- Modal "loading" generico esperando una respuesta del API  ---------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalLoadingGeneral({ children, open, close }) {
  const [mostrarImagen, setMostrarImagen] = useState();

  useEffect(() => {
    async function fetchData() {
      const empresa = await obtEmpresaUrl();
      if (empresa && empresa.imagenLoading) {
        setMostrarImagen(empresa.imagenLoading);
      }
    }
    fetchData();
  }, []);

  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop">
        {children}
        <div className="cont_text">
          <img src={mostrarImagen} alt="" />
          <h1>Espere un momento</h1>
          <p>Cargando información</p>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------- Modal que se muestra cuando tienes una edad >= 84 años  -------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalVerificarEdad({ children, open, close, plazo, edad }) {
  const navigate = useNavigate();

  function regresarHome() {
    navigate("/home");
  }

  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop">
        {children}
        <div className="cont_text">
          <h1>
            Inicia tu simulación <br /> nuevamente{" "}
          </h1>
          <p>
            <b>
              El plazo que seleccionaste '{plazo} años' más tu edad '{edad}{" "}
              años' no debe ser mayor o igual a 84 años
            </b>
          </p>
        </div>
        <div className="botonesModal">
          <button className="btn btn_morado_modal" onClick={regresarHome}>
            {" "}
            Aceptar y regresar
          </button>
        </div>
      </div>
    </>
  );
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------- Modal en donde se muestra vermas de solicitud aceptada ---------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function ModalVerMasSolicitudAceptada({
  children,
  open,
  close,
  ModalBanco,
  ModalCreditoOfertado,
  ModalMontoSolicitado,
  ModalPlazo,
  ModalMensualidad,
  ModalTasa,
  descripcion,
  PorcentajeTasa,
}) {
  let cardBancos = (
    <InfoBancos
      banco={ModalBanco}
      creditoOfertado={ModalCreditoOfertado}
      montoSolicitado={ModalMontoSolicitado}
      plazo={ModalPlazo}
      mensualidad={ModalMensualidad}
      tasa={ModalTasa}
      descripcion={descripcion}
      PorcentajeTasa={PorcentajeTasa}
    ></InfoBancos>
  );

  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop_buro anchoGrandePop">
        <div className="closeModal">
          <span onClick={close}>
            {" "}
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </span>
        </div>
        {children}
        <div className="cont_flex">{cardBancos}</div>
      </div>
    </>
  );
}
// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------------- Modal Favor de llenar información general ------------------------------------------>
// <-------------------------------------------------------------------------------------------------------------------------------->

//JPB
export function ModalFavorLlenarInformacion({
  tieneCoAcrediado,
  infoUsuario,
  infoCoAcreditado,
  children,
  open,
  close,
}) {
  function rederigir() {
    //En caso de tener coacreditado
    if (tieneCoAcrediado) {
      if (!infoUsuario) {
        window.location.href = "../../informacion-general";
      }
      if (!infoCoAcreditado) {
        window.location.href = "../../InfocoAcreditado";
      }
    }
    //En caso de no tener coacreditado solo evalua la info del usuario
    else {
      if (!infoUsuario) {
        window.location.href = "../../informacion-general";
      }
    }
  }

  if (!open) return null;
  return (
    <>
      <div className="modal_back" />
      <div className="modal_pop_informacion_subida">
        <div className="closeModal">
          <span onClick={close}>
            {" "}
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </span>
        </div>
        {children}
        {/* <img src={noValido} /> */}
        <div className="cont_text">
          <h1>
            Favor de llenar su informaci&oacute;n general
            <br />{" "}
          </h1>
        </div>
        <div className="botonesModal">
          <button className="btn btn_morado_modal" onClick={rederigir}>
            {" "}
            Aceptar y completar formulario
          </button>
        </div>
      </div>
    </>
  );
}


// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------- Modal que muestra carrusel al iniciar por primera vez en Remax socios ---------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export function ModalInicioSocios({logo, onClose}) {
  let sliderRef = useRef(null);
  
  const next = () => {
    sliderRef.slickNext();
  };

  const Siguiente = () => {
    return(
        <button className="flecha_siguiente" onClick={next}>
          <i className="fa fa-chevron-right icono_flecha"></i>
        </button>
    );
  }

  const settings = {
    dots: true,
    infinite: false,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <br/>,
    nextArrow: <Siguiente />
  }

  return(
    <div className="modal_back flex_centrado">
      <div className="slider_cont">
        <Slider 
          ref={slider => {
            sliderRef = slider;
          }} 
          {...settings}>
          <div className="slider_card">
            <h1>Bienvenido a FINMAX</h1>
            <p>
              FINMAX te permite ayudar a tus clientes a
              encontrar el mejor financiamiento, lo que te
              permitirá cierres más rápidos.
            </p>
            <div className="flex_centrado">
              <img src={logo} className="img_slider" alt="" />
            </div>
          </div>
          
          <div className="slider_card">
            <h1>Te ofrece ganancias adicionales</h1>
            <p>
              Ingresa al home para conocer el plan de
              ingresos.
            </p>
            <div className="flex_centrado">
              <img src={imgGanancias} className="img_slider" alt="" />
            </div>
          </div>
          
          <div className="slider_card">
            <h1>Conoce más de cómo funciona</h1>
            <p>
              Ingresa a la universidad Remax para
              encontrar la capacitación completa.
            </p>
            <div className="flex_centrado">
              <img src={imgUniversidad} className="img_slider" alt="" />
            </div>
          </div>

          <div className="slider_card">
            <div className="cierra_carrusel">
              <button onClick={onClose}>
                <i className="fa fa-times-circle" aria-hidden="true"></i>
              </button>
            </div>
            <h1>Entérate de lo básico</h1>
            <p>
              Ve este pequeño video y entérate de lo
              básico
            </p>
            <div className="flex_centrado">
              <iframe 
                title="video remax" 
                className="video"
                src="https://www.youtube.com/embed/OfEsAg41kdc?si=W_sZPrx4QYoiDRCV" 
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerpolicy="strict-origin-when-cross-origin" 
                allowFullScreen={true}
              ></iframe>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------------- Modal que muetsra la opción de escanear la INE ----------- ---------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export function ModalEscanearINE({onSuccess, onClose}) {
  const [QR, setQR] = useState(false);

  function RedireccionQRINE({session, onSuccess, onClose}){
    const containerRef = useRef();
    const isMounted = useRef(false);
  
    useEffect(() => {
      if (isMounted.current) {
        return;
      }

      incode.renderQr(containerRef.current, {
        onSuccess: () => {
          onSuccess(session);
        },
        session: session,
        flowId: incodeFlowId,
      });
      
      isMounted.current = true;
    }, [session, onSuccess]);
  
    return (
      <div>
        <div className="cierra_pop">
          <button onClick={onClose}>
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </button>
        </div>
        <h2>Escanea el código QR para acceder desde tu dispositivo móvil</h2>
        <div className="cont_QR" ref={containerRef}></div>
        <div>
          <ul>
            <li>Utiliza la cámara de tu dispositivo móvil para escanear el código QR.</li>
            <li>Recomendamos usar Safari en iOS o Chrome en Android para abrir el código QR escaneado.</li>
            <li>Si utiliza una aplicación para escanear el código QR, copie la URL y péguela en los navegadores recomendados.</li>
          </ul>
        </div>
      </div>
    );
  }

  function LectorINE({onSuccess, onClose}){
    const [session, setSession] = useState();

    useEffect(() => {

      async function sessionStart(){
        const session = await incode.createSession("ALL", null, {
          configurationId: incodeFlowId,
        });
        await incode.warmup();
        setSession(session);
        //console.log("Session:", session);
      }
    
      if (session){
        return;
      }
  
      sessionStart();
  
    }, []);

    if (!session){
      return (
        <div className="flex_centrado">
          <img className="loading_lector_INE" src={loadingicon} alt="" />
        </div>
      );
    }

    if (incode.isDesktop()){
      return <RedireccionQRINE session={session} onSuccess={onSuccess} onClose={onClose}/>
    } else {
      return <FlujoIncode session={session} onSuccess={onSuccess} onError={onClose}/>
    }
    
  }

  return(
    <div className="modal_back flex_centrado">
      <div className="cont_pop flex_centrado">
        {
          QR ? (
            <LectorINE 
              onSuccess={(session) => {
                onSuccess(session);
              }}
              onClose={onClose}
            />
          ) : ( 
            <div>
              <h2>Escanea tu INE/IFE para hacer más facil y rápido el proceso</h2>
              <div className="cont_img flex_centrado">
                <img src={imgEscanearINE} className="width80" alt="Escanea tu INE" />
              </div>
              <div className="cont_pop_btns">
                <button
                  className="btn btn_bco"
                  onClick={onClose}
                >
                  Prefiero capturar mi información manualmente
                </button>
                <button
                  className="btn btn_morado"
                  onClick={()=>{
                    setQR(true);
                  }}
                >
                  Continuar
                </button>
              </div>
            </div>
          )
        }
        
      </div>
    </div>
  )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------- Modal que solicita información del usuario de la calculadora firmauto -- ---------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export function ModalFormulario({onClose, onSend}){
  const { formState: { errors }, handleSubmit, register} = useForm();
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");

  function ruleTelefono(telefono) {
    //let ruleTelefono = /^[2|3|4|5|6|][0-9]{9}$/
    let ruleTelefono = /^[0-9]{10}$/
    return telefono.match(ruleTelefono) ? true : false
  }

  function onSubmit(values){
    onSend(values);
  }

  return(
    <div className="modal_back flex_centrado">
      <div className="modal">
        <div className="cierra_pop">
          <button onClick={onClose}>
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </button>
        </div>
        <div>
          <h2>Proporciona tu información para contactarnos contigo.</h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="campo_form">
            <label htmlFor="nombre">Nombre completo</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              autoComplete="off"
              value={nombre}
              onInput={(ev) => setNombre(ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, ""))}
              {...register("nombre", {required: true})}
            />
            {errors.nombre?.type === "required" && (
              <span className="span">Este campo es requerido</span>
            )}
          </div>

          <div className="campo_form">
            <label htmlFor="email">Correo electrónico </label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="off"
              value={email}
              onInput={(ev) => setEmail(ev.target.value)}
              {...register("email", { required: true, validate: ruleEmail })}
            />
            {errors.email?.type === "required" ? (
                <span className="span">Este campo es requerido</span>
              ) : (
                errors.email && (
                  <span className="span">Formato no válido</span>
                )
              )
            }
          </div>

          <div className="campo_form">
            <label htmlFor="telefono">Teléfono</label>
            <input
              type="tel"
              maxLength={10}
              name="telefono"
              id="telefono"
              placeholder="10 dígitos"
              autoComplete="off"
              value={telefono}
              onInput={(ev) => setTelefono(ev.target.value.replace(/[^0-9]/g, ""))}
              {...register("telefono", {required: true, validate: ruleTelefono})}
            />
            {errors.telefono?.type === "required" ? (
                <span className="span">Este campo es requerido</span>
              ) : (
                errors.telefono && (
                  <span className="span">Formato no válido</span>
                )
              )
            }
          </div>

          <div className="cont_btn_submit">
            <button type="submit" id="btnEnviaClienteInfo" className="btn_morado_personalizado btn_max_width">
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------- Modal que muestra las instrucciones de la tabla de amortización  ------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export function ModalInstruccinesAmortizacion({onClose}){

  return(
    <div className="modal_back flex_centrado">
      <div className="modal ancho_min">
        <div className="cierra_pop">
          <button onClick={onClose}>
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </button>
        </div>
        <div className="grid3">
          <div className="grid_col1 flex_centrado">
            <img src={imgInstrucciones} alt="" />
          </div>
          <div className="grid_col2">
            <h3>Instrucciones</h3>
            <p>Ingresa los pagos adicionales que planeas hacer cada mes. 
              Si lo prefieres, puedes ingresar una aportación que se refleje automáticamente en todos los meses. 
              El simulador te mostrará cuántos periodos faltan para alcanzar tu objetivo.
            </p>
          </div>
          
        </div>
        
      </div>
    </div>
  )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------- Modal que muestra el formulario para invitar vendedor  ------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export function ModalFormInvitacion({onClose, onSend}){
  const { formState: { errors }, handleSubmit, register} = useForm();

  return(
    <div className="modal_back flex_centrado">
      <div className="modal ancho_min">
        <div className="cierra_pop">
          <button onClick={onClose}>
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </button>
        </div>
        <div>
          <h3>Invita al vendedor de tu propiedad.</h3>
          <p>
            Mándale una liga a tu vendedor para que pueda subir los documentos de la vivienda.
          </p>
        </div>
        <form onSubmit={handleSubmit(onSend)}>
          <div>
            <label htmlFor="nombre">Nombre del vendedor</label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              autoComplete="off"
              onInput={(e) => e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")}
              {...register("nombre", {required: true})}
            />
            {errors.nombre?.type === "required" && (
              <span className="span">Este campo es requerido</span>
            )}
          </div>

          <div>
            <label htmlFor="email">Correo electrónico </label>
            <input
              type="text"
              name="email"
              id="email"
              autoComplete="off"
              {...register("email", { required: true, validate: ruleEmail })}
            />
            {errors.email?.type === "required" ? (
                <span className="span">Este campo es requerido</span>
              ) : (
                errors.email && (
                  <span className="span">Formato no válido</span>
                )
              )
            }
          </div>

          <div className="cont_btn_submit">
            <button type="submit" id="btnEnviaClienteInfo" className="btn_morado_personalizado btn_max_width">
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------- Modal de token expirado  ------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export function ModalTokenNoValido({actualizarToken}){

  return(
    <div className="modal_back flex_centrado">
      <div className="modal ancho_min">
        <div className="grid3">
          <div className="grid_col1 flex_centrado">
            <i className="fa fa-ban restringido_grande" aria-hidden="true"></i>
          </div>
          <div className="grid_col2">
            <h3>Acceso no autorizado</h3>
            <p>El timepo de este enlace expiró.</p>
            <button className="btn btn_morado btn_max_width"
              onClick={actualizarToken}
            >
              Nuevo enlace
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------- Modal que muestra más detalles de un cliente (SOCIO)  ------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export function ModalDetallesCliente({cliente, onClose}){
  const [cotizacion, setCotizacion] = useState({...cliente, procesosDeCredito: []});
  const [detallesOfertas, setDetallesOfertas] = useState(false);

  useEffect(() => {
    async function cargarDetallesCliente(){
      const peticion = await get("obtResumenCotizacion", cliente?.idCuestionario);
      if(peticion?.statuscode === 200){
        setCotizacion({...cotizacion, ...peticion.respuesta.resumenCotizacion, idResPaso1: peticion.respuesta.idResPaso1});
      }
    }

    cargarDetallesCliente();
  }, []);

  if(detallesOfertas){
    return(
      <ModalWrapper onClose={() => setDetallesOfertas(false)}>
        <OfertasBancos idCuestionario={cliente?.idCuestionario} idResPaso1={cotizacion.idResPaso1} />
      </ModalWrapper>
    );
  }

  return(
    <div className="modal_back flex_centrado">
      <div className="modal width50 ancho_min">
        <div className="cierra_pop">
          <button onClick={onClose}>
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </button>
        </div>
        <div className="flex_centrado">
          <div className="info_cont_scrollable">
            <div className="cont_grid">
              <div className="display_renglon">
                <h4>Cliente:</h4>
                <p>{cotizacion?.cliente}</p>
              </div>
              <div className="display_renglon">
                <h4>Correo:</h4>
                <p>{cotizacion?.correo}</p>
              </div>
              <div className="display_renglon">
                <h4>Teléfono:</h4>
                <p>{cotizacion?.telefono}</p>
              </div>
            </div>
            <br/>
            <div className="flex-derecho">
              <button type="button" className="link" onClick={() => setDetallesOfertas(true)}>
                Ver detalle
              </button>
            </div>

            <div>
              <h4 className="margin0">Credito Solicitado:</h4>
              <div className="grid3_modal margintop_1em">
                <div>
                  <p className="margin0">Costo Vivienda:</p>
                  <p className="margin0 sin_ruptura">{formatoMoneda(cotizacion?.costoVivienda ?? 0)}</p>
                </div>
                <div>
                  <p className="margin0">Enganche:</p>
                  <p className="margin0 sin_ruptura">{formatoMoneda(cotizacion?.enganche ?? 0)}</p>
                </div>
                <div>
                  <p className="margin0">Crédito:</p>
                  <p className="margin0 sin_ruptura">{formatoMoneda(cotizacion?.credito ?? 0)}</p>
                </div>
              </div>
            </div>
            <br/>

            <div>
              <h4 className="margin0">Posibilidades de Financiamiento:</h4>
              <div className="fuente_secundaria margintop_1em">
                <div className="grid3_modal gap_small">
                  <div>Prestamo Mínimo:</div>
                  <div className="sin_ruptura">{formatoMoneda(cotizacion?.prestamoMin?.monto ?? 0)}</div>
                  <div>{cotizacion?.prestamoMin?.banco}</div>
                </div>
                <div className="grid3_modal gap_small margintop_1em">
                  <div>Prestamo Máximo:</div>
                  <div className="sin_ruptura">{formatoMoneda(cotizacion?.prestamoMax?.monto ?? 0)}</div>
                  <div>{cotizacion?.prestamoMax?.banco}</div>
                </div>
                <div className="grid3_modal gap_small margintop_1em">
                  <div>Prestamo Optimo:</div>
                  <div className="sin_ruptura">{formatoMoneda(cotizacion?.prestamoOpt?.monto ?? 0)}</div>
                  <div>{cotizacion?.prestamoOpt?.banco}</div>
                </div>
              </div>
            </div>
            <br/>
            
            <div>
              {
                cotizacion?.procesosDeCredito.length > 0 ? (
                  <>
                    <h4 className="margin0">Créditos en Proceso:</h4>
                    {
                      cotizacion?.procesosDeCredito.map(proceso => {
                        return(
                          <div>
                            <p>{proceso.banco}</p>
                            <EstatusCredito pasos={proceso.estatus}/>
                          </div>
                        )
                      })
                    }
                  </>
                ) : (
                  <>
                    <h4 className="margin0">Estatus:</h4>
                    <br/>
                    <EstatusCredito/>
                  </>
                )
              }
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------- Modal que muestra las cotizaciones de un cliente (EJECUTIVO)  ------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export function ModalCotizacionesCliente({cliente, onClose}){
  const [cuestionarios, setCuestionarios] = useState([]);
  const [idCuestionarioActual, setIdCuestionarioActual] = useState(undefined);
  const [cotizacion, setCotizacion] = useState(undefined);
  const [detallesOfertas, setDetallesOfertas] = useState(false);

  useEffect(() => {
    async function actualizarCotizacion(){
      const peticion = await get("obtResumenCotizacion", idCuestionarioActual);

      if(peticion?.statuscode === 200){
        setCotizacion({
          ...peticion.respuesta.resumenCotizacion,
          idResPaso1: peticion.respuesta.idResPaso1
        });
      }
    }

    if(cuestionarios.length > 0){
      actualizarCotizacion();
    }
  }, [idCuestionarioActual]);

  useEffect(() => {
    async function cargarDetallesCliente(){
      const peticion = await get("obtCotizacionesIds", cliente?.idCliente);
      if(peticion?.statuscode === 200){
        setCuestionarios(peticion?.respuesta);
        if(peticion?.respuesta.length > 0){
          setIdCuestionarioActual(peticion?.respuesta[0]);
        }
      }
    }

    cargarDetallesCliente();
  }, []);

  if(detallesOfertas){
    return(
      <ModalWrapper onClose={() => setDetallesOfertas(false)}>
        <OfertasBancos idCuestionario={idCuestionarioActual} idResPaso1={cotizacion.idResPaso1} />
      </ModalWrapper>
    );
  }

  return(
    <div className="modal_back flex_centrado">
      <div className="modal width50 ancho_min">
        <div className="cierra_pop">
          <button onClick={onClose}>
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </button>
        </div>

        <div className="cont_tab">
          <div class="tabs">
            <div className="cont_scroll">
              {
                cuestionarios.map((cuestionario, i) => {
                  return (
                    <button class={`tablinks ${cuestionario === idCuestionarioActual ? "active" : ""}`} onClick={(ev) =>{
                      setIdCuestionarioActual(cuestionario);
                    }}>
                      Cotización {i+1}
                    </button>
                  );
                })
              }
            </div>
          </div>

          <div className="tab_contenido">
            <div className="cont_scroll">
              <div className="cont_grid">
                <div className="display_renglon">
                  <h4>Cliente:</h4>
                  <p>{cliente?.cliente}</p>
                </div>
                <div className="flex_centrado">
                  <button type="button" className="link" onClick={() => setDetallesOfertas(true)}>
                    Ver detalle
                  </button>
                </div>
              </div>
              <br/>

              <div>
                <h4 className="margin0">Crédito Solicitado:</h4>
                <div className="grid3_modal margintop_1em">
                  <div>
                    <p className="margin0">Costo Vivienda:</p>
                    <p className="margin0 sin_ruptura">{formatoMoneda(cotizacion?.costoVivienda)}</p>
                  </div>
                  <div>
                    <p className="margin0">Enganche:</p>
                    <p className="margin0 sin_ruptura">{formatoMoneda(cotizacion?.enganche)}</p>
                  </div>
                  <div>
                    <p className="margin0">Crédito:</p>
                    <p className="margin0 sin_ruptura">{formatoMoneda(cotizacion?.credito)}</p>
                  </div>
                </div>
              </div>
              <br/>

              <div>
                <h4 className="margin0">Posibilidades de Financiamiento:</h4>
                <div className="fuente_secundaria margintop_1em">
                  <div className="grid3_modal gap_small">
                    <div>Prestamo Mínimo:</div>
                    <div className="sin_ruptura">{formatoMoneda(cotizacion?.prestamoMin?.monto ?? 0)}</div>
                    <div>{cotizacion?.prestamoMin?.banco}</div>
                  </div>
                  <div className="grid3_modal gap_small margintop_1em">
                    <div>Prestamo Máximo:</div>
                    <div className="sin_ruptura">{formatoMoneda(cotizacion?.prestamoMax?.monto ?? 0)}</div>
                    <div>{cotizacion?.prestamoMax?.banco}</div>
                  </div>
                  <div className="grid3_modal gap_small margintop_1em">
                    <div>Prestamo Optimo:</div>
                    <div className="sin_ruptura">{formatoMoneda(cotizacion?.prestamoOpt?.monto ?? 0)}</div>
                    <div>{cotizacion?.prestamoOpt?.banco}</div>
                  </div>
                </div>
              </div>
              <br/>

              <div>
                {
                  cotizacion?.procesosDeCredito.length > 0 ? (
                    <>
                      <h4 className="margin0">Créditos en Proceso:</h4>
                      {
                        cotizacion?.procesosDeCredito.map(proceso => {
                          return(
                            <div>
                              <p>{proceso.banco}</p>
                              <EstatusCredito pasos={proceso.estatus}/>
                            </div>
                          )
                        })
                      }
                    </>
                  ) : (
                    <>
                      <h4 className="margin0">Estatus:</h4>
                      <br/>
                      <EstatusCredito/>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}


// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------- Modal para asignar un nombre a un archivo --------------------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export function ModalNombreArchivo({open, onClose, dataCallback}){
  const { 
    register, 
    handleSubmit,
    reset, 
    formState: {errors} 
  } = useForm({});

  if (!open) return null;

  function close(){
    reset();
    onClose();
  }

  const guardarArchivo = async (data) => {
    dataCallback(data);
    close();
  }

  return(
    <div className="modal_back flex_centrado fe-modal" onClick={close}>
      <div className="modal ancho_min" onClick={(e) => e.stopPropagation()}>
      <form onSubmit={handleSubmit(guardarArchivo)}>
        <div className="campo_form">
          <label htmlFor="nombreArchivo">Ingresa el nombre:</label>
          <input
            type="text"
            id="nombreArchivo"
            name="nombreArchivo"
            autoComplete="off"
            maxLength={50}
            {...register("nombreArchivo", {required: true, validate: archivo => archivo.length > 3})}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9_]/g, "");
            }}
          />
          {errors.nombreArchivo?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.nombreArchivo?.type === "validate" && (
              <span className="span">Debe contener mínimo 3 caracteres</span>
            )
          )}
        </div>
        <div className="form-btns">
        <button type="submit" className="btn btn_morado">
            Aceptar
          </button>
          <button type="button" className="btn btn_bco" onClick={close}>
            Cancelar
          </button>
        </div>
      </form>
      </div>
    </div>
  )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------- Modal para envolver pantallas --------------------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export function ModalWrapper({onClose, children}){

  return(
    <div className="modal_back flex_centrado" onClick={onClose}>
      <div className="modal wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="cierra_pop">
          <button onClick={onClose}>
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </button>
        </div>
        <div style={{overflowY: "auto", overflowX: "hidden", width: "100%", height: "100%"}}>
          {children}
        </div>
      </div>
    </div>
  )
}

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------------- Modal para conocer motivo de eliminación ---------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->

export function ModalEliminacion({onSend, onClose}){
  const { formState: { errors }, handleSubmit, register} = useForm();

  async function renderDropdown(){
    let peticionCatalogo = await get("obtCatalogoEliminacion", "");
    if (peticionCatalogo.statusCode === 200) {
      let root;
      let opcionDefault = (
        <option value="" selected disabled>
          Seleccione una opción...
        </option>
      );

      const actividades = peticionCatalogo.respuesta.catalogoEliminacion.map((causa) => (
        <option
          value={causa.idCatCausaEliminacion}
          key={causa.idCatCausaEliminacion}
        >
          {causa.causa}
        </option>
      ));
      actividades.unshift(opcionDefault);
      root = ReactDOM.createRoot(
        document.getElementById("selectCausaEliminacion")
      );
      root.render(actividades);
    }
  }

  useEffect(() => {
    renderDropdown();
  }, []);

  return(
    <div className="modal_back flex_centrado">
      <div className="modal ancho_min">
        <div className="cierra_pop">
          <button onClick={onClose}>
            <i className="fa fa-times-circle" aria-hidden="true"></i>
          </button>
        </div>
        <div>
          <h3>¿Estás seguro que lo quieres eliminar?</h3>
          <p>Indica el motivo para continuar.</p>
        </div>
        <form onSubmit={handleSubmit(onSend)}>
          <div>
            <label>Causa</label>
            <select
              id="selectCausaEliminacion"
              className="form-select"
              {...register("causa", { required: true })}
              defaultValue=""
            >
            </select>
            {errors.causa?.type === "required" && (
              <span className="span">Este campo es requerido</span>
            )}
          </div>

          <div>
            <label htmlFor="email">Razón</label>
            <textarea
            className="form-textarea"
              wrap="soft"
              rows={3}
              maxLength={1024}
              {...register("razon", { required: true })}
            />
            {errors.razon?.type === "required" && (
              <span className="span">Este campo es requerido</span>
            )}
          </div>

          <div className="cont_btn_submit">
            <button type="submit" className="btn_morado_personalizado btn_max_width">
              Eliminar
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}