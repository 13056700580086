import { useEffect, useRef, useState } from "react";
import { useSetChatContext } from "../../../providers/ChatProvider";
import { Cookies } from "react-cookie";
import { jwtDecode } from "jwt-decode";
import PanelIzquierdo from "../../../components/chat/PanelIzquierdo";
import PanelDerecho from "../../../components/chat/PanelDerecho";

export default function ConversacionesEjecutivo() {
  const cookies = new Cookies();
  const tokenEjecutivo = useRef(cookies.get("token"));
  const idEjecutivo = useRef(jwtDecode(cookies.get("token"))?.idEjecutivo);

  const setDataChat = useSetChatContext();

  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    setCargando(true);
    setDataChat({
      ejecutivo: {
        id: idEjecutivo.current,
        token: tokenEjecutivo.current,
      }
    });
    setCargando(false);
  }, []);

  return (
    <>
      <div className="cont_Info">
        <h1 className="titt">Conversaciones</h1>
        <p className="descripcionClientes">
          Aquí encontraras las conversaciones con tus clientes y prospectos
        </p>

        <div className="cont-conversaciones" style={{width: "100%", height: "clamp(350px, 70dvh, 650px)", display: "flex", overflowY: "hidden", margin: "auto 0"}}>
            {!cargando && <PanelIzquierdo/>}
            <PanelDerecho/>
        </div>
        <br />
      </div>
    </>
  );
}