import { CheckCheck, Download, FileText } from "lucide-react";
import { Tooltip } from "react-tooltip";
import IndicadorFecha from "./IndicadorFecha";
import { useState } from "react";
import AudioPlayer from "./AudioPlayer";

export default function BurbujaChat({ mensaje, prevMensaje }){
	const [abirirMedia, setAbrirMedia] = useState(false);
	const date = new Date(mensaje.fechaCreacion + "Z");
	const hour = date.getHours().toString().padStart(2, "0");
	const minute = date.getMinutes().toString().padStart(2, "0");
	const time = `${hour}:${minute}`;

	const fromMe = mensaje.remitente !== "cliente";
	const bgClass = fromMe ? "bg-mensaje-propio" : "bg-otro-mensaje";

	const renderMessageContent = () => {
		switch (mensaje.tipo) {
			case "text":
				return <TextMessage mensaje={mensaje} />;
			case "image":
				return <ImageMessage mensaje={mensaje} handleClick={() => setAbrirMedia(true)} />;
			case "document":
				return <DocumentMessage mensaje={mensaje} handleClick={() => setAbrirMedia(true)} />;
			case "audio":
				return <AudioMessage mensaje={mensaje} />;
			default:
				return null;
		}
	};

	if (!fromMe) {
		return (
			<>
				<IndicadorFecha mensaje={mensaje} prevMensaje={prevMensaje} />
				<div className='cont-mensaje'>
					<div className={`burbuja-mensaje ${bgClass}`}>
						<OtherMessageIndicator />
						{renderMessageContent()}
						<MessageTime time={time} fromMe={fromMe} />
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<IndicadorFecha mensaje={mensaje} prevMensaje={prevMensaje} />
			<div className="cont-mensaje" style={{marginLeft: "auto"}}>
				<div className={`burbuja-mensaje-propia ${bgClass}`}>
					<SelfMessageIndicator />
					{renderMessageContent()}
					<MessageTime time={time} fromMe={fromMe} />
				</div>
				<div>
					<i id={`tooltip-mensaje-propio-${mensaje.id}`} className="fa fa-info-circle"></i>
					<Tooltip anchorSelect={`#tooltip-mensaje-propio-${mensaje.id}`} style={{zIndex: 99999}} place="bottom">
						<div className="tooltip_normal">
							{mensaje.nombreEjecutivo}
						</div>
					</Tooltip>
				</div>
			</div>
		</>
	);
};

const MessageTime = ({ time, fromMe }) => {
	return (
		<p className="hora-mensaje">
			{time} {fromMe && <CheckCheck size={16} />}
		</p>
	);
};

const OtherMessageIndicator = () => (
	<div className='indicador-otro-mensaje bg-otro-mensaje' />
);

const SelfMessageIndicator = () => (
	<div className='indicador-mensaje-propio bg-mensaje-propio' />
);

const TextMessage = ({ mensaje }) => {
	const isLink = /^(ftp|http|https):\/\/[^ "]+$/.test(mensaje.texto);

	return (
		<div>
			{isLink ? (
				<a
					href={mensaje.texto}
					target='_blank'
					rel='noopener noreferrer'
					className="mensaje-texto-link"
				>
					{mensaje.texto}
				</a>
			) : (
				<p className="mensaje-texto">{mensaje.texto}</p>
			)}
		</div>
	);
};

const ImageMessage = ({ mensaje, handleClick }) => {
	return (
		<div className='cont-mensaje-imagen'>
			<img
				src={mensaje.url}
				alt=""
				className='cursor-pointer'
				onClick={handleClick}
			/>
		</div>
	);
};

const DocumentMessage = ({ mensaje, handleClick }) => {
	return (
		<div className='cont-mensaje-documento'>
			<a
				href={mensaje.url}
				target="_blank"
				rel="noopener noreferrer"
				onClick={handleClick}
			>
				<div className="cont-doc-data">
					<FileText size={16}/>
					<p className="margin0">
						{mensaje?.nombreMedia}
					</p>
					<p className="margin0">.{mensaje?.nombreMedia?.split(".").at(-1)}</p>
				</div>
				<Download size={16}/>
			</a>
		</div>
	);
};

const AudioMessage = ({ mensaje }) => {
	return (
		<div className='cont-mensaje-audio'>
			<AudioPlayer src={mensaje.url} />
		</div>
	);
};