import { useForm } from "react-hook-form";
import { agergarDecimales, formatoFechaDmaAIso, formatoFechaIsoADma, formatoMoneda, textoAMoneda } from "../utils/operaciones_formulario";
import { ruleCP, ruleCURP, ruleEmail, ruleNacimiento, ruleNoTelefono, ruleNumeroSeguridad, ruleRFC, ruleTelefono } from "../assets/validateRules";
import { useEffect, useState } from "react";
import { deleteDocEndpoint, get, getCP, post, put, putEP, putS3 } from "../utils/http";
import Swal from "sweetalert2";
import { CardArchivo, CardArchivos, FullCardArchivo } from "./SubidorArchivos";
import { ModalWrapper } from "./Modals";

// --------------- Formulario de datos decl cliente ----------------

export function DatosCliente({idCliente}){
  const [isLoading, setIsLoading] = useState(true);
  const [direccionNueva, setDireccionNueva] = useState(false);
  const [lockBtn, setLockBtn] = useState(false);

  // Dropdowns
  const [catColonias, setCatColonias] = useState([]);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ingresos: "$ 0.00"
    }
  });

  async function cargarDatosCliente(idCliente){
    const peticion = await get("obtDatosCliente", idCliente);

    if(peticion?.statuscode === 200){
      const datosUsuario = peticion.respuesta;
      setValue("nombre", datosUsuario.nombre);
      setValue("apellidoPaterno", datosUsuario.paterno);
      setValue("apellidoMaterno", datosUsuario.materno);
      setValue("rfc", datosUsuario.rfc);
      setValue("telefono", datosUsuario.telefono);
      setValue("email", datosUsuario.correo);
      setValue("fechaNacimiento", formatoFechaIsoADma(datosUsuario.fNacimiento));
      setValue("ingresos", formatoMoneda(datosUsuario.ingresoB));
      setValue("cp", datosUsuario.cp);
      setValue("direccion", datosUsuario.direccion);
      setValue("colonia", datosUsuario.colonia);
      setValue("numeroExterior", datosUsuario.nExteriors);
      setValue("numeroInterior", datosUsuario.nInterior);
      setValue("estado", datosUsuario.estado);
      setValue("delegacion", datosUsuario.ciudad);
    }
  }

  const enviar = async (datos) => {
    setLockBtn(true);

    const peticion = await putEP("actualizarDatosUsuario/" + idCliente, {}, {
      correo: datos.email,
      nombre: datos.nombre,
      paterno: datos.apellidoPaterno,
      materno: datos.apellidoMaterno,
      fNacimiento: formatoFechaDmaAIso(datos.fechaNacimiento),
      ingresoB: Number(datos.ingresos.replace(/[^0-9.]/g, "")),
      telefono: datos.telefono,
      rfc: datos.rfc,
      direccion: datos.direccion,
      nExteriors: datos.numeroExterior,
      nInterior: datos.numeroInterior,
      colonia: datos.colonia,
      ciudad: datos.delegacion,
      estado: datos.estado,
      cp: datos.cp
    });

    if(peticion?.statusCode === 200){
      Swal.fire({
        icon: "success",
        title: "Datos actualizados",
        confirmButtonText: "Aceptar"
      });
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al actualizar datos",
        text: peticion?.msg,
        confirmButtonText: "Aceptar"
      });
    }
    
    setLockBtn(false);
  }

  const obtenerDataCp = async (cp) => {
    let respuestaPorCP = await getCP("info_cp", cp);

    obtenerClaveIso(respuestaPorCP.response.estado);
    setDireccionNueva(true);
    setCatColonias(respuestaPorCP.response.asentamiento);
    setValue("delegacion", respuestaPorCP.response.municipio);
  }

  const obtenerClaveIso = async (estadoCP) => {
    let obtenerClave = await get("obtClaveISOPorEstado", estadoCP);
    if (obtenerClave.statuscode === 200) {
      setValue("estado", obtenerClave.iso);
    }else{
      setValue("estado", estadoCP);
    }
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await cargarDatosCliente(idCliente);
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      <div>
        <h1 className="titt">Datos del cliente</h1>

        <div className="cont_flex cont-form">
          <form onSubmit={handleSubmit(enviar)}>
            
            <div className="col3 campo-form">
              <label>Nombre</label>
              <input
                type="text"
                maxLength={50}
                autoComplete="off"
                {...register("nombre", { required: true })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                }}
              />
              {errors.nombre?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form">
              <label>RFC</label>
              <input
                type="text"
                minLength={13}
                maxLength={13}
                autoComplete="off"
                {...register("rfc", { required: true, validate: (rfc) => ruleRFC(rfc) })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z0-9]/g, "");
                }}
              />
              {errors.rfc?.type === "required" ? (
                <span className="span">Este campo es requerido</span>
              ) : (
                errors.rfc?.type === "validate" && (
                  <span className="span">Formato no válido</span>
                )
              )}
            </div>
            <div className="col3 campo-form">
              <label>Fecha de Nacimiento</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="dd/mm/aaaa"
                {...register("fechaNacimiento", {required: true, validate: fecha => ruleNacimiento(fecha)})}
                onInput={(e) =>{
                  let fecha = e.target.value.replace(/\D/g, "");
                  // Insertar las diagonales en la posición correcta
                  if (fecha.length > 2 && fecha.length < 5) {
                    fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
                  } else if (fecha.length > 4) {
                    fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
                  }
                  e.target.value = fecha;
                }}
              />
              {errors.fechaNacimiento?.type === "required" ? (
                <span className="span">Este campo es requerido</span>
              ) : (
                errors.fechaNacimiento?.type === "validate" && (
                  <span className="span">Formato no válido</span>
                )
              )}
            </div>

            <div className="col3 campo-form">
              <label>Apellido Paterno</label>
              <input
                type="text"
                maxLength={15}
                autoComplete="off"
                {...register("apellidoPaterno", { required: true })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                }}
              />
              {errors.apellidoPaterno?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form">
              <label>Teléfono</label>
              <input
                type="tel"
                minLength={10}
                maxLength={10}
                autoComplete="off"
                {...register("telefono", { required: true })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
              {errors.telefono?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
              <small>
                Asegura que el número de teléfono sea válido
              </small>
            </div>
            <div className="col3 campo-form">
              <label>Ingresos</label>
              <input
                type="text"
                autoComplete="off"
                {...register("ingresos", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}               
              />
              {errors.ingresos?.type === "validate" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <div className="col3 campo-form">
              <label>Apellido Materno</label>
              <input
                type="text"
                maxLength={50}
                autoComplete="off"
                {...register("apellidoMaterno", { required: true })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                }}
              />
              {errors.apellidoMaterno?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form">
              <label>E-mail</label>
              <input
                type="text"
                maxLength={50}
                autoComplete="off"
                {...register("email", { required: true, validate: (email) => ruleEmail(email) })}
                
              />
              {errors.email?.type === "required" ? (
                <span className="span">Este campo es requerido</span>
              ) : (
                errors.email?.type === "validate" && (
                  <span className="span">Formato no válido</span>
                )
              )}
            </div>

            <br/><br/>

            <div className="col3 campo-form">
              <label>Codigo Postal</label>
              <input
                type="tel"
                minLength={5}
                maxLength={5}
                autoComplete="off"
                {...register("cp", { required: true, validate: (cp) => ruleCP(cp) })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9 ]/g, "");
                  if (e.target.value.length === 5) {
                    obtenerDataCp(e.target.value);
                  }
                }}
              />
              {errors.cp?.type === "required" ? (
                <span className="span">Este campo es requerido</span>
              ) : (
                errors.cp?.type === "validate" && (
                  <span className="span">Formato no válido</span>
                )
              )}
            </div>
            <div className="col3 campo-form">
              <label>Número exterior</label>
              <input
                type="text"
                maxLength={50}
                autoComplete="off"
                {...register("numeroExterior", { required: true })}
              />
              {errors.numeroExterior?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form">
              <label>Estado</label>
              <input
                disabled
                type="text"
                maxLength={50}
                autoComplete="off"
                {...register("estado", { required: true })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
                }}
              />
              {errors.estado?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <div className="col3 campo-form">
              <label>Dirección</label>
              <input
                type="text"
                maxLength={200}
                autoComplete="off"
                {...register("direccion", { required: true })}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ# ]/g, "");
                }}
              />
              {errors.direccion?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form">
              <label>Número interior</label>
              <input
                type="text"
                maxLength={50}
                autoComplete="off"
                {...register("numeroInterior")}
              />
            </div>
            <div className="col3 campo-form">
              <label>Delegación / Municipio</label>
              <input
                disabled
                type="text"
                maxLength={50}
                autoComplete="off"
                {...register("delegacion", { required: true })}
              />
              {errors.delegacion?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <div className="col3 campo-form estado marginEspacio">
              <label>Colonia</label>
              {
                direccionNueva ? (
                  <select
                    id="selectColonia"
                    {...register("colonia", { required: true })}
                  >
                    <option value="" selected disabled>
                      Seleccione una opción...
                    </option>
                    {
                      catColonias.map((colonia) => (
                        <option value={colonia} key={colonia}>
                          {colonia}
                        </option>
                      ))
                    }
                  </select>
                ) : (
                  <input
                    type="text"
                    disabled
                    value={getValues("colonia")}
                  />
                )
              }
              {errors.colonia?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <br/><br/>
            
            <button 
              type="submit" 
              className="btn btn_morado"
              disabled={lockBtn}
            >
              {lockBtn ? <span className="spinner"></span> : "Completar"}
            </button>

            <br/><br/>

          </form>
        </div>

        <br/>

      </div>
    </>
  );
}

// --------------- Formularios de documentación personal acreditado ----------------

export function DocumentacionInicial({ cliente, idResPrecalifica, alCompletar = () => {}, alIncompletar = () => {} }) {
  const [identificacion, setIdentificacion] = useState({});
  const [comprobanteIngresos, setComprobanteIngresos] = useState({});
  const [comprobanteDomicilio, setComprobanteDomicilio] = useState({});

  async function obtDocIdentificacion(){
    const peticionCalifica = await get("getInfoCalifica", cliente?.id ?? 0);

    if(peticionCalifica?.statuscode === 200){
      const idRes = peticionCalifica.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas.idResPrecalifica;

      await post("registrarINEComoDocInicial", {
        idUser: cliente?.id,
        idResPrecalifica: idRes,
        esCoAcreditado: false,
      });
    }
  }

  async function verificarDocumento(idTipoDoc, idUsuario, idResPrecalifica){
    const peticionDocInicial = await post("obtInfoDocumentacionInicial", {
      idUser: idUsuario,
      idResPrecalifica: idResPrecalifica,
      idTipoDocInicial: idTipoDoc,
      esCoacreditado: false
    });

    let parrafo = "";
    let nota = "";
    let demo = await get("obtenerUltimoIdDemoCI", idUsuario ?? 0);
    let actividad = (demo.statuscode === 200) ? demo.ultimoIdDemoCI : -1;
    switch (idTipoDoc) {
      case 1:
        nota = "Asegúrate que la identificación sea vigente y legible";
        break;

      case 2:
        parrafo = "Comprobante de Ingresos";
        switch (actividad) {
          case 1:
            nota = "Anexa los últimos 3 meses de recibos de nomina.";
            break;

          case 2:
            nota =
              "Anexa la ultima declaración de impuestos anual y parcial.";
            break;

          case 3:
            nota =
              "Anexa 6 meses donde se reflejen los ingresos que comprueben tus ingresos.";
            break;

          case 4:
            nota =
              "Anexar los últimos 3 meses de recibos de nomina y carta laboral que justifique ingresos.";
            break;

          case 5:
            nota = "Anexar los últimos 3 meses de estados de cuenta.";
            break;
          case 6:
            nota =
              "Anexa la ultima declaración de impuestos anual y parcial.";
            break;
          case 7:
            nota = "Anexa el ultimo año de estados financieros";
            break;

          default:
            nota = "Opción no válida";
            break;
        }
        break;

      case 3:
        parrafo = "Comprobante de domicilio";
        nota =
          "Menor a 3 meses de antigüedad - De preferencia CFE, Agua, Predial o Telefonia";
        break;

      default:
        break;
    }

    if(peticionDocInicial?.statuscode === 200){
      let documentos = [];

      if(peticionDocInicial.respuesta.documentacion?.length > 0){
        if(idTipoDoc === 2){
          documentos = peticionDocInicial.respuesta.documentacion?.map(documento => {
            return {
              id: documento?.idDocInicial,
              nombreArchivo: documento?.nombreArchivo,
              rutaArchivo: documento?.archivo
            }
          });
        }else{
          documentos.push({
            id: peticionDocInicial.respuesta.documentacion[0]?.idDocInicial,
            nombreArchivo: peticionDocInicial.respuesta.documentacion[0]?.nombreArchivo,
            rutaArchivo: peticionDocInicial.respuesta.documentacion[0]?.archivo
          });
        }
      }

      return {
        parrafo: parrafo,
        tipoDocumento: idTipoDoc,
        nota: nota,
        documentos: documentos
      }
    }else{
      return null;
    }
  }

  async function obtDocumentacion(){
    let peticionDocs = await get("getTipoDocumentos", "");

    if (peticionDocs?.statuscode === 200) {
      const docs = peticionDocs.documentos;

      const verificarDocumentos = docs.map(async (doc) => {
        const docVerificado = await verificarDocumento(doc.idTipoDocInicial, cliente?.id ?? 0, idResPrecalifica ?? 0)

        return docVerificado !== null ? {
          titulo: doc.nombre,
          ...docVerificado
        } : null ;
      });
      const documentosVerificados = (await Promise.all(verificarDocumentos)).filter(verificacion => verificacion !== null);

      setIdentificacion(documentosVerificados.find(doc => doc.tipoDocumento === 1));
      setComprobanteIngresos(documentosVerificados.find(doc => doc.tipoDocumento === 2));
      setComprobanteDomicilio(documentosVerificados.find(doc => doc.tipoDocumento === 3));
    }
  }

  async function eliminarArchivo(idDocInicial, documento, setDocumento){
    const peticionEliminarArchivo = await deleteDocEndpoint("borrarArchivoEspecifico", {
      idUser: cliente?.id,
      idDocInicial: idDocInicial,
      coAcreditado: false,
    });

    if (peticionEliminarArchivo.statuscode === 200) {
      const archivosRestantes = documento?.documentos.filter(doc => doc.id !== idDocInicial);
      setDocumento({...documento, documentos: archivosRestantes});

      Swal.fire({
        title: "Documento borrado correctamente",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    } else {
      Swal.fire({
        title: "No se pudo borrar el documento",
        text: "Intente más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
    
  }

  async function subirArchivo(archivo, documento, setDocumento){
    const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
      nombreArchivo: archivo.name,
    });

    if(peticionArchivoS3?.statuscode === 200){
      const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, archivo);

      if(peticionS3?.ok){
        const peticionArchivo = await post("subirArchivo", {
          rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
          idUser: cliente?.id,
          idResPrecalifica: idResPrecalifica,
          idTipoDocInicial: documento?.tipoDocumento,
          esCoAcreditado: false
        });

        if (peticionArchivo?.statuscode === 200) {
          setDocumento({...documento, documentos: [{
            id: peticionArchivo.respuesta.documento.idDocInicial,
            nombreArchivo: peticionArchivo.respuesta.documento.nombreArchivo,
            rutaArchivo: peticionArchivo.respuesta.documento.rutaArchivo
          }]});
        }else{
          Swal.fire({
            title: "No se pudo subir el documento",
            text: "Intente más tarde",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  }

  async function subirArchivos(archivos, documento, setDocumento){
    const archivosNuevos = [];

    const peticionesArchivos = archivos.map(async (archivo) => {
      const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
        nombreArchivo: archivo.name,
      });

      if(peticionArchivoS3?.statuscode === 200){
        const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, archivo);
  
        if(peticionS3?.ok){
          const peticionArchivo = await post("subirArchivo", {
            rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
            idUser: cliente?.id,
            idResPrecalifica: idResPrecalifica,
            idTipoDocInicial: documento?.tipoDocumento,
            esCoAcreditado: false
          });
  
          if (peticionArchivo?.statuscode === 200) {
            archivosNuevos.push({
              id: peticionArchivo.respuesta.documento.idDocInicial,
              nombreArchivo: peticionArchivo.respuesta.documento.nombreArchivo,
              rutaArchivo: peticionArchivo.respuesta.documento.rutaArchivo
            });
            return true;
          }
        }
      }

      return false;
    });

    const respuestas = await Promise.all(peticionesArchivos);

    setDocumento({...documento, documentos: [...documento?.documentos, ...archivosNuevos]});

    if(respuestas.some(respuesta => respuesta === false)){
      Swal.fire({
        title: "No se pudo subir alguno de los archivos",
        text: "Vuelve a intentarlo más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return;
    }
  }

  useEffect(() => {
    if(identificacion?.documentos?.length > 0 && comprobanteIngresos?.documentos?.length > 0 && comprobanteDomicilio?.documentos?.length > 0){
      alCompletar();
    }else{
      alIncompletar();
    }
  }, [identificacion?.documentos, comprobanteIngresos?.documentos, comprobanteDomicilio?.documentos]);
  
  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtDocIdentificacion();
        await obtDocumentacion();
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);

  return (
    <>
      <div>
        <h1 className="titt">Documentación Inicial</h1>
        <p>
          Aseg&uacute;rate que la identificaci&oacute;n sea vigente y legible
        </p>
        <div className="cont-cards-archivos">
          <CardArchivo 
            titulo={identificacion?.titulo}
            descripcion={identificacion?.nota}
            archivo={identificacion?.documentos?.[0] ?? {}}
            onUpload={(archivo) => subirArchivo(archivo, identificacion, setIdentificacion)}
            onDelete={(idDocInicial) => eliminarArchivo(idDocInicial, identificacion, setIdentificacion)}
          />
          <CardArchivo
            titulo={comprobanteDomicilio?.titulo}
            descripcion={comprobanteDomicilio?.nota}
            archivo={comprobanteDomicilio?.documentos?.[0] ?? {}}
            onUpload={(archivo) => subirArchivo(archivo, comprobanteDomicilio, setComprobanteDomicilio)}
            onDelete={(idDocInicial) => eliminarArchivo(idDocInicial, comprobanteDomicilio, setComprobanteDomicilio)}
          />
          <CardArchivos
            titulo={comprobanteIngresos?.titulo}
            descripcion={comprobanteIngresos?.nota}
            archivos={comprobanteIngresos?.documentos ?? []}
            onUpload={(archivos) => subirArchivos(archivos, comprobanteIngresos, setComprobanteIngresos)}
            onDelete={(idDocInicial) => eliminarArchivo(idDocInicial, comprobanteIngresos, setComprobanteIngresos)}
          />
        </div>
      </div>
    </>
  );
}

export function InfoPersonal({ cliente, alCompletar = () => {} }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [idReferenciaProducto, setIdReferenciaProducto] = useState();
  const [idReferencia, setidReferencia] = useState();
  const [infoPersonal, setInfoPersonal] = useState({ sueldo: 0 });
  const [infoReferenciasProductos, setInfoReferenciasProducto] = useState({});
  const [infoReferenciasProductos2, setInfoReferenciasProducto2] = useState({});
  const [infoReferencias, setInfoReferencias] = useState({});
  const [infoReferencias2, setInfoReferencias2] = useState({});
  const [infoBeneficiarios, setInfoBeneficiarios] = useState({});
  const [idResPrecalifica, setIdResPrecalifica] = useState({});
  const [validarReglaCurp, setValidarReglaCurp] = useState({});
  const [validarReglaNumeroSeguridad, setValidarReglaNumeroSeguridad] = useState({});

  let dataInfoPersonal = {};
  let dataInfoReferenciasProductos = {};
  let dataInfoReferencia = {};

  let dataInfoBeneficiariosInsert = {};
  let dataInfoBeneficiariosUpdate = {};

  const infoCalifica = async () => {
    const peticionCalifica = await get("getInfoCalifica", cliente?.id);

    if(peticionCalifica?.statuscode === 200){
      setIdResPrecalifica(
        peticionCalifica.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas.idResPrecalifica
      );
    }
  };

  const info = async () => {
    let data = await get("infoUser", cliente?.correo);
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoPersonal(() => ({
        ...infoPersonal,
        ...data.user.infoPersonals[0],
      }));
    }
    //console.log(data.user.infoPersonals[0]);
  };

  const info2 = async () => {
    let data = await get("getInfoReferenciasProductos", cliente?.id);
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoReferenciasProducto(() => ({
        ...infoReferenciasProductos,
        ...idReferenciaProducto,
        ...data.infoReferencias[0],
      }));
      setInfoReferenciasProducto2(() => ({
        ...infoReferenciasProductos2,
        ...idReferenciaProducto,
        ...data.infoReferencias[1],
      }));
      // Add more fields as needed
    }
    //console.log(data.infoReferencias[0]);
  };

  const info3 = async () => {
    let data = await get("getReferencia", cliente?.id);
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoReferencias(() => ({
        ...infoReferencias,
        ...idReferencia,
        ...data.infoReferencias[0],
      }));
      setInfoReferencias2(() => ({
        ...infoReferencias2,
        ...idReferencia,
        ...data.infoReferencias[1],
      }));
    }
    //console.log(data.infoReferencias[0]);
  };

  const info4 = async () => {
    let data = await get("getInfoBeneficiarios", cliente?.id);
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoBeneficiarios(() => ({
        ...infoBeneficiarios,
        ...data.infoReferencias[0],
      }));
    }
    //console.log(data.infoReferencias[0]);
  };

  function enviar() {
    if (infoPersonal.curp !== "") {
      addInfoPersonal();
      addReferenciasProductos();
      addReferencia();
      addInfoBeneficiarios();
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Por favor llena todos los campos",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  const addInfoPersonal = async () => {
    dataInfoPersonal = {
      /*Parte 1*/
      idUser: cliente?.id,
      curp: infoPersonal.curp,
      sexo: infoPersonal.sexo,
      numeroSeguroSocial: infoPersonal.numeroSeguroSocial,
      estadoCivil: infoPersonal.estadoCivil,
      gradoMaxEstudios: infoPersonal.gradoMaxEstudios,
      numDepEco: infoPersonal.numDepEco,
      tipoVivienda: infoPersonal.tipoVivienda,
      aniosVivienda: infoPersonal.aniosVivienda,
      idResPrecalifica: idResPrecalifica,

      /*Parte 2*/
      sueldo: infoPersonal.sueldo,
      otroIngreso: infoPersonal.otroIngreso,
      autoValor: infoPersonal.autoValor,
      viviendoActualValor: infoPersonal.viviendoActualValor,
      escoAcreditado: false,
    };
    try {
      const existingInfo = await get("getInfoPersonal", cliente?.id);

      if (existingInfo.msg === "El usuario no tiene informacion personal") {
        // Usuario sin información, agregar nueva entrada
        const insertar = await post("addInfoPersonal", dataInfoPersonal);

        if (insertar.statuscode === 200) {
          // console.log("addInfo personal correcto");
        } else {
          // console.log("addInfo personal fallo");
        }
      } else {
        // Usuario con información, actualizar la existente
        const actualizar = await put("updateinfoPersonal", dataInfoPersonal);

        if (actualizar.statuscode === 200) {
          // console.log("updateInfo personal correcto");
        } else {
          // console.log("updateInfo personal fallo");
        }
      }
    } catch (error) {
      // console.error("Error durante la operación:", error);
    }
  };

  const addReferenciasProductos = async () => {
    dataInfoReferenciasProductos = {
      idReferenciaProducto: infoReferenciasProductos.idReferenciaProducto,
      idResPrecalifica: idResPrecalifica,
      idUser: cliente?.id,
      tipoCuenta: infoReferenciasProductos.tipoCuenta,
      numeroCuenta: infoReferenciasProductos.numeroCuenta,
      institucionCredito: infoReferenciasProductos.institucionCredito,
      intitutoCapacitacion: infoReferenciasProductos.intitutoCapacitacion,
      tipoCuentaCapacitacion: infoReferenciasProductos.tipoCuentaCapacitacion,

      idReferenciaProducto2: infoReferenciasProductos2.idReferenciaProducto,
      idResPrecalifica2: idResPrecalifica, // Asigna un valor único
      idUser2: cliente?.id,
      tipoCuenta2: infoReferenciasProductos2.tipoCuenta,
      numeroCuenta2: infoReferenciasProductos2.numeroCuenta,
      institucionCredito2: infoReferenciasProductos2.institucionCredito,
      intitutoCapacitacion2: infoReferenciasProductos2.intitutoCapacitacion,
      tipoCuentaCapacitacion2: infoReferenciasProductos2.tipoCuentaCapacitacion,
      escoAcreditado: false,
    };

    let eleccionIU1 = await get(
      "getInfoReferenciasProductos",
      cliente?.id
    );

    if (eleccionIU1.msg === "El usuario no tiene informacion de referencias") {
      let insertar = await post(
        "addInfoReferenciasProductos",
        dataInfoReferenciasProductos
      );

      if (insertar.statuscode === 200) {
        const idResPrecalifica = insertar.idResPrecalifica;
        // console.log(
        //   `addInfoReferenciasProductos correcto. IdResPrecalifica: ${idResPrecalifica}`
        // );
      } else {
        // console.log("addInfoReferenciasProductos fallo");
      }
    } else {
      if (eleccionIU1.statuscode === 200) {
        let actualizar = await put(
          "updateInfoReferenciasProductos",
          dataInfoReferenciasProductos
        );

        if (actualizar.statuscode === 200) {
          // console.log("updateInfoReferenciasProductos correcto");
        } else {
          // console.log("updateInfoReferenciasProductos fallo");
        }
      }
    }
  };

  const addReferencia = async () => {
    dataInfoReferencia = {
      idReferencia: infoReferencias.idReferencia,
      idUser: cliente?.id,
      nombre: infoReferencias.nombre,
      aPaterno: infoReferencias.aPaterno,
      aMaterno: infoReferencias.aMaterno,
      relacion: infoReferencias.relacion,
      telefono: infoReferencias.telefono,
      email: infoReferencias.email,
      idResPrecalifica: idResPrecalifica,

      idReferencia2: infoReferencias2.idReferencia,
      idUser2: cliente?.id,
      nombre2: infoReferencias2.nombre,
      aPaterno2: infoReferencias2.aPaterno,
      aMaterno2: infoReferencias2.aMaterno,
      relacion2: infoReferencias2.relacion,
      telefono2: infoReferencias2.telefono,
      email2: infoReferencias2.email,
      idResPrecalifica2: idResPrecalifica,
      escoAcreditado: false,
    };

    let eleccionIU = await get("getReferencia", cliente?.id);
    if (eleccionIU.msg === "El usuario no tiene informacion de referencias") {
      let insertar = await post("addReferencia", dataInfoReferencia);

      if (insertar.statuscode === 200) {
        const idResPrecalifica = insertar.idResPrecalifica;
        // console.log(
        //   `addInfoReferenciasProductos correcto. IdResPrecalifica: ${idResPrecalifica}`
        // );
      } else {
        // console.log("addReferencia perosnal fallo");
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        //let presendInfoLaboral = await post("addInfoLaboral", data)
        let actualizar = await put("updateReferencia", dataInfoReferencia);
        if (actualizar.statuscode === 200) {
          // console.log("updateReferencia correcto");
        } else {
          // console.log("updateReferencia fallo");
        }
      }
    }
  };

  const addInfoBeneficiarios = async () => {
    let eleccionIU = await get("getInfoBeneficiarios", cliente?.id);

    if (eleccionIU.statuscode === 404) {
      //Sin datos en Info beneficiario
      dataInfoBeneficiariosInsert = {
        idResPrecalifica: idResPrecalifica,
        nombre: infoBeneficiarios.nombre,
        idUser: cliente?.id,
        aPaterno: infoBeneficiarios.aPaterno,
        aMaterno: infoBeneficiarios.aMaterno,
        parentesco: infoBeneficiarios.parentesco,
        telefono: infoBeneficiarios.telefono,
        escoAcreditado: false,
      };
      let insertar = await post(
        "addInfoBeneficiarios",
        dataInfoBeneficiariosInsert
      );

      if (insertar.statuscode === 200) {
        Swal.fire({
          title: "Datos insertados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        alCompletar();
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        dataInfoBeneficiariosUpdate = {
          idBeneficiario: eleccionIU.infoReferencias[0].idBeneficiario, //-
          idResPrecalifica: idResPrecalifica,
          nombre: infoBeneficiarios.nombre,
          idUser: cliente?.id,
          aPaterno: infoBeneficiarios.aPaterno,
          aMaterno: infoBeneficiarios.aMaterno,
          parentesco: infoBeneficiarios.parentesco,
          telefono: infoBeneficiarios.telefono,
          escoAcreditado: false,
        };
        let actualizar = await put(
          "updateInfoBeneficiarios",
          dataInfoBeneficiariosUpdate
        );

        if (actualizar.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } else {
          Swal.fire({
            title: "Datos actualizados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  //Formatear números a pesos
  const GetNF = (value) => {
    if (value != undefined) {
      return Number(value).toLocaleString("es-MX");
    } else {
      return "0";
    }
  };

  useEffect(() => {
    infoCalifica();
    info();
    info2();
    info3();
    info4();
  }, []);

  return (
    <>
      <div>
        <h1 className="titt">Información Personal</h1>
        <p>
          Asegurate de poner la información correcta. Podrás regresar a
          editarlo.
        </p>

        <form onSubmit={handleSubmit(enviar)}>
          <div className="Resum">
            <div className="header">
              <h3>Personales</h3>
            </div>

            <hr />

            <div className="cont_flex cont-form formInfoGeneral">
              {/* CURP */}
              <div className="col3 campo-form ">
                <label>CURP *</label>
                <input
                  type="text"
                  id="curp"
                  style={{ textTransform: "uppercase" }}
                  name="curp"
                  value={infoPersonal.curp}
                  maxLength={18}
                  onInput={(ev) => {
                    setValidarReglaCurp(ruleCURP(ev.target.value));
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      curp: ev.target.value,
                    }));
                  }}
                />
                {/* Muestra el mensaje de error si existe */}
                {(infoPersonal.curp === "" ||
                  infoPersonal.curp === " " ||
                  infoPersonal.curp === undefined) && (
                  <small className="span">Este campo es requerido</small>
                )}
                {validarReglaCurp === false && (
                  <small className="span">No cumple con el formato</small>
                )}
              </div>

              {/* GENERO */}
              <div className="col3 campo-form estado marginEspacio label">
                <label>Género *</label>
                <select
                  name="sexo"
                  id="sexo"
                  value={infoPersonal.sexo}
                  onChange={(ev) => {
                    const isMale = ev.target.value === "true"; // Convertir a booleano
                    setInfoPersonal((prevInfo) => ({
                      ...prevInfo,
                      sexo: isMale,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="true">Masculino</option>
                  <option value="false">Femenino</option>
                </select>
                {/* Muestra el mensaje de error si existe */}
                {(infoPersonal.sexo === "" ||
                  infoPersonal.sexo === " " ||
                  infoPersonal.sexo === undefined) && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>

              <div className="col3 campo-form">
                <label>Número de seguridad social *</label>
                <input
                  type="text"
                  id="numeroSeguroSocial"
                  name="numeroSeguroSocial"
                  maxLength={11}
                  pattern="[0-9]*"
                  value={infoPersonal.numeroSeguroSocial}
                  onInput={(ev) => {
                    setValidarReglaNumeroSeguridad(
                      ruleNumeroSeguridad(ev.target.value)
                    );
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      numeroSeguroSocial: ev.target.value,
                    }));
                  }}
                ></input>
                <small>
                  <a
                    href="https://serviciosdigitales.imss.gob.mx/gestionAsegurados-web-externo/asignacionNSS;JSESSIONIDASEGEXTERNO=QVtxJ5QWWr9Y0NGI725WkKIErOaz4Rr8OtbSIHTlf91Hl7neHlaW!806955352"
                    target="_blank" // Agregamos este atributo para abrir en una nueva pestaña
                    rel="noopener noreferrer" // Añadimos estos atributos de seguridad
                  >
                    Si no lo recuerdas, dar click aquí.
                  </a>
                </small>
                {(infoPersonal.numeroSeguroSocial === "" ||
                  infoPersonal.numeroSeguroSocial === " " ||
                  infoPersonal.numeroSeguroSocial === undefined ||
                  infoPersonal.numeroSeguroSocial === null) && (
                  <small className="span">Este campo es requerido</small>
                )}
                {validarReglaNumeroSeguridad === false && (
                  <small className="span">No cumple con el formato</small>
                )}
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Estado civil *</label>
                <select
                  name="estadoCivil>"
                  id="estadoCivil"
                  value={infoPersonal.estadoCivil}
                  onChange={(ev) => {
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      estadoCivil: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Soltero</option>
                  <option value="2">Unión libre</option>
                  <option value="3">Sociedad de Convivencia</option>
                  <option value="4">Casado (separación de Bienes)</option>
                  <option value="5">Casado (Sociedad Coyugal)</option>
                  <option value="6">Viudo</option>
                  <option value="7">Divorciado</option>
                </select>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Grado máximo de estudios *</label>
                <select
                  name="gradoMaxEstudios>"
                  id="gradoMaxEstudios"
                  value={infoPersonal.gradoMaxEstudios}
                  onChange={(ev) => {
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      gradoMaxEstudios: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Primaria</option>
                  <option value="2">Secundaria</option>
                  <option value="3">Preparatoria</option>
                  <option value="4">Técnico</option>
                  <option value="5">Licenciatura</option>
                  <option value="6">Postgrado</option>
                </select>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Número de dependientes económicos *</label>
                <select
                  name="numDepEco>"
                  id="numDepEco"
                  value={infoPersonal.numDepEco}
                  onChange={(ev) => {
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      numDepEco: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6 o más</option>
                </select>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>¿Que tipo de vivienda habita? *</label>
                <select
                  name="tipoVivienda>"
                  id="tipoVivienda"
                  value={infoPersonal.tipoVivienda}
                  onChange={(ev) => {
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      tipoVivienda: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Propia</option>
                  <option value="2">Hipotecada</option>
                  <option value="3">Rentada</option>
                  <option value="4">De un familiar</option>
                </select>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>¿Años que a vivido en dicha vivienda? *</label>
                <select
                  name="aniosVivienda"
                  id="aniosVivienda"
                  value={infoPersonal.aniosVivienda}
                  onChange={(ev) => {
                    setInfoPersonal((prevInfo) => ({
                      ...prevInfo,
                      aniosVivienda: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  {/* Array para opciones */}
                  {Array.from({ length: 50 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Referencias de Productos</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              {/* ------------------------------------
                        Referencia de crédito 1
             -------------------------------------------*/}

              <form>
                <div className="header">
                  <h3>Referencia de crédito 1</h3>
                  <input
                    id="idReferenciaProducto"
                    value={infoReferenciasProductos.idReferenciaProducto}
                    onChange={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        idReferenciaProducto: ev.target.value,
                      }));
                    }}
                    hidden
                  />
                </div>

                <div className="col3 campo-form estado marginEspacio">
                  <label>Tipo cuenta *</label>
                  <select
                    name="tipoCuenta"
                    id="tipoCuenta"
                    value={infoReferenciasProductos.tipoCuenta}
                    onChange={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        tipoCuenta: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Hipoteca</option>
                    <option value="2">Crédito Auto</option>
                    <option value="3">Crédito Personal</option>
                    <option value="4">Tarjeta de Crédito</option>
                  </select>
                </div>

                <div className="col3 campo-form">
                  <label>Numero de Tarjeta o Crédito *</label>
                  <input
                    type="number"
                    id="numeroCuenta"
                    name="numeroCuenta"
                    value={infoReferenciasProductos.numeroCuenta}
                    onInput={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        numeroCuenta: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                <div className="col3 campo-form">
                  <label>Institución (Banco) del Crédito *</label>
                  <input
                    type="text"
                    id="institucionCredito"
                    name="institucionCredito"
                    value={infoReferenciasProductos.institucionCredito}
                    onInput={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        institucionCredito: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                <div className="header">
                  <h3>Referencias de producto de débito </h3>
                </div>

                <div className="col3 campo-form">
                  <label>Institución (Banco) de la cuenta *</label>
                  <input
                    type="text"
                    id="intitutoCapacitacion"
                    name="intitutoCapacitacion"
                    value={infoReferenciasProductos.intitutoCapacitacion}
                    onInput={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        intitutoCapacitacion: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                {
                  <div className="col3 campo-form estado marginEspacio">
                    <label>Tipo de cuenta de débito *</label>
                    <select
                      name="tipoCuentaCapacitacion>"
                      id="tipoCuentaCapacitacion"
                      value={infoReferenciasProductos.tipoCuentaCapacitacion}
                      onChange={(ev) => {
                        setInfoReferenciasProducto(() => ({
                          ...infoReferenciasProductos,
                          tipoCuentaCapacitacion: ev.target.value,
                        }));
                      }}
                      required
                    >
                      <option value="">Seleccione una opción.....</option>
                      <option value="1">Inversiones</option>
                      <option value="2">Depósitos</option>
                    </select>
                  </div>
                }

                {/* ------------------------------------
                        Referencia de crédito 2
             -------------------------------------------*/}
                <div className="header">
                  <h3>Referencia de crédito 2</h3>
                  <input
                    id="idReferenciaProducto2"
                    value={infoReferenciasProductos2.idReferenciaProducto}
                    onChange={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        idReferenciaProducto: ev.target.value,
                      }));
                    }}
                    hidden
                  />
                </div>

                <div className="col3 campo-form estado marginEspacio">
                  <label>Tipo cuenta</label>
                  <select
                    name="tipoCuenta2>"
                    id="tipoCuenta2"
                    value={infoReferenciasProductos2.tipoCuenta}
                    onChange={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        tipoCuenta: ev.target.value,
                      }));
                    }}
                    // required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Hipoteca</option>
                    <option value="2">Crédito Auto</option>
                    <option value="3">Crédito Personal</option>
                    <option value="4">Tarjeta de Crédito</option>
                  </select>
                </div>

                <div className="col3 campo-form">
                  <label>Numero de Tarjeta o Crédito</label>
                  <input
                    type="number"
                    id="numeroCuenta2"
                    name="numeroCuenta2"
                    value={infoReferenciasProductos2.numeroCuenta}
                    onInput={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        numeroCuenta: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                <div className="col3 campo-form">
                  <label>Institución (Banco) del Crédito</label>
                  <input
                    type="text"
                    id="institucionCredito2"
                    name="institucionCredito2"
                    value={infoReferenciasProductos2.institucionCredito}
                    onInput={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        institucionCredito: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                <div className="header">
                  <h3>Referencias de producto de débito </h3>
                </div>

                <div className="col3 campo-form">
                  <label>Institución (Banco) de la cuenta</label>
                  <input
                    type="text"
                    id="intitutoCapacitacion2"
                    name="intitutoCapacitacion2"
                    value={infoReferenciasProductos2.intitutoCapacitacion}
                    onInput={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        intitutoCapacitacion: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                {
                  <div className="col3 campo-form estado marginEspacio">
                    <label>Tipo de cuenta de débito</label>
                    <select
                      name="tipoCuentaCapacitacion2>"
                      id="tipoCuentaCapacitacion2"
                      value={infoReferenciasProductos2.tipoCuentaCapacitacion}
                      onChange={(ev) => {
                        setInfoReferenciasProducto2(() => ({
                          ...infoReferenciasProductos2,
                          tipoCuentaCapacitacion: ev.target.value,
                        }));
                      }}
                      // required
                    >
                      <option value="">Seleccione una opción.....</option>
                      <option value="1">Inversiones</option>
                      <option value="2">Depósitos</option>
                    </select>
                  </div>
                }
              </form>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Referencias</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              {/* ------------------------------------
                        Referencia Familiar
             -------------------------------------------*/}

              <form>
                <div className="header">
                  <h3>Referencia Familiar</h3>
                  <input
                    id="idReferencia"
                    name="idReferencia"
                    value={infoReferencias.idReferencia}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        idReferencia: ev.target.value,
                      }));
                    }}
                    hidden
                  />
                </div>
                <div className="col3 campo-form">
                  <label>Nombre *</label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={infoReferencias.nombre}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        nombre: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Paterno *</label>
                  <input
                    type="text"
                    id="aPaterno"
                    name="aPaterno"
                    value={infoReferencias.aPaterno}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        aPaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Materno *</label>
                  <input
                    type="text"
                    id="aMaterno"
                    name="aMaterno"
                    value={infoReferencias.aMaterno}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        aMaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form estado marginEspacio">
                  <label>Parentesco *</label>
                  <select
                    name="relacion>"
                    id="relacion"
                    value={infoReferencias.relacion}
                    onChange={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        relacion: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Padre</option>
                    <option value="2">Madre</option>
                    <option value="3">Hijo</option>
                    <option value="4">Pareja</option>
                    <option value="5">Otro</option>
                  </select>
                </div>
                <div className="col3 campo-form" id="delegacion">
                  <label>Tel&eacute;fono *</label>
                  <input
                    type="number"
                    id="telefono"
                    name="telefono"
                    value={infoReferencias.telefono}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        telefono: ev.target.value,
                      }));
                    }}
                    required
                  ></input>
                </div>
                <div className="col3 campo-form" id="colonia">
                  <label>E-mail (opcional)</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={infoReferencias.email}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        email: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                {/* ------------------------------------
                        Referencia No Familiar
             -------------------------------------------*/}

                <div className="header">
                  <h3>Referencia No Familiar </h3>
                  <input
                    id="idReferencia2"
                    name="idReferencia2"
                    value={infoReferencias2.idReferencia}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        idReferencia: ev.target.value,
                      }));
                    }}
                    hidden
                  />
                </div>
                <div className="col3 campo-form">
                  <label>Nombre *</label>
                  <input
                    type="text"
                    id="nombre2"
                    name="nombre2"
                    value={infoReferencias2.nombre}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        nombre: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Paterno *</label>
                  <input
                    type="text"
                    id="aPaterno2"
                    name="aPaterno2"
                    value={infoReferencias2.aPaterno}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        aPaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Materno *</label>
                  <input
                    type="text"
                    id="aMaterno2"
                    name="aMaterno2"
                    value={infoReferencias2.aMaterno}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        aMaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form estado marginEspacio">
                  <label>Parentesco *</label>
                  <select
                    name="relacion2>"
                    id="relacion2"
                    value={infoReferencias2.relacion}
                    onChange={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        relacion: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Padre</option>
                    <option value="2">Madre</option>
                    <option value="3">Hijo</option>
                    <option value="4">Pareja</option>
                    <option value="5">Otro</option>
                  </select>
                </div>
                <div className="col3 campo-form" id="delegacion">
                  <label>Teléfono *</label>
                  <input
                    type="number"
                    id="telefono2"
                    name="telefono2"
                    value={infoReferencias2.telefono}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        telefono: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form" id="colonia">
                  <label>E-mail (opcional)</label>
                  <input
                    type="email"
                    id="email2"
                    name="email2"
                    value={infoReferencias2.email}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        email: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
              </form>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Ingresos y patrimonio</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              <form>
                <div className="header">
                  <h3>Ingresos</h3>
                </div>
                <div className="col3 campo-form">
                  <label>Ingresos Mensuales Brutos *</label>
                  <input
                    type="text"
                    id="sueldo"
                    name="sueldo"
                    value={"$" + GetNF(infoPersonal.sueldo)}
                    onInput={(ev) => {
                      // Elimina cualquier símbolo no numérico antes de actualizar el estado
                      const numericValue = ev.target.value.replace(
                        /[^0-9.-]/g,
                        ""
                      );
                      setInfoPersonal(() => ({
                        ...infoPersonal,
                        sueldo: numericValue,
                      }));
                    }}
                  />
                </div>

                <div className="col3 campo-form">
                  <label>Otros ingresos *</label>
                  <input
                    type="text"
                    id="otroIngreso"
                    name="otroIngreso"
                    value={"$" + GetNF(infoPersonal.otroIngreso)}
                    onInput={(ev) => {
                      // Elimina cualquier símbolo no numérico antes de actualizar el estado
                      const numericValue = ev.target.value.replace(
                        /[^0-9.-]/g,
                        ""
                      );
                      setInfoPersonal(() => ({
                        ...infoPersonal,
                        otroIngreso: numericValue,
                      }));
                    }}
                  />
                </div>
                <div className="col3 campo-form">
                  <label>Valor de Automoviles $$ *</label>
                  <input
                    type="text"
                    id="autoValor"
                    name="autoValor"
                    value={"$" + GetNF(infoPersonal.autoValor)}
                    onInput={(ev) => {
                      // Elimina cualquier símbolo no numérico antes de actualizar el estado
                      const numericValue = ev.target.value.replace(
                        /[^0-9.-]/g,
                        ""
                      );
                      setInfoPersonal(() => ({
                        ...infoPersonal,
                        autoValor: numericValue,
                      }));
                    }}
                  />
                </div>

                <div className="col3 campo-form">
                  <label>Valor de la vivienda Actual $$ *</label>
                  <input
                    type="text"
                    id="viviendoActualValor"
                    name="viviendoActualValor"
                    value={"$" + GetNF(infoPersonal.viviendoActualValor)}
                    onInput={(ev) => {
                      // Elimina cualquier símbolo no numérico antes de actualizar el estado
                      const numericValue = ev.target.value.replace(
                        /[^0-9.-]/g,
                        ""
                      );
                      setInfoPersonal(() => ({
                        ...infoPersonal,
                        viviendoActualValor: numericValue,
                      }));
                    }}
                  />
                </div>
              </form>
            </div>
          </div>

          {/* Beneficiario */}

          <div className="Resum">
            <div className="header">
              <h3>Beneficiario</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              <form>
                <div className="col3 campo-form">
                  <label>Nombre *</label>
                  <input
                    type="text"
                    id="nombre_bef"
                    name="nombre_bef"
                    value={infoBeneficiarios.nombre}
                    onInput={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        nombre: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Paterno *</label>
                  <input
                    type="text"
                    id="aPaterno_bef"
                    name="aPaterno_bef"
                    value={infoBeneficiarios.aPaterno}
                    onInput={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        aPaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido materno *</label>
                  <input
                    type="text"
                    id="aMaterno_bef"
                    name="aMaterno_bef"
                    value={infoBeneficiarios.aMaterno}
                    onInput={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        aMaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form estado marginEspacio">
                  <label>Parentesco *</label>
                  <select
                    name="parentesco_bef>"
                    id="parentesco_bef"
                    value={infoBeneficiarios.parentesco}
                    onChange={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        parentesco: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Padre</option>
                    <option value="2">Madre</option>
                    <option value="3">Hijo</option>
                    <option value="4">Pareja</option>
                    <option value="5">Otro</option>
                  </select>
                </div>
                <div className="col3 campo-form">
                  <label>Teléfono *</label>
                  <input
                    type="number"
                    id="telefono_bef"
                    name="telefono_bef"
                    value={infoBeneficiarios.telefono}
                    onInput={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        telefono: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
              </form>
            </div>
          </div>

          <div className="cont_flex cont-form">
            <div className="dosCuttonFormularios">
              <button className="btn btn_morado col3 campo-form" type="submit">
                Guardar
              </button>
            </div>
          </div>

          <div className="header">
            <h3>
              Si realizaste algun cambio, recuerda <br />{" "}
              <span className="morado"> guardar </span> antes de salir.
            </h3>
          </div>
        </form>
      </div>
    </>
  );
}

export function InfoLaboral({ cliente, alCompletar = () => {} }) {
  // Estado para almacenar la información laboral.
  const [infoLaboral, setInfoLaboral] = useState({});
  // Estados para validar reglas de teléfono y fecha de nacimiento.
  const [validarReglaTelefono, setValidarReglaTelefono] = useState();
  const [validarReglaNacimiento, setValidarReglaNacimento] = useState();
  const [validarIngresoOpcional, setValidarIngresoOpcional] = useState();
  const [validarSalidaOpcional, setValidarSalidaOpcional] = useState();

  const [validarLongitudCP, setValidarLongitudCP] = useState();
  const [estadoDisabled, setEstadoDisabled] = useState(true);
  const [delegacionDisabled, setDelegacionDisabled] = useState(true);
  const [listColonias, setListColonias] = useState();

  // Estados para almacenar información de dirección.
  const [direccion, setDireccion] = useState("");
  const [exterior, setExterior] = useState("");
  const [interior, setInterior] = useState("");
  const [estado, setEstado] = useState("");
  const [estadoAux, setEstadoAux] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [colonia, setColonia] = useState("");
  const [cp, setCp] = useState("");
  const [error, setError] = useState(null);

  //Para almacenar informacion del empleo
  const [nombreEmpresa, setNombreEmpresa] = useState("");
  const [puesto, setPuesto] = useState("");
  const [ocupacion, setOcupacion] = useState("");
  const [tipoEmpleo, setTipoEmpleo] = useState("");
  const [tipoContrato, setTipoContrato] = useState("");
  const [telefono, setTelefono] = useState("");
  const [fechaIngreso, setFechaIngreso] = useState("");
  const [giroEmpresa, setGiroEmpresa] = useState("");

  //Para almacenar el empleo anterior
  const [nombreEmpresaAnt, setNombreEmpresaAnt] = useState("");
  const [puestoAnt, setPuestoAnt] = useState("");
  const [ocupacionAnt, setOcupacionAnt] = useState("");
  const [tipoEmpleoAnt, setTipoEmpleoAnt] = useState("");
  const [telefonoAnt, setTelefonoAnt] = useState("");
  const [fechaIngresoAnt, setFechaIngresoAnt] = useState("");
  const [fechaSalidaAnt, setFechaSalidaAnt] = useState("");

  const [idResPrecalifica, setIdResPrecalifica] = useState({});

  // Utiliza useForm del hook react-hook-form para gestionar el estado del formulario.
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  //Para obtener informacion general del usuario
  const obtInfoCuestionario = async () => {
    let idCuestionario = await get("getInfoCalifica", cliente?.id);
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  // Función para dar formato a una entrada de fecha.
  const DateInput = (event, tipo, setValidarRegla) => {
    let input = event;

    // Remover las diagonales existentes
    input = input.replace(/\D/g, "");

    // Insertar las diagonales en la posición correcta
    if (input.length > 2 && input.length < 5) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    } else if (input.length > 4) {
      input =
        input.slice(0, 2) + "/" + input.slice(2, 4) + "/" + input.slice(4, 8);
    }

    const regex = /^\d{0,2}(\/\d{0,2}(\/\d{0,4})?)?$/;

    if (regex.test(input)) {
      // console.log("El formato de fecha es válido.");
      // Realizar acciones adicionales si la fecha es válida
    } else {
      // console.log("El formato de fecha no es válido.");
      // Realizar acciones adicionales si la fecha no es válida
    }

    if (tipo === 1) {
      setInfoLaboral(() => ({ ...infoLaboral, fechaIngreso: input }));
      setFechaIngreso(input);
      setValidarRegla(ruleNacimiento(input));
    } else if (tipo === 2) {
      setInfoLaboral(() => ({ ...infoLaboral, fechaIngresoAnt: input }));
      setFechaIngresoAnt(input);
      setValidarRegla(ruleNacimiento(input) || input === "");
    } else if (tipo === 3) {
      setInfoLaboral(() => ({ ...infoLaboral, fechaSalidaAnt: input }));
      setFechaSalidaAnt(input);
      setValidarRegla(ruleNacimiento(input) || input === "");
    }
    

    let fechaIngresoNF =
      input.substr(6, 4) + "-" + input.substr(3, 2) + "-" + input.substr(0, 2);
  };

  // Función para obtener datos de código postal.
  const obtenerDataCp = async (cp) => {
    try {
      let respuestaPorCP = await getCP("info_cp", cp);
      console.log(respuestaPorCP);
      setError(false);
      obtenerClaveIso(respuestaPorCP.response.estado);
      setEstadoAux(respuestaPorCP.response.estado);
      setMunicipio(respuestaPorCP.response.municipio);
      listColoniasCP(respuestaPorCP.response.asentamiento);
      setEstadoDisabled(true);
      setDelegacionDisabled(true);
    } catch (error) {
      // Habilitar los inputs en caso de error
      setEstadoDisabled(false);
      setDelegacionDisabled(false);
      setColonia(""); // Esto borrará el contenido del campo "colonia"
      setEstadoAux("");
      setMunicipio("");
      setError(true);
    }
  };

  // Función para obtener la clave ISO del estado.
  const obtenerClaveIso = async (estadoCP) => {
    let obtenerClave = await get("obtClaveISOPorEstado", estadoCP);
    setEstadoAux(estadoCP);
    if (obtenerClave.statuscode === 200) {
      setEstado(obtenerClave.iso);
    }
  };

  // Función para listar colonias.
  const listColoniasCP = (colonias) => {
    let opcionDefault = (
      <option value="" disabled>
        Seleccione una opción...{" "}
      </option>
    );

    colonias = colonias.map((colonia) => (
      <option value={colonia} key={colonia}>
        {colonia}
      </option>
    ));
    colonias.unshift(opcionDefault);

    setListColonias(colonias);
  };

  //Obtenemos la informacion laboral
  //Si es que existe
  const obtInfoLaboral = async () => {
    let data = await get("getInfoLaboral", cliente?.id);
    if (data.statuscode === 200) {
      //* Si la respuesta contiene una fecha de ingreso (fechaIngreso), la función convierte
      //* esa fecha en un formato específico (aaaa-mm-dd) y luego en otro formato (dd/mm/aaaa)
      //* antes de almacenarla nuevamente en el objeto data.infoLaboral[0].fechaIngreso.
      if (data.infoLaboral[0].fechaIngreso) {
        let fechaIngreso = new Date(data.infoLaboral[0].fechaIngreso);

        if (fechaIngreso.getMonth() + 1 < 10 && fechaIngreso.getDate() < 10) {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-0" +
            (fechaIngreso.getMonth() + 1) +
            "-0" +
            fechaIngreso.getDate();
        } else if (fechaIngreso.getDate() < 10) {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-" +
            (fechaIngreso.getMonth() + 1) +
            "-0" +
            fechaIngreso.getDate();
        } else if (fechaIngreso.getMonth() + 1 < 10) {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-0" +
            (fechaIngreso.getMonth() + 1) +
            "-" +
            fechaIngreso.getDate();
        } else {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-" +
            (fechaIngreso.getMonth() + 1) +
            "-" +
            fechaIngreso.getDate();
        }
        let fechaIN =
          data.infoLaboral[0].fechaIngreso.substr(8, 2) +
          "/" +
          data.infoLaboral[0].fechaIngreso.substr(5, 2) +
          "/" +
          data.infoLaboral[0].fechaIngreso.substr(0, 4);
        data.infoLaboral[0].fechaIngreso = fechaIN;
        setFechaIngreso(fechaIN);
        setValue("fechaIngreso", fechaIN);
      }

      if (data.infoLaboral[0].fechaIngresoAnt) {
        let fechaIngresoAnt = new Date(data.infoLaboral[0].fechaIngresoAnt);

        if (
          fechaIngresoAnt.getMonth() + 1 < 10 &&
          fechaIngresoAnt.getDate() < 10
        ) {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-0" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-0" +
            fechaIngresoAnt.getDate();
        } else if (fechaIngresoAnt.getDate() < 10) {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-0" +
            fechaIngresoAnt.getDate();
        } else if (fechaIngresoAnt.getMonth() + 1 < 10) {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-0" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-" +
            fechaIngresoAnt.getDate();
        } else {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-" +
            fechaIngresoAnt.getDate();
        }

        let fechaIN =
          data.infoLaboral[0].fechaIngresoAnt.substr(8, 2) +
          "/" +
          data.infoLaboral[0].fechaIngresoAnt.substr(5, 2) +
          "/" +
          data.infoLaboral[0].fechaIngresoAnt.substr(0, 4);
        data.infoLaboral[0].fechaIngresoAnt = fechaIN;
        setFechaIngresoAnt(fechaIN);
        setValue("fechaIngresoAnt", fechaIN);
      }

      if (data.infoLaboral[0].fechaSalidaAnt) {
        let fechaSalidaAnt = new Date(data.infoLaboral[0].fechaSalidaAnt);

        if (
          fechaSalidaAnt.getMonth() + 1 < 10 &&
          fechaSalidaAnt.getDate() < 10
        ) {
          data.infoLaboral[0].fechaSalidaAnt =
            fechaSalidaAnt.getFullYear() +
            "-0" +
            (fechaSalidaAnt.getMonth() + 1) +
            "-0" +
            fechaSalidaAnt.getDate();
        } else if (fechaSalidaAnt.getDate() < 10) {
          data.infoLaboral[0].fechaSalidaAnt =
            fechaSalidaAnt.getFullYear() +
            "-" +
            (fechaSalidaAnt.getMonth() + 1) +
            "-0" +
            fechaSalidaAnt.getDate();
        } else if (fechaSalidaAnt.getMonth() + 1 < 10) {
          data.infoLaboral[0].fechaSalidaAnt =
            fechaSalidaAnt.getFullYear() +
            "-0" +
            (fechaSalidaAnt.getMonth() + 1) +
            "-" +
            fechaSalidaAnt.getDate();
        } else {
          data.infoLaboral[0].fechaSalidaAnt =
            fechaSalidaAnt.getFullYear() +
            "-" +
            (fechaSalidaAnt.getMonth() + 1) +
            "-" +
            fechaSalidaAnt.getDate();
        }

        let fechaIN =
          data.infoLaboral[0].fechaSalidaAnt.substr(8, 2) +
          "/" +
          data.infoLaboral[0].fechaSalidaAnt.substr(5, 2) +
          "/" +
          data.infoLaboral[0].fechaSalidaAnt.substr(0, 4);
        data.infoLaboral[0].fechaSalidaAnt = fechaIN;
        setFechaSalidaAnt(fechaIN);
        setValue("fechaSalidaAnt", fechaIN);
      }

      //* Establece varios estados del componente (setEstado, setMunicipio, setColonia, setCp,
      //* setDireccion, setExterior, setInterior) con los valores correspondientes de la
      //* información laboral obtenida.
      setEstadoAux(data.infoLaboral[0].municipio);
      setValue("estado", data.infoLaboral[0].estado);

      setMunicipio(data.infoLaboral[0].municipio);
      setValue("municipio", data.infoLaboral[0].municipio);

      setError(true);
      setColonia(data.infoLaboral[0].colonia);
      setValue("colonia", data.infoLaboral[0].colonia);

      setCp(data.infoLaboral[0].cp);
      setValue("cp", data.infoLaboral[0].cp, ruleCP);

      setDireccion(data.infoLaboral[0].calle);
      setValue("direccion", data.infoLaboral[0].calle);

      setExterior(data.infoLaboral[0].numExt);
      setValue("numExt", data.infoLaboral[0].numExt);

      setInterior(data.infoLaboral[0].numInt);
      setValue("numInt", data.infoLaboral[0].numInt);

      setNombreEmpresa(data.infoLaboral[0].nombreEmpresa);
      setValue("nombreEmpresa", data.infoLaboral[0].nombreEmpresa);

      setPuesto(data.infoLaboral[0].puesto);
      setValue("puesto", data.infoLaboral[0].puesto);

      setOcupacion(data.infoLaboral[0].ocupacion);
      setValue("ocupacion", data.infoLaboral[0].ocupacion);

      setTipoEmpleo(data.infoLaboral[0].tipoEmpleo);
      setValue("tipoEmpleo", data.infoLaboral[0].tipoEmpleo);

      setTipoContrato(data.infoLaboral[0].tipoContrato);
      setValue("tipoContrato", data.infoLaboral[0].tipoContrato);

      setTelefono(data.infoLaboral[0].telefono);
      setValue("telefono", data.infoLaboral[0].telefono);

      setGiroEmpresa(data.infoLaboral[0].giro);
      setValue("giroEmpresa", data.infoLaboral[0].giro);

      setNombreEmpresaAnt(data.infoLaboral[0].nombreEmpresaAnt);
      setPuestoAnt(data.infoLaboral[0].puestoAnt);
      setOcupacionAnt(data.infoLaboral[0].ocupacionAnt);
      setTipoEmpleoAnt(data.infoLaboral[0].tipoEmpleoAnt);
      setTelefonoAnt(data.infoLaboral[0].telefonoAnt);

      //* Utiliza setInfoLaboral para actualizar el estado del componente. Aquí se usa el
      //* operador spread (...) para mezclar los datos anteriores de infoLaboral con los
      //* nuevos datos de data.infoLaboral[0]. Esto se hace para actualizar el estado del
      //* componente con la información laboral completa.
      setInfoLaboral(() => ({
        ...infoLaboral,
        ...data.infoLaboral[0],
      }));
    } else {
      console.log("Sin informacion guardada previamente");
    }
  };

  const enviar = (data) => {
    guardarInformacionLaboral();
  };

  function formatearFechaGuion(fecha){
    const fechaArray = fecha.split("/");
    return `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`;
  }

  const guardarInformacionLaboral = async () => {

    let dataInfoLaboral = {
      idUser: cliente?.id,
      nombreEmpresa: nombreEmpresa,
      puesto: puesto,
      telefono: telefono,
      fechaIngreso: formatearFechaGuion(fechaIngreso),
      giro: giroEmpresa,
      calle: direccion,
      numExt: exterior,
      numInt: interior,
      cp: cp,
      municipio: municipio,
      colonia: colonia,
      nombreEmpresaAnt: nombreEmpresaAnt,
      puestoAnt: puestoAnt,
      telefonoAnt: telefonoAnt,
      idResPrecalifica: idResPrecalifica,
      estado: estadoAux,
      ocupacion: ocupacion,
      tipoEmpleoAnt: tipoEmpleoAnt,
      ocupacionAnt: ocupacionAnt,
      tipoEmpleo: tipoEmpleo,
      tipoContrato: tipoContrato,
      escoAcreditado: false,
    };

    if (fechaIngresoAnt !== null && fechaIngresoAnt !== ""){
      dataInfoLaboral["fechaIngresoAnt"] = formatearFechaGuion(fechaIngresoAnt);
    }

    if (fechaSalidaAnt !== null && fechaSalidaAnt !== ""){
      dataInfoLaboral["fechaSalidaAnt"] = formatearFechaGuion(fechaSalidaAnt);
    }

    let consultarInfo = await get("getInfoLaboral", cliente?.id);
    if (consultarInfo.msg === "El usuario no tiene informacion laboral") {
      let guardarInfoLaboral = await post("addInfoLaboral", dataInfoLaboral);
      if (guardarInfoLaboral.statuscode === 200) {
        Swal.fire({
          title: "Datos guardados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        alCompletar();
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (consultarInfo.statuscode === 200) {
        let actInfoLaboral = await put("updateInfoLaboral", dataInfoLaboral);

        if (actInfoLaboral.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } else {
          Swal.fire({
            title: "Datos ingresados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  useEffect(() => {
    obtInfoLaboral();
    obtInfoCuestionario();
  }, []);

  return (
    <div>
      <h1 className="titt">Información Laboral</h1>
      <p>
        Asegurate de poner la información correcta. <br /> Podrás regresar a
        editarlo.
      </p>

      <form onSubmit={handleSubmit(enviar)}>
        <div className="Resum">
          <div className="header">
            <h3>Empleo actual</h3>
          </div>
          <div className="cont_flex cont-form formInfoGeneral">
            {/* NOMBRE */}
            <div className="col3 campo-form ">
              <label>Nombre de la empresa *</label>
              <input
                type="text"
                {...register("nombreEmpresa", { required: true })}
                autoComplete="off"
                id="nombreEmpresa"
                name="nombreEmpresa"
                value={nombreEmpresa || ""}
                onInput={(ev) => {
                  setNombreEmpresa(
                    ev.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
              {errors.nombreEmpresa?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/* Puesto*/}
            <div className="col3 campo-form">
              <label>Puesto *</label>
              <input
                type="text"
                {...register("puesto", { required: true })}
                id="puesto"
                name="puesto"
                value={puesto || ""}
                onInput={(ev) => {
                  setPuesto(
                    ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
              {errors.puesto?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*Ocupación */}
            <div className="col3 campo-form">
              <label>Ocupación *</label>
              <input
                type="text"
                {...register("ocupacion", { required: true })}
                id="ocupacion"
                name="ocupacion"
                value={ocupacion || ""}
                onInput={(ev) => {
                  setOcupacion(
                    ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
              {errors.ocupacion?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/* Tipo de empleo*/}
            <div className="col3 campo-form estado marginEspacio">
              <label>Tipo de empleo *</label>
              <select
                {...register("tipoEmpleo", { required: true })}
                id="tipoEmpleo"
                name="tipoEmpleo"
                value={tipoEmpleo || ""}
                onChange={(ev) => {
                  setTipoEmpleo(ev.target.value);
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="1">Empleado</option>
                <option value="2">Negocio propio</option>
                <option value="3">Otro</option>
              </select>
              {errors.tipoEmpleo?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <div className="col3 campo-form estado marginEspacio">
              <label>Tipo de contrato *</label>
              <select
                {...register("tipoContrato", { required: true })}
                id="tipoContrato"
                name="tipoContrato"
                value={tipoContrato || ""}
                onChange={(ev) => {
                  setTipoContrato(ev.target.value);
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="1">Definitivo (no temporal)</option>
                <option value="2">Temporal</option>
              </select>
              {errors.tipoContrato?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/* Teléfono*/}
            <div className="col3 campo-form">
              <label>Teléfono *</label>
              <input
                type="tel"
                {...register("telefono", { required: true })}
                id="telefono"
                name="telefono"
                size="14"
                maxLength={10}
                placeholder="Teléfono a 10 dígitos"
                autoComplete="off"
                value={telefono || ""}
                onInput={(ev) => {
                  setValidarReglaTelefono(ruleTelefono(ev.target.value));
                  setTelefono(() => ev.target.value);
                }}
              />
              {errors.telefono?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
              {validarReglaTelefono === false && (
                <small className="span"> Ingresar solo números</small>
              )}
            </div>

            {/* Fecha de ingreso*/}
            <div className="col3 campo-form">
              <label>Fecha de ingreso *</label>
              <input
                type="text"
                {...register("fechaIngreso", { required: true })}
                id="fechaIngreso"
                name="fechaIngreso"
                value={fechaIngreso || ""}
                placeholder="dd/mm/aaaa"
                onInput={(ev) => {
                  DateInput(ev.target.value, 1, setValidarReglaNacimento);
                }}
              />
              {validarReglaNacimiento === false && (
                <small className="span">
                  {" "}
                  Ingresar en el siguiente formato dd/mm/aaaa{" "}
                </small>
              )}
              {errors.fechaIngreso?.type === "required" && (
                <small className="span">Este campo es requerido</small>
              )}
            </div>

            {/* Giro de la empresa*/}
            <div className="col3 campo-form estado marginEspacio">
              <label>Giro de la empresa *</label>
              <select
                {...register("giroEmpresa", { required: true })}
                id="giroEmpresa"
                name="giroEmpresa"
                value={giroEmpresa || ""}
                onInput={(ev) => {
                  setGiroEmpresa(ev.target.value);
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="1">Servicios</option>
                <option value="2">Construcción</option>
                <option value="3">Industrial</option>
                <option value="4">Agricultura / Pesca</option>
                <option value="5">Otro</option>
              </select>
              {errors.giroEmpresa?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
          </div>
        </div>

        <div className="Resum">
          <div className="header">
            <h3>Dirección del empleo</h3>
          </div>
          <div className="cont_flex cont-form formInfoGeneral">
            {/* Código postal */}
            <div className="col3 campo-form ">
              <label htmlFor="cp">Código postal *</label>
              <input
                type="tel"
                id="cp"
                name="cp"
                size={5}
                maxLength="5"
                autoComplete="off"
                value={cp}
                {...register("cp", { required: true, validate: ruleCP })}
                onInput={(ev) => {
                  let tempCP = ev.target.value;
                  if (isNaN(tempCP) === false) {
                    if (tempCP.length < 5) {
                      setValidarLongitudCP(false);
                      setCp(tempCP);
                    } else {
                      if (tempCP.length === 5) {
                        obtenerDataCp(ev.target.value);
                        setCp(tempCP);
                        setValidarLongitudCP(true);
                      }
                    }
                  }
                }}
              />
              <small>
                Al ingresar su código postal correcto, en automático se
                registrará su estado y municipio.
              </small>
              {errors.cp?.type === "required" && (
                <span className="span">
                  {" "}
                  <br />
                  Este campo es requerdio{" "}
                </span>
              )}
              {validarLongitudCP === false && (
                <span className="span"> Ingresa 5 dígitos</span>
              )}
            </div>

            {/* Direccion*/}
            <div className="col3 campo-form">
              <label>Dirección *</label>
              <input
                type="text"
                {...register("direccion", { required: true })}
                autoComplete="off"
                id="direccion"
                name="direccion"
                value={direccion || ""}
                onInput={(ev) => {
                  setDireccion(
                    ev.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
              {errors.direccion?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*Número Ext */}
            <div className="col3 campo-form">
              <label>Número Ext *</label>
              <input
                type="tel"
                name="numExt"
                id="numExt"
                autoComplete="off"
                value={exterior || ""}
                onInput={(ev) => {
                  setExterior(ev.target.value);
                }}
              />
            </div>

            {/* Número Int */}
            <div className="col3 campo-form">
              <label>Número Int</label>
              <input
                type="tel"
                name="numInt"
                id="numInt"
                max={20}
                autoComplete="off"
                value={interior || ""}
                onInput={(ev) => {
                  setInterior(ev.target.value);
                }}
              />
            </div>

            {/* Estado*/}
            <div className="col3 campo-form estado">
              <label htmlFor="estado">Estado *</label>
              <input
                type="text"
                name="estado"
                id="estado"
                disabled={estadoDisabled}
                value={estadoAux}
                onInput={(ev) => {
                  setEstadoAux(ev.target.value);
                }}
              />
            </div>

            {/* Delegación / Municipio*/}
            <div className="col3 campo-form colonia">
              <label htmlFor="municipio">Delegación / Municipio *</label>
              <input
                name="municipio"
                id="municipio"
                disabled={delegacionDisabled}
                value={municipio}
                onInput={(ev) => {
                  setMunicipio(ev.target.value);
                }}
              ></input>
            </div>

            {/* Colonia*/}
            <div className="col3 campo-form colonia">
              <label htmlFor="coloniaSelect">Colonia *</label>
              {error ? (
                <input
                  type="text"
                  id="colonia"
                  value={colonia}
                  name="colonia"
                  onChange={(ev) => {
                    setColonia(ev.target.value);
                  }}
                />
              ) : (
                <select
                  type="text"
                  id="colonia"
                  value={colonia}
                  name="colonia"
                  onChange={(ev) => {
                    setColonia(ev.target.value);
                  }}
                >
                  {listColonias}
                </select>
              )}
            </div>
          </div>
        </div>

        {/*Campos opcionales no aplicar validaciones */}
        <div className="Resum">
          <div className="header">
            <h3>Empleo anterior (Opcional)</h3>
          </div>
          <div className="cont_flex cont-form">
            <div className="col3 campo-form">
              <label>Nombre de la empresa</label>
              <input
                type="text"
                id="nombreEmpresaAnt"
                name="nombreEmpresaAnt"
                value={nombreEmpresaAnt || ""}
                onInput={(ev) => {
                  setNombreEmpresaAnt(
                    ev.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
            </div>

            <div className="col3 campo-form">
              <label>Puesto</label>
              <input
                type="text"
                id="puestoAnt"
                name="puestoAnt"
                value={puestoAnt || ""}
                onInput={(ev) => {
                  setPuestoAnt(
                    ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
            </div>

            <div className="col3 campo-form">
              <label>Ocupación</label>
              <input
                type="text"
                id="ocupacionAnt"
                name="ocupacionAnt"
                value={ocupacionAnt || ""}
                onInput={(ev) => {
                  setOcupacionAnt(
                    ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
            </div>

            <div className="col3 campo-form estado marginEspacio">
              <label>Tipo de empleo</label>
              <select
                id="tipoEmpleoAnt"
                name="tipoEmpleoAnt"
                value={tipoEmpleoAnt || ""}
                onInput={(ev) => {
                  setTipoEmpleoAnt(ev.target.value);
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="1">Empleado</option>
                <option value="2">Negocio propio</option>
                <option value="3">Otro</option>
              </select>
            </div>

            <div className="col3 campo-form">
              <label>Teléfono</label>
              <input
                type="tel"
                size="14"
                maxLength={10}
                name="telefonoAnt"
                id="telefonoAnt"
                placeholder="Teléfono a 10 dígitos"
                autoComplete="off"
                value={telefonoAnt || ""}
                onInput={(ev) => {
                  setValidarReglaTelefono(ruleTelefono(ev.target.value));
                  setTelefonoAnt(() => ev.target.value);
                }}
              />
            </div>

            <div className="col3 campo-form">
              <label>Fecha de ingreso</label>
              <input
                type="text"
                value={fechaIngresoAnt || ""}
                placeholder="dd/mm/aaaa"
                name="fechaIngresoAnt"
                id="fechaIngresoAnt"
                onInput={(ev) => {
                  DateInput(ev.target.value, 2, setValidarIngresoOpcional);
                }}
              />
              {validarIngresoOpcional === false && (
                <small className="span">
                  {" "}
                  Ingresar en el siguiente formato dd/mm/aaaa <br />
                </small>
              )}
            </div>

            <div className="col3 campo-form">
              <label>Fecha de salida</label>
              <input
                type="text"
                value={fechaSalidaAnt || ""}
                placeholder="dd/mm/aaaa"
                name="fechaSalidaAnt"
                id="fechaSalidaAnt"
                onInput={(ev) => {
                  DateInput(ev.target.value, 3, setValidarSalidaOpcional);
                }}
              />
              {validarSalidaOpcional === false && (
                <small className="span">
                  {" "}
                  Ingresar en el siguiente formato dd/mm/aaaa <br />
                </small>
              )}
            </div>
          </div>
        </div>

        <div className="cont_flex cont-form">
          <div className="dosCuttonFormularios">
            <button type="submit" className="btn btn_morado campo-form">
              Guardar
            </button>
          </div>
        </div>
        <div className="header">
          <h3>
            Si realizaste algun cambio, recuerda <br />{" "}
            <span className="morado"> guardar </span> antes de salir.
          </h3>
        </div>
        {/* <Link to="/informacion/inf-personal" className="btn btn_morado">Continuar con información personal</Link> */}
      </form>
    </div>
  );
}

export function InfoMedica({ cliente, alCompletar = () => {} }) {
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // Estado para guardar información médica

  const [peso, setPeso] = useState("");
  const [estatura, setEstatura] = useState("");
  const [consumoAlcohol, setConsumoAlcohol] = useState("No");
  const [consumoDrogas, setConsumoDrogas] = useState("No");
  const [consumoTabaco, setConsumoTabaco] = useState("No");
  const [deporteAltoRiezgo, setDeporteAltoRiezgo] = useState("No");
  const [tieneEnfermedad, setTieneEnfermedad] = useState("No");
  const [enfermedadDescripcion, setEnfermedadDescripcion] = useState("");
  const [descripcionDeshabilitada, setDescripcionDeshabilitada] = useState(true);

  const [idResPrecalifica, setIdResPrecalifica] = useState({});

  //Para detectar cambios en radios y guardarlos
  const handleRadioChangeDeporteAltoRiezgo = (event) => {
    setDeporteAltoRiezgo(event.target.value);
  };

  const handleRadioChangeAlchol = (event) => {
    setConsumoAlcohol(event.target.value);
  };

  const handleRadioChangeDrogas = (event) => {
    setConsumoDrogas(event.target.value);
  };

  const handleRadioChangeTabaco = (event) => {
    setConsumoTabaco(event.target.value);
  };

  const handleRadioChangeTieneEnfermedad = (event) => {
    setTieneEnfermedad(event.target.value);
  };

  //Para obtener informacion general del usuario
  const obtInfoCuestionario = async () => {
    let idCuestionario = await get("getInfoCalifica", cliente?.id);
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const obtInformacionMedica = async () => {
    let dataMedica = await get("getInfoMedica", cliente?.id);
    if (dataMedica.statuscode === 200) {
      //console.log(dataMedica);

      //* Establecemos todos los campos para mostrarlos y que las validaciones no fallen
      setPeso(dataMedica.infoMedica[0].peso);
      setValue("peso", dataMedica.infoMedica[0].peso);

      setEstatura(dataMedica.infoMedica[0].estatura);
      setValue("estatura", dataMedica.infoMedica[0].estatura);

      setDeporteAltoRiezgo(dataMedica.infoMedica[0].deportesAr === "0" ? "false" : "true");
      setValue("deporteAltoRiezgo", dataMedica.infoMedica[0].deportesAr === "0" ? "false" : "true");

      //TODO: agregar a la bd
      //!de momento usamos los campos de diabetes
      setConsumoAlcohol(dataMedica.infoMedica[0].diabetis.toString());
      setValue("consumoAlcohol", dataMedica.infoMedica[0].diabetis.toString());

      //TODO: agregar a la bd drogas
      //!de momento usamos los campos de cancer
      setConsumoDrogas(dataMedica.infoMedica[0].cancer.toString());
      setValue("consumoDrogas", dataMedica.infoMedica[0].cancer.toString());

      setConsumoTabaco(dataMedica.infoMedica[0].consumoCigarro.toString());
      setValue(
        "consumoTabaco",
        dataMedica.infoMedica[0].consumoCigarro.toString()
      );

      setTieneEnfermedad(dataMedica.infoMedica[0].tieneEnfermedad === 0 ? "false" : "true");
      setValue("tieneEnfermedad", dataMedica.infoMedica[0].tieneEnfermedad === 0 ? "false" : "true");

      setEnfermedadDescripcion(dataMedica.infoMedica[0].enfermedadDescripcion);
      setValue(
        "enfermedadDescripcion",
        dataMedica.infoMedica[0].enfermedadDescripcion
      );
    } else {
      // console.log("no hay información medica");
    }
  };

  const guardarInformacionMedica = async () => {
    //TODO: Falta campo consumo alcohol
    //TODO: Falta campo consumo drogas
    let dataMedica = {
      idUser: parseInt(cliente?.id),
      estadoSalud: "string",
      tratamientoMedico: "string",
      enfermedadesCv: false,
      diabetis: consumoAlcohol === "true" ? true : false, //Nota: debido a falta de tiempo estos campos quedan para guardar alcohol pero hay que crear su propio campo
      cancer: consumoDrogas === "true" ? true : false, //Nota: debido a falta de tiempo estos campos quedan para guardar drogas pero hay que crear su propio campo
      deportesAr: deporteAltoRiezgo === "true" ? "1" : "0",
      ocupacionAr: "",
      consumoCigarro: consumoTabaco === "true" ? true : false,
      peso: parseFloat(peso),
      estatura: parseFloat(estatura),
      idResPrecalifica: parseInt(idResPrecalifica),
      tieneEnfermedad: tieneEnfermedad === "true" ? 1 : 0,
      enfermedadDescripcion: enfermedadDescripcion,
    };
    //Consulamos si ya hay información previamente guardada
    let eleccionIU = await get("getInfoMedica", cliente?.id);

    //Insertar
    if (eleccionIU.msg === "El usuario no tiene informacion medica") {
      let guardarInfoMedica = await post("addInfoMedica", dataMedica);
      if (guardarInfoMedica.statuscode === 200) {
        Swal.fire({
          title: "Datos guardados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        alCompletar();
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        let actualizar = await put("updateInfoMedica", dataMedica);

        if (actualizar.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } else {
          Swal.fire({
            title: "Datos ingresados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  useEffect(() => {
    obtInfoCuestionario();
    obtInformacionMedica();
  }, []);

  useEffect(() => {
    if (tieneEnfermedad === "true" || 
      deporteAltoRiezgo === "true" ||
      consumoAlcohol === "true" ||
      consumoDrogas === "true" ||
      consumoTabaco === "true"){
      setDescripcionDeshabilitada(false);
    }else{
      setEnfermedadDescripcion("");
      setDescripcionDeshabilitada(true);
    }
  }, [deporteAltoRiezgo, consumoAlcohol, consumoDrogas, consumoTabaco, tieneEnfermedad])

  const enviar = (data) => {
    guardarInformacionMedica();
  };

  return (
    <div>
      <h1 className="titt">Información Médica</h1>
      <p>
        Asegurate de poner la información correcta. Podrás regresar a
        editarlo.
      </p>

      <div className="Resum">
        <div className="header">
          <h3></h3>
        </div>

        <div className="cont_flex cont-form">
          <form onSubmit={handleSubmit(enviar)}>
            {/*Peso */}
            <div className="col3 campo-form">
              <label>¿Peso? (kg) *</label>
              <input
                type="number"
                {...register("peso", { required: true })}
                id="peso"
                name="peso"
                value={peso || ""}
                size="14"
                maxLength={10}
                autoComplete="off"
                onInput={(ev) => {
                  setPeso(ev.target.value);
                }}
              />
              {errors.peso?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*estatura */}
            <div className="col3 campo-form">
              <label>¿Estatura? (mt) *</label>
              <input
                type="number"
                {...register("estatura", { required: true })}
                id="estatura"
                name="estatura"
                value={estatura}
                onInput={(ev) => {
                  setEstatura(ev.target.value);
                }}
              />
              {errors.estatura?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*Deportes alto riesgo */}
            <div className="col3 campo-form">
              <label>¿Deportes de alto riesgo? *</label>

              <div className="form-check form-check-inline">
                <div className="opcionesRadios">
                  <input
                    className="form-check-input"
                    {...register("deporteAltoRiezgo", { required: true })}
                    type="radio"
                    name="deporteAltoRiezgo"
                    id="inlineRadio1_deporteAltoRiezgo"
                    value={true}
                    checked={deporteAltoRiezgo === "true"}
                    onChange={handleRadioChangeDeporteAltoRiezgo}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio1_deporteAltoRiezgo"
                  >
                    Si
                  </label>
                </div>

                <div className="opcionesRadios centradoRadiosButon">
                  <input
                    className="form-check-input"
                    {...register("deporteAltoRiezgo", { required: true })}
                    type="radio"
                    name="deporteAltoRiezgo"
                    id="inlineRadio2_deporteAltoRiezgo"
                    value={false}
                    checked={deporteAltoRiezgo === "false"}
                    onChange={handleRadioChangeDeporteAltoRiezgo}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio2_deporteAltoRiezgo"
                  >
                    No
                  </label>
                </div>
              </div>
              {errors.deporteAltoRiezgo?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*alcohol */}
            <div className="col3 campo-form">
              <label>¿Consumo de alcohol? *</label>
              <div className="form-check form-check-inline">
                <div className="opcionesRadios">
                  <input
                    className="form-check-input"
                    {...register("consumoAlcohol", { required: true })}
                    type="radio"
                    name="consumoAlcohol"
                    id="inlineRadio1_alcohol"
                    value={true}
                    checked={consumoAlcohol === "true"}
                    onChange={handleRadioChangeAlchol}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio1_alcohol"
                  >
                    Si
                  </label>
                </div>

                <div className="opcionesRadios centradoRadiosButon">
                  <input
                    className="form-check-input"
                    {...register("consumoAlcohol", { required: true })}
                    type="radio"
                    name="consumoAlcohol"
                    id="inlineRadio2_alcohol"
                    value={false}
                    checked={consumoAlcohol === "false"}
                    onChange={handleRadioChangeAlchol}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio2_alcohol"
                  >
                    No
                  </label>
                </div>
              </div>
              {errors.consumoAlcohol?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*Drogas */}
            <div className="col3 campo-form">
              <label>¿Consumo de drogas? *</label>
              <div className="form-check form-check-inline">
                <div className="opcionesRadios">
                  <input
                    className="form-check-input"
                    {...register("consumoDrogas", { required: true })}
                    type="radio"
                    name="consumoDrogas"
                    id="inlineRadio1_drogas"
                    value={true}
                    checked={consumoDrogas === "true"}
                    onChange={handleRadioChangeDrogas}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio1_drogas"
                  >
                    Si
                  </label>
                </div>

                <div className="opcionesRadios centradoRadiosButon">
                  <input
                    className="form-check-input"
                    {...register("consumoDrogas", { required: true })}
                    type="radio"
                    name="consumoDrogas"
                    id="inlineRadio2_drogas"
                    value={false}
                    checked={consumoDrogas === "false"}
                    onChange={handleRadioChangeDrogas}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio2_drogas"
                  >
                    No
                  </label>
                </div>
              </div>
              {errors.consumoDrogas?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*Cigarro */}
            <div className="col3 campo-form">
              <label>¿Consumo de cigarro? *</label>
              <div className="form-check form-check-inline">
                <div className="opcionesRadios">
                  <input
                    className="form-check-input"
                    {...register("consumoTabaco", { required: true })}
                    type="radio"
                    name="consumoTabaco"
                    id="inlineRadio1_tabaco"
                    value={true}
                    checked={consumoTabaco === "true"}
                    onChange={handleRadioChangeTabaco}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio1_tabaco"
                  >
                    Si
                  </label>
                </div>

                <div className="opcionesRadios centradoRadiosButon">
                  <input
                    className="form-check-input"
                    {...register("consumoTabaco", { required: true })}
                    type="radio"
                    name="consumoTabaco"
                    id="inlineRadio2_tabaco"
                    value={false}
                    checked={consumoTabaco === "false"}
                    onChange={handleRadioChangeTabaco}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio2_tabaco"
                  >
                    No
                  </label>
                </div>
              </div>
              {errors.consumoTabaco?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <hr />

            <div className="justificado">
              <label>
                ¿Padece o ha padecido alguna de las siguientes enfermedades? *{" "}
              </label>

              <p>
                ¿Leucemia, sida, insuficiencia renal, diabetes, epilepsia,
                enfermedades del hígado (por ejemplo hepatitis crónica,
                cirrosis, insuficiencia hepática), enfermedades del corazón
                (por ejemplo angina de pecho, infarto al miocardio, cirugía
                del corazón), hipertensión, tumores malignos o cáncer,
                hemorragias o tumor cerebral, padecimientos psiquiátricos,
                cirugías de columna, glaucoma, sordera, enfermedad pulmonar
                obstructiva crónica?
              </p>

              <div className="form-check form-check-inline">
                <div className="opcionesRadios">
                  <input
                    className="form-check-input"
                    {...register("tieneEnfermedad", { required: true })}
                    type="radio"
                    name="tieneEnfermedad"
                    id="inlineRadio1_tieneEnfermedad"
                    value={true}
                    checked={tieneEnfermedad === "true"}
                    onChange={handleRadioChangeTieneEnfermedad}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio1_tieneEnfermedad"
                  >
                    Si
                  </label>
                </div>

                <div className="opcionesRadios centradoRadiosButon">
                  <input
                    className="form-check-input"
                    {...register("tieneEnfermedad", { required: true })}
                    type="radio"
                    name="tieneEnfermedad"
                    id="inlineRadio2_tieneEnfermedad"
                    value={false}
                    checked={tieneEnfermedad === "false"}
                    onChange={handleRadioChangeTieneEnfermedad}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio2_tieneEnfermedad"
                  >
                    No
                  </label>
                </div>
              </div>
              {errors.tieneEnfermedad?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <div className="col3 campo-form">
              <label>
                En caso de cualquier si, por favor especifique (Opcional)
              </label>

              <textarea
                id="cualEnfermedad"
                name="cualEnfermedad"
                value={enfermedadDescripcion || ""}
                maxLength={200} // Limita a 200 caracteres
                disabled={descripcionDeshabilitada}
                onInput={(ev) => {
                  // Asegúrate de que el valor no supere los 200 caracteres
                  const inputValue = ev.target.value.substring(0, 200);
                  setEnfermedadDescripcion(
                    inputValue.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              ></textarea>
            </div>

            <div className="cont_flex cont-form">
              <div className="dosCuttonFormularios">
                <button type="submit" className="btn btn_morado campo-form">
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="header">
        <h3>
          Si realizaste algun cambio, recuerda <br />{" "}
          <span className="morado"> guardar </span> antes de salir.
        </h3>
      </div>
    </div>
  );
}

// --------------- Formularios de documentación personal coacreditado ----------------

export function DocumentacionInicialCoAcreditado({ cliente, idResPrecalifica, actividadEconomica, alCompletar = () => {}, alIncompletar = () => {} }) {
  const [identificacion, setIdentificacion] = useState({});
  const [comprobanteIngresos, setComprobanteIngresos] = useState({});
  const [comprobanteDomicilio, setComprobanteDomicilio] = useState({});

  async function verificarDocumento(idTipoDoc, idUsuario, idResPrecalifica){
    const peticionDocInicial = await post("obtInfoDocumentacionInicial", {
      idUser: idUsuario,
      idResPrecalifica: idResPrecalifica,
      idTipoDocInicial: idTipoDoc,
      esCoacreditado: true
    });

    let parrafo = "";
    let nota = "";
    let actividad = actividadEconomica;
    switch (idTipoDoc) {
      case 1:
        parrafo = "INE o pasaporte";
        nota = "Asegúrate que la identificación sea vigente y legible";
        break;

      case 2:
        parrafo = "Comprobante de Ingresos";
        switch (actividad) {
          case 1:
            nota = "Anexa los últimos 3 meses de recibos de nomina.";
            break;

          case 2:
            nota =
              "Anexa la ultima declaración de impuestos anual y parcial.";
            break;

          case 3:
            nota =
              "Anexa 6 meses donde se reflejen los ingresos que comprueben tus ingresos.";
            break;

          case 4:
            nota =
              "Anexar los últimos 3 meses de recibos de nomina y carta laboral que justifique ingresos.";
            break;

          case 5:
            nota = "Anexar los últimos 3 meses de estados de cuenta.";
            break;
          case 6:
            nota =
              "Anexa la ultima declaración de impuestos anual y parcial.";
            break;
          case 7:
            nota = "Anexa el ultimo año de estados financieros";
            break;

          default:
            nota = "Opción no válida";
            break;
        }
        break;

      case 3:
        parrafo = "Comprobante de domicilio";
        nota =
          "Menor a 3 meses de antigüedad - De preferencia CFE, Agua, Predial o Telefonia";
        break;

      default:
        break;
    }

    if(peticionDocInicial?.statuscode === 200){
      let documentos = [];

      if (peticionDocInicial.respuesta.documentacion.length > 0) {
        if(idTipoDoc === 2){
          documentos = peticionDocInicial.respuesta.documentacion?.map(documento => {
            return {
              id: documento?.idDocInicial,
              nombreArchivo: documento?.nombreArchivo,
              rutaArchivo: documento?.archivo
            }
          });
        }else{
          documentos.push({
            id: peticionDocInicial.respuesta.documentacion[0]?.idDocInicial,
            nombreArchivo: peticionDocInicial.respuesta.documentacion[0]?.nombreArchivo,
            rutaArchivo: peticionDocInicial.respuesta.documentacion[0]?.archivo
          });
        }
      }

      return {
        parrafo: parrafo,
        tipoDocumento: idTipoDoc,
        nota: nota,
        documentos: documentos
      }
    }else{
      return null;
    }
  }

  async function obtDocumentacion(){
    let peticionDocs = await get("getTipoDocumentos", "");

    if (peticionDocs?.statuscode === 200) {
      const docs = peticionDocs.documentos;

      const verificarDocumentos = docs.map(async (doc) => {
        const docVerificado = await verificarDocumento(doc.idTipoDocInicial, cliente?.id ?? 0, idResPrecalifica ?? 0)

        return docVerificado !== null ? {
          titulo: doc.nombre,
          ...docVerificado
        } : null ;
      });
      const documentosVerificados = (await Promise.all(verificarDocumentos)).filter(verificacion => verificacion !== null);

      setIdentificacion(documentosVerificados.find(doc => doc.tipoDocumento === 1));
      setComprobanteIngresos(documentosVerificados.find(doc => doc.tipoDocumento === 2));
      setComprobanteDomicilio(documentosVerificados.find(doc => doc.tipoDocumento === 3));
    }
  }

  async function eliminarArchivo(idDocInicial, documento, setDocumento){
    const peticionEliminarArchivo = await deleteDocEndpoint("borrarArchivoEspecifico", {
      idUser: cliente?.id,
      idDocInicial: idDocInicial,
      coAcreditado: true,
    });

    if (peticionEliminarArchivo.statuscode === 200) {
      const archivosRestantes = documento?.documentos.filter(doc => doc.id !== idDocInicial);
      setDocumento({...documento, documentos: archivosRestantes});

      Swal.fire({
        title: "Documento borrado correctamente",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    } else {
      Swal.fire({
        title: "No se pudo borrar el documento",
        text: "Intente más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  async function subirArchivo(archivo, documento, setDocumento){
    if(cliente && idResPrecalifica){
      const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
        nombreArchivo: archivo.name,
      });
  
      if(peticionArchivoS3?.statuscode === 200){
        const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, archivo);
  
        if(peticionS3?.ok){
          const peticionArchivo = await post("subirArchivo", {
            rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
            idUser: cliente?.id,
            idResPrecalifica: idResPrecalifica,
            idTipoDocInicial: documento?.tipoDocumento,
            esCoAcreditado: true
          });
  
          if (peticionArchivo?.statuscode === 200) {
            setDocumento({...documento, documentos: [{
              id: peticionArchivo.respuesta.documento.idDocInicial,
              nombreArchivo: peticionArchivo.respuesta.documento.nombreArchivo,
              rutaArchivo: peticionArchivo.respuesta.documento.rutaArchivo
            }]});
          }else{
            Swal.fire({
              title: "No se pudo subir el documento",
              text: "Intente más tarde",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
        }
      }
    }else{
      Swal.fire({
        title: "No se pudo subir el documento",
        text: "Información de usuario incompleta",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  async function subirArchivos(archivos, documento, setDocumento){
    if(cliente && idResPrecalifica){
      const archivosNuevos = [];

      const peticionesArchivos = archivos.map(async (archivo) => {
        const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
          nombreArchivo: archivo.name,
        });

        if(peticionArchivoS3?.statuscode === 200){
          const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, archivo);
    
          if(peticionS3?.ok){
            const peticionArchivo = await post("subirArchivo", {
              rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
              idUser: cliente?.id,
              idResPrecalifica: idResPrecalifica,
              idTipoDocInicial: documento?.tipoDocumento,
              esCoAcreditado: true
            });
    
            if (peticionArchivo?.statuscode === 200) {
              archivosNuevos.push({
                id: peticionArchivo.respuesta.documento.idDocInicial,
                nombreArchivo: peticionArchivo.respuesta.documento.nombreArchivo,
                rutaArchivo: peticionArchivo.respuesta.documento.rutaArchivo
              });
              return true;
            }
          }
        }

        return false;
      });

      const respuestas = await Promise.all(peticionesArchivos);

      setDocumento({...documento, documentos: [...documento?.documentos, ...archivosNuevos]});

      if(respuestas.some(respuesta => respuesta === false)){
        Swal.fire({
          title: "No se pudo subir alguno de los archivos",
          text: "Vuelve a intentarlo más tarde",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
        return;
      }
    }else{
      Swal.fire({
        title: "No se pudo subir el documento",
        text: "Información de usuario incompleta",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
    
  }

  useEffect(() => {
    if(identificacion?.documentos?.length > 0 && comprobanteIngresos?.documentos?.length > 0 && comprobanteDomicilio?.documentos?.length > 0){
      alCompletar();
    }else{
      alIncompletar();
    }
  }, [identificacion?.documentos, comprobanteIngresos?.documentos, comprobanteDomicilio?.documentos]);

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtDocumentacion();
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    cargarDatos();
  }, []);

  return (
    <div>
      <h1 className="titt">Documentación Inicial - Co Acreditado</h1>
      <p>
        Aseg&uacute;rate que la identificaci&oacute;n sea vigente y legible
      </p>

      <div className="cont-cards-archivos">
        <CardArchivo 
          titulo={identificacion?.titulo}
          descripcion={identificacion?.nota}
          archivo={identificacion?.documentos?.[0] ?? {}}
          onUpload={(archivo) => subirArchivo(archivo, identificacion, setIdentificacion)}
          onDelete={(idDocInicial) => eliminarArchivo(idDocInicial, identificacion, setIdentificacion)}
        />
        <CardArchivo
          titulo={comprobanteDomicilio?.titulo}
          descripcion={comprobanteDomicilio?.nota}
          archivo={comprobanteDomicilio?.documentos?.[0] ?? {}}
          onUpload={(archivo) => subirArchivo(archivo, comprobanteDomicilio, setComprobanteDomicilio)}
          onDelete={(idDocInicial) => eliminarArchivo(idDocInicial, comprobanteDomicilio, setComprobanteDomicilio)}
        />
        <CardArchivos
          titulo={comprobanteIngresos?.titulo}
          descripcion={comprobanteIngresos?.nota}
          archivos={comprobanteIngresos?.documentos ?? []}
          onUpload={(archivos) => subirArchivos(archivos, comprobanteIngresos, setComprobanteIngresos)}
          onDelete={(idDocInicial) => eliminarArchivo(idDocInicial, comprobanteIngresos, setComprobanteIngresos)}
        />
      </div>
    </div>
  );
}

export function InfoPersonalCoAcreditado({ cliente, alCompletar = () => {} }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [idReferenciaProducto, setIdReferenciaProducto] = useState();
  const [idReferencia, setidReferencia] = useState();
  const [infoPersonal, setInfoPersonal] = useState({});
  const [infoReferenciasProductos, setInfoReferenciasProducto] = useState({});
  const [infoReferenciasProductos2, setInfoReferenciasProducto2] = useState({});
  const [infoReferencias, setInfoReferencias] = useState({});
  const [infoReferencias2, setInfoReferencias2] = useState({});
  const [infoBeneficiarios, setInfoBeneficiarios] = useState({});
  const [idResPrecalifica, setIdResPrecalifica] = useState({});
  const [validarReglaCurp, setValidarReglaCurp] = useState({});
  const [validarReglaNumeroSeguridad, setValidarReglaNumeroSeguridad] = useState({});

  const infoCalifica = async () => {
    let idCuestionario = await get("getInfoCalifica", cliente?.id);
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const info = async () => {
    let data = await get("infoUserCoAcreditado", cliente?.correo);
    if (data.statuscode === 200) {
      setInfoPersonal(() => ({
        ...infoPersonal,
        ...data.user.infoPersonals[0],
      }));
    }
    //console.log(data.user.infoPersonals[0]);
  };

  const info2 = async () => {
    let data = await get(
      "getInfoReferenciasProductosCoAcreditado",
      cliente?.id
    );
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoReferenciasProducto(() => ({
        ...infoReferenciasProductos,
        ...idReferenciaProducto,
        ...data.infoReferencias[0],
      }));
      setInfoReferenciasProducto2(() => ({
        ...infoReferenciasProductos2,
        ...idReferenciaProducto,
        ...data.infoReferencias[1],
      }));
      // Add more fields as needed
    }
    //console.log(data.infoReferencias[0]);
  };

  // Familiar

  const info3 = async () => {
    let data = await get("getReferenciaEsCoacreditado", cliente?.id);
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoReferencias(() => ({
        ...infoReferencias,
        ...idReferencia,
        ...data.infoReferencias[0],
      }));
      setInfoReferencias2(() => ({
        ...infoReferencias2,
        ...idReferencia,
        ...data.infoReferencias[1],
      }));
    }
    //console.log(data.infoReferencias[0]);
  };

  const info4 = async () => {
    let data = await get("getInfoBeneficiariosCoAcreditado", cliente?.id);
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoBeneficiarios(() => ({
        ...infoBeneficiarios,
        ...data.infoReferencias[0],
      }));
    }
    // console.log(data.infoReferencias[0]);
  };

  useEffect(() => {
    infoCalifica();
    info();
    info2();
    info3();
    info4();
  }, []);

  function guardarDatos() {
    if (infoPersonal.curp !== "") {
      addInfoPersonal();
      addReferenciasProductos();
      addReferencia();
      addInfoBeneficiarios();
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Por favor llena todos los campos",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  let dataInfoPersonal = {};
  let dataInfoReferenciasProductos = {};
  let dataInfoReferencia = {};
  let dataInfoBeneficiarios = {};
  let dataInfoBeneficiariosActualizar = {};

  const addInfoPersonal = async () => {
    dataInfoPersonal = {
      /*Parte 1*/
      idUser: cliente?.id,
      curp: infoPersonal.curp,
      sexo: infoPersonal.sexo,
      numeroSeguroSocial: infoPersonal.numeroSeguroSocial,
      estadoCivil: infoPersonal.estadoCivil,
      gradoMaxEstudios: infoPersonal.gradoMaxEstudios,
      numDepEco: infoPersonal.numDepEco,
      tipoVivienda: infoPersonal.tipoVivienda,
      aniosVivienda: infoPersonal.aniosVivienda,
      idResPrecalifica: idResPrecalifica,

      /*Parte 2*/
      sueldo: infoPersonal.sueldo,
      otroIngreso: infoPersonal.otroIngreso,
      autoValor: infoPersonal.autoValor,
      viviendoActualValor: infoPersonal.viviendoActualValor,
      escoAcreditado: true,
    };
    try {
      const existingInfo = await get(
        "getInfoPersonalCoAcreditado",
        cliente?.id
      );
      if (existingInfo.msg === "El usuario no tiene informacion personal") {
        // Usuario sin información, agregar nueva entrada
        const insertar = await post(
          "addInfoPersonalCoAcreditado",
          dataInfoPersonal
        );

        if (insertar.statuscode === 200) {
          // console.log("addInfo personal correcto");
        } else {
          // console.log("addInfo personal fallo");
        }
      } else {
        // Usuario con información, actualizar la existente
        const actualizar = await put("updateinfoPersonal", dataInfoPersonal);

        if (actualizar.statuscode === 200) {
          // console.log("updateInfo personal correcto");
        } else {
          // console.log("updateInfo personal fallo");
        }
      }
    } catch (error) {
      // console.error("Error durante la operación:", error);
    }
  };

  /** -------------------------------------------
                  Referencia Productos              
          --------------------------------------- **/

  const addReferenciasProductos = async () => {
    dataInfoReferenciasProductos = {
      idReferenciaProducto: infoReferenciasProductos.idReferenciaProducto,
      idResPrecalifica: idResPrecalifica,
      idUser: cliente?.id,
      tipoCuenta: infoReferenciasProductos.tipoCuenta,
      numeroCuenta: infoReferenciasProductos.numeroCuenta,
      institucionCredito: infoReferenciasProductos.institucionCredito,
      intitutoCapacitacion: infoReferenciasProductos.intitutoCapacitacion,
      tipoCuentaCapacitacion: infoReferenciasProductos.tipoCuentaCapacitacion,

      idReferenciaProducto2: infoReferenciasProductos2.idReferenciaProducto,
      idResPrecalifica2: idResPrecalifica, // Asigna un valor único
      idUser2: cliente?.id,
      tipoCuenta2: infoReferenciasProductos2.tipoCuenta,
      numeroCuenta2: infoReferenciasProductos2.numeroCuenta,
      institucionCredito2: infoReferenciasProductos2.institucionCredito,
      intitutoCapacitacion2: infoReferenciasProductos2.intitutoCapacitacion,
      tipoCuentaCapacitacion2: infoReferenciasProductos2.tipoCuentaCapacitacion,

      escoAcreditado: true,
    };

    let eleccionIU1 = await get(
      "getInfoReferenciasProductosCoAcreditado",
      cliente?.id
    );

    if (eleccionIU1.msg === "El usuario no tiene informacion de referencias") {
      let insertar = await post(
        "addInfoReferenciasProductosCoAcreditado",
        dataInfoReferenciasProductos
      );

      if (insertar.statuscode === 200) {
        const idResPrecalifica = insertar.idResPrecalifica;
        console.log(
          `addInfoReferenciasProductosCoAcreditado correcto. IdResPrecalifica: ${idResPrecalifica}`
        );
      } else {
        // console.log("addInfoReferenciasProductosCoAcreditado fallo");
      }
    } else {
      if (eleccionIU1.statuscode === 200) {
        let actualizar = await put(
          "updateInfoReferenciasProductosCoAcreditado",
          dataInfoReferenciasProductos
        );

        if (actualizar.statuscode === 200) {
          // console.log("updateInfoReferenciasProductosCoAcreditado correcto");
        } else {
          // console.log("updateInfoReferenciasProductosCoAcreditado fallo");
        }
      }
    }
  };

  const addReferencia = async () => {
    dataInfoReferencia = {
      idReferencia: infoReferencias.idReferencia,
      idUser: cliente?.id,
      nombre: infoReferencias.nombre,
      aPaterno: infoReferencias.aPaterno,
      aMaterno: infoReferencias.aMaterno,
      relacion: infoReferencias.relacion,
      telefono: infoReferencias.telefono,
      email: infoReferencias.email,
      idResPrecalifica: idResPrecalifica,

      idReferencia2: infoReferencias2.idReferencia,
      idUser2: cliente?.id,
      nombre2: infoReferencias2.nombre,
      aPaterno2: infoReferencias2.aPaterno,
      aMaterno2: infoReferencias2.aMaterno,
      relacion2: infoReferencias2.relacion,
      telefono2: infoReferencias2.telefono,
      email2: infoReferencias2.email,
      idResPrecalifica2: idResPrecalifica,
      escoAcreditado: true,
    };

    let eleccionIU = await get(
      "getReferenciaEsCoacreditado",
      cliente?.id
    );
    if (eleccionIU.msg === "El usuario no tiene informacion de referencias") {
      let insertar = await post(
        "addReferenciaCoAcreditado",
        dataInfoReferencia
      );

      if (insertar.statuscode === 200) {
        const idResPrecalifica = insertar.idResPrecalifica;
        // console.log(
        //   `addReferenciaCoAcreditado correcto. IdResPrecalifica: ${idResPrecalifica}`
        // );
      } else {
        // console.log("addReferenciaCoAcreditado perosnal fallo");
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        //let presendInfoLaboral = await post("addInfoLaboral", data)
        let actualizar = await put(
          "updateReferenciaCoAcreditado",
          dataInfoReferencia
        );
        if (actualizar.statuscode === 200) {
          // console.log("updateReferenciaCoAcreditado correcto");
        } else {
          // console.log("updateReferenciaCoAcreditado fallo");
        }
      }
    }
  };

  const addInfoBeneficiarios = async () => {
    let eleccionIU = await get(
      "getInfoBeneficiariosCoAcreditado",
      cliente?.id
    );
    if (eleccionIU.msg === "El usuario no tiene informacion de referencias") {
      dataInfoBeneficiarios = {
        idUser: cliente?.id,
        nombre: infoBeneficiarios.nombre,
        aPaterno: infoBeneficiarios.aPaterno,
        aMaterno: infoBeneficiarios.aMaterno,
        parentesco: infoBeneficiarios.parentesco,
        telefono: infoBeneficiarios.telefono,
        idResPrecalifica: idResPrecalifica,
        escoAcreditado: true,
      };
      let insertar = await post(
        "addInfoBeneficiariosCoAcreditado",
        dataInfoBeneficiarios
      );

      if (insertar.statuscode === 200) {
        Swal.fire({
          title: "Datos insertados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        alCompletar();
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        dataInfoBeneficiariosActualizar = {
          idBeneficiario: eleccionIU.infoReferencias[0].idBeneficiario, //-
          idResPrecalifica: idResPrecalifica,
          nombre: infoBeneficiarios.nombre,
          idUser: cliente?.id,
          aPaterno: infoBeneficiarios.aPaterno,
          aMaterno: infoBeneficiarios.aMaterno,
          parentesco: infoBeneficiarios.parentesco,
          telefono: infoBeneficiarios.telefono,
          escoAcreditado: true,
        };
        let actualizar = await put(
          "updateInfoBeneficiariosCoAcreditado",
          dataInfoBeneficiariosActualizar
        );

        if (actualizar.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } else {
          Swal.fire({
            title: "Datos actualizados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  //Formatear números a pesos
  const GetNF = (value) => {
    if (value != undefined) {
      return Number(value).toLocaleString("es-MX");
    } else {
      return "0";
    }
  };

  return (
    <div>
      <h1 className="titt">Información Personal Coacreditado</h1>
      <p>
        Asegurate de poner la información correcta. Podrás regresar a
        editarlo.
      </p>

      <form onSubmit={handleSubmit(guardarDatos)}>
        <div className="Resum">
          <div className="header">
            <h3>Personales</h3>
          </div>

          <hr />

          <div className="cont_flex cont-form formInfoGeneral">
            {/* CURP */}
            <div className="col3 campo-form ">
              <label>CURP *</label>
              <input
                type="text"
                id="curp"
                style={{ textTransform: "uppercase" }}
                name="curp"
                value={infoPersonal.curp}
                maxLength={18}
                onInput={(ev) => {
                  setValidarReglaCurp(ruleCURP(ev.target.value));
                  setInfoPersonal(() => ({
                    ...infoPersonal,
                    curp: ev.target.value,
                  }));
                }}
              />
              {/* Muestra el mensaje de error si existe */}
              {(infoPersonal.curp === "" ||
                infoPersonal.curp === " " ||
                infoPersonal.curp === undefined) && (
                <small className="span">Este campo es requerido</small>
              )}
              {validarReglaCurp === false && (
                <small className="span">No cumple con el formato</small>
              )}
            </div>

            {/* GENERO */}
            <div className="col3 campo-form estado marginEspacio label">
              <label>Género *</label>
              <select
                name="sexo"
                id="sexo"
                value={infoPersonal.sexo}
                onChange={(ev) => {
                  const isMale = ev.target.value === "true"; // Convertir a booleano
                  setInfoPersonal((prevInfo) => ({
                    ...prevInfo,
                    sexo: isMale,
                  }));
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="true">Masculino</option>
                <option value="false">Femenino</option>
              </select>
              {/* Muestra el mensaje de error si existe */}
              {(infoPersonal.sexo === "" ||
                infoPersonal.sexo === " " ||
                infoPersonal.sexo === undefined) && (
                <small className="span">Este campo es requerido</small>
              )}
            </div>

            <div className="col3 campo-form">
              <label>Número de seguridad social *</label>
              <input
                type="tel"
                id="numeroSeguroSocial"
                name="numeroSeguroSocial"
                maxLength={12}
                value={infoPersonal.numeroSeguroSocial}
                onInput={(ev) => {
                  setValidarReglaNumeroSeguridad(
                    ruleNumeroSeguridad(ev.target.value)
                  );
                  setInfoPersonal(() => ({
                    ...infoPersonal,
                    numeroSeguroSocial: ev.target.value,
                  }));
                }}
              ></input>
              <small>
                <a
                  href="https://serviciosdigitales.imss.gob.mx/gestionAsegurados-web-externo/asignacionNSS;JSESSIONIDASEGEXTERNO=QVtxJ5QWWr9Y0NGI725WkKIErOaz4Rr8OtbSIHTlf91Hl7neHlaW!806955352"
                  target="_blank" // Agregamos este atributo para abrir en una nueva pestaña
                  rel="noopener noreferrer" // Añadimos estos atributos de seguridad
                >
                  Si no lo recuerdas, dar click aquí.
                </a>
              </small>
              {(infoPersonal.numeroSeguroSocial === "" ||
                infoPersonal.numeroSeguroSocial === " " ||
                infoPersonal.numeroSeguroSocial === undefined ||
                infoPersonal.numeroSeguroSocial === null) && (
                <small className="span">Este campo es requerido</small>
              )}
              {validarReglaNumeroSeguridad === false && (
                <small className="span">No cumple con el formato</small>
              )}
            </div>

            <div className="col3 campo-form estado marginEspacio">
              <label>Estado civil *</label>
              <select
                name="estadoCivil>"
                id="estadoCivil"
                value={infoPersonal.estadoCivil}
                onChange={(ev) => {
                  setInfoPersonal(() => ({
                    ...infoPersonal,
                    estadoCivil: ev.target.value,
                  }));
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="1">Soltero</option>
                <option value="2">Unión libre</option>
                <option value="3">Sociedad de Convivencia</option>
                <option value="4">Casado (separación de Bienes)</option>
                <option value="5">Casado (Sociedad Coyugal)</option>
                <option value="6">Viudo</option>
                <option value="7">Divorciado</option>
              </select>
            </div>

            <div className="col3 campo-form estado marginEspacio">
              <label>Grado máximo de estudios *</label>
              <select
                name="gradoMaxEstudios>"
                id="gradoMaxEstudios"
                value={infoPersonal.gradoMaxEstudios}
                onChange={(ev) => {
                  setInfoPersonal(() => ({
                    ...infoPersonal,
                    gradoMaxEstudios: ev.target.value,
                  }));
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="1">Primaria</option>
                <option value="2">Secundaria</option>
                <option value="3">Preparatoria</option>
                <option value="4">Técnico</option>
                <option value="5">Licenciatura</option>
                <option value="6">Postgrado</option>
              </select>
            </div>

            <div className="col3 campo-form estado marginEspacio">
              <label>Número de dependientes económicos *</label>
              <select
                name="numDepEco>"
                id="numDepEco"
                value={infoPersonal.numDepEco}
                onChange={(ev) => {
                  setInfoPersonal(() => ({
                    ...infoPersonal,
                    numDepEco: ev.target.value,
                  }));
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6 o más</option>
              </select>
            </div>

            <div className="col3 campo-form estado marginEspacio">
              <label>¿Que tipo de vivienda habita? *</label>
              <select
                name="tipoVivienda>"
                id="tipoVivienda"
                value={infoPersonal.tipoVivienda}
                onChange={(ev) => {
                  setInfoPersonal(() => ({
                    ...infoPersonal,
                    tipoVivienda: ev.target.value,
                  }));
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="1">Propia</option>
                <option value="2">Hipotecada</option>
                <option value="3">Rentada</option>
                <option value="4">De un familiar</option>
              </select>
            </div>

            <div className="col3 campo-form estado marginEspacio">
              <label>¿Años que a vivido en dicha vivienda? *</label>
              <select
                name="aniosVivienda"
                id="aniosVivienda"
                value={infoPersonal.aniosVivienda}
                onChange={(ev) => {
                  setInfoPersonal((prevInfo) => ({
                    ...prevInfo,
                    aniosVivienda: ev.target.value,
                  }));
                }}
              >
                <option value="">Seleccione una opción.....</option>
                {/* Array para opciones */}
                {Array.from({ length: 50 }, (_, index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="Resum">
          <div className="header">
            <h3>Referencias de Productos</h3>
          </div>
          <hr />
          <div className="cont_flex cont-form">
            {/* ------------------------------------
                        Referencia de crédito 1
              -------------------------------------------*/}

            <form>
              <div className="header">
                <h3>Referencia de crédito 1</h3>
                <input
                  id="idReferenciaProducto"
                  value={infoReferenciasProductos.idReferenciaProducto}
                  onChange={(ev) => {
                    setInfoReferenciasProducto(() => ({
                      ...infoReferenciasProductos,
                      idReferenciaProducto: ev.target.value,
                    }));
                  }}
                  hidden
                />
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo cuenta *</label>
                <select
                  name="tipoCuenta"
                  id="tipoCuenta"
                  value={infoReferenciasProductos.tipoCuenta}
                  onChange={(ev) => {
                    setInfoReferenciasProducto(() => ({
                      ...infoReferenciasProductos,
                      tipoCuenta: ev.target.value,
                    }));
                  }}
                  required
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Hipoteca</option>
                  <option value="2">Crédito Auto</option>
                  <option value="3">Crédito Personal</option>
                  <option value="3">Tarjeta de Crédito</option>
                </select>
              </div>

              <div className="col3 campo-form">
                <label>Numero de Tarjeta o Crédito *</label>
                <input
                  type="number"
                  id="numeroCuenta"
                  name="numeroCuenta"
                  value={infoReferenciasProductos.numeroCuenta}
                  onInput={(ev) => {
                    setInfoReferenciasProducto(() => ({
                      ...infoReferenciasProductos,
                      numeroCuenta: ev.target.value,
                    }));
                  }}
                ></input>
              </div>

              <div className="col3 campo-form">
                <label>Institución (Banco) del Crédito *</label>
                <input
                  type="text"
                  id="institucionCredito"
                  name="institucionCredito"
                  value={infoReferenciasProductos.institucionCredito}
                  onInput={(ev) => {
                    setInfoReferenciasProducto(() => ({
                      ...infoReferenciasProductos,
                      institucionCredito: ev.target.value,
                    }));
                  }}
                ></input>
              </div>

              <div className="header">
                <h3>Referencias de producto de débito </h3>
              </div>

              <div className="col3 campo-form">
                <label>Institución (Banco) de la cuenta *</label>
                <input
                  type="text"
                  id="intitutoCapacitacion"
                  name="intitutoCapacitacion"
                  value={infoReferenciasProductos.intitutoCapacitacion}
                  onInput={(ev) => {
                    setInfoReferenciasProducto(() => ({
                      ...infoReferenciasProductos,
                      intitutoCapacitacion: ev.target.value,
                    }));
                  }}
                ></input>
              </div>

              {
                <div className="col3 campo-form estado marginEspacio">
                  <label>Tipo de cuenta de débito *</label>
                  <select
                    name="tipoCuentaCapacitacion>"
                    id="tipoCuentaCapacitacion"
                    value={infoReferenciasProductos.tipoCuentaCapacitacion}
                    onChange={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        tipoCuentaCapacitacion: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Inversiones</option>
                    <option value="2">Depósitos</option>
                  </select>
                </div>
              }

              {/* ------------------------------------
                        Referencia de crédito 2
              -------------------------------------------*/}
              <div className="header">
                <h3>Referencia de crédito 2</h3>
                <input
                  id="idReferenciaProducto2"
                  value={infoReferenciasProductos2.idReferenciaProducto}
                  onChange={(ev) => {
                    setInfoReferenciasProducto2(() => ({
                      ...infoReferenciasProductos2,
                      idReferenciaProducto: ev.target.value,
                    }));
                  }}
                  hidden
                />
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo cuenta 2</label>
                <select
                  name="tipoCuenta2>"
                  id="tipoCuenta2"
                  value={infoReferenciasProductos2.tipoCuenta}
                  onChange={(ev) => {
                    setInfoReferenciasProducto2(() => ({
                      ...infoReferenciasProductos2,
                      tipoCuenta: ev.target.value,
                    }));
                  }}
                  // required
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Hipoteca</option>
                  <option value="2">Crédito Auto</option>
                  <option value="3">Crédito Personal</option>
                  <option value="3">Tarjeta de Crédito</option>
                </select>
              </div>

              <div className="col3 campo-form">
                <label>Numero de Tarjeta o Crédito</label>
                <input
                  type="number"
                  id="numeroCuenta2"
                  name="numeroCuenta2"
                  value={infoReferenciasProductos2.numeroCuenta}
                  onInput={(ev) => {
                    setInfoReferenciasProducto2(() => ({
                      ...infoReferenciasProductos2,
                      numeroCuenta: ev.target.value,
                    }));
                  }}
                ></input>
              </div>

              <div className="col3 campo-form">
                <label>Institución (Banco) del Crédito</label>
                <input
                  type="text"
                  id="institucionCredito2"
                  name="institucionCredito2"
                  value={infoReferenciasProductos2.institucionCredito}
                  onInput={(ev) => {
                    setInfoReferenciasProducto2(() => ({
                      ...infoReferenciasProductos2,
                      institucionCredito: ev.target.value,
                    }));
                  }}
                ></input>
              </div>

              <div className="header">
                <h3>Referencias de producto de débito </h3>
              </div>

              <div className="col3 campo-form">
                <label>Institución (Banco) de la cuenta</label>
                <input
                  type="text"
                  id="intitutoCapacitacion2"
                  name="intitutoCapacitacion2"
                  value={infoReferenciasProductos2.intitutoCapacitacion}
                  onInput={(ev) => {
                    setInfoReferenciasProducto2(() => ({
                      ...infoReferenciasProductos2,
                      intitutoCapacitacion: ev.target.value,
                    }));
                  }}
                ></input>
              </div>

              {
                <div className="col3 campo-form estado marginEspacio">
                  <label>Tipo de cuenta de débito</label>
                  <select
                    name="tipoCuentaCapacitacion2>"
                    id="tipoCuentaCapacitacion2"
                    value={infoReferenciasProductos2.tipoCuentaCapacitacion}
                    onChange={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        tipoCuentaCapacitacion: ev.target.value,
                      }));
                    }}
                    // required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Inversiones</option>
                    <option value="2">Depósitos</option>
                  </select>
                </div>
              }
            </form>
          </div>
        </div>

        <div className="Resum">
          <div className="header">
            <h3>Referencias</h3>
          </div>
          <hr />
          <div className="cont_flex cont-form">
            {/* ------------------------------------
                        Referencia Familiar
              -------------------------------------------*/}

            <form>
              <div className="header">
                <h3>Referencia Familiar</h3>
                <input
                  id="idReferencia"
                  name="idReferencia"
                  value={infoReferencias.idReferencia}
                  onInput={(ev) => {
                    setInfoReferencias(() => ({
                      ...infoReferencias,
                      idReferencia: ev.target.value,
                    }));
                  }}
                  hidden
                />
              </div>
              <div className="col3 campo-form">
                <label>Nombre *</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={infoReferencias.nombre}
                  onInput={(ev) => {
                    setInfoReferencias(() => ({
                      ...infoReferencias,
                      nombre: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>Apellido Paterno *</label>
                <input
                  type="text"
                  id="aPaterno"
                  name="aPaterno"
                  value={infoReferencias.aPaterno}
                  onInput={(ev) => {
                    setInfoReferencias(() => ({
                      ...infoReferencias,
                      aPaterno: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>Apellido Materno *</label>
                <input
                  type="text"
                  id="aMaterno"
                  name="aMaterno"
                  value={infoReferencias.aMaterno}
                  onInput={(ev) => {
                    setInfoReferencias(() => ({
                      ...infoReferencias,
                      aMaterno: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>Parentesco *</label>
                <select
                  name="relacion>"
                  id="relacion"
                  value={infoReferencias.relacion}
                  onChange={(ev) => {
                    setInfoReferencias(() => ({
                      ...infoReferencias,
                      relacion: ev.target.value,
                    }));
                  }}
                  required
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Padre</option>
                  <option value="2">Madre</option>
                  <option value="3">Hijo</option>
                  <option value="4">Pareja</option>
                  <option value="5">Otro</option>
                </select>
              </div>
              <div className="col3 campo-form" id="delegacion">
                <label>Tel&eacute;fono *</label>
                <input
                  type="tel"
                  id="telefono"
                  name="telefono"
                  value={infoReferencias.telefono}
                  onInput={(ev) => {
                    setInfoReferencias(() => ({
                      ...infoReferencias,
                      telefono: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form" id="colonia">
                <label>E-mail (opcional)</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={infoReferencias.email}
                  onInput={(ev) => {
                    setInfoReferencias(() => ({
                      ...infoReferencias,
                      email: ev.target.value,
                    }));
                  }}
                ></input>
              </div>

              {/* ------------------------------------
                        Referencia No Familiar
              -------------------------------------------*/}

              <div className="header">
                <h3>Referencia No Familiar </h3>
                <input
                  id="idReferencia2"
                  name="idReferencia2"
                  value={infoReferencias2.idReferencia}
                  onInput={(ev) => {
                    setInfoReferencias2(() => ({
                      ...infoReferencias2,
                      idReferencia: ev.target.value,
                    }));
                  }}
                  hidden
                />
              </div>
              <div className="col3 campo-form">
                <label>Nombre *</label>
                <input
                  type="text"
                  id="nombre2"
                  name="nombre2"
                  value={infoReferencias2.nombre}
                  onInput={(ev) => {
                    setInfoReferencias2(() => ({
                      ...infoReferencias2,
                      nombre: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>Apellido Paterno *</label>
                <input
                  type="text"
                  id="aPaterno2"
                  name="aPaterno2"
                  value={infoReferencias2.aPaterno}
                  onInput={(ev) => {
                    setInfoReferencias2(() => ({
                      ...infoReferencias2,
                      aPaterno: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>Apellido Materno *</label>
                <input
                  type="text"
                  id="aMaterno2"
                  name="aMaterno2"
                  value={infoReferencias2.aMaterno}
                  onInput={(ev) => {
                    setInfoReferencias2(() => ({
                      ...infoReferencias2,
                      aMaterno: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>Parentesco *</label>
                <select
                  name="relacion2>"
                  id="relacion2"
                  value={infoReferencias2.relacion}
                  onChange={(ev) => {
                    setInfoReferencias2(() => ({
                      ...infoReferencias2,
                      relacion: ev.target.value,
                    }));
                  }}
                  required
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Padre</option>
                  <option value="2">Madre</option>
                  <option value="3">Hijo</option>
                  <option value="4">Pareja</option>
                  <option value="5">Otro</option>
                </select>
              </div>
              <div className="col3 campo-form" id="delegacion">
                <label>Teléfono *</label>
                <input
                  type="number"
                  id="telefono2"
                  name="telefono2"
                  value={infoReferencias2.telefono}
                  onInput={(ev) => {
                    setInfoReferencias2(() => ({
                      ...infoReferencias2,
                      telefono: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form" id="colonia">
                <label>E-mail (opcional)</label>
                <input
                  type="email"
                  id="email2"
                  name="email2"
                  value={infoReferencias2.email}
                  onInput={(ev) => {
                    setInfoReferencias2(() => ({
                      ...infoReferencias2,
                      email: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
            </form>
          </div>
        </div>

        <div className="Resum">
          <div className="header">
            <h3>Ingresos y patrimonio</h3>
          </div>
          <hr />
          <div className="cont_flex cont-form">
            <form>
              <div className="header">
                <h3>Ingresos</h3>
              </div>
              <div className="col3 campo-form">
                <label>Ingresos Mensuales Brutos *</label>
                <input
                  type="text"
                  id="sueldo"
                  name="sueldo"
                  value={"$" + GetNF(infoPersonal.sueldo)}
                  onInput={(ev) => {
                    // Elimina cualquier símbolo no numérico antes de actualizar el estado
                    const numericValue = ev.target.value.replace(
                      /[^0-9.-]/g,
                      ""
                    );
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      sueldo: numericValue,
                    }));
                  }}
                />
              </div>
              <div className="col3 campo-form">
                <label>Otros ingresos *</label>
                <input
                  type="text"
                  id="otroIngreso"
                  name="otroIngreso"
                  value={"$" + GetNF(infoPersonal.otroIngreso)}
                  onInput={(ev) => {
                    // Elimina cualquier símbolo no numérico antes de actualizar el estado
                    const numericValue = ev.target.value.replace(
                      /[^0-9.-]/g,
                      ""
                    );
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      otroIngreso: numericValue,
                    }));
                  }}
                />
              </div>
              <div className="header">
                <h3>Patrimonio</h3>
              </div>
              <div className="col3 campo-form">
                <label>Valor de Automoviles $$ *</label>
                <input
                  type="text"
                  id="autoValor"
                  name="autoValor"
                  value={"$" + GetNF(infoPersonal.autoValor)}
                  onInput={(ev) => {
                    // Elimina cualquier símbolo no numérico antes de actualizar el estado
                    const numericValue = ev.target.value.replace(
                      /[^0-9.-]/g,
                      ""
                    );
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      autoValor: numericValue,
                    }));
                  }}
                />
              </div>
              <div className="col3 campo-form">
                <label>Valor de la vivienda Actual $$ *</label>
                <input
                  type="text"
                  id="viviendoActualValor"
                  name="viviendoActualValor"
                  value={"$" + GetNF(infoPersonal.viviendoActualValor)}
                  onInput={(ev) => {
                    // Elimina cualquier símbolo no numérico antes de actualizar el estado
                    const numericValue = ev.target.value.replace(
                      /[^0-9.-]/g,
                      ""
                    );
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      viviendoActualValor: numericValue,
                    }));
                  }}
                />
              </div>
            </form>
          </div>
        </div>

        {/* ------------Beneficiario-------------*/}

        <div className="Resum">
          <div className="header">
            <h3>Beneficiario</h3>
          </div>
          <hr />
          <div className="cont_flex cont-form">
            <form>
              <div className="col3 campo-form">
                <label>Nombre *</label>
                <input
                  type="text"
                  id="nombre"
                  name="nombre"
                  value={infoBeneficiarios.nombre}
                  onInput={(ev) => {
                    setInfoBeneficiarios(() => ({
                      ...infoBeneficiarios,
                      nombre: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>Apellido Paterno *</label>
                <input
                  type="text"
                  id="aPaterno"
                  name="aPaterno"
                  value={infoBeneficiarios.aPaterno}
                  onInput={(ev) => {
                    setInfoBeneficiarios(() => ({
                      ...infoBeneficiarios,
                      aPaterno: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>Apellido materno *</label>
                <input
                  type="text"
                  id="aMaterno"
                  name="aMaterno"
                  value={infoBeneficiarios.aMaterno}
                  onInput={(ev) => {
                    setInfoBeneficiarios(() => ({
                      ...infoBeneficiarios,
                      aMaterno: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>Parentesco *</label>
                <select
                  name="parentesco>"
                  id="parentesco"
                  value={infoBeneficiarios.parentesco}
                  onChange={(ev) => {
                    setInfoBeneficiarios(() => ({
                      ...infoBeneficiarios,
                      parentesco: ev.target.value,
                    }));
                  }}
                  required
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Padre</option>
                  <option value="2">Madre</option>
                  <option value="3">Hijo</option>
                  <option value="4">Pareja</option>
                  <option value="5">Otro</option>
                </select>
              </div>
              <div className="col3 campo-form">
                <label>Teléfono *</label>
                <input
                  type="tel"
                  id="telefono"
                  name="telefono"
                  value={infoBeneficiarios.telefono}
                  onInput={(ev) => {
                    setInfoBeneficiarios(() => ({
                      ...infoBeneficiarios,
                      telefono: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
            </form>
          </div>
        </div>

        <div className="cont_flex cont-form">
          <div className="dosCuttonFormularios">
            <button className="btn btn_morado col3 campo-form" type="submit">
              Guardar
            </button>
          </div>
        </div>

        <div className="header">
          <h3>
            Si realizaste algun cambio, recuerda <br />{" "}
            <span className="morado"> guardar </span> antes de salir.
          </h3>
        </div>
      </form>
    </div>
  );
}

export function InfoLaboralCoAcreditado({ cliente, alCompletar = () => {} }) {
  // Estado para almacenar la información laboral.
  const [infoLaboral, setInfoLaboral] = useState({});
  // Estados para validar reglas de teléfono y fecha de nacimiento.
  const [validarReglaTelefono, setValidarReglaTelefono] = useState();
  const [validarReglaNacimiento, setValidarReglaNacimento] = useState();
  const [validarLongitudCP, setValidarLongitudCP] = useState();
  const [estadoDisabled, setEstadoDisabled] = useState(true);
  const [delegacionDisabled, setDelegacionDisabled] = useState(true);
  const [listColonias, setListColonias] = useState();

  // Estados para almacenar información de dirección.
  const [direccion, setDireccion] = useState("");
  const [exterior, setExterior] = useState("");
  const [interior, setInterior] = useState("");
  const [estado, setEstado] = useState("");
  const [estadoAux, setEstadoAux] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [colonia, setColonia] = useState("");
  const [cp, setCp] = useState("");
  const [error, setError] = useState(null);

  //Para almacenar informacion del empleo
  const [nombreEmpresa, setNombreEmpresa] = useState("");
  const [puesto, setPuesto] = useState("");
  const [ocupacion, setOcupacion] = useState("");
  const [tipoEmpleo, setTipoEmpleo] = useState("");
  const [tipoContrato, setTipoContrato] = useState("");
  const [telefono, setTelefono] = useState("");
  const [fechaIngreso, setFechaIngreso] = useState("");
  const [giroEmpresa, setGiroEmpresa] = useState("");

  //Para almacenar el empleo anterior
  const [nombreEmpresaAnt, setNombreEmpresaAnt] = useState("");
  const [puestoAnt, setPuestoAnt] = useState("");
  const [ocupacionAnt, setOcupacionAnt] = useState("");
  const [tipoEmpleoAnt, setTipoEmpleoAnt] = useState("");
  const [telefonoAnt, setTelefonoAnt] = useState("");
  const [fechaIngresoAnt, setFechaIngresoAnt] = useState("");
  const [fechaSalidaAnt, setFechaSalidaAnt] = useState("");

  const [idResPrecalifica, setIdResPrecalifica] = useState({});

  // Utiliza useForm del hook react-hook-form para gestionar el estado del formulario.
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  //Para obtener informacion general del usuario
  const obtInfoCuestionario = async () => {
    let idCuestionario = await get("getInfoCalifica", cliente?.id);
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  // Función para dar formato a una entrada de fecha.
  const DateInput = (event, tipo) => {
    let input = event;

    // Remover las diagonales existentes
    input = input.replace(/\D/g, "");

    // Insertar las diagonales en la posición correcta
    if (input.length > 2 && input.length < 5) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    } else if (input.length > 4) {
      input =
        input.slice(0, 2) + "/" + input.slice(2, 4) + "/" + input.slice(4, 8);
    }

    const regex = /^\d{0,2}(\/\d{0,2}(\/\d{0,4})?)?$/;

    if (regex.test(input)) {
      // console.log("El formato de fecha es válido.");
      // Realizar acciones adicionales si la fecha es válida
    } else {
      // console.log("El formato de fecha no es válido.");
      // Realizar acciones adicionales si la fecha no es válida
    }

    setInfoLaboral(() => ({ ...infoLaboral, fechaIngreso: input }));
    if (tipo === 1) {
      setFechaIngreso(input);
    } else if (tipo === 2) {
      setFechaIngresoAnt(input);
    } else if (tipo === 3) {
      setFechaSalidaAnt(input);
    }
    setValidarReglaNacimento(ruleNacimiento(input));
  };

  // Función para obtener datos de código postal.
  const obtenerDataCp = async (cp) => {
    try {
      let respuestaPorCP = await getCP("info_cp", cp);
      // console.log(respuestaPorCP);
      setError(false);
      obtenerClaveIso(respuestaPorCP.response.estado);
      setEstadoAux(respuestaPorCP.response.estado);
      setMunicipio(respuestaPorCP.response.municipio);
      listColoniasCP(respuestaPorCP.response.asentamiento);
      setEstadoDisabled(true);
      setDelegacionDisabled(true);
    } catch (error) {
      // Habilitar los inputs en caso de error
      setEstadoDisabled(false);
      setDelegacionDisabled(false);
      setColonia(""); // Esto borrará el contenido del campo "colonia"
      setEstadoAux("");
      setMunicipio("");
      setError(true);
    }
  };

  // Función para obtener la clave ISO del estado.
  const obtenerClaveIso = async (estadoCP) => {
    let obtenerClave = await get("obtClaveISOPorEstado", estadoCP);
    setEstadoAux(estadoCP);
    if (obtenerClave.statuscode === 200) {
      setEstado(obtenerClave.iso);
    }
  };

  // Función para listar colonias.
  const listColoniasCP = (colonias) => {
    let opcionDefault = (
      <option value="" disabled>
        Seleccione una opción...{" "}
      </option>
    );

    colonias = colonias.map((colonia) => (
      <option value={colonia} key={colonia}>
        {colonia}
      </option>
    ));
    colonias.unshift(opcionDefault);

    setListColonias(colonias);
  };

  //Obtenemos la informacion laboral
  //Si es que existe
  const obtInfoLaboral = async () => {
    let data = await get("getInfoLaboralCoAcreditado", cliente?.id);
    //console.log(data);
    if (data.statuscode === 200) {
      //* Si la respuesta contiene una fecha de ingreso (fechaIngreso), la función convierte
      //* esa fecha en un formato específico (aaaa-mm-dd) y luego en otro formato (dd/mm/aaaa)
      //* antes de almacenarla nuevamente en el objeto data.infoLaboral[0].fechaIngreso.
      if (data.infoLaboral[0].fechaIngreso) {
        let fechaIngreso = new Date(data.infoLaboral[0].fechaIngreso);

        if (fechaIngreso.getMonth() + 1 < 10 && fechaIngreso.getDate() < 10) {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-0" +
            (fechaIngreso.getMonth() + 1) +
            "-0" +
            fechaIngreso.getDate();
        } else if (fechaIngreso.getDate() < 10) {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-" +
            (fechaIngreso.getMonth() + 1) +
            "-0" +
            fechaIngreso.getDate();
        } else if (fechaIngreso.getMonth() + 1 < 10) {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-0" +
            (fechaIngreso.getMonth() + 1) +
            "-" +
            fechaIngreso.getDate();
        } else {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-" +
            (fechaIngreso.getMonth() + 1) +
            "-" +
            fechaIngreso.getDate();
        }
        let fechaIN =
          data.infoLaboral[0].fechaIngreso.substr(8, 2) +
          "/" +
          data.infoLaboral[0].fechaIngreso.substr(5, 2) +
          "/" +
          data.infoLaboral[0].fechaIngreso.substr(0, 4);
        data.infoLaboral[0].fechaIngreso = fechaIN;
        setFechaIngreso(fechaIN);
        setValue("fechaIngreso", fechaIN);
      }

      if (data.infoLaboral[0].fechaIngresoAnt) {
        let fechaIngresoAnt = new Date(data.infoLaboral[0].fechaIngresoAnt);

        if (
          fechaIngresoAnt.getMonth() + 1 < 10 &&
          fechaIngresoAnt.getDate() < 10
        ) {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-0" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-0" +
            fechaIngresoAnt.getDate();
        } else if (fechaIngresoAnt.getDate() < 10) {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-0" +
            fechaIngresoAnt.getDate();
        } else if (fechaIngresoAnt.getMonth() + 1 < 10) {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-0" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-" +
            fechaIngresoAnt.getDate();
        } else {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-" +
            fechaIngresoAnt.getDate();
        }

        let fechaIN =
          data.infoLaboral[0].fechaIngresoAnt.substr(8, 2) +
          "/" +
          data.infoLaboral[0].fechaIngresoAnt.substr(5, 2) +
          "/" +
          data.infoLaboral[0].fechaIngresoAnt.substr(0, 4);
        data.infoLaboral[0].fechaIngresoAnt = fechaIN;
        setFechaIngresoAnt(fechaIN);
        setValue("fechaIngresoAnt", fechaIN);
      }

      if (data.infoLaboral[0].fechaSalidaAnt) {
        let fechaSalidaAnt = new Date(data.infoLaboral[0].fechaSalidaAnt);

        if (
          fechaSalidaAnt.getMonth() + 1 < 10 &&
          fechaSalidaAnt.getDate() < 10
        ) {
          data.infoLaboral[0].fechaSalidaAnt =
            fechaSalidaAnt.getFullYear() +
            "-0" +
            (fechaSalidaAnt.getMonth() + 1) +
            "-0" +
            fechaSalidaAnt.getDate();
        } else if (fechaSalidaAnt.getDate() < 10) {
          data.infoLaboral[0].fechaSalidaAnt =
            fechaSalidaAnt.getFullYear() +
            "-" +
            (fechaSalidaAnt.getMonth() + 1) +
            "-0" +
            fechaSalidaAnt.getDate();
        } else if (fechaSalidaAnt.getMonth() + 1 < 10) {
          data.infoLaboral[0].fechaSalidaAnt =
            fechaSalidaAnt.getFullYear() +
            "-0" +
            (fechaSalidaAnt.getMonth() + 1) +
            "-" +
            fechaSalidaAnt.getDate();
        } else {
          data.infoLaboral[0].fechaSalidaAnt =
            fechaSalidaAnt.getFullYear() +
            "-" +
            (fechaSalidaAnt.getMonth() + 1) +
            "-" +
            fechaSalidaAnt.getDate();
        }

        let fechaIN =
          data.infoLaboral[0].fechaSalidaAnt.substr(8, 2) +
          "/" +
          data.infoLaboral[0].fechaSalidaAnt.substr(5, 2) +
          "/" +
          data.infoLaboral[0].fechaSalidaAnt.substr(0, 4);
        data.infoLaboral[0].fechaSalidaAnt = fechaIN;
        setFechaSalidaAnt(fechaIN);
        setValue("fechaSalidaAnt", fechaIN);
      }

      //* Establece varios estados del componente (setEstado, setMunicipio, setColonia, setCp,
      //* setDireccion, setExterior, setInterior) con los valores correspondientes de la
      //* información laboral obtenida.
      setEstadoAux(data.infoLaboral[0].municipio);
      setValue("estado", data.infoLaboral[0].estado);

      setMunicipio(data.infoLaboral[0].municipio);
      setValue("municipio", data.infoLaboral[0].municipio);

      setError(true);
      setColonia(data.infoLaboral[0].colonia);
      setValue("colonia", data.infoLaboral[0].colonia);

      setCp(data.infoLaboral[0].cp);
      setValue("cp", data.infoLaboral[0].cp, ruleCP);

      setDireccion(data.infoLaboral[0].calle);
      setValue("direccion", data.infoLaboral[0].calle);

      setExterior(data.infoLaboral[0].numExt);
      setValue("numExt", data.infoLaboral[0].numExt);

      setInterior(data.infoLaboral[0].numInt);
      setValue("numInt", data.infoLaboral[0].numInt);

      setNombreEmpresa(data.infoLaboral[0].nombreEmpresa);
      setValue("nombreEmpresa", data.infoLaboral[0].nombreEmpresa);

      setPuesto(data.infoLaboral[0].puesto);
      setValue("puesto", data.infoLaboral[0].puesto);

      setOcupacion(data.infoLaboral[0].ocupacion);
      setValue("ocupacion", data.infoLaboral[0].ocupacion);

      setTipoEmpleo(data.infoLaboral[0].tipoEmpleo);
      setValue("tipoEmpleo", data.infoLaboral[0].tipoEmpleo);

      setTipoContrato(data.infoLaboral[0].tipoContrato);
      setValue("tipoContrato", data.infoLaboral[0].tipoContrato);

      setTelefono(data.infoLaboral[0].telefono);
      setValue("telefono", data.infoLaboral[0].telefono);

      setGiroEmpresa(data.infoLaboral[0].giro);
      setValue("giroEmpresa", data.infoLaboral[0].giro);

      setNombreEmpresaAnt(data.infoLaboral[0].nombreEmpresaAnt);
      setPuestoAnt(data.infoLaboral[0].puestoAnt);
      setOcupacionAnt(data.infoLaboral[0].ocupacionAnt);
      setTipoEmpleoAnt(data.infoLaboral[0].tipoEmpleoAnt);
      setTelefonoAnt(data.infoLaboral[0].telefonoAnt);

      //* Utiliza setInfoLaboral para actualizar el estado del componente. Aquí se usa el
      //* operador spread (...) para mezclar los datos anteriores de infoLaboral con los
      //* nuevos datos de data.infoLaboral[0]. Esto se hace para actualizar el estado del
      //* componente con la información laboral completa.
      setInfoLaboral(() => ({
        ...infoLaboral,
        ...data.infoLaboral[0],
      }));
    } else {
      console.log("Sin informacion guardada previamente");
    }
  };

  const onSubmit = (data) => {
    //console.log(data); // Aquí puedes hacer algo con los datos del formulario
    guardarInformacionLaboral();
  };

  function formatearFechaGuion(fecha){
    const fechaArray = fecha.split("/");
    return `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`;
  }

  const guardarInformacionLaboral = async () => {

    let dataInfoLaboral = {
      idUser: cliente?.id,
      nombreEmpresa: nombreEmpresa,
      puesto: puesto,
      telefono: telefono,
      fechaIngreso: formatearFechaGuion(fechaIngreso),
      giro: giroEmpresa,
      calle: direccion,
      numExt: exterior,
      numInt: interior,
      cp: cp,
      municipio: municipio,
      colonia: colonia,
      nombreEmpresaAnt: nombreEmpresaAnt,
      puestoAnt: puestoAnt,
      telefonoAnt: telefonoAnt,
      idResPrecalifica: idResPrecalifica,
      estado: estadoAux,
      ocupacion: ocupacion,
      tipoEmpleoAnt: tipoEmpleoAnt,
      ocupacionAnt: ocupacionAnt,
      tipoEmpleo: tipoEmpleo,
      tipoContrato: tipoContrato,
      escoAcreditado: true,
    };

    if (fechaIngresoAnt !== null && fechaIngresoAnt !== ""){
      dataInfoLaboral["fechaIngresoAnt"] = formatearFechaGuion(fechaIngresoAnt);
    }

    if (fechaSalidaAnt !== null && fechaSalidaAnt !== ""){
      dataInfoLaboral["fechaSalidaAnt"] = formatearFechaGuion(fechaSalidaAnt);
    }

    let consultarInfo = await get(
      "getInfoLaboralCoAcreditado",
      cliente?.id
    );
    if (consultarInfo.msg === "El usuario no tiene informacion laboral") {
      let guardarInfoLaboral = await post(
        "addInfoLaboralCoAcreditado",
        dataInfoLaboral
      );
      if (guardarInfoLaboral.statuscode === 200) {
        alCompletar();
        Swal.fire({
          title: "Datos guardados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (consultarInfo.statuscode === 200) {
        let actInfoLaboral = await put("updateInfoLaboral", dataInfoLaboral);

        if (actInfoLaboral.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } else {
          Swal.fire({
            title: "Datos ingresados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  useEffect(() => {
    obtInfoLaboral();
    obtInfoCuestionario();
  }, []);

  return (
    <div>
      <h1 className="titt">Información Laboral del Coacreditado​</h1>
      <p>
        Asegurate de poner la información correcta. <br /> Podrás regresar a
        editarlo.
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="Resum">
          <div className="header">
            <h3>Empleo actual</h3>
          </div>
          <div className="cont_flex cont-form formInfoGeneral">
            {/* NOMBRE */}
            <div className="col3 campo-form ">
              <label>Nombre de la empresa</label>
              <input
                type="text"
                {...register("nombreEmpresa", { required: true })}
                autoComplete="off"
                id="nombreEmpresa"
                name="nombreEmpresa"
                value={nombreEmpresa || ""}
                onInput={(ev) => {
                  setNombreEmpresa(
                    ev.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
              {errors.nombreEmpresa?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/* Puesto*/}
            <div className="col3 campo-form">
              <label>Puesto</label>
              <input
                type="text"
                {...register("puesto", { required: true })}
                id="puesto"
                name="puesto"
                value={puesto || ""}
                onInput={(ev) => {
                  setPuesto(
                    ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
              {errors.puesto?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*Ocupación */}
            <div className="col3 campo-form">
              <label>Ocupación</label>
              <input
                type="text"
                {...register("ocupacion", { required: true })}
                id="ocupacion"
                name="ocupacion"
                value={ocupacion || ""}
                onInput={(ev) => {
                  setOcupacion(
                    ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
              {errors.ocupacion?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/* Tipo de empleo*/}
            <div className="col3 campo-form estado marginEspacio">
              <label>Tipo de empleo</label>
              <select
                {...register("tipoEmpleo", { required: true })}
                id="tipoEmpleo"
                name="tipoEmpleo"
                value={tipoEmpleo || ""}
                onChange={(ev) => {
                  setTipoEmpleo(ev.target.value);
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="1">Empleado</option>
                <option value="2">Negocio propio</option>
                <option value="3">Otro</option>
              </select>
              {errors.tipoEmpleo?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <div className="col3 campo-form estado marginEspacio">
              <label>Tipo de contrato</label>
              <select
                {...register("tipoContrato", { required: true })}
                id="tipoContrato"
                name="tipoContrato"
                value={tipoContrato || ""}
                onChange={(ev) => {
                  setTipoContrato(ev.target.value);
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="1">Definitivo (no temporal)</option>
                <option value="2">Temporal</option>
              </select>
              {errors.tipoContrato?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/* Teléfono*/}
            <div className="col3 campo-form">
              <label>Teléfono</label>
              <input
                type="tel"
                {...register("telefono", { required: true })}
                id="telefono"
                name="telefono"
                size="14"
                maxLength={10}
                placeholder="Teléfono a 10 dígitos"
                autoComplete="off"
                value={telefono || ""}
                onInput={(ev) => {
                  setValidarReglaTelefono(ruleTelefono(ev.target.value));
                  setTelefono(() => ev.target.value);
                }}
              />
              {errors.telefono?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
              {validarReglaTelefono === false && (
                <small className="span"> Ingresar solo números</small>
              )}
            </div>

            {/* Fecha de ingreso*/}
            <div className="col3 campo-form">
              <label>Fecha de ingreso</label>
              <input
                type="text"
                {...register("fechaIngreso", { required: true })}
                id="fechaIngreso"
                name="fechaIngreso"
                value={fechaIngreso || ""}
                placeholder="dd/mm/aaaa"
                onInput={(ev) => {
                  DateInput(ev.target.value, 1);
                }}
              />
              {validarReglaNacimiento === false && (
                <small className="span">
                  {" "}
                  Ingresar en el siguiente formato dd/mm/aaaa{" "}
                </small>
              )}
              {errors.fechaIngreso?.type === "required" && (
                <small className="span">Este campo es requerido</small>
              )}
            </div>

            {/* Giro de la empresa*/}
            <div className="col3 campo-form estado marginEspacio">
              <label>Giro de la empresa</label>
              <select
                {...register("giroEmpresa", { required: true })}
                id="giroEmpresa"
                name="giroEmpresa"
                value={giroEmpresa || ""}
                onInput={(ev) => {
                  setGiroEmpresa(ev.target.value);
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="1">Servicios</option>
                <option value="2">Construcción</option>
                <option value="3">Industrial</option>
                <option value="4">Agricultura / Pesca</option>
                <option value="5">Otro</option>
              </select>
              {errors.giroEmpresa?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
          </div>
        </div>

        <div className="Resum">
          <div className="header">
            <h3>Dirección del empleo</h3>
          </div>
          <div className="cont_flex cont-form formInfoGeneral">
            {/* Código postal */}
            <div className="col3 campo-form ">
              <label htmlFor="cp">Código postal</label>
              <input
                type="tel"
                id="cp"
                name="cp"
                size={5}
                maxLength="5"
                autoComplete="off"
                value={cp}
                {...register("cp", { required: true, validate: ruleCP })}
                onInput={(ev) => {
                  let tempCP = ev.target.value;
                  if (isNaN(tempCP) === false) {
                    if (tempCP.length < 5) {
                      setValidarLongitudCP(false);
                      setCp(tempCP);
                    } else {
                      if (tempCP.length === 5) {
                        obtenerDataCp(ev.target.value);
                        setCp(tempCP);
                        setValidarLongitudCP(true);
                      }
                    }
                  }
                }}
              />
              <small>
                Al ingresar su código postal correcto, en automático se
                registrará su estado y municipio.
              </small>
              {errors.cp?.type === "required" && (
                <span className="span">
                  {" "}
                  <br />
                  Este campo es requerdio{" "}
                </span>
              )}
              {validarLongitudCP === false && (
                <span className="span"> Ingresa 5 dígitos</span>
              )}
            </div>

            {/* Direccion*/}
            <div className="col3 campo-form">
              <label>Dirección</label>
              <input
                type="text"
                {...register("direccion", { required: true })}
                autoComplete="off"
                id="direccion"
                name="direccion"
                value={direccion || ""}
                onInput={(ev) => {
                  setDireccion(
                    ev.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
              {errors.direccion?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*Número Ext */}
            <div className="col3 campo-form">
              <label>Número Ext</label>
              <input
                type="tel"
                name="numExt"
                id="numExt"
                autoComplete="off"
                value={exterior || ""}
                onInput={(ev) => {
                  setExterior(ev.target.value);
                }}
              />
            </div>

            {/* Número Int */}
            <div className="col3 campo-form">
              <label>Número Int</label>
              <input
                type="tel"
                name="numInt"
                id="numInt"
                max={20}
                autoComplete="off"
                value={interior || ""}
                onInput={(ev) => {
                  setInterior(ev.target.value);
                }}
              />
            </div>

            {/* Estado*/}
            <div className="col3 campo-form estado">
              <label htmlFor="estado">Estado</label>
              <input
                type="text"
                name="estado"
                id="estado"
                disabled={estadoDisabled}
                value={estadoAux}
                onInput={(ev) => {
                  setEstadoAux(ev.target.value);
                }}
              />
            </div>

            {/* Delegación / Municipio*/}
            <div className="col3 campo-form colonia">
              <label htmlFor="municipio">Delegación / Municipio</label>
              <input
                name="municipio"
                id="municipio"
                disabled={delegacionDisabled}
                value={municipio}
                onInput={(ev) => {
                  setMunicipio(ev.target.value);
                }}
              ></input>
            </div>

            {/* Colonia*/}
            <div className="col3 campo-form colonia">
              <label htmlFor="coloniaSelect">Colonia</label>
              {error ? (
                <input
                  type="text"
                  id="colonia"
                  value={colonia}
                  name="colonia"
                  onChange={(ev) => {
                    setColonia(ev.target.value);
                  }}
                />
              ) : (
                <select
                  type="text"
                  id="colonia"
                  value={colonia}
                  name="colonia"
                  onChange={(ev) => {
                    setColonia(ev.target.value);
                  }}
                >
                  {listColonias}
                </select>
              )}
            </div>
          </div>
        </div>

        {/*Campos opcionales no aplicar validaciones */}
        <div className="Resum">
          <div className="header">
            <h3>Empleo anterior (Opcional)</h3>
          </div>
          <div className="cont_flex cont-form">
            <div className="col3 campo-form">
              <label>Nombre de la empresa</label>
              <input
                type="text"
                id="nombreEmpresaAnt"
                name="nombreEmpresaAnt"
                value={nombreEmpresaAnt || ""}
                onInput={(ev) => {
                  setNombreEmpresaAnt(
                    ev.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
            </div>

            <div className="col3 campo-form">
              <label>Puesto</label>
              <input
                type="text"
                id="puestoAnt"
                name="puestoAnt"
                value={puestoAnt || ""}
                onInput={(ev) => {
                  setPuestoAnt(
                    ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
            </div>

            <div className="col3 campo-form">
              <label>Ocupación</label>
              <input
                type="text"
                id="ocupacionAnt"
                name="ocupacionAnt"
                value={ocupacionAnt || ""}
                onInput={(ev) => {
                  setOcupacionAnt(
                    ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              />
            </div>

            <div className="col3 campo-form estado marginEspacio">
              <label>Tipo de empleo</label>
              <select
                id="tipoEmpleoAnt"
                name="tipoEmpleoAnt"
                value={tipoEmpleoAnt || ""}
                onInput={(ev) => {
                  setTipoEmpleoAnt(ev.target.value);
                }}
              >
                <option value="">Seleccione una opción.....</option>
                <option value="1">Empleado</option>
                <option value="2">Negocio propio</option>
                <option value="3">Otro</option>
              </select>
            </div>

            <div className="col3 campo-form">
              <label>Teléfono</label>
              <input
                type="tel"
                size="14"
                maxLength={10}
                name="telefonoAnt"
                id="telefonoAnt"
                placeholder="Teléfono a 10 dígitos"
                autoComplete="off"
                value={telefonoAnt || ""}
                onInput={(ev) => {
                  setValidarReglaTelefono(ruleTelefono(ev.target.value));
                  setTelefonoAnt(() => ev.target.value);
                }}
              />
            </div>

            <div className="col3 campo-form">
              <label>Fecha de ingreso</label>
              <input
                type="text"
                value={fechaIngresoAnt || ""}
                name="fechaIngresoAnt"
                id="fechaIngresoAnt"
                onInput={(ev) => {
                  DateInput(ev.target.value, 2);
                }}
              />
              {validarReglaNacimiento === false && (
                <small className="span">
                  {" "}
                  Ingresar en el siguiente formato dd/mm/aaaa <br />
                </small>
              )}
            </div>

            <div className="col3 campo-form">
              <label>Fecha de salida</label>
              <input
                type="text"
                value={fechaSalidaAnt || ""}
                name="fechaSalidaAnt"
                id="fechaSalidaAnt"
                onInput={(ev) => {
                  DateInput(ev.target.value, 3);
                }}
              />
              {validarReglaNacimiento === false && (
                <small className="span">
                  {" "}
                  Ingresar en el siguiente formato dd/mm/aaaa <br />
                </small>
              )}
            </div>
          </div>
        </div>

        <div className="cont_flex cont-form">
          <div className="dosCuttonFormularios">
            <button type="submit" className="btn btn_morado campo-form">
              Guardar
            </button>
          </div>
        </div>
        <div className="header">
          <h3>
            Si realizaste algun cambio, recuerda <br />{" "}
            <span className="morado"> guardar </span> antes de salir.
          </h3>
        </div>
      </form>
    </div>
  );
}

export function InfoMedicaCoAcreditado({ cliente, alCompletar = () => {} }) {

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [peso, setPeso] = useState("");
  const [estatura, setEstatura] = useState("");
  const [consumoAlcohol, setConsumoAlcohol] = useState("No");
  const [consumoDrogas, setConsumoDrogas] = useState("No");
  const [consumoTabaco, setConsumoTabaco] = useState("No");
  const [deporteAltoRiezgo, setDeporteAltoRiezgo] = useState("No");
  const [tieneEnfermedad, setTieneEnfermedad] = useState("No");
  const [enfermedadDescripcion, setEnfermedadDescripcion] = useState("");
  const [descripcionDeshabilitada, setDescripcionDeshabilitada] = useState(true);

  const [idResPrecalifica, setIdResPrecalifica] = useState({});

  //Para detectar cambios en radios y guardarlos
  const handleRadioChangeDeporteAltoRiezgo = (event) => {
    setDeporteAltoRiezgo(event.target.value);
  };

  const handleRadioChangeAlchol = (event) => {
    setConsumoAlcohol(event.target.value);
  };

  const handleRadioChangeDrogas = (event) => {
    setConsumoDrogas(event.target.value);
  };

  const handleRadioChangeTabaco = (event) => {
    setConsumoTabaco(event.target.value);
  };

  const handleRadioChangeTieneEnfermedad = (event) => {
    setTieneEnfermedad(event.target.value);
    if (event.target.value === "false") {
      setEnfermedadDescripcion("");
    }
  };

  //Para obtener informacion general del usuario
  const obtInfoCuestionario = async () => {
    let idCuestionario = await get("getInfoCalifica", cliente?.id);
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const obtInformacionMedica = async () => {
    let dataMedica = await get("getInfoMedicaCoAcreditado", cliente?.id);
    if (dataMedica.statuscode === 200) {
      // console.log(dataMedica);

      //* Establecemos todos los campos para mostrarlos y que las validaciones no fallen
      setPeso(dataMedica.infoMedica[0].peso);
      setValue("peso", dataMedica.infoMedica[0].peso);

      setEstatura(dataMedica.infoMedica[0].estatura);
      setValue("estatura", dataMedica.infoMedica[0].estatura);

      setDeporteAltoRiezgo(dataMedica.infoMedica[0].deportesAr === "0" ? "false" : "true");
      setValue("deporteAltoRiezgo", dataMedica.infoMedica[0].deportesAr === "0" ? "false" : "true");

      //TODO: agregar a la bd
      //!de momento usamos los campos de diabetes
      setConsumoAlcohol(dataMedica.infoMedica[0].diabetis.toString());
      setValue("consumoAlcohol", dataMedica.infoMedica[0].diabetis.toString());

      //TODO: agregar a la bd drogas
      //!de momento usamos los campos de cancer
      setConsumoDrogas(dataMedica.infoMedica[0].cancer.toString());
      setValue("consumoDrogas", dataMedica.infoMedica[0].cancer.toString());

      setConsumoTabaco(dataMedica.infoMedica[0].consumoCigarro.toString());
      setValue(
        "ConsumoTabaco",
        dataMedica.infoMedica[0].consumoCigarro.toString()
      );

      setTieneEnfermedad(dataMedica.infoMedica[0].tieneEnfermedad === 0 ? "false" : "true");
      setValue("tieneEnfermedad", dataMedica.infoMedica[0].tieneEnfermedad === 0 ? "false" : "true");

      setEnfermedadDescripcion(dataMedica.infoMedica[0].enfermedadDescripcion);
      setValue(
        "enfermedadDescripcion",
        dataMedica.infoMedica[0].enfermedadDescripcion
      );
    } else {
      console.log("no hay información medica");
    }
  };

  const guardarInformacionMedica = async () => {
    //TODO: Falta campo consumo alcohol
    //TODO: Falta campo consumo drogas
    let dataMedica = {
      idUser: parseInt(cliente?.id),
      estadoSalud: "string",
      tratamientoMedico: "string",
      enfermedadesCv: false,
      diabetis: consumoAlcohol === "true" ? true : false, //Nota: debido a falta de tiempo estos campos quedan para guardar alcohol pero hay que crear su propio campo
      cancer: consumoDrogas === "true" ? true : false, //Nota: debido a falta de tiempo estos campos quedan para guardar drogas pero hay que crear su propio campo
      deportesAr: deporteAltoRiezgo === "true" ? "1" : "0",
      ocupacionAr: "",
      consumoCigarro: consumoTabaco === "true" ? true : false,
      peso: parseFloat(peso),
      estatura: parseFloat(estatura),
      idResPrecalifica: parseInt(idResPrecalifica),
      tieneEnfermedad: tieneEnfermedad === "true" ? 1 : 0,
      enfermedadDescripcion: enfermedadDescripcion,
      escoAcreditado: true,
    };
    //Consulamos si ya hay información previamente guardada
    let eleccionIU = await get("getInfoMedicaCoAcreditado", cliente?.id);

    //Insertar
    if (eleccionIU.msg === "El usuario no tiene informacion medica") {
      let guardarInfoMedica = await post("addInfoMedicaCoAcredito", dataMedica);
      if (guardarInfoMedica.statuscode === 200) {
        alCompletar();
        Swal.fire({
          title: "Datos guardados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        let actualizar = await put("updateInfoMedica", dataMedica);

        if (actualizar.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } else {
          Swal.fire({
            title: "Datos ingresados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  useEffect(() => {
    obtInfoCuestionario();
    obtInformacionMedica();
  }, []);

  useEffect(() => {
    if (tieneEnfermedad === "true" || 
      deporteAltoRiezgo === "true" ||
      consumoAlcohol === "true" ||
      consumoDrogas === "true" ||
      consumoTabaco === "true"){
      setDescripcionDeshabilitada(false);
    }else{
      setEnfermedadDescripcion("");
      setDescripcionDeshabilitada(true);
    }
  }, [deporteAltoRiezgo, consumoAlcohol, consumoDrogas, consumoTabaco, tieneEnfermedad])

  const onSubmit = (data) => {
    guardarInformacionMedica();
  };

  return (
    <div>
      <h1 className="titt">Información Médica del Coacreditado</h1>
      <p>
        Asegurate de poner la información correcta. Podrás regresar a
        editarlo.
      </p>

      <div className="Resum">
        <div className="header">
          <h3></h3>
        </div>

        <div className="cont_flex cont-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/*Peso */}
            <div className="col3 campo-form">
              <label>¿Peso? (kg)</label>
              <input
                type="number"
                {...register("peso", { required: true })}
                id="peso"
                name="peso"
                value={peso || ""}
                size="14"
                maxLength={10}
                autoComplete="off"
                onInput={(ev) => {
                  setPeso(ev.target.value);
                }}
              />
              {errors.peso?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*estatura */}
            <div className="col3 campo-form">
              <label>¿Estatura? (mt)</label>
              <input
                type="number"
                {...register("estatura", { required: true })}
                id="estatura"
                name="estatura"
                value={estatura}
                onInput={(ev) => {
                  setEstatura(ev.target.value);
                }}
              />
              {errors.estatura?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*Deportes alto riesgo */}
            <div className="col3 campo-form">
              <label>¿Deportes de alto riesgo?</label>

              <div className="form-check form-check-inline">
                <div className="opcionesRadios">
                  <input
                    className="form-check-input"
                    {...register("deporteAltoRiezgo", { required: true })}
                    type="radio"
                    name="deporteAltoRiezgo"
                    id="inlineRadio1_deporteAltoRiezgo"
                    value={true}
                    checked={deporteAltoRiezgo === "true"}
                    onChange={handleRadioChangeDeporteAltoRiezgo}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio1_deporteAltoRiezgo"
                  >
                    Si
                  </label>
                </div>

                <div className="opcionesRadios centradoRadiosButon">
                  <input
                    className="form-check-input"
                    {...register("deporteAltoRiezgo", { required: true })}
                    type="radio"
                    name="deporteAltoRiezgo"
                    id="inlineRadio2_deporteAltoRiezgo"
                    value={false}
                    checked={deporteAltoRiezgo === "false"}
                    onChange={handleRadioChangeDeporteAltoRiezgo}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio2_deporteAltoRiezgo"
                  >
                    No
                  </label>
                </div>
              </div>
              {errors.deporteAltoRiezgo?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*alcohol */}
            <div className="col3 campo-form">
              <label>¿Consumo de alcohol?</label>
              <div className="form-check form-check-inline">
                <div className="opcionesRadios">
                  <input
                    className="form-check-input"
                    {...register("consumoAlcohol", { required: true })}
                    type="radio"
                    name="consumoAlcohol"
                    id="inlineRadio1_alcohol"
                    value={true}
                    checked={consumoAlcohol === "true"}
                    onChange={handleRadioChangeAlchol}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio1_alcohol"
                  >
                    Si
                  </label>
                </div>

                <div className="opcionesRadios centradoRadiosButon">
                  <input
                    className="form-check-input"
                    {...register("consumoAlcohol", { required: true })}
                    type="radio"
                    name="consumoAlcohol"
                    id="inlineRadio2_alcohol"
                    value={false}
                    checked={consumoAlcohol === "false"}
                    onChange={handleRadioChangeAlchol}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio2_alcohol"
                  >
                    No
                  </label>
                </div>
              </div>
              {errors.consumoAlcohol?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*Drogas */}
            <div className="col3 campo-form">
              <label>¿Consumo de drogas?</label>
              <div className="form-check form-check-inline">
                <div className="opcionesRadios">
                  <input
                    className="form-check-input"
                    {...register("consumoDrogas", { required: true })}
                    type="radio"
                    name="consumoDrogas"
                    id="inlineRadio1_drogas"
                    value={true}
                    checked={consumoDrogas === "true"}
                    onChange={handleRadioChangeDrogas}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio1_drogas"
                  >
                    Si
                  </label>
                </div>

                <div className="opcionesRadios centradoRadiosButon">
                  <input
                    className="form-check-input"
                    {...register("consumoDrogas", { required: true })}
                    type="radio"
                    name="consumoDrogas"
                    id="inlineRadio2_drogas"
                    value={false}
                    checked={consumoDrogas === "false"}
                    onChange={handleRadioChangeDrogas}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio2_drogas"
                  >
                    No
                  </label>
                </div>
              </div>
              {errors.consumoDrogas?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*Cigarro */}
            <div className="col3 campo-form">
              <label>¿Consumo de cigarro?</label>
              <div className="form-check form-check-inline">
                <div className="opcionesRadios">
                  <input
                    className="form-check-input"
                    {...register("consumoTabaco", { required: true })}
                    type="radio"
                    name="consumoTabaco"
                    id="inlineRadio1_tabaco"
                    value={true}
                    checked={consumoTabaco === "true"}
                    onChange={handleRadioChangeTabaco}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio1_tabaco"
                  >
                    Si
                  </label>
                </div>

                <div className="opcionesRadios centradoRadiosButon">
                  <input
                    className="form-check-input"
                    {...register("consumoTabaco", { required: true })}
                    type="radio"
                    name="consumoTabaco"
                    id="inlineRadio2_tabaco"
                    value={false}
                    checked={consumoTabaco === "false"}
                    onChange={handleRadioChangeTabaco}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio2_tabaco"
                  >
                    No
                  </label>
                </div>
              </div>
              {errors.consumoTabaco?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <hr />

            <div className="justificado">
              <label>
                ¿Padece o ha padecido alguna de las siguientes enfermedades?{" "}
              </label>

              <p>
                ¿Leucemia, sida, insuficiencia renal, diabetes, epilepsia,
                enfermedades del hígado (por ejemplo hepatitis crónica,
                cirrosis, insuficiencia hepática), enfermedades del corazón
                (por ejemplo angina de pecho, infarto al miocardio, cirugía
                del corazón), hipertensión, tumores malignos o cáncer,
                hemorragias o tumor cerebral, padecimientos psiquiátricos,
                cirugías de columna, glaucoma, sordera, enfermedad pulmonar
                obstructiva crónica?
              </p>

              <div className="form-check form-check-inline">
                <div className="opcionesRadios">
                  <input
                    className="form-check-input"
                    {...register("tieneEnfermedad", { required: true })}
                    type="radio"
                    name="tieneEnfermedad"
                    id="inlineRadio1_tieneEnfermedad"
                    value={true}
                    checked={tieneEnfermedad === "true"}
                    onChange={handleRadioChangeTieneEnfermedad}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio1_tieneEnfermedad"
                  >
                    Si
                  </label>
                </div>

                <div className="opcionesRadios centradoRadiosButon">
                  <input
                    className="form-check-input"
                    {...register("tieneEnfermedad", { required: true })}
                    type="radio"
                    name="tieneEnfermedad"
                    id="inlineRadio2_tieneEnfermedad"
                    value={false}
                    checked={tieneEnfermedad === "false"}
                    onChange={handleRadioChangeTieneEnfermedad}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="inlineRadio2_tieneEnfermedad"
                  >
                    No
                  </label>
                </div>
              </div>
              {errors.tieneEnfermedad?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <div className="col3 campo-form">
              <label>
                En caso de cualquier si, por favor especifique (Opcional)
              </label>

              {/*<input
                                  type="text"
                                  id="cualEnfermedad"
                                  name="cualEnfermedad"
                                  value={enfermedadDescripcion || ''}
                                  onInput={(ev) => {setEnfermedadDescripcion(ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, ""))}}
                              />*/}
              <textarea
                id="cualEnfermedad"
                name="cualEnfermedad"
                value={enfermedadDescripcion || ""}
                maxLength={200} // Limita a 200 caracteres
                disabled={descripcionDeshabilitada}
                onInput={(ev) => {
                  // Asegúrate de que el valor no supere los 200 caracteres
                  const inputValue = ev.target.value.substring(0, 200);
                  setEnfermedadDescripcion(
                    inputValue.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                  );
                }}
              ></textarea>
            </div>

            <div className="cont_flex cont-form">
              <div className="dosCuttonFormularios">
                <button type="submit" className="btn btn_morado campo-form">
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="header">
        <h3>
          Si realizaste algun cambio, recuerda <br />{" "}
          <span className="morado"> guardar </span> antes de salir.
        </h3>
      </div>
    </div>
  );
}

export function InfoDatosExtra({ cliente, alCompletar = () => {} }) {

  const { handleSubmit } = useForm();
  const [idResPrecalifica, setIdResPrecalifica] = useState({});
  const [infoDatosExtras, setInfoDatosExtras] = useState({
    nombre: "",
    aPaterno: "",
    aMaterno: "",
    rfc: "",
    telefono: "",
    email: "",
  });

  const infoCalifica = async () => {
    let idCuestionario = await get("getInfoCalifica", cliente?.id);
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const info = async () => {
    let data = await get("getInfoDatosExtras", cliente?.id);
    if (data.statuscode === 200) {
      setInfoDatosExtras(() => ({
        ...infoDatosExtras,
        ...data.infoDatosExtras[0],
      }));
    }
    // console.log(data.infoDatosExtras[0]);
  };

  let dataInfoDatosExtras;

  const addDatosExtra = async () => {
    dataInfoDatosExtras = {
      idUser: cliente?.id,
      idResPrecalifica: idResPrecalifica,
      nombre: infoDatosExtras.nombre,
      aPaterno: infoDatosExtras.aPaterno,
      aMaterno: infoDatosExtras.aMaterno,
      rfc: infoDatosExtras.rfc,
      email: infoDatosExtras.email,
      telefono: infoDatosExtras.telefono,
    };
    let eleccionIU = await get("getInfoDatosExtras", cliente?.id);
    if (eleccionIU.msg === "El usuario no tiene informacion de datos extras") {
      let insertar = await post("addInfoDatosExtras", dataInfoDatosExtras);
      if (insertar.statuscode === 200) {
        alCompletar();
        Swal.fire({
          title: "Datos insertados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      console.log("antes de insertar", dataInfoDatosExtras);
      if (eleccionIU.statuscode === 200) {
        let actualizar = await put(
          "updateInfoDatosExtras",
          dataInfoDatosExtras
        );
        console.log("despues de insertar", dataInfoDatosExtras);

        if (actualizar.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } else {
          Swal.fire({
            title: "Datos actualizados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  const handleGuardarClick = async () => {
    await addDatosExtra();
  };

  useEffect(() => {
    infoCalifica();
    info();
  }, []);

  return (
    <div>
      <h1 className="titt">Datos extras - Coacreditado</h1>
      <p>Aquí puedes guardar los datos que se piden</p>
      <div className="Resum">
        <div className="header">
          <h3>Datos Extras</h3>
        </div>
        <hr />
        <div className="cont_flex cont-form">
          {/* ------------------------------------
                        Datos Extra
              -------------------------------------------*/}

          <form onSubmit={handleSubmit(handleGuardarClick)}>
            <div className="col3 campo-form">
              <label>Nombre *</label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={infoDatosExtras.nombre}
                onChange={(ev) => {
                  setInfoDatosExtras(() => ({
                    ...infoDatosExtras,
                    nombre: ev.target.value,
                  }));
                }}
              ></input>
            </div>
            <div className="col3 campo-form">
              <label>Apellido Paterno *</label>
              <input
                type="text"
                id="aPaterno"
                name="aPaterno"
                value={infoDatosExtras.aPaterno}
                onChange={(ev) => {
                  setInfoDatosExtras(() => ({
                    ...infoDatosExtras,
                    aPaterno: ev.target.value,
                  }));
                }}
              ></input>
            </div>
            <div className="col3 campo-form">
              <label>Apellido Materno *</label>
              <input
                type="text"
                id="aMaterno"
                name="aMaterno"
                value={infoDatosExtras.aMaterno}
                onChange={(ev) => {
                  setInfoDatosExtras(() => ({
                    ...infoDatosExtras,
                    aMaterno: ev.target.value,
                  }));
                }}
              ></input>
            </div>
            <div className="col3 campo-form">
              <label>RFC *</label>
              <input
                type="text"
                id="rfc"
                name="rfc"
                value={infoDatosExtras.rfc}
                onChange={(ev) => {
                  setInfoDatosExtras(() => ({
                    ...infoDatosExtras,
                    rfc: ev.target.value,
                  }));
                }}
              ></input>
            </div>
            <div className="col3 campo-form">
              <label>Teléfono *</label>
              <input
                type="tel"
                id="telefono"
                name="telefono"
                value={infoDatosExtras.telefono}
                onChange={(ev) => {
                  setInfoDatosExtras(() => ({
                    ...infoDatosExtras,
                    telefono: ev.target.value,
                  }));
                }}
              ></input>
            </div>
            <div className="col3 campo-form">
              <label>E-mail *</label>
              <input
                type="text"
                id="email"
                name="email"
                value={infoDatosExtras.email}
                onChange={(ev) => {
                  setInfoDatosExtras(() => ({
                    ...infoDatosExtras,
                    email: ev.target.value,
                  }));
                }}
              ></input>
            </div>

            <div className="cont_flex cont-form">
              <div className="dosCuttonFormularios">
                <button
                  type="button"
                  className="btn btn_morado col3 campo-form"
                  onClick={handleGuardarClick}
                >
                  Guardar
                </button>
              </div>
            </div>
            <div className="header">
              <h3>
                Si realizaste algun cambio, recuerda <br />{" "}
                <span className="morado"> guardar </span> antes de salir.
              </h3>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

// ---------------- Formularios de etapa 2 (Ejecutivo) ----------------

export function FormularioSolicitud({ idCuestionario, idResPrecalifica, alCompletar = () => {} }) {
  const [nuevoComentario, setNuevoComentario] = useState(true);
  const [solicitud, setSolicitud] = useState(null);
  const [solicitudFirmada, setSolicitudFirmada] = useState(null);

  const {
    register,
    setValue,
    handleSubmit,
  } = useForm();

  async function subirArchivo(archivo, documento, setDocumento){
    const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
      nombreArchivo: archivo.name,
    });

    if(peticionArchivoS3?.statuscode === 200){
      const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, archivo);

      if(peticionS3?.ok){
        const peticionSolicitud = await post("subirDocumento", {
          rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
          idCatDocumento: documento?.idCatDocumento,
          idCuestionario: idCuestionario,
          idResPrecalifica: idResPrecalifica
        });

        if (peticionSolicitud?.statuscode === 200) {
          const archivo = {
            id: peticionSolicitud.respuesta.documento?.idDocsTramite,
            nombreArchivo: peticionSolicitud.respuesta.documento?.archivo?.split("/").pop(),
            rutaArchivo: peticionSolicitud.respuesta.documento?.archivo,
          }
          setDocumento({...documento, archivo: archivo});
        }else{
          Swal.fire({
            title: "No se pudo subir el documento",
            text: "Intente más tarde",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  }

  async function eliminarArchivo(idDocsTramite, documento, setDocumento){
    const peticionEliminarArchivo = await deleteDocEndpoint("borrarDocumentoEspecifico", {
      idDocsTramite: idDocsTramite,
      idCatDocumento: documento?.idCatDocumento,
      idCuestionario: idCuestionario,
      idResPrecalifica: idResPrecalifica
    });

    if (peticionEliminarArchivo.statuscode === 200) {
      setDocumento({...documento, archivo: {}});

      Swal.fire({
        title: "Documento borrado correctamente",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    } else {
      Swal.fire({
        title: "No se pudo borrar el documento",
        text: "Intente más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  const enviar = async (data) => {
    const paramsNota = {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idCatPantallas: 1,
      nombre: "Descargar solicitud",
      nota1: data.comentariosSolicitud
    }

    const peticionComentario = nuevoComentario ? await post("addNota", paramsNota) 
      : await put("actualizarNota", paramsNota);

    if(peticionComentario?.statuscode === 200){
      Swal.fire({
        icon: "success",
        title: "Datos guardados correctamente",
        confirmButtonText: "Aceptar",
      });

      if(nuevoComentario){
        setNuevoComentario(false);
      }
      alCompletar();
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al guardar comentario",
        text: "Inténtalo nuevamente más tarde.",
        confirmButtonText: "Aceptar",
      });
    }
  }

  async function obtNotas() {
    const peticionNota = await post("getNotas", {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idPantallaActual: 1
    });

    if(peticionNota?.statuscode === 200){
      setValue("comentariosSolicitud", peticionNota.infoNotas.nota1);
      setNuevoComentario(false);
    }else{
      setValue("comentariosSolicitud", "");
      setNuevoComentario(true);
    }
  }

  async function obtSolicitud(){
    const peticionSolicitud = await post("obtInfoDocumentacionTramite", {
      idCatDocumento: 18,
      idCuestionario: idCuestionario,
      idResPrecalifica: idResPrecalifica,
    });

    const archivo = peticionSolicitud?.statuscode === 200 ? {
      id: peticionSolicitud.respuesta.documentacion?.[0]?.idDocsTramite,
      nombreArchivo: peticionSolicitud.respuesta.documentacion?.[0]?.archivo.split("/").pop(),
      rutaArchivo: peticionSolicitud.respuesta.documentacion?.[0]?.archivo,
    } : {};

    setSolicitud({idCatDocumento: 18, archivo: archivo});
  }

  async function obtSolicitudFirmada(){
    const peticionSolicitudFirmada = await post("obtInfoDocumentacionTramite", {
      idCatDocumento: 1,
      idCuestionario: idCuestionario,
      idResPrecalifica: idResPrecalifica,
    });

    const archivo = peticionSolicitudFirmada?.statuscode === 200 ? {
      id: peticionSolicitudFirmada.respuesta.documentacion?.[0]?.idDocsTramite,
      nombreArchivo: peticionSolicitudFirmada.respuesta.documentacion?.[0]?.archivo.split("/").pop(),
      rutaArchivo: peticionSolicitudFirmada.respuesta.documentacion?.[0]?.archivo,
    } : {};

    setSolicitudFirmada({idCatDocumento: 1, archivo: archivo});
  }

  useEffect(() => {
    obtSolicitud();
    obtSolicitudFirmada();
    obtNotas();
  }, [idCuestionario, idResPrecalifica]);

  return(
    <div className="cont_flex cont-form">
      <div className="cont2cards">
        <FullCardArchivo
          titulo="Sube tu solicitud"
          descripcion="Envía la solicitud que el cliente deberá firmar."
          archivo={solicitud?.archivo ?? {}}
          onUpload={(archivo) => subirArchivo(archivo, solicitud, setSolicitud)}
          onDelete={(idDocsTramite) => eliminarArchivo(idDocsTramite, solicitud, setSolicitud)}
        />
        <FullCardArchivo 
          titulo="Solicitud firmada"
          descripcion="Sube o descarga la solicitud firmada por tu cliente."
          archivo={solicitudFirmada?.archivo ?? {}}
          onUpload={(archivo) => subirArchivo(archivo, solicitudFirmada, setSolicitudFirmada)}
          onDelete={(idDocsTramite) => eliminarArchivo(idDocsTramite, solicitudFirmada, setSolicitudFirmada)}
        />
      </div>
      <div>
        <form onSubmit={handleSubmit(enviar)}>
          <div>
            <label>Comentarios:</label>

            <textarea
              {...register("comentariosSolicitud", {required: false})}
              className="width100"
              rows={5}
              maxLength={200}
            ></textarea>
          </div>
          <br/>

          <button type="submit" className="btn btn_morado">
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
}

export function FormularioRespuestaBanco({ idCuestionario, idResPrecalifica, alCompletar = () => {} }){
  const [nuevoRegistro, setNuevoRegistro] = useState(true);
  const [nuevoRegistroPagoAvaluo, setNuevoRegistroPagoAvaluo] = useState(true);
  const [nuevoComentario, setNuevoComentario] = useState(true);

  const [nuevaNotaVivienda, setNuevaNotaVivienda] = useState(true);
  const [nuevaNotaViviendaInfoVendedor, setNuevaViviendaInfoVendedor] = useState(true);
  const [nuevaNotaDocumentosVivienda, setNuevaNotaDocumentosVivienda] = useState(true);
  const [nuevaNotaPagoAvaluo, setNuevaNotaPagoAvaluo] = useState(true);

  
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      montoSolicitado: "$ 0.00",
      pagoMensual: "$ 0.00",
      mensualidadInicial: "$ 0.00",
      valorCompraventa: "$ 0.00",
      costoApertura: "$ 0.00",
      avaluo: "$ 0.00",
      seguros: "$ 0.00",
      costoPagoAnticipado: "$ 0.00",
      prestamoMaximo: "$ 0.00",
      montoPagoAvaluo: "$ 0.00",
    }
  });

  const enviar = async (data) => {
    const datosRespuestaBanco = {
      producto: data.producto,
      tipoTasa: data.tipoTasa,
      respuestaEmitidaBanco: data.respuestaEmitidaBanco,
      avaluo: data.avaluo.replace(/[^0-9.]/g, ""),
      costoApertura: data.costoApertura.replace(/[^0-9.]/g, ""),
      costoPagoAnticipado: data.costoPagoAnticipado.replace(/[^0-9.]/g, ""),
      mensualidadInicial: data.mensualidadInicial.replace(/[^0-9.]/g, ""),
      montoPagoAvaluo: data.montoPagoAvaluo.replace(/[^0-9.]/g, ""),
      montoSolicitado: data.montoSolicitado.replace(/[^0-9.]/g, ""),
      pagoMensual: Number(data.pagoMensual.replace(/[^0-9.]/g, "")),
      prestamoMaximo: data.prestamoMaximo.replace(/[^0-9.]/g, ""),
      seguros: data.seguros.replace(/[^0-9.]/g, ""),
      valorCompraventa: Number(data.valorCompraventa.replace(/[^0-9.]/g, "")),
      tasa: data.tasa.replace(/[^0-9.]/g, ""),
      fechaVencimiento: formatoFechaDmaAIso(data.fechaVencimiento),
      plazo: data.plazo,
      instruccionesPagoAvaluo: data.instruccionesPagoAvaluo,
      notasAceptacion: data.notasAceptacion,
      notasVivienda: data.notasVivienda,
      notasViviendaInfoVendedor: data.notasViviendaInfoVendedor,
      notasDocumentosVivienda: data.notasDocumentosVivienda,
      notasPagoAvaluo: data.notasPagoAvaluo
    };

    const params = {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      producto: datosRespuestaBanco.producto,
      prestamoSolicitado: datosRespuestaBanco.montoSolicitado,
      mensualidadInicial: datosRespuestaBanco.mensualidadInicial,
      tasa: datosRespuestaBanco.tasa,
      tipoTasa: datosRespuestaBanco.tipoTasa,
      plazo: datosRespuestaBanco.plazo,
      costoApertura: datosRespuestaBanco.costoApertura,
      avaluo: datosRespuestaBanco.avaluo,
      seguros: datosRespuestaBanco.seguros,
      costoPagoAnticipado: datosRespuestaBanco.costoPagoAnticipado,
      prestamoMaximo: datosRespuestaBanco.prestamoMaximo,
      resJson: "",
      estatus: datosRespuestaBanco.respuestaEmitidaBanco,
      fechaSolicitud: datosRespuestaBanco.fechaVencimiento,
      pagoMensual: datosRespuestaBanco.pagoMensual,
      valorCompraventa: datosRespuestaBanco.valorCompraventa
    }

    const paramsPagoAvaluo = {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      monto: datosRespuestaBanco.montoPagoAvaluo,
      instruccionesPagoAvaluo: datosRespuestaBanco.instruccionesPagoAvaluo
    }

    const paramsNota = {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idCatPantallas: 2,
      nombre: "Aceptar solicitud",
      nota1: data.comentariosRespuestaBanco
    }

    const parametrosNotasVivienda = [
      {
        registroNuevo: nuevaNotaVivienda,
        paramsNota: {
          idCatPantallas: 3,
          nombre: "Vivienda",
          nota1: data.notasVivienda
        }
      },
      {
        registroNuevo: nuevaNotaViviendaInfoVendedor,
        paramsNota: {
          idCatPantallas: 8,
          nombre: "Informacion documentacion del vendedor",
          nota1: data.notasViviendaInfoVendedor
        }
      },
      {
        registroNuevo: nuevaNotaDocumentosVivienda,
        paramsNota: {
          idCatPantallas: 9,
          nombre: "Documentacion de la vivienda",
          nota1: data.notasDocumentosVivienda
        }
      },
      {
        registroNuevo: nuevaNotaPagoAvaluo,
        paramsNota: {
          idCatPantallas: 10,
          nombre: "Pago de avaluo",
          nota1: data.notasPagoAvaluo
        }
      }
    ]

    const peticionRespuestaBanco = nuevoRegistro ? await post("addSolicitudAceptada", params) 
      : await put("updateSolicitudAceptada", params);

    if(peticionRespuestaBanco?.statuscode === 200){

      const peticionPagoAvaluo = nuevoRegistroPagoAvaluo ? await post("addPagoAvaluo", paramsPagoAvaluo)
      : await put("updatePagoAvaluo", paramsPagoAvaluo);

      if(peticionPagoAvaluo?.statuscode === 200){

        const peticionComentario = nuevoComentario ? await post("addNota", paramsNota) 
          : await put("actualizarNota", paramsNota);

        if(peticionComentario?.statuscode === 200){
          Swal.fire({
            icon: "success",
            title: "Datos guardados correctamente",
            confirmButtonText: "Aceptar",
          });

          if(nuevoComentario){
            setNuevoComentario(false);
          }
        }else{
          Swal.fire({
            icon: "error",
            title: "Error al guardar comentario",
            text: "Inténtalo nuevamente más tarde.",
            confirmButtonText: "Aceptar",
          });
        }

        setNuevoRegistroPagoAvaluo(false);
      }else{
        Swal.fire({
          icon: "error",
          title: "Error al guardar datos",
          text: "Inténtalo nuevamente más tarde.",
          confirmButtonText: "Aceptar",
        });
      }

      if(nuevoRegistro){
        setNuevoRegistro(false);
      }
      alCompletar();
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al guardar datos",
        text: "Inténtalo nuevamente más tarde.",
        confirmButtonText: "Aceptar",
      });
    }

    const peticionesNotasVivienda = parametrosNotasVivienda.map(async (notaViv) =>  {
      return notaViv?.registroNuevo ? await post("addNota", {
        idCuestionario: idCuestionario,
        idResPrecalifica: idResPrecalifica,
        ...notaViv?.paramsNota
      }) : await put("actualizarNota", {
        idCuestionario: idCuestionario,
        idResPrecalifica: idResPrecalifica,
        ...notaViv?.paramsNota
      });
    })

    const respuestasNotasVivienda = await Promise.all(peticionesNotasVivienda);

    if(respuestasNotasVivienda.some(respuesta => respuesta?.statuscode !== 200)){
      Swal.fire({
        icon: "error",
        title: "Error al guardar un comentario de vivienda",
        text: "Inténtalo nuevamente más tarde.",
        confirmButtonText: "Aceptar",
      });
    }

  }

  async function obtDatosRespuestaBanco() {
    const peticionResBanco = await get("getSolicitudAceptada", idResPrecalifica);

    if(peticionResBanco?.statuscode === 200){
      const respuestaBanco = peticionResBanco.solicitud[0];

      setValue("fechaVencimiento", formatoFechaIsoADma(respuestaBanco?.fechaSolicitud ?? ""));
      setValue("respuestaEmitidaBanco", respuestaBanco?.estatus);
      setValue("producto", respuestaBanco?.producto ?? "");
      setValue("montoSolicitado", formatoMoneda(respuestaBanco?.prestamoSolicitado ?? 0));
      setValue("tipoTasa", respuestaBanco?.tipoTasa ?? "");
      setValue("plazo", respuestaBanco?.plazo ?? "");
      setValue("tasa", respuestaBanco?.tasa ?? "");
      setValue("pagoMensual", formatoMoneda(respuestaBanco?.pagoMensual ?? 0));
      setValue("mensualidadInicial", formatoMoneda(respuestaBanco?.mensualidadInicial ?? 0));
      setValue("valorCompraventa", formatoMoneda(respuestaBanco?.valorCompraventa ?? 0));
      setValue("costoApertura", formatoMoneda(respuestaBanco?.costoApertura ?? 0));
      setValue("avaluo", formatoMoneda(respuestaBanco?.avaluo ?? 0));
      setValue("seguros", formatoMoneda(respuestaBanco?.seguros ?? 0));
      setValue("costoPagoAnticipado", formatoMoneda(respuestaBanco?.costoPagoAnticipado ?? 0));
      setValue("prestamoMaximo", formatoMoneda(respuestaBanco?.prestamoMaximo ?? 0));

      setNuevoRegistro(false);
    }else{
      setValue("fechaVencimiento", "");
      setValue("respuestaEmitidaBanco", null);
      setValue("producto", "");
      setValue("montoSolicitado", formatoMoneda(0));
      setValue("tipoTasa", "");
      setValue("plazo", "");
      setValue("tasa", "");
      setValue("pagoMensual", formatoMoneda(0));
      setValue("mensualidadInicial", formatoMoneda(0));
      setValue("valorCompraventa", formatoMoneda(0));
      setValue("costoApertura", formatoMoneda(0));
      setValue("avaluo", formatoMoneda(0));
      setValue("seguros", formatoMoneda(0));
      setValue("costoPagoAnticipado", formatoMoneda(0));
      setValue("prestamoMaximo", formatoMoneda(0));

      setNuevoRegistro(true);
    }
  }

  async function obtDatosPagoAvaluo() {
    const peticionPagoAvaluo = await get("getPagoAvaluo", idResPrecalifica);

    if(peticionPagoAvaluo?.statuscode === 200){
      const respuestaPagoAvaluo = peticionPagoAvaluo.avaluo[0];

      setValue("montoPagoAvaluo", formatoMoneda(respuestaPagoAvaluo?.monto ?? 0));
      setValue("instruccionesPagoAvaluo", respuestaPagoAvaluo?.instruccionesPagoAvaluo ?? "");
      setNuevoRegistroPagoAvaluo(false);
    }else{
      setValue("montoPagoAvaluo", formatoMoneda(0));
      setValue("instruccionesPagoAvaluo", "");

      setNuevoRegistroPagoAvaluo(true);
    }
  }

  async function obtNotas() {
    const peticionNota = await post("getNotas", {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idPantallaActual: 2
    });

    if(peticionNota?.statuscode === 200){
      setValue("comentariosRespuestaBanco", peticionNota.infoNotas.nota1);
      setNuevoComentario(false);
    }else{
      setValue("comentariosRespuestaBanco", "");
      setNuevoComentario(true);
    }
  }

  async function obtNotasVivienda() {
    const peticionNota = await post("getNotas", {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idPantallaActual: 3
    });

    if(peticionNota?.statuscode === 200){
      setValue("notasVivienda", peticionNota.infoNotas.nota1);
      setNuevaNotaVivienda(false);
    }else{
      setValue("notasVivienda", "");
      setNuevaNotaVivienda(true);
    }
  }

  async function obtNotasInfovendedor() {
    const peticionNota = await post("getNotas", {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idPantallaActual: 8
    });

    if(peticionNota?.statuscode === 200){
      setValue("notasViviendaInfoVendedor", peticionNota.infoNotas.nota1);
      setNuevaViviendaInfoVendedor(false);
    }else{
      setValue("notasViviendaInfoVendedor", "");
      setNuevaViviendaInfoVendedor(true);
    }
  }

  async function obtNotasDocsVivienda() {
    const peticionNota = await post("getNotas", {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idPantallaActual: 9
    });

    if(peticionNota?.statuscode === 200){
      setValue("notasDocumentosVivienda", peticionNota.infoNotas.nota1);
      setNuevaNotaDocumentosVivienda(false);
    }else{
      setValue("notasDocumentosVivienda", "");
      setNuevaNotaDocumentosVivienda(true);
    }
  }

  async function obtNotasPagoAvaluo() {
    const peticionNota = await post("getNotas", {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idPantallaActual: 10
    });

    if(peticionNota?.statuscode === 200){
      setValue("notasPagoAvaluo", peticionNota.infoNotas.nota1);
      setNuevaNotaPagoAvaluo(false);
    }else{
      setValue("notasPagoAvaluo", "");
      setNuevaNotaPagoAvaluo(true);
    }
  }

  useEffect(() => {
    obtDatosRespuestaBanco();
    obtDatosPagoAvaluo();
    obtNotas();
    obtNotasVivienda();
    obtNotasInfovendedor();
    obtNotasDocsVivienda();
    obtNotasPagoAvaluo();
  }, [idCuestionario, idResPrecalifica]);

  return(
    <div className="cont_flex cont-form">
      <form onSubmit={handleSubmit(enviar)}>

        <div className="col3 campo-form marginEspacio">
          <label>Fecha de vencimiento</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("fechaVencimiento", {required: true, validate: fecha => ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.fechaVencimiento?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.fechaVencimiento?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>
        <div className="col3 campo-form estado marginEspacio">
          <label>Respuesta emitida del banco</label>
          <select
            {...register("respuestaEmitidaBanco", { required: true })}
            defaultValue=""
          >
            <option value="" disabled>
              Seleccione una opción...
            </option>
            <option value={0}>
              Aceptado
            </option>
            <option value={1}>
              Rechazado
            </option>
            <option value={2}>
              Faltan datos adicionales
            </option>
          </select>
          {errors.respuestaEmitidaBanco?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <br/><br/>
        <hr/>

        <div className="col3 campo-form">
          <label>Producto</label>
          <input
            type="text"
            maxLength={50}
            autoComplete="off"
            {...register("producto", { required: true })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          />
          {errors.producto?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Monto solicitado</label>
          <input
            type="text"
            autoComplete="off"
            {...register("montoSolicitado", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
          {errors.montoSolicitado?.type === "validate" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Tipo de tasa</label>
          <input
            type="text"
            maxLength={50}
            autoComplete="off"
            {...register("tipoTasa", { required: true })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          />
          {errors.tipoTasa?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>

        <div className="col3 campo-form">
          <label>Plazo</label>
          <input
            type="text"
            {...register("plazo", {required: true})}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
          />
          {errors.plazo?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Tasa</label>
          <input
            type="text"
            autoComplete="off"
            {...register("tasa", { required: true})}
            onInput={(e) => {
              let porcentaje = e.target.value.replace(/[^0-9.]/g, "");
              const partes = porcentaje.split(".");

              if (partes.length > 2) {
                porcentaje = partes[0] + "." + partes[1];
              }
              
              if (partes[1] && partes[1].length > 2) {
                porcentaje = partes[0] + "." + partes[1].substring(0, 2);
              }

              e.target.value = porcentaje;
            }}
            onBlur={(e) => {
              if(e.target.value.endsWith(".")){
                e.target.value = e.target.value.replace(".", "");
              }
            }}
          />
          {errors.tasa?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Pago mensual</label>
          <input
            type="text"
            autoComplete="off"
            {...register("pagoMensual", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
          {errors.pagoMensual?.type === "validate" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>

        <div className="col3 campo-form">
          <label>Mensualidad inicial</label>
          <input
            type="text"
            autoComplete="off"
            {...register("mensualidadInicial", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
          {errors.mensualidadInicial?.type === "validate" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Valor compraventa</label>
          <input
            type="text"
            autoComplete="off"
            {...register("valorCompraventa", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
          {errors.valorCompraventa?.type === "validate" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Costo de apertura</label>
          <input
            type="text"
            autoComplete="off"
            {...register("costoApertura")}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
        </div>

        <div className="col3 campo-form">
          <label>Avaluo</label>
          <input
            type="text"
            autoComplete="off"
            {...register("avaluo")}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
        </div>
        <div className="col3 campo-form">
          <label>Seguros</label>
          <input
            type="text"
            autoComplete="off"
            {...register("seguros")}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
        </div>
        <div className="col3 campo-form">
          <label>Costo del pago anticipado</label>
          <input
            type="text"
            autoComplete="off"
            {...register("costoPagoAnticipado")}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
        </div>

        <div className="col3 campo-form">
          <label>Préstamo máximo</label>
          <input
            type="text"
            autoComplete="off"
            {...register("prestamoMaximo", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
          {errors.prestamoMaximo?.type === "validate" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Monto de pago del avaluo</label>
          <input
            type="text"
            autoComplete="off"
            {...register("montoPagoAvaluo", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
          {errors.montoPagoAvaluo?.type === "validate" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <br/><br/>

        {/*----Notas----*/}
        <div>
          <label>Instrucciones para pagar avaluo</label>

          <textarea
            {...register("instruccionesPagoAvaluo", {required: false})}
            className="width100"
            maxLength={200}
            rows={5}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          ></textarea>
        </div>

        <div>
          <label>Notas de la aceptación:</label>

          <textarea
            {...register("comentariosRespuestaBanco", {required: false})}
            className="width100"
            maxLength={200}
            rows={5}
          ></textarea>
        </div>
        
        <div>
          <label>Notas de vivienda:</label>
          
          <textarea
            {...register("notasVivienda", {required: false})}
            className="width100"
            maxLength={200}
            rows={5}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          ></textarea>
        </div>
        
        <div>
          <label>Notas de vivienda información vendedor:</label>

          <textarea
            {...register("notasViviendaInfoVendedor", {required: false})}
            className="width100"
            maxLength={200}
            rows={5}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          ></textarea>
        </div>
        
        <div>
          <label>Notas de documentos de vivienda:</label>

          <textarea
            {...register("notasDocumentosVivienda", {required: false})}
            className="width100"
            maxLength={200}
            rows={5}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          ></textarea>
        </div>
        
        <div>
          <label>Notas de pago de avaluo:</label>

          <textarea
            {...register("notasPagoAvaluo", {required: false})}
            className="width100"
            maxLength={200}
            rows={5}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          ></textarea>
        </div>

        <br/>
        
        <button 
          type="submit" 
          className="btn btn_morado"
        >
          Enviar al cliente
        </button>

        <br/><br/>

      </form>
    </div>
  );
}

function InfoVendedorVivienda({ idCuestionario, idResPrecalifica, alCompletar = () => {}, alIncompletar = () => {} }) {
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [validarLongitudCP, setValidarLongitudCP] = useState();

  //Datos del vendedor
  const [tipoPersona, setTipoPersona] = useState("");
  const [tipoInmueble, setTipoInmueble] = useState("");
  const [usoInmueble, setUsoInmueble] = useState("");

  //Fisica
  const [email, setEmail] = useState("");
  const [nombre, setNombre] = useState("");
  const [aPaterno, setAPaterno] = useState("");
  const [aMaterno, setAMaterno] = useState("");
  const [telCel, setTelCel] = useState("");
  const [validarLongitudTelefono, setValidarLongitudTelefono] = useState();
  const [invalidEmail, setInvalidEmail] = useState(false);

  //Moral
  const [razonSocial, setRazonSocial] = useState("");
  const [nombrecontactoEmpresa, setNombrecontactoEmpresa] = useState("");
  const [telContacto, setTelContacto] = useState("");
  const [emailContacto, setEmailContacto] = useState("");
  const [rfc, setRfc] = useState("");
  const [validarLongitudTelefonoMoral, setValidarLongitudTelefonoMoral] = useState();
  const [invalidEmailMoral, setInvalidEmailMoral] = useState(false);

  //Direccion
  const [cp, setCp] = useState("");
  const [calle, setCalle] = useState("");
  const [numExt, setNumExt] = useState("");
  const [numInt, setNumInt] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [estado, setEstado] = useState("");
  const [colonia, setColonia] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  //Para controlar logica de direcciones o validaciones
  const [error, setError] = useState(null);
  const [estadoDisabled, setEstadoDisabled] = useState(true);
  const [estadoAux, setEstadoAux] = useState("");
  const [delegacionDisabled, setDelegacionDisabled] = useState(true);
  const [listColonias, setListColonias] = useState();

  const [documentacion, setDocumentacion] = useState([]);

  let params;

  // Función para obtener datos de código postal.
  const obtenerDataCp = async (cp) => {
    try {
      let respuestaPorCP = await getCP("info_cp", cp);
      // console.log(respuestaPorCP);
      setError(false);
      obtenerClaveIso(respuestaPorCP.response.estado);
      setEstadoAux(respuestaPorCP.response.estado);
      setDelegacion(respuestaPorCP.response.municipio);
      listColoniasCP(respuestaPorCP.response.asentamiento);
      setEstadoDisabled(true);
      setDelegacionDisabled(true);
    } catch (error) {
      // Habilitar los inputs en caso de error
      setEstadoDisabled(false);
      setDelegacionDisabled(false);
      setColonia(""); // Esto borrará el contenido del campo "colonia"
      setEstadoAux("");
      setDelegacion("");
      setError(true);
    }
  };

  // Función para listar colonias.
  const listColoniasCP = (colonias) => {
    let opcionDefault = (
      <option value="" disabled>
        Seleccione una opción...{" "}
      </option>
    );

    colonias = colonias.map((colonia) => (
      <option value={colonia} key={colonia}>
        {colonia}
      </option>
    ));
    colonias.unshift(opcionDefault);

    setListColonias(colonias);
  };

  // Función para obtener la clave ISO del estado.
  const obtenerClaveIso = async (estadoCP) => {
    let obtenerClave = await get("obtClaveISOPorEstado", estadoCP);
    setEstadoAux(estadoCP);
    if (obtenerClave.statuscode === 200) {
      setEstado(obtenerClave.iso);
    }
  };

  //Obtenemos información general
  const obtInformacion = async () => {
    //Parametro fijo hasta que determinemos como se obtiene
    //let data = await get('getInfoVendedorVivienda', 294);
    let data = await get("getInfoVendedorVivienda", idResPrecalifica);

    // console.log(data);
    if (data.statuscode === 200) {
      setTipoPersona(data.infoVendedorVivienda[0].tipoVendedor.toString());
      setValue(
        "tipoPersona",
        data.infoVendedorVivienda[0].tipoVendedor.toString()
      );
      setNombrecontactoEmpresa(
        data.infoVendedorVivienda[0].nombrecontactoEmpresa
      );
      setEmail(data.infoVendedorVivienda[0].email);
      setEmailContacto(data.infoVendedorVivienda[0].email);
      setNombre(data.infoVendedorVivienda[0].nombre);
      setAPaterno(data.infoVendedorVivienda[0].aPaterno);
      setAMaterno(data.infoVendedorVivienda[0].aMaterno);
      setCalle(data.infoVendedorVivienda[0].calle);
      setNumExt(data.infoVendedorVivienda[0].numExt);
      setNumInt(data.infoVendedorVivienda[0].numInt);
      setCp(data.infoVendedorVivienda[0].cp);
      setDelegacion(data.infoVendedorVivienda[0].delegacion);
      setEstado(data.infoVendedorVivienda[0].estado);
      setError(true);
      setColonia(data.infoVendedorVivienda[0].colonia);
      setRfc(data.infoVendedorVivienda[0].rfc);
      setTelCel(data.infoVendedorVivienda[0].telCel);
      setRazonSocial(data.infoVendedorVivienda[0].razonSocial);
      setTelContacto(data.infoVendedorVivienda[0].telContacto);

      setTipoInmueble(data.infoVendedorVivienda[0].tipoInmueble);
      setUsoInmueble(data.infoVendedorVivienda[0].usoInmueble);

      setEstadoAux(data.infoVendedorVivienda[0].delegacion);
    }
  };

  //Función especifica para enviar información
  const sendInfoVendedor = async () => {
    params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionario),
      tipoVendedor: parseInt(tipoPersona),
      email: parseInt(tipoPersona) == 1 ? emailContacto : email,
      nombre: nombre,
      aPaterno: aPaterno,
      aMaterno: aMaterno,
      calle: calle,
      numExt: numExt,
      numInt: numInt,
      cp: cp !== "" ? cp : 0,
      //delegacion: delegacion,
      delegacion: estadoAux,
      estado: estado,
      colonia: colonia,
      rfc: rfc,
      telCel: telCel,
      razonSocial: razonSocial,
      nombrecontactoEmpresa: nombrecontactoEmpresa,
      telContacto: telContacto,
      tipoInmueble: parseInt(tipoInmueble),
      usoInmueble: usoInmueble !== "" ? parseInt(usoInmueble) : 0,
    };
    //console.log(params);
  };

  //Funcion general para guardar la información
  const guardarInformacion = async (mostrarDialogo = true) => {
    await sendInfoVendedor();
    // Verificamos si tiene información
    let getInfo = await get("getInfoVendedorVivienda", idResPrecalifica);
    console.log(getInfo);

    // Lógica de Swal aquí
    const mostrarSwal = (title, icon) => {
      if (mostrarDialogo) {
        Swal.fire({
          title: title,
          icon: icon,
          confirmButtonText: "Aceptar",
        });
      }
    };

    if (getInfo.statuscode === 404) {
      let insert = await post("addInfoVendedorVivienda", params);

      if (insert.statuscode === 200) {
        alCompletar();
        mostrarSwal("Datos guardados correctamente", "success");
        
      } else {
        mostrarSwal("Datos ingresados erroneos", "error");
      }
    } else {
      if (getInfo.statuscode === 200) {
        let update = await put("updateInfoVendedorVivienda", params);
        if (update.statuscode === 200) {
          alCompletar();
          mostrarSwal("Datos actualizados correctamente", "success");
        } else {
          mostrarSwal("Datos ingresados erroneos", "error");
        }
      }
    }
  };

  async function verificarDocumento(idCatDocumento, idCuestionario, idResPrecalifica) {
    const peticionDocTramite = await post("obtInfoDocumentacionTramite", {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idCatDocumento: idCatDocumento,
    });

    let nota = "";
    switch (idCatDocumento) {
      case 9:
        nota = "Asegura que sea legible y vigente.";
        break;

      case 10:
        nota = "Anexa los poderes de la persona que firmara la escritura, debe de ser legible y vigente.";
        break;

      case 11:
        nota = "Anexa INE o Pasaporte vigente y asegura que sea legible.";
        break;

      case 12:
        nota = "Asegura que sean legibles y vigentes.";
        break;

      case 13:
        nota = "Asegura que sean legibles y vigentes.";
        break;

      case 14:
        nota = "Asegura que sean legibles y vigentes.";
        break;

      case 6:
        nota = "Asegura que sean legibles y vigentes."
        break;

      case 7:
        nota = "Anexa INE o Pasaporte vigente y asegura que sea legible."
        break;

      case 8:
        nota = "Asegura que sean legibles y vigentes."
        break;

      default:
        break;
    }

    if (peticionDocTramite?.statuscode === 200) {
      let documentos = [];
      if (idCatDocumento === 16 || idCatDocumento === 13 || idCatDocumento === 8){
        documentos = peticionDocTramite.respuesta.documentacion?.map(documento => {
          return {
            id: documento.idDocsTramite,
            nombreArchivo: documento.archivo?.split("/").pop(),
            rutaArchivo: documento.archivo,
          }
        });
      }else{
        documentos.push({
          id: peticionDocTramite.respuesta.documentacion[0]?.idDocsTramite,
          nombreArchivo: peticionDocTramite.respuesta.documentacion[0]?.archivo?.split("/").pop(),
          rutaArchivo: peticionDocTramite.respuesta.documentacion[0]?.archivo,
        });
      }

      return {
        documentos: documentos,
        nota: nota
      }
    } else {
      return null;
    }
  };

  async function obtDocumentacion(tipo){
    if(parseInt(tipo) > 0){
      let peticionDocs = await get("getDocumentosByPantalla", "3");

      if (peticionDocs?.statuscode === 200) {
        const docsTipoPersona = peticionDocs.documentos[tipo].documentos;

        const verificarDocumentos = docsTipoPersona.map(async (doc) => {
          const docVerificado = await verificarDocumento(doc.idCatDocumentos, idCuestionario, idResPrecalifica) 

          return docVerificado !== null ? {
            idCatDocumento: doc.idCatDocumentos,
            titulo: doc.nombre,
            multiplesArchivos: doc.multiplesArchivos,
            ...docVerificado
          } : null ;
        });

        const documentosVerificados = (await Promise.all(verificarDocumentos)).filter(verificacion => verificacion !== null);
        setDocumentacion(documentosVerificados);
      }else{
        setDocumentacion([]);
      }
    }else{
      setDocumentacion([]);
    }
  }

  async function eliminarArchivo(idDocsTramite, idCatDocumento, estadoDocs, setEstadoDocs){
    const peticionEliminarArchivo = await deleteDocEndpoint("borrarDocumentoEspecifico", {
      idDocsTramite: idDocsTramite,
      idCatDocumento: idCatDocumento,
      idCuestionario: idCuestionario,
      idResPrecalifica: idResPrecalifica
    });

    if (peticionEliminarArchivo.statuscode === 200) {
      let copiaEstadoDocs = [...estadoDocs];
      const index = copiaEstadoDocs.findIndex(doc => doc.idCatDocumento === idCatDocumento);

      if (index !== -1) {
        copiaEstadoDocs[index].documentos = copiaEstadoDocs[index]?.documentos.filter(doc => doc.id !== idDocsTramite);
        setEstadoDocs(copiaEstadoDocs);

        Swal.fire({
          title: "Documento borrado correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      Swal.fire({
        title: "No se pudo borrar el documento",
        text: "Intente más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
    
  }

  async function subirArchivo(archivo, idCatDocumento, estadoDocs, setEstadoDocs){
    const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
      nombreArchivo: archivo.name,
    });

    if(peticionArchivoS3?.statuscode === 200){
      const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, archivo);

      if(peticionS3?.ok){
        const peticionArchivo = await post("subirDocumento", {
          rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
          idCatDocumento: idCatDocumento,
          idCuestionario: idCuestionario,
          idResPrecalifica: idResPrecalifica
        });

        if (peticionArchivo?.statuscode === 200) {
          let copiaEstadoDocs = [...estadoDocs];
          const index = copiaEstadoDocs.findIndex(doc => doc.idCatDocumento === idCatDocumento);

          if (index !== -1) {
            // el filtro se coloca para evitar que se guarde un registro nulo, esto sucede al intentar cargar dos archivos la mismo tiempo
            copiaEstadoDocs[index].documentos = [...(copiaEstadoDocs[index]?.documentos?.filter(doc => doc.id !== undefined && doc.id !== null)), {
              id: peticionArchivo.respuesta.documento?.idDocsTramite,
              nombreArchivo: peticionArchivo.respuesta.documento?.archivo?.split("/").pop(),
              rutaArchivo: peticionArchivo.respuesta.documento?.archivo
            }];
            setEstadoDocs(copiaEstadoDocs); 
          }
        }else{
          Swal.fire({
            title: "No se pudo subir el documento",
            text: "Intente más tarde",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  }

  async function subirArchivos(archivos, idCatDocumento, estadoDocs, setEstadoDocs){
    const archivosNuevos = [];

    const peticionesArchivos = archivos.map(async (archivo) => {
      const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
        nombreArchivo: archivo.name,
      });

      if(peticionArchivoS3?.statuscode === 200){
        const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, archivo);
  
        if(peticionS3?.ok){
          const peticionArchivo = await post("subirDocumento", {
            rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
            idCatDocumento: idCatDocumento,
            idCuestionario: idCuestionario,
            idResPrecalifica: idResPrecalifica
          });
  
          if (peticionArchivo?.statuscode === 200) {
            archivosNuevos.push({
              id: peticionArchivo.respuesta.documento?.idDocsTramite,
              nombreArchivo: peticionArchivo.respuesta.documento?.archivo?.split("/").pop(),
              rutaArchivo: peticionArchivo.respuesta.documento?.archivo
            });
            return true;
          }
        }
      }

      return false;
    });

    const respuestas = await Promise.all(peticionesArchivos);

    let copiaEstadoDocs = [...estadoDocs];
    const index = copiaEstadoDocs.findIndex(doc => doc.idCatDocumento === idCatDocumento);

    if (index !== -1) {
      // el filtro se coloca para evitar que se guarde un registro nulo, esto sucede al intentar cargar dos archivos la mismo tiempo
      copiaEstadoDocs[index].documentos = [...(copiaEstadoDocs[index]?.documentos?.filter(doc => doc.id !== undefined && doc.id !== null)),
      ...archivosNuevos];
      setEstadoDocs(copiaEstadoDocs); 
    }

    if(respuestas.some(respuesta => respuesta === false)){
      Swal.fire({
        title: "No se pudo subir alguno de los archivos",
        text: "Vuelve a intentarlo más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return;
    }
  }

  useEffect(() => {
    const documentacionIncompleta = documentacion.some(doc => doc.documentos.length === 0);

    if(documentacionIncompleta){
      alIncompletar();
    }else{
      alCompletar();
    }
    
  }, [documentacion]);

  useEffect(() => {
    obtDocumentacion(tipoPersona);
  }, [tipoPersona])

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtInformacion();
    const cargarDatos = async () => {
      try {
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
  }, []);

  return (
    <div>
      <h1>Información vendedor y vivienda</h1>
      <p>
        Asegurate de poner la información correcta. Podrás regresar a
        editarlo.
      </p>

      <hr />

      <div className="Resum">
        <div className="header">
          <h3>Datos del vendedor</h3>
        </div>
        <div className="cont_flex cont-form">
          <form onSubmit={handleSubmit(() => guardarInformacion(true))}>
            {/*Tipo vendedor */}
            <div className="col3 campo-form estado marginEspacio">
              <label>Tipo de vendedor </label>
              <select
                {...register("tipoPersona", { required: true })}
                id="tipoPersona"
                name="tipoPersona"
                value={tipoPersona}
                onChange={(ev) => {
                  setTipoPersona(ev.target.value);
                }}
              >
                <option value="">Selecciona</option>
                <option value="1">Moral</option>
                <option value="2">Fisica</option>
              </select>
              {errors.tipoPersona?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*Tipo de inmueble*/}
            <div className="col3 campo-form estado marginEspacio">
              <label>Tipo de inmueble </label>
              <select
                value={tipoInmueble}
                name="tipoInmueble"
                onChange={(ev) => {
                  setTipoInmueble(ev.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                <option value="1">Casa Nueva</option>
                <option value="2">Casa Usada</option>
                <option value="3">Departamento Nuevo</option>
                <option value="4">Departamento Usado</option>
                <option value="5">Terreno</option>
                <option value="6">Otro</option>
              </select>
              {errors.tipoInmueble?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {/*Uso que le dara al inmueble */}
            <div className="col3 campo-form estado marginEspacio">
              <label>Uso que le dara al inmueble</label>
              <select
                value={usoInmueble}
                name="usoInmueble"
                onChange={(ev) => {
                  setUsoInmueble(ev.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                <option value="1">Principal</option>
                <option value="2">Inversion/Descanso</option>
              </select>
              {errors.usoInmueble?.type === "required" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            {tipoPersona === "2" && (
              <div id="personaFisica" name="personaFisica">
                {/* Email */}
                <div className="col3 campo-form">
                  <label>Email</label>
                  <input
                    type="email"
                    value={email}
                    name="emailFisica"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    autoComplete="off"
                    onInput={(ev) => {
                      setEmail(ev.target.value);
                      const isValidEmail = ruleEmail(ev.target.value);
                      if (!isValidEmail) {
                        setInvalidEmail(true);
                      } else {
                        setInvalidEmail(false);
                      }
                    }}
                  />
                  {errors.emailFisica?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                  {invalidEmail && (
                    <span className="span">
                      El formato del correo no es válido
                    </span>
                  )}
                </div>

                {/* Nombre */}
                <div className="col3 campo-form">
                  <label>Nombre </label>

                  <input
                    type="text"
                    value={nombre}
                    name="nombre"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      setNombre(ev.target.value);
                    }}
                  />
                  {errors.nombre?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </div>

                {/* Apellido Paterno */}
                <div className="col3 campo-form">
                  <label>Apellido Paterno</label>
                  <input
                    type="text"
                    value={aPaterno}
                    name="aPaterno"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      setAPaterno(ev.target.value);
                    }}
                  />
                  {errors.aPaterno?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </div>

                {/* Apellido Materno */}
                <div className="col3 campo-form">
                  <label>Apellido Materno</label>
                  <input
                    type="text"
                    value={aMaterno}
                    name="aMaterno"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      setAMaterno(ev.target.value);
                    }}
                  />
                  {errors.aMaterno?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </div>

                {/* Telefono celular */}
                <div className="col3 campo-form">
                  <label>Telefono celular</label>
                  <input
                    type="tel"
                    value={telCel}
                    name="telCel"
                    size="10"
                    maxLength={10}
                    placeholder="Teléfono a 10 dígitos"
                    autoComplete="off"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      // Filtrar solo dígitos
                      const tempTelefono = ev.target.value.replace(/\D/g, "");

                      if (tempTelefono.length <= 10) {
                        setTelCel(tempTelefono);
                        setValidarLongitudTelefono(
                          tempTelefono.length === 10
                        );
                      }
                    }}
                  />
                  {errors.telCel?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                  {validarLongitudTelefono === false && (
                    <span className="span">Ingresar 10 digitos</span>
                  )}
                </div>
              </div>
            )}

            {tipoPersona === "1" && (
              <div id="personaMoral" name="personaMoral">
                {/* Razón social  */}
                <div className="col3 campo-form">
                  <label>Razón social </label>
                  <input
                    type="text"
                    value={razonSocial}
                    name="razonSocial"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      setRazonSocial(ev.target.value);
                    }}
                  />
                  {errors.razonSocial?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </div>

                {/* Nombre contacto de la empresa */}
                <div className="col3 campo-form">
                  <label>Nombre contacto de la empresa </label>
                  <input
                    type="text"
                    value={nombrecontactoEmpresa}
                    name="nombrecontactoEmpresa"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      setNombrecontactoEmpresa(ev.target.value);
                    }}
                  />
                  {errors.nombrecontactoEmpresa?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </div>

                {/* Telefono del contacto */}
                <div className="col3 campo-form">
                  <label>Telefono del contacto</label>
                  <input
                    type="tel"
                    value={telContacto}
                    name="telContacto"
                    size="10"
                    maxLength={10}
                    placeholder="Teléfono a 10 dígitos"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      let tempTelefono = ev.target.value;
                      if (
                        !isNaN(tempTelefono) &&
                        tempTelefono.length === 10
                      ) {
                        setTelContacto(tempTelefono);
                        setValidarLongitudTelefonoMoral(false);
                      } else {
                        setTelContacto(tempTelefono);
                        setValidarLongitudTelefonoMoral(true);
                      }
                    }}
                  />
                  {errors.telContacto?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                  {validarLongitudTelefonoMoral && (
                    <span className="span">Ingresar 10 dígitos</span>
                  )}
                </div>

                {/* Email del contactoo */}
                <div className="col3 campo-form">
                  <label>Email del contacto</label>
                  <input
                    type="email"
                    value={emailContacto}
                    name="email"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    autoComplete="off"
                    onInput={(ev) => {
                      setEmailContacto(ev.target.value);
                      const isValidEmail = ruleEmail(ev.target.value);
                      if (!isValidEmail) {
                        setInvalidEmailMoral(true);
                      } else {
                        setInvalidEmailMoral(false);
                      }
                    }}
                  />
                  {errors.email?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                  {invalidEmailMoral && (
                    <span className="span">
                      El formato del correo no es válido
                    </span>
                  )}
                </div>

                {/* RFC */}
                <div className="col3 campo-form">
                  <label>RFC</label>
                  <input
                    type="text"
                    value={rfc}
                    name="rfc"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      setRfc(ev.target.value);
                    }}
                  />
                  {errors.rfc?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </div>
              </div>
            )}

            {tipoPersona === "" && (
              <div id="mensaje">
                <p>Primero debe seleccionar el tipo de vendedor</p>
              </div>
            )}

            <div className="Resum">
              <div className="header">
                <h3>Dirección de inmueble</h3>
              </div>
              <div className="cont_flex cont-form">
                {/*Codigo postal */}
                <div className="col3 campo-form">
                  <label>C&oacute;digo postal </label>
                  <input
                    type="tel"
                    value={cp}
                    id="cp"
                    name="cp"
                    size={5}
                    maxLength="5"
                    autoComplete="off"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      let tempCP = ev.target.value;
                      if (isNaN(tempCP) === false) {
                        if (tempCP.length < 5) {
                          setValidarLongitudCP(false);
                          setCp(tempCP);
                        } else {
                          if (tempCP.length === 5) {
                            obtenerDataCp(ev.target.value);
                            setCp(tempCP);
                            setValidarLongitudCP(true);
                          }
                        }
                      }
                    }}
                  />
                  <small>
                    Al ingresar su código postal correcto, en automático se
                    registrará su estado y municipio.
                  </small>
                  {errors.cp?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </div>

                {/*Calle */}
                <div className="col3 campo-form">
                  <label>Calle </label>
                  <input
                    type="text"
                    value={calle}
                    name="calle"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      setCalle(ev.target.value);
                    }}
                  />
                  {errors.calle?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </div>

                {/*Num. Ext */}
                <div className="col3 campo-form">
                  <label>Num. Ext </label>
                  <input
                    type="number"
                    value={numExt}
                    name="numExt"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      setNumExt(ev.target.value);
                    }}
                  />
                  {errors.numExt?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </div>

                {/*Num. Int */}
                <div className="col3 campo-form">
                  <label>Num. Int </label>
                  <input
                    type="number"
                    value={numInt}
                    name="numInt"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      setNumInt(ev.target.value);
                    }}
                  />
                  {errors.numInt?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </div>

                {/*Estado*/}
                <div className="col3 campo-form">
                  <label>Estado</label>
                  <input
                    type="text"
                    value={estado}
                    name="estado"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      setEstado(ev.target.value);
                    }}
                  />
                  {errors.estado?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </div>

                {/*Delegacion*/}
                <div className="col3 campo-form">
                  <label>Delegaci&oacute;n</label>
                  <input
                    type="text"
                    value={estadoAux}
                    name="delegacion"
                    disabled={tipoPersona === "" || tipoPersona === "0"}
                    onInput={(ev) => {
                      setEstadoAux(ev.target.value);
                    }}
                  />
                  {errors.delegacion?.type === "required" && (
                    <span className="span">Este campo es requerido</span>
                  )}
                </div>

                {/*Colonia*/}
                <div className="col3 campo-form colonia">
                  <label htmlFor="coloniaSelect">Colonia</label>
                  {error ? (
                    <input
                      type="text"
                      id="colonia"
                      value={colonia}
                      name="colonia"
                      onChange={(ev) => {
                        setColonia(ev.target.value);
                      }}
                    />
                  ) : (
                    <select
                      type="text"
                      id="colonia"
                      value={colonia}
                      name="colonia"
                      onChange={(ev) => {
                        setColonia(ev.target.value);
                      }}
                    >
                      {listColonias}
                    </select>
                  )}
                </div>

                <br />
                <br />
                <hr />
                <br />

                <div>
                  <div className="header">
                    <h3>Documentos</h3>
                  </div>
                  <br/>

                  <div className="cont-cards-archivos">
                    {
                      documentacion?.map(documento => {
                        if(documento?.multiplesArchivos === true){
                          return(
                            <CardArchivos
                              titulo={documento?.titulo}
                              descripcion={documento?.nota}
                              archivos={documento?.documentos ?? []}
                              onUpload={(archivos) => subirArchivos(archivos, documento.idCatDocumento, documentacion, setDocumentacion)}
                              onDelete={(idDocsTramite)  => eliminarArchivo(idDocsTramite, documento.idCatDocumento, documentacion, setDocumentacion)}
                            />
                          );
                        }else{
                          return (
                            <CardArchivo
                              titulo={documento?.titulo}
                              descripcion={documento?.nota}
                              archivo={documento?.documentos?.[0] ?? {}}
                              onUpload={(archivo) => subirArchivo(archivo, documento.idCatDocumento, documentacion, setDocumentacion)}
                              onDelete={(idDocsTramite)  => eliminarArchivo(idDocsTramite, documento.idCatDocumento, documentacion, setDocumentacion)}
                            />
                          );
                        }
                      })
                    }
                  </div>
                </div>
                <br/>

                <div>
                  <button  type="submit" className="btn btn_morado estiloBotonVivienda">
                    Guardar
                  </button >
                </div>
                <p>
                  Si realizaste un cambio recuerda guardar antes de salir.
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function DocumentosViviendaDocs({ idCuestionario, idResPrecalifica, alCompletar = () => {}, alIncompletar = () => {} }) {
  const [isLoading, setIsLoading] = useState(true);

  const [documentacion, setDocumentacion] = useState([]);

  async function verificarDocumento(idCatDocumento, idCuestionario, idResPrecalifica) {
    const peticionDocTramite = await post("obtInfoDocumentacionTramite", {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idCatDocumento: idCatDocumento,
    });

    let nota = "";
    switch (idCatDocumento) {
      case 2:
        nota = "Asegura que este firmado, vigente, legible y asegura que tenga la información correcta de la propiedad a adquirir.";
        break;

      case 3:
        nota = "Asegura que sea legible y reciente.";
        break;

      case 4:
        nota = "Asegura que sea legible y reciente.";
        break;

      case 5:
        nota = "Asegura que el documento este completo, sea legible y cuente con la hoja de inscripción al registro público de la propiedad.";
        break;

      case 16:
        nota = "Asegura que sean legibles y vigentes."
        break;

      default:
        break;
    }

    if (peticionDocTramite?.statuscode === 200) {
      let documentos = [];

      if(peticionDocTramite.respuesta.documentacion.length > 0){
        if (idCatDocumento === 16 || idCatDocumento === 13 || idCatDocumento === 8){
          documentos = peticionDocTramite.respuesta.documentacion?.map(documento => {
            return {
              id: documento.idDocsTramite,
              nombreArchivo: documento.archivo?.split("/").pop(),
              rutaArchivo: documento.archivo,
            }
          });
        }else{
          documentos.push({
            id: peticionDocTramite.respuesta.documentacion[0]?.idDocsTramite,
            nombreArchivo: peticionDocTramite.respuesta.documentacion[0]?.archivo?.split("/").pop(),
            rutaArchivo: peticionDocTramite.respuesta.documentacion[0]?.archivo,
          });
        }
      }
      
      return {
        documentos: documentos,
        nota: nota
      }
    } else {
      return null;
    }
  };

  async function obtDocumentacion(tipo){
    let peticionDocs = await get("getDocumentosByPantalla", "3");

    if (peticionDocs?.statuscode === 200) {
      const docsTipoPersona = peticionDocs.documentos[tipo].documentos;

      const verificarDocumentos = docsTipoPersona.map(async (doc) => {
        const docVerificado = await verificarDocumento(doc.idCatDocumentos, idCuestionario, idResPrecalifica) 

        return docVerificado !== null ? {
          idCatDocumento: doc.idCatDocumentos,
          titulo: doc.nombre,
          multiplesArchivos: doc.multiplesArchivos,
          ...docVerificado
        } : null ;
      });

      const documentosVerificados = (await Promise.all(verificarDocumentos)).filter(verificacion => verificacion !== null);
      setDocumentacion(documentosVerificados);
    }else{
      setDocumentacion([]);
    }
  }

  async function eliminarArchivo(idDocsTramite, idCatDocumento, estadoDocs, setEstadoDocs){
    const peticionEliminarArchivo = await deleteDocEndpoint("borrarDocumentoEspecifico", {
      idDocsTramite: idDocsTramite,
      idCatDocumento: idCatDocumento,
      idCuestionario: idCuestionario,
      idResPrecalifica: idResPrecalifica
    });

    if (peticionEliminarArchivo.statuscode === 200) {
      let copiaEstadoDocs = [...estadoDocs];
      const index = copiaEstadoDocs.findIndex(doc => doc.idCatDocumento === idCatDocumento);

      if (index !== -1) {
        copiaEstadoDocs[index].documentos = copiaEstadoDocs[index]?.documentos.filter(doc => doc.id !== idDocsTramite);
        setEstadoDocs(copiaEstadoDocs);

        Swal.fire({
          title: "Documento borrado correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      Swal.fire({
        title: "No se pudo borrar el documento",
        text: "Intente más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
    
  }

  async function subirArchivo(archivo, idCatDocumento, estadoDocs, setEstadoDocs){
    const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
      nombreArchivo: archivo.name,
    });

    if(peticionArchivoS3?.statuscode === 200){
      const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, archivo);

      if(peticionS3?.ok){
        const peticionArchivo = await post("subirDocumento", {
          rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
          idCatDocumento: idCatDocumento,
          idCuestionario: idCuestionario,
          idResPrecalifica: idResPrecalifica
        });

        if (peticionArchivo?.statuscode === 200) {
          let copiaEstadoDocs = [...estadoDocs];
          const index = copiaEstadoDocs.findIndex(doc => doc.idCatDocumento === idCatDocumento);

          if (index !== -1) {
            // el filtro se coloca para evitar que se guarde un registro nulo, esto sucede al intentar cargar dos archivos la mismo tiempo
            copiaEstadoDocs[index].documentos = [...(copiaEstadoDocs[index]?.documentos?.filter(doc => doc.id !== undefined && doc.id !== null)), {
              id: peticionArchivo.respuesta.documento?.idDocsTramite,
              nombreArchivo: peticionArchivo.respuesta.documento?.archivo?.split("/").pop(),
              rutaArchivo: peticionArchivo.respuesta.documento?.archivo
            }];
            setEstadoDocs(copiaEstadoDocs); 
          }
        }else{
          Swal.fire({
            title: "No se pudo subir el documento",
            text: "Intente más tarde",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  }

  async function subirArchivos(archivos, idCatDocumento, estadoDocs, setEstadoDocs){
    const archivosNuevos = [];

    const peticionesArchivos = archivos.map(async (archivo) => {
      const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
        nombreArchivo: archivo.name,
      });

      if(peticionArchivoS3?.statuscode === 200){
        const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, archivo);
  
        if(peticionS3?.ok){
          const peticionArchivo = await post("subirDocumento", {
            rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
            idCatDocumento: idCatDocumento,
            idCuestionario: idCuestionario,
            idResPrecalifica: idResPrecalifica
          });
  
          if (peticionArchivo?.statuscode === 200) {
            archivosNuevos.push({
              id: peticionArchivo.respuesta.documento?.idDocsTramite,
              nombreArchivo: peticionArchivo.respuesta.documento?.archivo?.split("/").pop(),
              rutaArchivo: peticionArchivo.respuesta.documento?.archivo
            });
            return true;
          }
        }
      }

      return false;
    });

    const respuestas = await Promise.all(peticionesArchivos);

    let copiaEstadoDocs = [...estadoDocs];
    const index = copiaEstadoDocs.findIndex(doc => doc.idCatDocumento === idCatDocumento);

    if (index !== -1) {
      // el filtro se coloca para evitar que se guarde un registro nulo, esto sucede al intentar cargar dos archivos la mismo tiempo
      copiaEstadoDocs[index].documentos = [...(copiaEstadoDocs[index]?.documentos?.filter(doc => doc.id !== undefined && doc.id !== null)),
      ...archivosNuevos];
      setEstadoDocs(copiaEstadoDocs); 
    }

    if(respuestas.some(respuesta => respuesta === false)){
      Swal.fire({
        title: "No se pudo subir alguno de los archivos",
        text: "Vuelve a intentarlo más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return;
    }
  }

  useEffect(() => {
    const documentacionIncompleta = documentacion.some(doc => doc.documentos.length === 0);

    if(documentacionIncompleta){
      alIncompletar();
    }else{
      alCompletar();
    }

  }, [documentacion]);

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        await obtDocumentacion(0); // 0 es el identificador para los documentos de esta sección
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
  }, []);

  return (
    <div>
      <h1>Documentaci&oacute;n vivienda</h1>
      <p>
        Debes de poner los documentos de la vivienda que deseas comprar, la
        persona que te est&aacute; vendiendo la vivienda debe de darte esta
        informaci&oacute;n.
      </p>
      <hr />
      <br />

      <div className="cont-cards-archivos">
        {
          documentacion?.map(documento => {
            if(documento?.multiplesArchivos === true){
              return(
                <CardArchivos
                  titulo={documento?.titulo}
                  descripcion={documento?.nota}
                  archivos={documento?.documentos ?? []}
                  onUpload={(archivos) => subirArchivos(archivos, documento.idCatDocumento, documentacion, setDocumentacion)}
                  onDelete={(idDocsTramite)  => eliminarArchivo(idDocsTramite, documento.idCatDocumento, documentacion, setDocumentacion)}
                />
              );
            }else{
              return (
                <CardArchivo
                  titulo={documento?.titulo}
                  descripcion={documento?.nota}
                  archivo={documento?.documentos?.[0] ?? {}}
                  onUpload={(archivo) => subirArchivo(archivo, documento.idCatDocumento, documentacion, setDocumentacion)}
                  onDelete={(idDocsTramite)  => eliminarArchivo(idDocsTramite, documento.idCatDocumento, documentacion, setDocumentacion)}
                />
              );
            }
          })
        }
      </div>

    </div>
  );
}

function PagoAvaluo({ idCuestionario, idResPrecalifica, alCompletar = () => {}, alIncompletar = () => {} }) {
  const [pagoAvaluoData, setPagoAvaluoData] = useState({});
  const [comprobanteAvaluo, setComprobanteAvaluo] = useState(null);

  //Formatear números a pesos
  const GetNF = (value) => {
    // console.log(value);
    if (value !== undefined) {
      return value.toLocaleString("es-MX", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return 0;
    }
  };

  //Obtenemos información general
  const obtInformacion = async () => {
    let data = await get("getPagoAvaluo", idResPrecalifica);
    //console.log(data);
    if (data.statuscode === 200) {
      localStorage.setItem("pagoAvaluo", data.avaluo[0]['monto'] );
      setPagoAvaluoData(() => ({
        ...pagoAvaluoData,
        ...data.avaluo[0],
      }));
    } else {
      //en caso de no tener informacion esto se ejecutara
      //console.log("Sin informacion");
    }
  };

  async function subirArchivo(archivo, documento, setDocumento){
    const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
      nombreArchivo: archivo.name,
    });

    if(peticionArchivoS3?.statuscode === 200){
      const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, archivo);

      if(peticionS3?.ok){
        const peticionSolicitud = await post("subirDocumento", {
          rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
          idCatDocumento: documento?.idCatDocumento,
          idCuestionario: idCuestionario,
          idResPrecalifica: idResPrecalifica
        });

        if (peticionSolicitud?.statuscode === 200) {
          const archivo = {
            id: peticionSolicitud.respuesta.documento?.idDocsTramite,
            nombreArchivo: peticionSolicitud.respuesta.documento?.archivo?.split("/").pop(),
            rutaArchivo: peticionSolicitud.respuesta.documento?.archivo,
          }
          setDocumento({...documento, archivo: archivo});
        }else{
          Swal.fire({
            title: "No se pudo subir el documento",
            text: "Intente más tarde",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  }

  async function eliminarArchivo(idDocsTramite, documento, setDocumento){
    const peticionEliminarArchivo = await deleteDocEndpoint("borrarDocumentoEspecifico", {
      idDocsTramite: idDocsTramite,
      idCatDocumento: documento?.idCatDocumento,
      idCuestionario: idCuestionario,
      idResPrecalifica: idResPrecalifica
    });

    if (peticionEliminarArchivo.statuscode === 200) {
      setDocumento({...documento, archivo: null});

      Swal.fire({
        title: "Documento borrado correctamente",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    } else {
      Swal.fire({
        title: "No se pudo borrar el documento",
        text: "Intente más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  async function obtComprobante(){
    const peticionSolicitud = await post("obtInfoDocumentacionTramite", {
      idCatDocumento: 17,
      idCuestionario: idCuestionario,
      idResPrecalifica: idResPrecalifica,
    });

    const archivo = peticionSolicitud?.statuscode === 200 && peticionSolicitud?.respuesta?.documentacion.length > 0 ? {
      id: peticionSolicitud.respuesta.documentacion?.[0]?.idDocsTramite,
      nombreArchivo: peticionSolicitud.respuesta.documentacion?.[0]?.archivo.split("/").pop(),
      rutaArchivo: peticionSolicitud.respuesta.documentacion?.[0]?.archivo,
    } : null;

    setComprobanteAvaluo({idCatDocumento: 17, archivo: archivo});
  }

  useEffect(() => {
    if(comprobanteAvaluo?.archivo){
      alCompletar();
    }else{
      alIncompletar();
    }
  }, [comprobanteAvaluo?.archivo]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtInformacion();
    obtComprobante();
  }, []);

  return (
    <>
      <div>
        <h1>Pago de aval&uacute;o</h1>
        <p>
          Es necesario pagar el aval&uacute;o, puedes pagarlo siguiendo las
          instrucciones de pago y anexando el comprobante de pago o si lo
          prefieres puedes pagarlo directamente aqu&iacute; utilizando tu
          tarjeta de d&eacute;bito o cr&eacute;dito.
        </p>

        <div className="cont_flex con_flex_wrap resumen">
          <h3>Instrucciones de Pago de Avaluo</h3>

          <div className="bg_gris">
            <p>{pagoAvaluoData.instruccionesPagoAvaluo}</p>
          </div>

          <div className="bg_gris">
            <p>Monto</p>
            <p>{pagoAvaluoData?.monto && ("$" + GetNF(parseFloat(pagoAvaluoData?.monto)))}</p>
          </div>
        </div>

        <div className="cont2cards">
          <CardArchivo
            titulo="Comprobante de pago"
            descripcion="Sube o descarga el comprobante de pago de tu cliente."
            archivo={comprobanteAvaluo?.archivo ?? {}}
            onUpload={(archivo) => subirArchivo(archivo, comprobanteAvaluo, setComprobanteAvaluo)}
            onDelete={(idDocsTramite) => eliminarArchivo(idDocsTramite, comprobanteAvaluo, setComprobanteAvaluo)}
          />
        </div>

      </div>
    </>
  );
}

export function FormularioInmueble({ idCuestionario, idResPrecalifica, alCompletar = () => {} }){
  const [pop, setPop] = useState(null);
  const [seccionesInmueble, setSeccionesInmueble] = useState([]);

  const btnsVivienda = [
    {id: 1, titulo: "Información y documentación del vendedor", accion: () => {setPop(<InfoVendedorVivienda idCuestionario={idCuestionario} idResPrecalifica={idResPrecalifica} alCompletar={() => {
      setSeccionesInmueble(prevSecciones => [...prevSecciones, 1]);
      alCompletar();
    }} alIncompletar={() => setSeccionesInmueble(prevSecciones => prevSecciones.filter(seccion => seccion !== 1))} />)}},
    {id: 2, titulo: "Documentacion de la vivienda", accion: () => {setPop(<DocumentosViviendaDocs idCuestionario={idCuestionario} idResPrecalifica={idResPrecalifica} alCompletar={() => {
      setSeccionesInmueble(prevSecciones => [...prevSecciones, 2]);
      alCompletar();
    }} alIncompletar={() => setSeccionesInmueble(prevSecciones => prevSecciones.filter(seccion => seccion !== 2))} />)}},
    {id: 3, titulo: "Avalúo", accion: () => {setPop(<PagoAvaluo idCuestionario={idCuestionario} idResPrecalifica={idResPrecalifica} alCompletar={() => {
      setSeccionesInmueble(prevSecciones => [...prevSecciones, 3]);
      alCompletar();
    }} alIncompletar={() => setSeccionesInmueble(prevSecciones => prevSecciones.filter(seccion => seccion !== 3))} />)}},
  ];

  async function obtEstatusInmueble(){
    const peticionInfoVendedor = get("getVerificarVendedorVivienda", idResPrecalifica);
    const peticionDocVivienda = get("getVerificarDocumentacionVivienda", idResPrecalifica);
    const peticionPagoAvaluo = get("getVerificarPagoAvaluo", idResPrecalifica);

    const respuestas = await Promise.all([peticionInfoVendedor, peticionDocVivienda, peticionPagoAvaluo]);

    if(respuestas[0]?.statuscode === 200){
      setSeccionesInmueble(prevSecciones => [...prevSecciones, 1]);
    }else{
      setSeccionesInmueble(prevSecciones => prevSecciones.filter(seccion => seccion !== 1));
    }

    if(respuestas[1]?.statuscode === 200){
      setSeccionesInmueble(prevSecciones => [...prevSecciones, 2]);
    }else{
      setSeccionesInmueble(prevSecciones => prevSecciones.filter(seccion => seccion !== 2));
    }

    if(respuestas[2]?.statuscode === 200){
      setSeccionesInmueble(prevSecciones => [...prevSecciones, 3]);
    }else{
      setSeccionesInmueble(prevSecciones => prevSecciones.filter(seccion => seccion !== 3));
    }
  }

  useEffect(() => {
    obtEstatusInmueble();
  }, [idResPrecalifica]);

  return(
    <div className="cont_flex cont-form">
      {
        pop !== null && (
          <ModalWrapper onClose={() => setPop(null)}>
            {pop}
          </ModalWrapper>
        )
      }

      <div className="cont_Info docVivienda">
        <div className="cont_flex con_flex_wrap responsivoColumn">
          {
            btnsVivienda?.map((btn) => {
              return(
                <div className={`cont_loop margin0 ${seccionesInmueble.includes(btn.id) ? "documentoSubido" : ""}`}>
                  <h3>{btn?.titulo}</h3>
                  <button className="btn_morado circulo"
                    onClick={btn?.accion}
                  >
                    <svg
                      className="logo_mobil"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5 7L17.0303 7.53033L17.5607 7L17.0303 6.46967L16.5 7ZM1.5 6.25C1.08579 6.25 0.75 6.58579 0.75 7C0.75 7.41421 1.08579 7.75 1.5 7.75V6.25ZM9.96967 1.53033L15.9697 7.53033L17.0303 6.46967L11.0303 0.46967L9.96967 1.53033ZM15.9697 6.46967L9.96967 12.4697L11.0303 13.5303L17.0303 7.53033L15.9697 6.46967ZM16.5 6.25H1.5V7.75H16.5V6.25Z"
                        fill="#FAFAFA"
                      />
                    </svg>
                  </button>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export function FormularioAvaluo({ idCuestionario, idResPrecalifica, alCompletar = () => {} }){
  const [nuevoRegistro, setNuevoRegistro] = useState(true);
  const [nuevoComentario, setNuevoComentario] = useState(true);

  const [direccionNueva, setDireccionNueva] = useState(false);
  const [catColonias, setCatColonias] = useState([]);
  
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      valorCompraventa: "$ 0.00",
      valorAvaluo: "$ 0.00"
    }
  });

  const obtenerDataCp = async (cp) => {
    let respuestaPorCP = await getCP("info_cp", cp);

    obtenerClaveIso(respuestaPorCP.response.estado);
    setDireccionNueva(true);
    setCatColonias(respuestaPorCP.response.asentamiento);
    setValue("delegacion", respuestaPorCP.response.municipio);
  };

  const obtenerClaveIso = async (estadoCP) => {
    let obtenerClave = await get("obtClaveISOPorEstado", estadoCP);
    if (obtenerClave.statuscode === 200) {
      setValue("estado", obtenerClave.iso);
    }else{
      setValue("estado", estadoCP);
    }
  }

  const guardarDatos = async (data) => {
    const datosAvaluo = {
      fechaAsignacion: data.fechaAsignacion ? formatoFechaDmaAIso(data.fechaAsignacion) : null,
      valorCompraventa: Number(data.valorCompraventa.replace(/[^0-9.]/g, "")),
      nombreUnidadValuadora: data.nombreUnidadValuadora ? data.nombreUnidadValuadora : null,
      estado: data.estado ? data.estado : null,
      delegacion: data.delegacion ? data.delegacion : null,
      colonia: data.colonia ? data.colonia : null,
      cp: data.cp ? data.cp : null,
      calle: data.calle ? data.calle : null,
      numeroExterior: data.numeroExterior,
      numeroInterior: data.numeroInterior,
      fechaElaboracionAvaluo: data.fechaElaboracionAvaluo ? formatoFechaDmaAIso(data.fechaElaboracionAvaluo) : null,
      valorAvaluo: data.valorAvaluo.replace(/[^0-9.]/g, ""),
      fechaCierreAvaluo: data.fechaCierreAvaluo ? formatoFechaDmaAIso(data.fechaCierreAvaluo) : null,
      vigenciaAvaluo: data.vigenciaAvaluo ? formatoFechaDmaAIso(data.vigenciaAvaluo) : null,
      resultadoAvaluo: data.resultadoAvaluo ? data.resultadoAvaluo : null,
      comentariosAvaluo: data.comentariosAvaluo
    }

    const params = {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      fechaAsignacion: datosAvaluo.fechaAsignacion,
      valorCompraVentaEstimado: datosAvaluo.valorCompraventa,
      nombreUnidad: datosAvaluo.nombreUnidadValuadora,
      estado: datosAvaluo.estado,
      municipio: datosAvaluo.delegacion,
      colonia: datosAvaluo.colonia,
      cp: datosAvaluo.cp,
      calle: datosAvaluo.calle,
      numExt: datosAvaluo.numeroExterior,
      numInt: datosAvaluo.numeroInterior,
      numFolio: "",
      fechaCita: datosAvaluo.fechaElaboracionAvaluo,
      valorAvaluo: datosAvaluo.valorAvaluo,
      fechaCierre: datosAvaluo.fechaCierreAvaluo,
      vigencia: datosAvaluo.vigenciaAvaluo,
      resAvaluo: datosAvaluo.resultadoAvaluo,
    }

    const paramsNota = {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idCatPantallas: 4,
      nombre: "Avaluo",
      nota1: data.comentariosAvaluo
    }

    const peticionAvaluo = nuevoRegistro ? await post("ejecutivo/crearAvaluo", params) 
      : await put("updateAvaluo", params);

    if(peticionAvaluo?.statuscode === 200){
      const peticionComentario = nuevoComentario ? await post("addNota", paramsNota) 
        : await put("actualizarNota", paramsNota);

      if(peticionComentario?.statuscode === 200){
        Swal.fire({
          icon: "success",
          title: "Datos guardados correctamente",
          confirmButtonText: "Aceptar",
        });

        if(nuevoComentario){
          setNuevoComentario(false);
        }
      }else{
        Swal.fire({
          icon: "error",
          title: "Error al guardar comentario",
          text: "Inténtalo nuevamente más tarde.",
          confirmButtonText: "Aceptar",
        });
      }

      if(nuevoRegistro){
        setNuevoRegistro(false);
      }
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al guardar datos",
        text: "Inténtalo nuevamente más tarde.",
        confirmButtonText: "Aceptar",
      });
    }
  }

  async function enviarAvaluo(){
    const peticionAvaluo = await get("ejecutivo/enviarAvaluo", idResPrecalifica);

    if(peticionAvaluo?.statuscode === 200){
      Swal.fire({
        icon: "success",
        title: "Datos enviados correctamente",
        confirmButtonText: "Aceptar",
      });
      alCompletar();
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al enviar datos",
        text: peticionAvaluo?.msg,
        confirmButtonText: "Aceptar",
      });
    }
  }

  async function obtDatosAvaluo(){
    const peticionAvaluo = await get("getAvaluo", idResPrecalifica);

    if(peticionAvaluo?.statuscode === 200){
      const respuestaAvaluo = peticionAvaluo.avaluo[0];

      setValue("fechaAsignacion", formatoFechaIsoADma(respuestaAvaluo?.fechaAsignacion ?? ""));
      setValue("valorCompraventa", formatoMoneda(respuestaAvaluo?.valorCompraVentaEstimado ?? 0));
      setValue("nombreUnidadValuadora", respuestaAvaluo?.nombreUnidad ?? "");
      setValue("estado", respuestaAvaluo?.estado ?? "");
      setValue("delegacion", respuestaAvaluo?.municipio ?? "");
      setValue("colonia", respuestaAvaluo?.colonia ?? "");
      setValue("cp", respuestaAvaluo?.cp ?? "");
      setValue("calle", respuestaAvaluo?.calle ?? "");
      setValue("numeroExterior", respuestaAvaluo?.numExt ?? "");
      setValue("numeroInterior", respuestaAvaluo?.numInt ?? "");
      setValue("fechaElaboracionAvaluo", formatoFechaIsoADma(respuestaAvaluo?.fechaCita ?? ""));
      setValue("valorAvaluo", formatoMoneda(respuestaAvaluo?.valorAvaluo ?? 0));
      setValue("fechaCierreAvaluo", formatoFechaIsoADma(respuestaAvaluo?.fechaCierre ?? ""));
      setValue("vigenciaAvaluo", formatoFechaIsoADma(respuestaAvaluo?.vigencia ?? ""));
      setValue("resultadoAvaluo", respuestaAvaluo?.resAvaluo ?? "");

      setNuevoRegistro(false);
    }else{
      setValue("fechaAsignacion", "");
      setValue("valorCompraventa", formatoMoneda(0));
      setValue("nombreUnidadValuadora", "");
      setValue("estado", "");
      setValue("delegacion", "");
      setValue("colonia", "");
      setValue("cp", "");
      setValue("calle", "");
      setValue("numeroExterior", "");
      setValue("numeroInterior", "");
      setValue("fechaElaboracionAvaluo", "");
      setValue("valorAvaluo", formatoMoneda(0));
      setValue("fechaCierreAvaluo", "");
      setValue("vigenciaAvaluo", "");
      setValue("resultadoAvaluo", "");

      setNuevoRegistro(true);
    }
  }

  async function obtNotas() {
    const peticionNota = await post("getNotas", {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idPantallaActual: 4
    });

    if(peticionNota?.statuscode === 200){
      setValue("comentariosAvaluo", peticionNota.infoNotas.nota1);
      setNuevoComentario(false);
    }else{
      setValue("comentariosAvaluo", "");
      setNuevoComentario(true);
    }
  }

  useEffect(() => {
    obtDatosAvaluo();
    obtNotas();
  }, [idCuestionario, idResPrecalifica]);

  return(
    <div className="cont_flex cont-form">
      <form onSubmit={handleSubmit(guardarDatos)}>
        <div className="header">
          <h3>Asignación de unidad de valuación</h3>
        </div>

        <div className="col3 campo-form">
          <label>Fecha de asignación</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("fechaAsignacion", {required: false, validate: fecha => fecha === "" ? true : ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.fechaAsignacion?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.fechaAsignacion?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>
        <div className="col3 campo-form">
          <label>Valor compraventa</label>
          <input
            type="text"
            autoComplete="off"
            {...register("valorCompraventa")}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
          {errors.valorCompraventa?.type === "validate" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Nombre de unidad valuadora</label>
          <input
            type="text"
            maxLength={50}
            autoComplete="off"
            {...register("nombreUnidadValuadora", { required: false })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          />
          {errors.nombreUnidadValuadora?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>

        <br/><br/>
        <hr/>

        <div className="header">
          <h3>Domicilio de garantía</h3>
        </div>

        <div className="col3 campo-form">
          <label>Calle</label>
          <input
            type="text"
            maxLength={200}
            autoComplete="off"
            {...register("calle", { required: false })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ# ]/g, "");
            }}
          />
          {errors.calle?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Número exterior</label>
          <input
            type="text"
            maxLength={50}
            autoComplete="off"
            {...register("numeroExterior", { required: false })}
          />
          {errors.numeroExterior?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Número interior</label>
          <input
            type="text"
            maxLength={50}
            autoComplete="off"
            {...register("numeroInterior")}
          />
        </div>

        <div className="col3 campo-form">
          <label>Codigo Postal</label>
          <input
            type="tel"
            minLength={5}
            maxLength={5}
            autoComplete="off"
            {...register("cp", { required: false, validate: (cp) => cp === "" ? true : ruleCP(cp) })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9 ]/g, "");
              if (e.target.value.length === 5) {
                obtenerDataCp(e.target.value);
              }
            }}
          />
          {errors.cp?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.cp?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>
        <div className="col3 campo-form">
          <label>Estado</label>
          <input
            disabled
            type="text"
            maxLength={50}
            autoComplete="off"
            {...register("estado", { required: false })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          />
          {errors.estado?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Delegación / Municipio</label>
          <input
            disabled
            type="text"
            maxLength={50}
            autoComplete="off"
            {...register("delegacion", { required: false })}
          />
          {errors.delegacion?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>

        <div className="col3 campo-form marginEspacio">
          <label>Colonia</label>
          {
            direccionNueva ? (
              <select
                id="selectColonia"
                className="width100"
                {...register("colonia", { required: false })}
              >
                <option value="" selected disabled>
                  Seleccione una opción...
                </option>
                {
                  catColonias.map((colonia) => (
                    <option value={colonia} key={colonia}>
                      {colonia}
                    </option>
                  ))
                }
              </select>
            ) : (
              <input
                type="text"
                disabled
                value={getValues("colonia")}
              />
            )
          }
          {errors.colonia?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Cita para la elaboración del avalúo</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("fechaElaboracionAvaluo", {required: false, validate: fecha => fecha === "" ? true : ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.fechaElaboracionAvaluo?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.fechaElaboracionAvaluo?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>

        <br/><br/>
        <hr/>

        <div className="header">
          <h3>Resultado de avalúo</h3>
        </div>
        
        <div className="col3 campo-form">
          <label>Valor avaluo</label>
          <input
            type="text"
            autoComplete="off"
            {...register("valorAvaluo")}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
          {errors.valorAvaluo?.type === "validate" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Fecha de cierre del avalúo</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("fechaCierreAvaluo", {required: false, validate: fecha => fecha === "" ? true : ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.fechaCierreAvaluo?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.fechaCierreAvaluo?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>
        <div className="col3 campo-form">
          <label>Vigencia del avalúo</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("vigenciaAvaluo", {required: false, validate: fecha => fecha === "" ? true : ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.vigenciaAvaluo?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.vigenciaAvaluo?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>

        <div className="col3 campo-form">
          <label>Resultado avalúo</label>
          <input
            type="text"
            maxLength={50}
            autoComplete="off"
            {...register("resultadoAvaluo", { required: false })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          />
          {errors.resultadoAvaluo?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <br/><br/>
        
        <div>
          <label>Comentarios:</label>

          <textarea
            {...register("comentariosAvaluo", { required: false })}
            className="width100"
            rows={5}
            maxLength={200}
          ></textarea>
        </div>
        <br/>

        <button 
          type="submit" 
          className="btn btn_morado"
        >
          Guardar
        </button>
        
        <button 
          type="button" 
          className="btn btn_bco"
          onClick={enviarAvaluo}
        >
          Enviar al cliente
        </button>

        <br/><br/>

      </form>
    </div>
  );
}

export function FormularioNotaria({ idCuestionario, idResPrecalifica, alCompletar = () => {} }){
  const [nuevoRegistro, setNuevoRegistro] = useState(true);
  const [nuevoComentario, setNuevoComentario] = useState(true);
  
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const guardarDatos = async (data) => {
    const datosNotaria = {
      estado: data.estado === "" ? null : data.estado,
      notaria: data.numNotaria === "" ? null : data.numNotaria,
      nombreNotario: data.nombreNotario === "" ? null : data.nombreNotario,
      abogado: data.abogado === "" ? null : data.abogado,
      telefono: data.telefono === "" ? null : data.telefono,
      direccion: data.direccion === "" ? null : data.direccion,

      certificadoAgua: Number(data.certificadoAgua),
      fechaCertificadoAgua: data.fechaAgua === "" ? null : formatoFechaDmaAIso(data.fechaAgua),
      predio: Number(data.predio),
      fechaPredio: data.fechaPredio === "" ? null : formatoFechaDmaAIso(data.fechaPredio),
      libreGravamen: Number(data.libreGravamen),
      fechaLibreGravamen: data.fechaLibreGravamen === "" ? null : formatoFechaDmaAIso(data.fechaLibreGravamen),
      constanciaZonificacion: Number(data.constanciaZonificacion),
      fechaConstanciaZonificacion: data.fechaZonificacion === "" ? null : formatoFechaDmaAIso(data.fechaZonificacion)
    }

    const params = {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      estado: datosNotaria.estado,
      notaria: datosNotaria.notaria,
      nombreNotario: datosNotaria.nombreNotario,
      abogado: datosNotaria.abogado,
      telefono: datosNotaria.telefono,
      direccion: datosNotaria.direccion,
      certificados: [
        {idCatCertificados: 1, estatus: datosNotaria.certificadoAgua, fechaCertificado: datosNotaria.fechaCertificadoAgua},
        {idCatCertificados: 2, estatus: datosNotaria.predio, fechaCertificado: datosNotaria.fechaPredio},
        {idCatCertificados: 3, estatus: datosNotaria.libreGravamen, fechaCertificado: datosNotaria.fechaLibreGravamen},
        {idCatCertificados: 4, estatus: datosNotaria.constanciaZonificacion, fechaCertificado: datosNotaria.fechaConstanciaZonificacion}
      ]
    }

    const paramsNota = {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idCatPantallas: 11,
      nombre: "Notaria asignada",
      nota1: data.comentariosNotaria
    }

    const peticionNotaria = nuevoRegistro ? await post("ejecutivo/crearNotariaAsignada", params) 
      : await put("ejecutivo/actualizarNotariaAsignada", params);

    if(peticionNotaria?.statuscode === 200){
      const peticionComentario = nuevoComentario ? await post("addNota", paramsNota) 
        : await put("actualizarNota", paramsNota);

      if(peticionComentario?.statuscode === 200){
        Swal.fire({
          icon: "success",
          title: "Datos guardados correctamente",
          confirmButtonText: "Aceptar",
        });

        if(nuevoComentario){
          setNuevoComentario(false);
        }
      }else{
        Swal.fire({
          icon: "error",
          title: "Error al guardar comentario",
          text: "Inténtalo nuevamente más tarde.",
          confirmButtonText: "Aceptar",
        });
      }

      if(nuevoRegistro){
        setNuevoRegistro(false);
      }
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al guardar datos",
        text: "Inténtalo nuevamente más tarde.",
        confirmButtonText: "Aceptar",
      });
    }
  }

  async function enviarNotaria(){
    const peticionNotaria = await get("ejecutivo/enviarNotariaAsignada", idResPrecalifica);

    if(peticionNotaria?.statuscode === 200){
      Swal.fire({
        icon: "success",
        title: "Datos enviados correctamente",
        confirmButtonText: "Aceptar",
      });
      alCompletar();
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al enviar datos",
        text: peticionNotaria?.msg,
        confirmButtonText: "Aceptar",
      });
    }
  }

  async function obtDatosNotaria(){
    const peticionNotaria = await get("ejecutivo/obtNotariaAsignada", idResPrecalifica);

    if(peticionNotaria?.statuscode === 200){
      const respuestaNotaria = peticionNotaria.respuesta.notaria;

      setValue("estado", respuestaNotaria?.estado ?? "");
      setValue("numNotaria", respuestaNotaria?.notaria ?? "");
      setValue("nombreNotario", respuestaNotaria?.nombreNotario ?? "");
      setValue("abogado", respuestaNotaria?.abogado ?? "");
      setValue("telefono", respuestaNotaria?.telefono ?? "");
      setValue("direccion", respuestaNotaria?.direccion ?? "");

      const agua = respuestaNotaria?.certificados?.find(certificado => certificado.idCatCertificados === 1);
      const predio = respuestaNotaria?.certificados?.find(certificado => certificado.idCatCertificados === 2);
      const libreGravamen = respuestaNotaria?.certificados?.find(certificado => certificado.idCatCertificados === 3);
      const constanciaZonificacion = respuestaNotaria?.certificados?.find(certificado => certificado.idCatCertificados === 4);

      setValue("certificadoAgua", agua?.estatus);
      setValue("fechaAgua", formatoFechaIsoADma(agua?.fechaCertificado ?? ""));
      setValue("predio", predio?.estatus);
      setValue("fechaPredio", formatoFechaIsoADma(predio?.fechaCertificado ?? ""));
      setValue("libreGravamen", libreGravamen?.estatus);
      setValue("fechaLibreGravamen", formatoFechaIsoADma(libreGravamen?.fechaCertificado ?? ""));
      setValue("constanciaZonificacion", constanciaZonificacion?.estatus);
      setValue("fechaZonificacion", formatoFechaIsoADma(constanciaZonificacion?.fechaCertificado ?? ""));

      setNuevoRegistro(false);
    }else{
      setValue("estado", "");
      setValue("numNotaria", "");
      setValue("nombreNotario", "");
      setValue("abogado", "");
      setValue("telefono", "");
      setValue("direccion", "");

      setValue("certificadoAgua", null);
      setValue("fechaAgua", "");
      setValue("predio", null);
      setValue("fechaPredio", "");
      setValue("libreGravamen", null);
      setValue("fechaLibreGravamen", "");
      setValue("constanciaZonificacion", null);
      setValue("fechaZonificacion", "");

      setNuevoRegistro(true);
    }
  }

  async function obtNotas() {
    const peticionNota = await post("getNotas", {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idPantallaActual: 11
    });

    if(peticionNota?.statuscode === 200){
      setValue("comentariosNotaria", peticionNota.infoNotas.nota1);
      setNuevoComentario(false);
    }else{
      setValue("comentariosNotaria", "");
      setNuevoComentario(true);
    }
  }

  useEffect(() => {
    obtDatosNotaria();
    obtNotas();
  }, [idCuestionario, idResPrecalifica]);

  return(
    <div className="cont_flex cont-form">
      <form onSubmit={handleSubmit(guardarDatos)}>
        <div className="header">
          <h3>Datos de la notaría</h3>
        </div>

        <div className="col3 campo-form">
          <label>Estado</label>
          <input
            type="text"
            maxLength={50}
            autoComplete="off"
            {...register("estado", { required: false })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          />
          {errors.estado?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Notaría</label>
          <input
            type="text"
            {...register("numNotaria", { required: false })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
          />
          {errors.numNotaria?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Nombre del notario</label>
          <input
            type="text"
            maxLength={50}
            autoComplete="off"
            {...register("nombreNotario", { required: false })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          />
          {errors.nombreNotario?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>

        <div className="col3 campo-form">
          <label>Abogado</label>
          <input
            type="text"
            maxLength={50}
            autoComplete="off"
            {...register("abogado", { required: false })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "");
            }}
          />
          {errors.abogado?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Telefono</label>
          <input
            type="tel"
            minLength={10}
            maxLength={10}
            autoComplete="off"
            {...register("telefono", { required: false, validate: telefono => telefono === "" ? true :  ruleNoTelefono(telefono) })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
          />
          {errors.telefono?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.telefono?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>
        <div className="col3 campo-form">
          <label>Dirección</label>
          <input
            type="text"
            maxLength={200}
            autoComplete="off"
            {...register("direccion", { required: false })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ# ]/g, "");
            }}
          />
          {errors.direccion?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>

        <br/><br/>
        <hr/>

        <div className="header">
          <h3>Certificados</h3>
        </div>

        <div className="col3 campo-form estado">
          <label>Certificado de agua</label>
          <select
            {...register("certificadoAgua", { required: false })}
            defaultValue={null}
          >
            <option value={null} disabled>
              Seleccione una opción...
            </option>
            <option value={2}>
              Aceptado
            </option>
            <option value={3}>
              No aceptado
            </option>
          </select>
          {errors.certificadoAgua?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Fecha agua</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("fechaAgua", {required: false, validate: fecha => fecha === "" ? true : ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.fechaAgua?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.fechaAgua?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>

        <div className="col3 campo-form estado">
          <label>Predio</label>
          <select
            {...register("predio", { required: false })}
            defaultValue={null}
          >
            <option value={null} disabled>
              Seleccione una opción...
            </option>
            <option value={2}>
              Aceptado
            </option>
            <option value={3}>
              No aceptado
            </option>
          </select>
          {errors.predio?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Fecha predio</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("fechaPredio", {required: false, validate: fecha => fecha === "" ? true : ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.fechaPredio?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.fechaPredio?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>

        <div className="col3 campo-form estado">
          <label>Libre gravamen</label>
          <select
            {...register("libreGravamen", { required: false })}
            defaultValue={null}
          >
            <option value={null} disabled>
              Seleccione una opción...
            </option>
            <option value={2}>
              Aceptado
            </option>
            <option value={3}>
              No aceptado
            </option>
          </select>
          {errors.libreGravamen?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Fecha libre gravamen</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("fechaLibreGravamen", {required: false, validate: fecha => fecha === "" ? true : ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.fechaLibreGravamen?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.fechaLibreGravamen?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>

        <div className="col3 campo-form estado">
          <label>Constancia de zonificación</label>
          <select
            {...register("constanciaZonificacion", { required: false })}
            defaultValue={null}
          >
            <option value={null} disabled>
              Seleccione una opción...
            </option>
            <option value={2}>
              Aceptado
            </option>
            <option value={3}>
              No aceptado
            </option>
          </select>
          {errors.constanciaZonificacion?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Fecha zonificación</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("fechaZonificacion", {required: false, validate: fecha => fecha === "" ? true : ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.fechaZonificacion?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.fechaZonificacion?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>

        <br/><br/>
        
        <div>
          <label>Comentarios:</label>

          <textarea
            {...register("comentariosNotaria", { required: false })}
            className="width100"
            rows={5}
            maxLength={200}
          ></textarea>
        </div>
        <br/>

        <button 
          type="submit" 
          className="btn btn_morado"
        >
          Guardar
        </button>

        <button 
          type="button" 
          className="btn btn_bco"
          onClick={enviarNotaria}
        >
          Enviar al cliente
        </button>

        <br/><br/>

      </form>
    </div>
  );
}

export function FormularioFormalizacion({ idCuestionario, idResPrecalifica, alCompletar = () => {} }){
  const [nuevoRegistro, setNuevoRegistro] = useState(true);
  const [nuevoComentario, setNuevoComentario] = useState(true);
  const [proyectoEscritura, setProyectoEscritura] = useState({});
  
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      valorPropiedad: "$ 0.00",
      enganche: "$ 0.00",
      impuestosAdquisicionInmuebles: "$ 0.00",
      valorCredito: "$ 0.00",
      gastosRegistro: "$ 0.00",
      honorariosNotaria: "$ 0.00",
      gastosEscrituracion: "$ 0.00",
      costoTotalOperacion: "$ 0.00",
      total: "$ 0.00",
      montoFirmar: "$ 0.00"

    }
  });

  async function subirArchivo(archivo,setDocumento){
    const peticionArchivoS3 = await post("S3/subirArchivoUrl", {
      nombreArchivo: archivo.name,
    });

    if(peticionArchivoS3?.statuscode === 200){
      const peticionS3 = await putS3(peticionArchivoS3.respuesta.url, archivo);

      if(peticionS3?.ok){
        const peticionSolicitud = await post("subirDocumento", {
          rutaArchivo: peticionArchivoS3.respuesta.rutaArchivo,
          idCatDocumento: 23, // Proyecto escritura
          idCuestionario: idCuestionario,
          idResPrecalifica: idResPrecalifica
        });

        if (peticionSolicitud?.statuscode === 200) {
          const archivo = {
            id: peticionSolicitud.respuesta.documento?.idDocsTramite,
            nombreArchivo: peticionSolicitud.respuesta.documento?.archivo?.split("/").pop(),
            rutaArchivo: peticionSolicitud.respuesta.documento?.archivo,
          }
          setDocumento(archivo);
        }else{
          Swal.fire({
            title: "No se pudo subir el documento",
            text: "Intente más tarde",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  }

  async function eliminarArchivo(idDocsTramite, setDocumento){
    const peticionEliminarArchivo = await deleteDocEndpoint("borrarDocumentoEspecifico", {
      idDocsTramite: idDocsTramite,
      idCatDocumento: 23, // Proyecto de escritura
      idCuestionario: idCuestionario,
      idResPrecalifica: idResPrecalifica
    });

    if (peticionEliminarArchivo.statuscode === 200) {
      setDocumento({});

      Swal.fire({
        title: "Documento borrado correctamente",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    } else {
      Swal.fire({
        title: "No se pudo borrar el documento",
        text: "Intente más tarde",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  const enviar = async (data) => {
    const datosFormalizacion = {
      valorPropiedad: Number(data.valorPropiedad.replace(/[^0-9.]/g, "")),
      enganche: Number(data.enganche.replace(/[^0-9.]/g, "")),
      impuestosAdquisicionInmuebles: Number(data.impuestosAdquisicionInmuebles.replace(/[^0-9.]/g, "")),
      valorCredito: Number(data.valorCredito.replace(/[^0-9.]/g, "")),
      gastosRegistro: Number(data.gastosRegistro.replace(/[^0-9.]/g, "")),
      honorariosNotaria: Number(data.honorariosNotaria.replace(/[^0-9.]/g, "")),
      gastosEscrituracion: Number(data.gastosEscrituracion.replace(/[^0-9.]/g, "")),
      costoTotalOperacion: Number(data.costoTotalOperacion.replace(/[^0-9.]/g, "")),
      total: Number(data.total.replace(/[^0-9.]/g, "")),

      fechaSolicitudBanco: formatoFechaDmaAIso(data.fechaSolicitudBanco),
      fechaEnvioNotaria: formatoFechaDmaAIso(data.fechaEnvioNotaria),
      fechaFirma: formatoFechaDmaAIso(data.fechaFirma),
      fechaConfirmacion: formatoFechaDmaAIso(data.fechaConfirmacion),
      montoFirmar: Number(data.montoFirmar.replace(/[^0-9.]/g, "")),
      lugar: data.lugar,
      hora: `${data.hora}:00`,
    }

    const params = {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      valorPropiedad: datosFormalizacion.valorPropiedad,
      impuestoAi: datosFormalizacion.impuestosAdquisicionInmuebles,
      gastoRegistro: datosFormalizacion.gastosRegistro,
      honorarioNotaria: datosFormalizacion.honorariosNotaria,
      totalGastoEscrituracion: datosFormalizacion.gastosEscrituracion,
      totalOperacion: datosFormalizacion.costoTotalOperacion,
      enganche: datosFormalizacion.enganche,
      valorCredito: datosFormalizacion.valorCredito,
      total: datosFormalizacion.total,
      "fechaSolicitudBanco": datosFormalizacion.fechaSolicitudBanco,
      "fechaEnvioNotaria": datosFormalizacion.fechaEnvioNotaria,
      "fechaFirma": datosFormalizacion.fechaFirma,
      "fechaConfirmacion": datosFormalizacion.fechaConfirmacion,
      hora: datosFormalizacion.hora,
      montoFirmar: datosFormalizacion.montoFirmar,
      lugar: datosFormalizacion.lugar
    }

    const paramsNota = {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idCatPantallas: 6,
      nombre: "Formalización y firma",
      nota1: data.comentariosFormalizacion
    }

    const peticionNotaria = nuevoRegistro ? await post("addFormalizacionFirma", params) 
      : await put("updateFormalizacionFirma", params);

    if(peticionNotaria?.statuscode === 200){
      const peticionComentario = nuevoComentario ? await post("addNota", paramsNota) 
        : await put("actualizarNota", paramsNota);

      if(peticionComentario?.statuscode === 200){
        Swal.fire({
          icon: "success",
          title: "Datos guardados correctamente",
          confirmButtonText: "Aceptar",
        });

        if(nuevoComentario){
          setNuevoComentario(false);
        }
      }else{
        Swal.fire({
          icon: "error",
          title: "Error al guardar comentario",
          text: "Inténtalo nuevamente más tarde.",
          confirmButtonText: "Aceptar",
        });
      }
      
      if(nuevoRegistro){
        await post("finalizarProceso", {
          idResPrecalifica: idResPrecalifica,
          idCuestionario: idCuestionario,
        });
        setNuevoRegistro(false);
      }
      alCompletar();
    }else{
      Swal.fire({
        icon: "error",
        title: "Error al guardar datos",
        text: "Inténtalo nuevamente más tarde.",
        confirmButtonText: "Aceptar",
      });
    }
  }

  async function obtDatosFormalizacion(){
    const peticionFormalizacion = await get("getFormalizacionFirma", idResPrecalifica);

    if(peticionFormalizacion?.statuscode === 200){
      const respuestaFormalizacion = peticionFormalizacion.formalizacionFirma[0];

      setValue("valorPropiedad", formatoMoneda(respuestaFormalizacion?.valorPropiedad ?? 0));
      setValue("enganche", formatoMoneda(respuestaFormalizacion?.enganche ?? 0));
      setValue("impuestosAdquisicionInmuebles", formatoMoneda(respuestaFormalizacion?.impuestoAi ?? 0));
      setValue("valorCredito", formatoMoneda(respuestaFormalizacion?.valorCredito ?? 0));
      setValue("gastosRegistro", formatoMoneda(respuestaFormalizacion?.gastoRegistro ?? 0));
      setValue("honorariosNotaria", formatoMoneda(respuestaFormalizacion?.honorarioNotaria ?? 0));
      setValue("gastosEscrituracion", formatoMoneda(respuestaFormalizacion?.totalGastoEscrituracion ?? 0));
      setValue("costoTotalOperacion", formatoMoneda(respuestaFormalizacion?.totalOperacion ?? 0));
      setValue("total", formatoMoneda(respuestaFormalizacion?.total ?? 0));

      setValue("fechaSolicitudBanco", formatoFechaIsoADma(respuestaFormalizacion?.fechaSolicitudBanco ?? ""));
      setValue("fechaEnvioNotaria", formatoFechaIsoADma(respuestaFormalizacion?.fechaEnvioNotaria ?? ""));
      setValue("fechaFirma", formatoFechaIsoADma(respuestaFormalizacion?.fechaFirma ?? ""));
      setValue("fechaConfirmacion", formatoFechaIsoADma(respuestaFormalizacion?.fechaConfirmacion ?? ""));
      setValue("montoFirmar", formatoMoneda(respuestaFormalizacion?.montoFirmar ?? 0));
      setValue("lugar", respuestaFormalizacion?.lugar);
      setValue("hora", respuestaFormalizacion?.hora.match(/^\d{2}:\d{2}/)[0]);
      
      setNuevoRegistro(false);
    }else{

      setValue("valorPropiedad", formatoMoneda(0));
      setValue("enganche", formatoMoneda(0));
      setValue("impuestosAdquisicionInmuebles", formatoMoneda(0));
      setValue("valorCredito", formatoMoneda(0));
      setValue("gastosRegistro", formatoMoneda(0));
      setValue("honorariosNotaria", formatoMoneda(0));
      setValue("gastosEscrituracion", formatoMoneda(0));
      setValue("costoTotalOperacion", formatoMoneda(0));
      setValue("total", formatoMoneda(0));

      setValue("fechaSolicitudBanco", "");
      setValue("fechaEnvioNotaria", "");
      setValue("fechaFirma", "");
      setValue("fechaConfirmacion", "");
      setValue("montoFirmar", formatoMoneda(0));
      setValue("lugar", "");
      setValue("hora", "");

      setNuevoRegistro(true);
    }
  }

  async function obtNotas() {
    const peticionNota = await post("getNotas", {
      idResPrecalifica: idResPrecalifica,
      idCuestionario: idCuestionario,
      idPantallaActual: 6
    });

    if(peticionNota?.statuscode === 200){
      setValue("comentariosFormalizacion", peticionNota.infoNotas.nota1);
      setNuevoComentario(false);
    }else{
      setValue("comentariosFormalizacion", "");
      setNuevoComentario(true);
    }
  }

  async function obtProyectoEscritura(){
    const peticionSolicitud = await post("obtInfoDocumentacionTramite", {
      idCatDocumento: 23, // Proyecto escritura
      idCuestionario: idCuestionario,
      idResPrecalifica: idResPrecalifica,
    });

    if(peticionSolicitud?.statuscode === 200){
      const archivo = {
        id: peticionSolicitud.respuesta.documentacion?.[0]?.idDocsTramite,
        nombreArchivo: peticionSolicitud.respuesta.documentacion?.[0]?.archivo.split("/").pop(),
        rutaArchivo: peticionSolicitud.respuesta.documentacion?.[0]?.archivo,
      }
      setProyectoEscritura(archivo);
    }else{
      setProyectoEscritura({});
    }
  }

  useEffect(() => {
    obtDatosFormalizacion();
    obtNotas();
    obtProyectoEscritura();
  }, [idCuestionario, idResPrecalifica]);

  return(
    <div className="cont_flex cont-form">
      <form onSubmit={handleSubmit(enviar)}>
        <div className="header">
          <h3>Formalización y firma</h3>
        </div>

        <div className="grid-160px-1fr">
          <div>
            <div className="col3 campo-form width100">
              <label>Valor propiedad</label>
              <input
                type="text"
                autoComplete="off"
                {...register("valorPropiedad", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}               
              />
              {errors.valorPropiedad?.type === "validate" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form width100">
              <label>Impuestos sobre adquisición de inmuebles</label>
              <input
                type="text"
                autoComplete="off"
                {...register("impuestosAdquisicionInmuebles", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}               
              />
              {errors.impuestosAdquisicionInmuebles?.type === "validate" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form width100">
              <label>Gastos de registro</label>
              <input
                type="text"
                autoComplete="off"
                {...register("gastosRegistro", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}               
              />
              {errors.gastosRegistro?.type === "validate" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form width100">
              <label>Honorarios notaria</label>
              <input
                type="text"
                autoComplete="off"
                {...register("honorariosNotaria", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}               
              />
              {errors.honorariosNotaria?.type === "validate" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>

            <div className="col3 campo-form width100">
              <label>Total gastos escrituración</label>
              <input
                type="text"
                autoComplete="off"
                {...register("gastosEscrituracion", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}               
              />
              {errors.gastosEscrituracion?.type === "validate" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form width100">
              <label>Costo total de la operación</label>
              <input
                type="text"
                autoComplete="off"
                {...register("costoTotalOperacion", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}               
              />
              {errors.costoTotalOperacion?.type === "validate" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
          </div>

          <div>
            <div className="col3 campo-form width100">
              <label>Enganche</label>
              <input
                type="text"
                autoComplete="off"
                {...register("enganche", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}               
              />
              {errors.enganche?.type === "validate" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form width100">
              <label>Valor crédito</label>
              <input
                type="text"
                autoComplete="off"
                {...register("valorCredito", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}               
              />
              {errors.valorCredito?.type === "validate" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
            <div className="col3 campo-form width100">
              <label>Total</label>
              <input
                type="text"
                autoComplete="off"
                {...register("total", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
                onInput={(e) => {
                  e.target.value = textoAMoneda(e.target.value);
                }}
                onBlur={(e) => {
                  e.target.value = agergarDecimales(e.target.value);
                }}               
              />
              {errors.total?.type === "validate" && (
                <span className="span">Este campo es requerido</span>
              )}
            </div>
          </div>
        </div>

        <br/><br/>
        <hr/>

        <div className="header">
          <h3>Firma</h3>
        </div>

        <div className="col3 campo-form">
          <label>Fecha solicitud del banco</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("fechaSolicitudBanco", {required: true, validate: fecha => ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.fechaSolicitudBanco?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.fechaSolicitudBanco?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>
        <div className="col3 campo-form">
          <label>Fecha de envío notaría</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("fechaEnvioNotaria", {required: true, validate: fecha => ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.fechaEnvioNotaria?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.fechaEnvioNotaria?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>
        <div className="col3 campo-form">
          <label>Fecha de firma</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("fechaFirma", {required: true, validate: fecha => ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.fechaFirma?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.fechaFirma?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>

        <div className="col3 campo-form">
          <label>Fecha de confirmación</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="dd/mm/aaaa"
            {...register("fechaConfirmacion", {required: true, validate: fecha => ruleNacimiento(fecha)})}
            onInput={(e) =>{
              let fecha = e.target.value.replace(/\D/g, "");
              // Insertar las diagonales en la posición correcta
              if (fecha.length > 2 && fecha.length < 5) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
              } else if (fecha.length > 4) {
                fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
              }
              e.target.value = fecha;
            }}
          />
          {errors.fechaConfirmacion?.type === "required" ? (
            <span className="span">Este campo es requerido</span>
          ) : (
            errors.fechaConfirmacion?.type === "validate" && (
              <span className="span">Formato no válido</span>
            )
          )}
        </div>
        <div className="col3 campo-form">
          <label>Monto a firmar</label>
          <input
            type="text"
            autoComplete="off"
            {...register("montoFirmar", {validate: (valor) => Number(valor.replace(/[^0-9.]/g, "")) > 0})}
            onInput={(e) => {
              e.target.value = textoAMoneda(e.target.value);
            }}
            onBlur={(e) => {
              e.target.value = agergarDecimales(e.target.value);
            }}               
          />
          {errors.montoFirmar?.type === "validate" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <div className="col3 campo-form">
          <label>Lugar</label>
          <input
            type="text"
            maxLength={200}
            autoComplete="off"
            {...register("lugar", { required: true })}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ# ]/g, "");
            }}
          />
          {errors.lugar?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>

        <div className="col3 campo-form">
          <label>Hora</label>
          <input
            type="time"
            autoComplete="off"
            {...register("hora", { required: true })}
          />
          {errors.hora?.type === "required" && (
            <span className="span">Este campo es requerido</span>
          )}
        </div>
        <br/><br/>
        <hr/>

        <div className="cont2cards">
          <FullCardArchivo
            titulo="Sube tu archivo"
            descripcion="Envía el proyecto de escritura a tu cliente."
            archivo={proyectoEscritura}
            onUpload={(archivo) => subirArchivo(archivo, setProyectoEscritura)}
            onDelete={(idDocsTramite) => eliminarArchivo(idDocsTramite, setProyectoEscritura)}
          />
        </div>

        <div>
          <label>Comentarios:</label>

          <textarea
            {...register("comentariosFormalizacion", {required: false})}
            className="width100"
            rows={5}
            maxLength={200}
          ></textarea>
        </div>
        <br/>
        
        <button 
          type="submit" 
          className="btn btn_morado"
        >
          Guardar
        </button>

        <br/><br/>

      </form>
    </div>
  );
}